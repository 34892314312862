import axios from 'axios'

const Api = {
  getPeiScorecard: queryObject => axios.put('/pei/score/brand', queryObject),
  getAllPei: queryObject => axios.put('pei/score/competitor', queryObject),
  getPEIBySource: queryObject => axios.put('pei/score/competitor/source', queryObject),
  getPEIByCategory: queryObject => axios.put('pei/score/competitor/category', queryObject)
}

export default Api
