import {
  RECEIVE_PEI_TREND_BRAND,
  RECEIVE_PEI_TREND_COMPETITOR,
  RECEIVE_THEME_TREND_BRAND,
  RECEIVE_THEME_TREND_COMPETITOR,
  RECEIVE_RISKS_PRODUCT,
  RECEIVE_THEME_FREQUENCY,
  RECEIVE_TOPIC_FREQUENCY,
  SET_SENTIMENT_TREND_LOADING_STATE,
  SET_TOPIC_FREQUENCY_LOADING_STATE,
} from './constants/ActionTypes'

const INITIAL_STATE = {
  peiTrendBrand: [],
  peiTrendCompetitor: [],
  themeTrendBrand: [],
  themeTrendCompetitor: [],
  risksProduct: [],
  themeFrequency: [],
  topicFrequency: {},
  isLoading: false,
  topicFrequencyIsLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PEI_TREND_BRAND:
      return { ...state, peiTrendBrand: action.payload }
    case RECEIVE_PEI_TREND_COMPETITOR:
      return { ...state, peiTrendCompetitor: action.payload }
    case RECEIVE_THEME_TREND_BRAND:
      return { ...state, themeTrendBrand: action.payload }
    case RECEIVE_THEME_TREND_COMPETITOR:
      return { ...state, themeTrendCompetitor: action.payload }
    case RECEIVE_RISKS_PRODUCT:
      return { ...state, risksProduct: action.payload }
    case RECEIVE_THEME_FREQUENCY:
      return { ...state, themeFrequency: action.payload }
    case RECEIVE_TOPIC_FREQUENCY:
      return { ...state, topicFrequency: action.payload }
    case SET_SENTIMENT_TREND_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_TOPIC_FREQUENCY_LOADING_STATE:
      return { ...state, topicFrequencyIsLoading: action.payload }
    default:
      return state
  }
}
