const prefix = 'quality_control'

export const REQUEST_PRODUCTS = `${prefix}/request_products`
export const RECEIVE_PRODUCTS = `${prefix}/receive_products`
export const RECEIVE_AVG_SENT_SCORE = `${prefix}/receive_avg_sent_score`

export const SET_PRODUCT_LOADING_STATE = `${prefix}/set_product_loading_state`
export const SET_RANGE = `${prefix}/set_range`
export const SET_PAGINATION = `${prefix}/set_pagination`
export const SET_QUALITY_THRESHOLD = `${prefix}/set_quality_threshold`
export const SET_FILTER_SELECTION = `${prefix}/set_filter_selection`

export const REQUEST_ALL_PRODUCTS = `${prefix}/request_all_products`
export const RECEIVE_ALL_PRODUCTS = `${prefix}/receive_all_products`

export const RECEIVE_SORT_FILTERS = `${prefix}/receive_sort_filters`
