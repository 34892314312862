import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Linechart from './linechart'
import { linechartColor } from '../../common'

const ThemeCompetitorLinechart = ({ data, text, height, showLegend }) => {
  const themeTrend = data
  const series = []
  const { brand_theme_sentiment_trend } = themeTrend[0]
  const brandsObj = _.groupBy(brand_theme_sentiment_trend, 'brand_id')
  let time = []
  Object.keys(brandsObj).forEach(e => {
    const brand_pei_trend = brandsObj[e]
    const arr = _.sortBy(brand_pei_trend, ['review_quarter'])
    time = time.concat(_.map(arr, 'review_quarter'))
  })
  time = _.uniq(time.sort())

  const brands = JSON.parse(window.localStorage.getItem('brand'))
  brands.forEach(e => {
    const brandId = _.keys(e)[0]
    const brandName = e[brandId]
    const brand_pei_trend = brandsObj[parseInt(brandId, 10)]
    if (!brand_pei_trend) return
    time.forEach(t => {
      if (_.findIndex(brand_pei_trend, ['review_quarter', t]) === -1) {
        brand_pei_trend.push({ review_quarter: t, brand_pei: null })
      }
    })
    const arr = _.sortBy(brand_pei_trend, ['review_quarter'])

    series.push({
      name: brandName,
      data: _.map(arr, 'brand_theme_sentiment'),
      type: 'line',
    })
  })

  const props = {}
  props.height = height
  props.series = [...series]
  props.legend = {
    show: showLegend,
    data: [window.localStorage.getItem('clientName')],
  }
  props.text = text
  props.xAxisData = _.map(_.uniq(time.sort()), o => `${_.toInteger(o / 100) % 100}-Q${o % 10}`)
  props.color = [...linechartColor]
  return <Linechart {...props} />
}

ThemeCompetitorLinechart.defaultProps = {
  height: 300,
  showLegend: true,
}

ThemeCompetitorLinechart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  text: PropTypes.string.isRequired,
  height: PropTypes.number,
  showLegend: PropTypes.bool,
}

export default ThemeCompetitorLinechart
