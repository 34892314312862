import { all } from 'redux-saga/effects'
import { saga as competitiveAdvantageSaga } from './competitive-advantage'
import { saga as facetedSaga } from './faceted-explorer'
import { saga as forumSaga } from './forum'
import { saga as homepageSaga } from './homepage'
import { saga as insightsSaga } from './insights'
import { saga as marketplaceTrendSaga } from './marketplace-trend'
import { saga as peiScorecardSaga } from './pei-scorecard'
import { saga as productSaga } from './product'
import { saga as qualityControlSaga } from './quality-control'
import { saga as reviewSaga } from './review'
import { saga as riskOpportunitySaga } from './risk-opportunity'
import { saga as sentimentTrendSaga } from './sentiment-trend'
import { saga as userSaga } from './user'
import { saga as varianceSaga } from './variance'

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    competitiveAdvantageSaga(),
    facetedSaga(),
    forumSaga(),
    homepageSaga(),
    insightsSaga(),
    marketplaceTrendSaga(),
    peiScorecardSaga(),
    productSaga(),
    qualityControlSaga(),
    reviewSaga(),
    riskOpportunitySaga(),
    sentimentTrendSaga(),
    userSaga(),
    varianceSaga(),
  ])
}
