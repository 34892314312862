import axios from 'axios'

const Api = {
  getFacets: queryObject => axios.put('/views/insightminer', queryObject),
  saveFacets: queryObject => axios.post('/reports/insight', queryObject),
  getSavedFacets: queryObject => axios.put('/reports/insight', queryObject),
  deleteFacets: queryObject =>
    axios.delete('/reports/insight', {
      data: queryObject,
    }),
}

export default Api
