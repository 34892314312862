import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Button, Tooltip } from 'antd'

class TagForm extends Component {
  constructor(props) {
    super(props)

    this.state = { addDisplay: 'none', deleteDisplay: 'none' }

    this.handelAddAllTag = this.handelAddAllTag.bind(this)
    this.handelDeleteAllTag = this.handelDeleteAllTag.bind(this)
    this.handleOnClickAdd = this.handleOnClickAdd.bind(this)
    this.handleOnClickDelete = this.handleOnClickDelete.bind(this)
    this.handleAddReset = this.handleAddReset.bind(this)
    this.handleDeleteReset = this.handleDeleteReset.bind(this)
  }

  static getDerivedStateFromProps(props) {
    if (_.isEmpty(props.selectedReviews)) {
      return { addDisplay: 'none', deleteDisplay: 'none' }
    }
    return null
  }

  handelAddAllTag() {
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        this.props.addAllTag(values.tag)
        this.props.form.resetFields()
        this.setState({ addDisplay: 'none' })
      }
    })
  }

  handelDeleteAllTag() {
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        this.props.deleteAllTag(values.tag)
        this.props.form.resetFields()
        this.setState({ deleteDisplay: 'none' })
      }
    })
  }

  handleAddReset = () => {
    this.props.form.resetFields()
    this.setState({ addDisplay: 'none' })
  }

  handleDeleteReset = () => {
    this.props.form.resetFields()
    this.setState({ deleteDisplay: 'none' })
  }

  handleOnClickAdd = () => {
    this.setState({ addDisplay: 'inline' })
    this.setState({ deleteDisplay: 'none' })
  }

  handleOnClickDelete = () => {
    this.setState({ deleteDisplay: 'inline' })
    this.setState({ addDisplay: 'none' })
  }

  render() {
    const FormItem = Form.Item
    const { getFieldDecorator } = this.props.form
    const { addDisplay, deleteDisplay } = this.state
    const { selectedReviews, path } = this.props
    const reviewTextArr = _.map(selectedReviews, (o, idx) => `${idx + 1}. ${o.reviewText}`).join('%0D%0A')
    return (
      <div style={{ marginBottom: 10, borderBottom: '1px solid #eee' }}>
        {_.isEmpty(selectedReviews) ? null : (
          <Form layout="inline" style={{ paddingBottom: 10 }}>
            <FormItem>
              <Tooltip title="Add Tags">
                <Button shape="circle" icon="tags" onClick={this.handleOnClickAdd} />
              </Tooltip>
            </FormItem>
            <div style={{ display: addDisplay }}>
              <FormItem>
                {getFieldDecorator('tag', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your tag!',
                    },
                  ],
                })(<Input placeholder="Enter tag" />)}
              </FormItem>
              <FormItem>
                <FormItem>
                  <Button type="primary" onClick={this.handelAddAllTag}>
                    Add
                  </Button>
                  <Button style={{ marginLeft: 16 }} onClick={this.handleAddReset}>
                    Cancel
                  </Button>
                </FormItem>
              </FormItem>
            </div>
            <FormItem>
              <Tooltip title="Remove Tags">
                <Button shape="circle" icon="tags-o" onClick={this.handleOnClickDelete} />
              </Tooltip>
            </FormItem>
            <div style={{ display: deleteDisplay }}>
              <FormItem>
                {getFieldDecorator('tag', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your tag!',
                    },
                  ],
                })(<Input placeholder="Enter tag" />)}
              </FormItem>
              <FormItem>
                <FormItem>
                  <Button type="primary" onClick={this.handelDeleteAllTag}>
                    Delete
                  </Button>
                  <Button style={{ marginLeft: 16 }} onClick={this.handleDeleteReset}>
                    Cancel
                  </Button>
                </FormItem>
              </FormItem>
            </div>
            {!_.isEqual(path, 'retell') ? null : (
              <FormItem>
                <Tooltip title="Delete Reviews">
                  <Button shape="circle" icon="delete" onClick={this.props.deleteAllReview} />
                </Tooltip>
              </FormItem>
            )}
            <FormItem>
              <Tooltip title="Email Selected Reviews">
                <a href={`mailto:insert_recipient_address@address.com?subject=${_.capitalize(path)} Explorer From HearFul 2.0&body=Reviews Reference:%0D%0A${reviewTextArr}`}>
                  <Button shape="circle" icon="mail" />
                </a>
              </Tooltip>
            </FormItem>
            <FormItem>
              <Tooltip title="Unselect Reviews">
                <Button shape="circle" icon="reload" onClick={this.props.reloadReview} />
              </Tooltip>
            </FormItem>
            <FormItem>
              <span>{!_.isEmpty(selectedReviews) ? `Selected ${selectedReviews.length} reviews` : ''}</span>
            </FormItem>
          </Form>
        )}
      </div>
    )
  }
}

TagForm.defaultProps = {
  deleteAllReview: () => {},
}

TagForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  addAllTag: PropTypes.func.isRequired,
  deleteAllTag: PropTypes.func.isRequired,
  deleteAllReview: PropTypes.func,
  reloadReview: PropTypes.func.isRequired,
  selectedReviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  path: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {}
}

TagForm = Form.create()(TagForm)
export { TagForm }
export default connect(mapStateToProps, {})(TagForm)
