import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  REQUEST_ALL_PRODUCTS,
  RECEIVE_ALL_PRODUCTS,
  REQUEST_SORT_FILTERS,
  RECEIVE_SORT_FILTERS,
  REQUEST_ADD_CUSTOM_ATTRIBUTE,
  REQUEST_DELETE_CUSTOM_ATTRIBUTE,
  REQUEST_ADD_PRODUCT_ATTR,
  RECEIVE_ADD_PRODUCT_ATTRS,
  RECEIVE_ADD_PRODUCT_ATTR,
  REQUEST_DELETE_PRODUCT_ATTR,
  RECEIVE_DELETE_PRODUCT_ATTR,
  RECEIVE_DELETE_PRODUCT_ATTRS,
  REQUEST_ADD_PRODUCTS_ATTR,
  REQUEST_DELETE_PRODUCTS_ATTR,
  SET_PRODUCT_LOADING_STATE,
  SET_RANGE,
  SET_PAGINATION,
  SET_SEARCH_PARAMS,
  SET_PRODUCT_ATTRS,
  SET_DELETED_PRODUCT_ATTR_ID_ARR,
  SET_SELECTED_PAGINATION,
  SET_IS_PRODUCT_DETAILS_HIDDEN,
} from './constants/ActionTypes'

/**
 * Action creator to fetch products
 * @param  {object} params {
 *           TBD
 * }
 * @return {object} plain object
 */
export const getProducts = (params, additionalParams) => ({
  type: REQUEST_PRODUCTS,
  payload: {
    params,
    additionalParams,
  },
})

export const storeSortFilters = (sortAttribute, sortOrder) => ({
  type: RECEIVE_SORT_FILTERS,
  payload: {
    sortAttribute,
    sortOrder,
  },
})

export const storeProducts = products => ({
  type: RECEIVE_PRODUCTS,
  payload: products,
})

export const getAllProducts = (params, additionalParams, resolve, reject) => ({
  type: REQUEST_ALL_PRODUCTS,
  payload: {
    params,
    additionalParams,
    resolve,
    reject,
  },
})

export const storeAllProducts = products => ({
  type: RECEIVE_ALL_PRODUCTS,
  payload: products,
})

export const addCustomAttr = (customAttr, attributeType = 'private') => ({
  type: REQUEST_ADD_CUSTOM_ATTRIBUTE,
  payload: {
    customAttr,
    attributeType,
  },
})

export const deleteCustomAttr = (customAttrId, resolve, reject) => ({
  type: REQUEST_DELETE_CUSTOM_ATTRIBUTE,
  payload: {
    customAttrId,
    resolve,
    reject,
  },
})

export const setProductLoadingState = isLoading => ({
  type: SET_PRODUCT_LOADING_STATE,
  payload: isLoading,
})

export const storeProductCount = countArr => ({
  type: SET_RANGE,
  payload: countArr,
})

export const addProductAttr = (index, productIdAndExternalId, customAttr) => ({
  type: REQUEST_ADD_PRODUCT_ATTR,
  payload: {
    index,
    productIdAndExternalId,
    customAttr,
  },
})

export const addProductsAttr = (indexArr, productIdAndExternalId, customAttr, attributeId) => ({
  type: REQUEST_ADD_PRODUCTS_ATTR,
  payload: {
    indexArr,
    productIdAndExternalId,
    customAttr,
    attributeId,
  },
})

export const storeProductAttr = (index, customAttr) => ({
  type: RECEIVE_ADD_PRODUCT_ATTR,
  payload: {
    index,
    customAttr,
  },
})

export const storeProductAttrs = productAttrs => ({
  type: RECEIVE_ADD_PRODUCT_ATTRS,
  payload: productAttrs,
})

export const deleteProductAttr = (index, productAttrId) => ({
  type: REQUEST_DELETE_PRODUCT_ATTR,
  payload: {
    index,
    productAttrId,
  },
})

export const deleteProductsAttr = (indexArr, productAttrIdArr, productIdsArr, attributeId) => ({
  type: REQUEST_DELETE_PRODUCTS_ATTR,
  payload: {
    indexArr,
    productAttrIdArr,
    productIdsArr,
    attributeId,
  },
})

export const storeDeleteProductAttrs = deletedProductAttrs => ({
  type: RECEIVE_DELETE_PRODUCT_ATTRS,
  payload: deletedProductAttrs,
})

export const storeDeleteProductAttr = (index, productAttrId) => ({
  type: RECEIVE_DELETE_PRODUCT_ATTR,
  payload: {
    index,
    productAttrId,
  },
})

export const setPagination = (pageNum, pageSize) => ({
  type: SET_PAGINATION,
  payload: {
    pageNum,
    pageSize,
  },
})

export const setSearchParams = searchParams => ({
  type: SET_SEARCH_PARAMS,
  payload: searchParams,
})

export const setProductAttrs = productAttrsObj => ({
  type: SET_PRODUCT_ATTRS,
  payload: productAttrsObj,
})

export const setDeleteProductAttrsId = deleteProductAttrIdArr => ({
  type: SET_DELETED_PRODUCT_ATTR_ID_ARR,
  payload: deleteProductAttrIdArr,
})

export const setSelectedPagination = selectedObj => ({
  type: SET_SELECTED_PAGINATION,
  payload: selectedObj,
})

export const setIsProductDetailsHidden = isHidden => ({
  type: SET_IS_PRODUCT_DETAILS_HIDDEN,
  payload: isHidden,
})
