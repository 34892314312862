import _ from 'lodash'
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { parseRating, buildFilterSelectionObject } from '../insights'
import { storeRiskOpportunity, setRiskOpportunityLoadingState, setCurrentTime, setTimeMode } from './actions'
import { REQUEST_RISK_OPPORTUNITY } from './constants/ActionTypes'
import { buildAdditionalParamsQueryObject, buildRiskOpportunityData, monthComparator, quarterComparator, yearComparator } from './constants/helper'
import Api from './api'

export function* getRiskOpportunity(action) {
  yield put(setRiskOpportunityLoadingState(true))
  const { filterSelection, timeMode, threshold, levelFilter, curTime, levelChangeflag } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const queryObject = buildFilterSelectionObject({
    ...filterSelection,
    review_rating: ratingArr,
  })
  const additionalParamsObject = buildAdditionalParamsQueryObject(timeMode, threshold, levelFilter)
  try {
    const response = yield call(Api.getRiskOpportunity, {
      query_object: {
        ...queryObject.filter_object,
        ...additionalParamsObject.additional_filter,
      },
    })
    if (!_.isEmpty(response.data)) {
      const riskOpportunity = buildRiskOpportunityData(response.data, timeMode)
      const { times, timeArray } = riskOpportunity
      // insert current time period while drill down to next level but no data in this period
      if (levelChangeflag && !_.includes(times, curTime)) {
        times.push(curTime)
        if (timeMode === 'quarter') {
          times.sort(quarterComparator)
        }
        if (timeMode === 'month') {
          times.sort(monthComparator)
        }
        if (timeMode === 'year') {
          times.sort(yearComparator)
        }
        timeArray[curTime] = []
      }
      if (_.isUndefined(curTime) || _.isEqual(curTime, '')) {
        yield put(setCurrentTime(riskOpportunity.times[0]))
      }
      yield put(setTimeMode(timeMode))
      yield put(storeRiskOpportunity(riskOpportunity))
    } else {
      yield put(setTimeMode(timeMode))
      yield put(storeRiskOpportunity({}))
    }
    yield put(setRiskOpportunityLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setRiskOpportunityLoadingState(false))
  }
}

/**
 * watch Saga for actions of risks & opportunities
 * @return {Generator}
 */
export default function* watchRiskOpportunity() {
  yield all([takeLatest(REQUEST_RISK_OPPORTUNITY, getRiskOpportunity)])
}
