const prefix = 'product_explorer'

// accept a post fix to generate action types to avoid naming conflict
export const REQUEST_PRODUCTS = `${prefix}/request_products`
export const RECEIVE_PRODUCTS = `${prefix}/receive_products`

export const REQUEST_ALL_PRODUCTS = `${prefix}/request_all_products`
export const RECEIVE_ALL_PRODUCTS = `${prefix}/receive_all_products`

export const REQUEST_ADD_CUSTOM_ATTRIBUTE = `${prefix}/request_add_custom_attribute`
export const REQUEST_DELETE_CUSTOM_ATTRIBUTE = `${prefix}/request_delete_custom_attribute`

export const REQUEST_ADD_PRODUCT_ATTR = `${prefix}/request_add_product_attr`
export const RECEIVE_ADD_PRODUCT_ATTR = `${prefix}/receive_add_product_attr`
export const RECEIVE_ADD_PRODUCT_ATTRS = `${prefix}/receive_add_product_attrs`
export const REQUEST_DELETE_PRODUCT_ATTR = `${prefix}/request_delete_product_attr`
export const RECEIVE_DELETE_PRODUCT_ATTR = `${prefix}/receive_delete_product_attr`
export const RECEIVE_DELETE_PRODUCT_ATTRS = `${prefix}/request_delete_product_attrs`

export const RECEIVE_SORT_FILTERS = `${prefix}/receive_sort_filters`
export const REQUEST_SORT_FILTERS = `${prefix}/request_sort_filters`

export const REQUEST_ADD_PRODUCTS_ATTR = `${prefix}/request_add_products_attr`
export const REQUEST_DELETE_PRODUCTS_ATTR = `${prefix}/request_delete_products_attr`

export const SET_PRODUCT_LOADING_STATE = `${prefix}/set_product_loading_state`
export const SET_RANGE = `${prefix}/set_range`
export const SET_PAGINATION = `${prefix}/set_pagination`
export const SET_SEARCH_PARAMS = `${prefix}/set_search_params`
export const SET_PRODUCT_ATTRS = `${prefix}/set_product_attrs`
export const SET_DELETED_PRODUCT_ATTR_ID_ARR = `${prefix}/set_deleted_product_attr_id_arr`
export const SET_SELECTED_PAGINATION = `${prefix}/set_selected_pagination`
export const SET_IS_PRODUCT_DETAILS_HIDDEN = `${prefix}/set_is_product_details_hidden`
