import { SET_REVIEW_VARIANCE_LOADING_STATE, RECEIVE_REVIEW_VARIANCE_COUNT, SET_SOURCE_REVIEW_VARIANCE_DATA_STATE } from './constants/ActionTypes'

const INITIAL_STATE = {
  reviewVariance: [],
  isLoading: false,
  isSourceReviewVarianceData: true,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REVIEW_VARIANCE_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case RECEIVE_REVIEW_VARIANCE_COUNT:
      return { ...state, reviewVariance: action.payload }
    case SET_SOURCE_REVIEW_VARIANCE_DATA_STATE:
      return { ...state, isSourceReviewVarianceData: action.payload }
    default:
      return state
  }
}
