const prefix = 'review_explorer'

// accept a post fix to generate action types to avoid naming conflict
export const REQUEST_ALL_REVIEWS = `${prefix}/request_all_reviews`
export const REQUEST_REVIEWS = `${prefix}/request_reviews`
export const REQUEST_REVIEWS_PAGINATION_CHANGE = `${prefix}/request_reviews_pagination_change`
export const RECEIVE_ALL_REVIEWS = `${prefix}/receive_all_reviews`
export const RECEIVE_REVIEWS = `${prefix}/receive_reviews`
export const REQUEST_WORDS = `${prefix}/request_words`
export const RECEIVE_WORDS = `${prefix}/receive_words`
export const REQUEST_PEI = `${prefix}/request_pei`
export const RECEIVE_PEI = `${prefix}/receive_pei`

export const REQUEST_ADD_TAG = `${prefix}/request_add_tag`
export const RECEIVE_ADD_TAG = `${prefix}/receive_add_tag`
export const REQUEST_DELETE_TAG = `${prefix}/request_delete_tag`
export const RECEIVE_DELETE_TAG = `${prefix}/receive_delete_tag`

export const REQUEST_ADD_ALL_TAGS = `${prefix}/request_add_all_tags`
export const REQUEST_DELETE_ALL_TAGS = `${prefix}/request_delete_all_tags`

export const REQUEST_SET_FLAG = `${prefix}/request_update_flag`
export const RECEIVE_SET_FLAG = `${prefix}/receive_update_flag`
export const REQUEST_DELETE_FLAG = `${prefix}/request_delete_flag`

export const SET_DOWNLOAD_PERCENTAGE = `${prefix}/set_download_percentage`
export const SET_EDITABLE_TAG_KEYS = `${prefix}/set_editable_tag_keys`
export const SET_EXPANDED_ROW_KEYS = `${prefix}/set_expanded_row_keys`
export const SET_FLAG_KEYS = `${prefix}/set_flag_keys`
export const SET_RANGE = `${prefix}/set_range`
export const SET_SEARCH_PARAMS = `${prefix}/set_search_params`
export const SET_PAGINATION = `${prefix}/set_pagination`
export const SET_REVIEW_LOADING_STATE = `${prefix}/set_review_loading_state`
export const SET_PEI_LOADING_STATE = `${prefix}/set_pei_loading_state`
export const SET_CHART_LOADING_STATE = `${prefix}/set_chart_loading_state`
export const SET_FILTER_OBJECT = `${prefix}/set_filter_object`
export const SET_REVIEW_TAGS = `${prefix}/set_review_tags`
export const SET_DELETE_TAGS_ID = `${prefix}/set_delete_tags_id`
export const SET_SELECTED_PAGINATION = `${prefix}/set_selected_pagination`
