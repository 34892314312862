import axios from 'axios'
import * as AWSCognito from 'amazon-cognito-identity-js'
import { USER_POOL_ID, CLIENT_ID } from '../common'

const Api = {
  signIn: credentials =>
    new Promise((resolve, reject) => {
      const poolData = {
        UserPoolId: USER_POOL_ID,
        ClientId: CLIENT_ID,
      }

      const userPool = new AWSCognito.CognitoUserPool(poolData)

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: credentials.email,
        Pool: userPool,
      })

      // This method is called for signin and new password cases. This is necessary
      // to support the current authentication flow in the user interface. Cognito's
      // authenticateUser returns newPasswordRequired with a set of attributes used
      // to challenge the response. The attributes are stored in local storage and
      // passed back to the signin method to complete the new password process.

      const authenticationDetails = new AWSCognito.AuthenticationDetails({
        Username: credentials.email,
        Password: credentials.password,
      })

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async result => {
          resolve({
            data: {
              user_id: result.idToken.payload['cognito:username'],
              token: result.idToken.jwtToken,
              customer: result.idToken.payload['custom:customer'],
              schema: result.idToken.payload['custom:schema'],
              logins: result.idToken.payload['custom:logins'],
              email: result.idToken.payload.email,
              auth_time: result.idToken.payload.auth_time,
            },
          })
        },
        onFailure: err => {
          console.error(err)
          reject(err)
        },
        newPasswordRequired: userAttributes => {
          if (credentials.newPassword && credentials.attributes) {
            const attributes = JSON.parse(credentials.attributes)
            delete attributes.email_verified // the api doesn't accept the email verified field back
            cognitoUser.completeNewPasswordChallenge(credentials.newPassword, attributes, {
              onSuccess: result => {
                resolve({
                  data: {
                    user_id: result.idToken.payload['cognito:username'],
                    token: result.idToken.jwtToken,
                    customer: result.idToken.payload['custom:customer'],
                    schema: result.idToken.payload['custom:schema'],
                    logins: result.idToken.payload['custom:logins'],
                  },
                })
              },
              onFailure: err => {
                reject(err)
              },
            })
          } else {
            resolve({
              newpassword: {
                userAttributes,
              },
            })
          }
        },
      })
    }),
  globalVar: queryObject => axios.put('/variables', queryObject),
  refreshToken: () =>
    new Promise((resolve, reject) => {
      const poolData = {
        UserPoolId: USER_POOL_ID,
        ClientId: CLIENT_ID,
      }

      const userPool = new AWSCognito.CognitoUserPool(poolData)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser !== null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            alert(err)
            return
          }
          const refreshToken = session.getRefreshToken()
          cognitoUser.refreshSession(refreshToken, (err, result) => {
            if (err) {
              alert(err)
              return
            }

            resolve({
              data: {
                token: result.idToken.jwtToken,
              },
            })
          })
        })
      }
    }),
}

export default Api
