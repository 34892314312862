const config = {
  grid: {
    show: true,
    borderColor: 'rgba(0,0,0,0)',
    containLabel: true,
    top: 0,
    left: '10%',
    right: '5%',
    bottom: '20%',
  },
  graphic: [
    {
      type: 'text',
      left: '13%',
      top: '5%',
      style: {
        text: 'At Risk',
        fill: '#ff7160',
        font: 'bold 12px "Microsoft YaHei", sans-serif',
      },
    },
    {
      type: 'text',
      right: '5%',
      top: '5%',
      style: {
        text: 'Maintaining',
        fill: '#069',
        font: 'bold 12px "Microsoft YaHei", sans-serif',
      },
    },
    {
      type: 'text',
      left: '13%',
      bottom: '30%',
      style: {
        text: 'Opportunity',
        fill: '#f0ad4f',
        font: 'bold 12px "Microsoft YaHei", sans-serif',
      },
    },
    {
      type: 'text',
      right: '5%',
      bottom: '30%',
      style: {
        text: 'Outperforming',
        fill: '#5cb85c',
        font: 'bold 12px "Microsoft YaHei", sans-serif',
      },
    },
  ],
  legend: {
    bottom: '3%',
    itemWidth: 0,
    textStyle: {
      color: 'hsla(0, 0%, 0%, 0.63)',
      fontSize: 12,
    },
  },
  toolbox: {
    show: true,
    showTitle: true,
    left: 13,
    top: 0,
    itemGap: 15,
    itemSize: 14,
    orient: 'vertical',
    feature: {
      dataView: {
        show: true,
        title: 'Data View',
        lang: ['Data View', 'Close', 'Refresh'],
      },
      magicType: {
        show: false,
      },
      restore: { show: true, title: 'Restore' },
      saveAsImage: {
        show: true,
        title: 'Save \n as Image',
      },
    },
  },
  xAxis: [
    {
      type: 'value',
      scale: true,
      min: -9.5,
      max: 9.5,
      name: 'Our PEI',
      nameLocation: 'start',
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#8a9fbe',
        },
      },
      nameTextStyle: {
        color: 'hsla(0, 0%, 0%, 0.63)',
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
      scale: true,
      min: -9.5,
      max: 9.5,
      name: 'Competitor PEI',
      nameLocation: 'start',
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#8a9fbe',
        },
      },
      nameTextStyle: {
        color: 'hsla(0, 0%, 0%, 0.63)',
      },
    },
  ],
}

export default config
// media: [
//   {
//     query: {
//       // <= 900px
//       maxWidth: 900,
//     },
//     option: {
//       grid: {
//         top: 0,
//         left: '10%',
//         right: '5%',
//         bottom: '20%',
//       },
//       graphic: [
//         {
//           type: 'text',
//           left: '12%',
//           top: '5%',
//           style: {
//             text: 'At Risk',
//             fill: '#ff7160',
//             font: 'bold 12px "Microsoft YaHei", sans-serif',
//           },
//         },
//         {
//           type: 'text',
//           right: '10%',
//           top: '5%',
//           style: {
//             text: 'Maintaining',
//             fill: '#069',
//             font: 'bold 12px "Microsoft YaHei", sans-serif',
//           },
//         },
//         {
//           type: 'text',
//           left: '12%',
//           bottom: '35%',
//           style: {
//             text: 'Opportunity',
//             fill: '#f0ad4f',
//             font: 'bold 12px "Microsoft YaHei", sans-serif',
//           },
//         },
//         {
//           type: 'text',
//           right: '10%',
//           bottom: '35%',
//           style: {
//             text: 'Outperforming',
//             fill: '#5cb85c',
//             font: 'bold 12px "Microsoft YaHei", sans-serif',
//           },
//         },
//       ],
//       legend: {
//         bottom: '6%',
//         left: 'center',
//       },
//     },
//   },
//   {
//     query: {
//       // >= 901px
//       minWidth: 901,
//     },
//     option: {
//       grid: {
//         left: '10%',
//         right: '12%',
//         top: '0',
//         bottom: '20%',
//       },
//       graphic: [
//         {
//           type: 'text',
//           left: '15%',
//           top: '5%',
//           cursor: 'default',
//           zLevel: -1,
//           style: {
//             text: 'At Risk',
//             fill: '#ff7160',
//             font: 'bold 16px "Microsoft YaHei", sans-serif',
//           },
//         },
//         {
//           type: 'text',
//           right: '15%',
//           top: '5%',
//           cursor: 'default',
//           zLevel: -1,
//           style: {
//             text: 'Maintaining',
//             fill: '#069',
//             font: 'bold 16px "Microsoft YaHei", sans-serif',
//           },
//         },
//         {
//           type: 'text',
//           left: '15%',
//           bottom: '25%',
//           cursor: 'default',
//           zLevel: -1,
//           style: {
//             text: 'Opportunity',
//             fill: '#f0ad4f',
//             font: 'bold 16px "Microsoft YaHei", sans-serif',
//           },
//         },
//         {
//           type: 'text',
//           right: '15%',
//           bottom: '25%',
//           cursor: 'default',
//           zLevel: -1,
//           style: {
//             text: 'Outperforming',
//             fill: '#5cb85c',
//             font: 'bold 16px "Microsoft YaHei", sans-serif',
//           },
//         },
//       ],
//       legend: {
//         bottom: '0',
//         left: 'center',
//       },
//     },
//   },
// ],
