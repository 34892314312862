import _ from 'lodash'
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { parseRating, buildQueryObject } from '../insights'
import { storeCompetitiveAdvantage, setCompetitiveAdvantageLoadingState, setCompetitiveAdvantageBrands } from './actions'
import { REQUEST_COMPETITIVE_ADVANTAGE } from './constants/ActionTypes'
import Api from './api'
import ThemeCompetitorLinechart from '../sentiment-trend/components/theme-competitor-linechart'

export function* getCompetitiveAdvantage(action) {
  yield put(setCompetitiveAdvantageLoadingState(true))
  try {
    const filterSelection = action.payload
    const ratingArr = parseRating(filterSelection.review_rating)
    const { brand, sub_brand } = filterSelection
    const brandLabel = brand === undefined ? [] : brand.label
    const clientName = window.localStorage.getItem('clientName')
    // only our client has sub_brand at this time.
    // if sub_brand has been selected, brand need to be shown instead
    // so if the client brand hasn't been selected, we need to add it manually
    if (sub_brand !== undefined && sub_brand.label.length > 0 && !brandLabel.includes(clientName)) {
      brandLabel.unshift(clientName)
    }
    yield put(setCompetitiveAdvantageBrands(brandLabel))
    const formattedQueryObj = buildQueryObject(
      {
        ...filterSelection,
        review_rating: ratingArr,
      },
      {},
      {},
    )
    const queryObject = {
      query_object: {
        ...formattedQueryObj.additional_filter,
        ...formattedQueryObj.filter_object,
        ...formattedQueryObj.search_object,
      },
    }
    const [brandThemeRes, brandRatingRes] = yield all([call(Api.getCompetitiveBrandTheme, { ...queryObject }), call(Api.getCompetitiveBrandRating, { ...queryObject })])
    const brandTheme = brandThemeRes.data[0]
    const brandRating = brandRatingRes.data
    const themeOrder = ['fit', 'quality', 'style', 'use', 'value']
    brandTheme.brand_theme = _.orderBy(brandTheme.brand_theme, 'theme_name', themeOrder)
    brandTheme.brand_theme.forEach(brand => {
      brand.brand_theme_data = _.sortBy(brand.brand_theme_data, 'brand_id')
    })
    brandRating.brand_avg_rating = _.sortBy(brandRating, 'brand_id')
    yield put(storeCompetitiveAdvantage({ ...brandTheme, ...brandRating }))
    yield put(setCompetitiveAdvantageLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setCompetitiveAdvantageLoadingState(false))
  }
}

export default function* watchCompetitiveAdvantage() {
  yield all([takeLatest(REQUEST_COMPETITIVE_ADVANTAGE, getCompetitiveAdvantage)])
}
