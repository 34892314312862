import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Checkbox, Row, Col } from 'antd'
import _ from 'lodash'

import { TreeSelect, GroupSelect } from '../../filter'
import { getFilters, storeFilters } from '../actions'
import { concatArraySelectorFactory, concatTimeArraySelectorFactory, getRatingRange, valueArraySelectorFactory } from '../selectors'
import { customizer, getAttrAttrFilter, getProcessedAttrVal, getParentChildValues, getAllCheckedGrandChildrenNodes, getPartiallySelectedParentValues } from '../constants/helper'
import { NoFilter, Slider } from '../../common'
import './insights.css'

const separator = '>'

export class LeftWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCEFOwnedChecked: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCEFCheckboxChange = this.handleCEFCheckboxChange.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleResetFilter = this.handleResetFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /**
   * Fetch filter data from the back end
   */
  componentDidMount() {
    this.props.getFilters()
  }

  getNewMergedObj(parentValues, allCheckedNodes) {
    const {
      filters: { cat_att_attval: ctgyAttrAttrVal },
    } = this.props
    const allCtgyValues = _.concat(parentValues, getPartiallySelectedParentValues(getAllCheckedGrandChildrenNodes(allCheckedNodes)))
    // quality_category will only be used in quality control view, for avg sent score calculating
    this.props.setFilterSelection({ quality_category: allCtgyValues })
    const toBeMergedArr = allCtgyValues.map(ctgyVal => ctgyAttrAttrVal[ctgyVal])
    return _.mergeWith({}, ...toBeMergedArr, customizer)
  }

  handleSubmit() {
    // fetch reviews if it is on review explorer page
    this.props.onSubmit()
  }

  /**
   * Update filter selection, also need to update attr list if ctgy changed
   * @param  {[type]} value [description]
   * @param  {[type]} label [description]
   * @param  {[type]} extra [description]
   * @param  {[type]} name  [description]
   * @return {[type]}       [description]
   */
  handleChange(value, label, extra, name) {
    const {
      filters,
      filterSelection: { attribute_value: attributeValue },
    } = this.props
    const { attribute, normalizedAttribute } = filters
    if (name.indexOf(separator) === -1) {
      // 1. flat filters
      this.props.setFilterSelection({ [name]: { value, label } })
    } else {
      // 2. nested filters，currently, limited to 2 levels, it has separator in the name
      // get filter names
      const [parent, children, grandChildren] = name.trim().split(separator)
      // flatten nested filters' value
      const [parentValues, childrenValues, grandChildrenValues] = getParentChildValues(extra.allCheckedNodes)
      const parentValueArray = _.map(parentValues, 'value')
      const parentLabelArray = _.map(parentValues, 'label')
      const childrenValueArray = _.map(childrenValues, 'value')
      const childrenLabelArray = _.map(childrenValues, 'label')
      const grandChildrenValueArray = _.map(grandChildrenValues, 'value')
      const grandChildrenLabelArray = _.map(grandChildrenValues, 'label')
      // if it is category subcategory, also need to update attr (list and selection)
      if (name === `review_year${separator}review_quarter${separator}review_month` || name === `product_year${separator}product_quarter${separator}product_month`) {
        this.props.setFilterSelection({
          [parent]: { label: parentLabelArray, value: parentValueArray },
          [children]: {
            label: childrenLabelArray,
            value: childrenValueArray,
          },
          [grandChildren]: {
            label: grandChildrenLabelArray,
            value: grandChildrenValueArray,
          },
        })
      } else if (name === `category${separator}subcategory`) {
        // if the value is []
        if (_.isEmpty(value)) {
          // reset ctgy selection, quality_category
          this.props.setFilterSelection({
            [parent]: parentValues,
            [children]: grandChildrenValues,
            quality_category: [],
          })
          // reset attribute list, not selection
          this.props.storeFilters({ ...filters, filteredAttribute: attribute })
          return
        }

        const newMergedObj = this.getNewMergedObj(parentValueArray, extra.allCheckedNodes)
        const newAttrAttrValFilter = getAttrAttrFilter(normalizedAttribute.entities, newMergedObj)

        // update selection of ctgy, subctgy and attr
        this.props.setFilterSelection({
          [parent]: { label: parentLabelArray, value: parentValueArray },
          [children]: {
            label: grandChildrenLabelArray,
            value: grandChildrenValueArray,
          },
          // attribute_value: getProcessedAttrVal(attributeValue, newMergedObj),
        })

        // update attribute_value filter list
        this.props.storeFilters({ ...filters, filteredAttribute: newAttrAttrValFilter })
      } else if (name === `theme${separator}aspect`) {
        const partiallySelectedThemeValues = getPartiallySelectedParentValues(getAllCheckedGrandChildrenNodes(extra.allCheckedNodes))
        this.props.setFilterSelection({
          [parent]: { label: parentLabelArray, value: parentValueArray },
          [children]: {
            label: grandChildrenLabelArray,
            value: grandChildrenValueArray,
          },
          partiallySelectedThemeValues,
        })
      } else {
        // update filters selection
        this.props.setFilterSelection({
          [parent]: { label: parentLabelArray, value: parentValueArray },
          [children]: {
            label: grandChildrenLabelArray,
            value: grandChildrenValueArray,
          },
        })
      }
    }
  }

  handleCEFCheckboxChange() {
    const { isCEFOwnedChecked } = this.state
    this.setState({
      isCEFOwnedChecked: !isCEFOwnedChecked,
    })

    if (!isCEFOwnedChecked) {
      this.props.setFilterSelection({
        attribute_value: { value: ['10033'], label: ['Yes'] },
      })
    } else {
      this.props.setFilterSelection({
        attribute_value: { value: [], label: [] },
      })
    }
  }

  handleSelectAll(event, name, filterName) {
    const filter = this.props.filters[filterName]
    const allValuesArray = _.map(filter, 'value')
    const allLabelsArray = _.map(filter, 'label')
    const { textContent } = event.target
    if (textContent === 'Select all') {
      if (name.indexOf(separator) === -1) {
        // 1. flat filters
        this.props.setFilterSelection({ [name]: { value: allValuesArray, label: allLabelsArray } })
      } else if (name === `review_year${separator}review_quarter${separator}review_month` || name === `product_year${separator}product_quarter${separator}product_month`) {
        const [parent, children, grandChildren] = name.trim().split(separator)
        this.props.setFilterSelection({
          [parent]: { label: allLabelsArray, value: allValuesArray },
          [children]: {
            label: [],
            value: [],
          },
          [grandChildren]: {
            label: [],
            value: [],
          },
        })
      } else {
        const [parent, children] = name.trim().split(separator)
        this.props.setFilterSelection({
          [parent]: { label: allLabelsArray, value: allValuesArray },
          [children]: {
            label: [],
            value: [],
          },
        })
      }
    } else {
      if (name.indexOf(separator) === -1) {
        // 1. flat filters
        this.props.setFilterSelection({ [name]: { value: [], label: [] } })
      } else {
        const [parent, children] = name.trim().split(separator)
        this.props.setFilterSelection({
          [parent]: { label: [], value: [] },
          [children]: {
            label: [],
            value: [],
          },
        })
      }
    }
  }

  handleResetFilter() {
    const { filterSelection, filters, currentViewName } = this.props
    const { attribute } = filters
    this.props.setFilterSelection(
      _.mapValues(filterSelection, (value, key) => {
        if (currentViewName === 'qualityControl' && (key === 'theme' || key === 'theme_sentiment')) {
          return value
        }
        if (key === 'attribute_value') {
          this.props.storeFilters({ ...filters, filteredAttribute: attribute })
        }
        return []
      }),
    )
  }

  render() {
    const schema = window.localStorage.getItem('schema')
    const {
      filters,
      isFilterHidden,
      reviewTimeValues,
      ctgySubctgyValues,
      brandSubbrandValues,
      themeAspectValues,
      userAttributeValues,
      ratingRange,
      sourceValues,
      attribute_value_values,
      theme_sentiment_values,
      review_sentiment_values,
      price_values,
      regionValues,
      departmentValues,
      ratingValues,
      custom_attribute_values,
      filterSetType,
      currentViewName,
      isSourceReviewVarianceData,
    } = this.props

    if (_.isEmpty(filters)) {
      return (
        <div className="left-wrapper">
          <NoFilter />
        </div>
      )
    }
    const {
      source,
      category,
      theme,
      brand,
      filteredAttribute, // use filteredAttribute instead of attribute
      theme_sentiment,
      user_attribute,
      review_sentiment,
      price,
      region,
      department,
      review_year,
      custom_attribute,
    } = filters

    const { isCEFOwnedChecked } = this.state

    return (
      <div className={`${isFilterHidden ? 'ml-neg-sidebar' : 'ml-0'} left-wrapper`}>
        <Button onClick={this.handleSubmit} className="filter-set-submit">
          Submit
        </Button>
        <div className="filter-set-title">Filter Set</div>
        <div className="span-link reset-filters" onClick={this.handleResetFilter}>
          Clear Filters
        </div>

        {/* CEF Checkbox (shortcut to attribute_value CEF Owned: Yes) */}
        {schema === 'travel_domains_cef' ? (
          <Row className="filter-wrapper">
            <Col span={21}>
              <h6 className="filter-name">CEF OWNED</h6>
            </Col>
            <Col span={3}>
              <Checkbox checked={isCEFOwnedChecked} onChange={this.handleCEFCheckboxChange} />
            </Col>
          </Row>
        ) : null}

        {/* Department */}
        {currentViewName === 'peiScorecard' || schema === 'travel_domains_cef' ? null : (
          <TreeSelect
            config={{
              id: 'department',
              data: department,
              value: departmentValues,
              title: 'department',
              onChange: (value, label, extra) => this.handleChange(value, label, extra, 'department'),
            }}
          />
        )}

        {/* NOT FOR CEF */}
        {/* Categories -> Subcategories */}
        {schema !== 'travel_domains_cef' ? (
          <TreeSelect
            config={{
              id: 'category',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, `category${separator}subcategory`, 'category')} className="select-all">
                      {ctgySubctgyValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...category,
              ],
              value: ctgySubctgyValues,
              title: 'category > subcategory',
              onChange: (value, label, extra) => this.handleChange(value, label, extra, `category${separator}subcategory`),
            }}
          />
        ) : null}

        {/* FOR CEF ONLY */}
        {/* This just changes category to locations for the UI; according to our database the locations are still categories */}
        {/* Locations -> Sublocations */}
        {schema === 'travel_domains_cef' ? (
          <TreeSelect
            config={{
              id: 'category',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, `category${separator}subcategory`, 'category')} className="select-all">
                      {ctgySubctgyValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...category,
              ],
              value: ctgySubctgyValues,
              title: 'locations > sublocations',
              onChange: (value, label, extra) => this.handleChange(value, label, extra, `category${separator}subcategory`),
            }}
          />
        ) : null}

        {/* Implicit Categories -> Attributes */}
        {filterSetType === 'variance' ? null : (
          <GroupSelect
            config={{
              title: 'attribute value',
              data: filteredAttribute,
              value: attribute_value_values,
              onChange: (value, label, extra) => {
                let attributeObject = {}
                filteredAttribute.forEach(each => {
                  const tmp = _.filter(each.children, o => _.includes(value, o.key))
                  if (!_.isEmpty(tmp)) {
                    attributeObject = tmp
                  }
                })
                if (!_.isEmpty(attributeObject)) {
                  this.handleChange(value, _.map(attributeObject, 'label'), extra, 'attribute_value')
                } else {
                  this.handleChange(value, value, extra, 'attribute_value')
                }
              },
            }}
          />
        )}

        {/* Brand -> Sub_brand */}
        {!isSourceReviewVarianceData && filterSetType === 'variance' ? null : (
          <TreeSelect
            config={{
              id: 'brand',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, `brand${separator}sub_brand`, 'brand')} className="select-all">
                      {brandSubbrandValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...brand,
              ],
              onChange: (value, label, extra) => this.handleChange(value, label, extra, `brand${separator}sub_brand`),
              value: brandSubbrandValues,
              title: 'brand > subbrand',
            }}
          />
        )}
        {/* Source */}
        {isSourceReviewVarianceData && filterSetType === 'variance' ? null : (
          <TreeSelect
            config={{
              id: 'source',
              title: 'source',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, 'source', 'source')} className="select-all">
                      {sourceValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...source,
              ],
              value: sourceValues,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, 'source'),
            }}
          />
        )}
        {/* Themes -> Aspects */}
        {filterSetType !== 'review' ? null : (
          <TreeSelect
            config={{
              id: 'theme',
              title: 'theme > aspect',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, `theme${separator}aspect`, 'theme')} className="select-all">
                      {themeAspectValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...theme,
              ],
              value: themeAspectValues,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, `theme${separator}aspect`),
            }}
          />
        )}

        {/* User -> Attributes */}
        {filterSetType !== 'review' || _.isEmpty(user_attribute) || schema !== 'beauty_grooming_domains_Skincare' ? null : (
          <TreeSelect
            config={{
              id: 'user_attribute',
              title: 'user > attributes',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, `user${separator}attributes`, 'user_attribute')} className="select-all">
                      {userAttributeValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...user_attribute,
              ],
              value: userAttributeValues,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, `user${separator}attributes`),
            }}
          />
        )}

        {/* Theme Sentiment */}
        {filterSetType !== 'review' ? null : (
          <TreeSelect
            config={{
              id: 'theme_sentiment',
              title: 'theme sentiment',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, 'theme_sentiment', 'theme_sentiment')} className="select-all">
                      {theme_sentiment_values.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...theme_sentiment,
              ],
              value: theme_sentiment_values,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, 'theme_sentiment'),
            }}
          />
        )}

        {/* Review Sentiment */}
        {/* {filterSetType !== 'review' ? null : (
          <TreeSelect
            config={{
              id: 'review_sentiment',
              title: 'review sentiment',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, 'review_sentiment', 'review_sentiment')} className="select-all">
                      {review_sentiment_values.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...review_sentiment,
              ],
              value: review_sentiment_values,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, 'review_sentiment'),
            }}
          />
        )} */}

        {/* Ticket Price */}
        {filterSetType === 'variance' ? null : (
          <TreeSelect
            config={{
              id: 'price',
              title: 'ticket price',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, 'price', 'price')} className="select-all">
                      {price_values.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...price,
              ],
              value: price_values,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, 'price'),
            }}
          />
        )}

        {/* User Region */}
        {filterSetType !== 'review' ? null : (
          <TreeSelect
            config={{
              id: 'region',
              title: 'region',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, 'region', 'region')} className="select-all">
                      {regionValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...region,
              ],
              value: regionValues,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, 'region'),
            }}
          />
        )}

        {/* Review Year -> Quarter -> Month */}
        {filterSetType === 'variance' ? null : (
          <TreeSelect
            config={{
              id: 'reviewTime',
              title: 'Review Year > Quarter > Month',
              data: [
                {
                  title: (
                    <span onClick={event => this.handleSelectAll(event, `review_year${separator}review_quarter${separator}review_month`, 'review_year')} className="select-all">
                      {reviewTimeValues.length > 0 ? 'Unselect all' : 'Select all'}
                    </span>
                  ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...review_year,
              ],
              value: reviewTimeValues,
              onChange: (value, label, extra) => this.handleChange(value, label, extra, `review_year${separator}review_quarter${separator}review_month`),
            }}
          />
        )}
        {/* Custom Attributes */}
        {_.isEmpty(custom_attribute) ? null : (
          <GroupSelect
            config={{
              title: 'custom attributes',
              data: custom_attribute,
              value: custom_attribute_values,
              onChange: (value, label, extra) => {
                const customAttributeArr = []
                custom_attribute.forEach(e => customAttributeArr.push(...e.children))
                let customAttrObject = {}
                const tmp = _.filter(customAttributeArr, o => _.includes(value, o.key))
                if (!_.isEmpty(tmp)) {
                  customAttrObject = tmp
                }
                if (!_.isEmpty(customAttrObject)) {
                  this.handleChange(value, _.map(customAttrObject, 'label'), extra, 'custom_attribute')
                } else {
                  this.handleChange(value, value, extra, 'custom_attribute')
                }
              },
            }}
          />
        )}

        {/* Review Rating Slider */}
        {filterSetType !== 'review' ? null : (
          <Slider
            config={{
              title: 'review rating',
              min: ratingRange[0] || 1,
              max: ratingRange[1] || 5,
              value: _.isEmpty(ratingValues) ? ratingRange : ratingValues,
              formatter: val => `${val} stars`,
              onChange: value => this.handleChange(value, undefined, undefined, 'review_rating'),
            }}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { currentViewName } = ownProps
  return {
    filters: state.insights.filters,
    filterSelection: state[currentViewName].filterSelection,
    ratingRange: getRatingRange(state.insights),
    reviewTimeValues: concatTimeArraySelectorFactory('review_year', 'review_quarter', 'review_month')(state[currentViewName]),
    ctgySubctgyValues: concatArraySelectorFactory('category', 'subcategory')(state[currentViewName]),
    brandSubbrandValues: concatArraySelectorFactory('brand', 'sub_brand')(state[currentViewName]),
    themeAspectValues: concatArraySelectorFactory('theme', 'aspect')(state[currentViewName]),
    userAttributeValues: concatArraySelectorFactory('user', 'attribute')(state[currentViewName]),
    sourceValues: valueArraySelectorFactory('source')(state[currentViewName]),
    attribute_value_values: valueArraySelectorFactory('attribute_value')(state[currentViewName]),
    theme_sentiment_values: valueArraySelectorFactory('theme_sentiment')(state[currentViewName]),
    review_sentiment_values: valueArraySelectorFactory('review_sentiment')(state[currentViewName]),
    price_values: valueArraySelectorFactory('price')(state[currentViewName]),
    regionValues: valueArraySelectorFactory('region')(state[currentViewName]),
    departmentValues: valueArraySelectorFactory('department')(state[currentViewName]),
    custom_attribute_values: valueArraySelectorFactory('custom_attribute')(state[currentViewName]),
    ratingValues: valueArraySelectorFactory('review_rating')(state[currentViewName]),
    pageSize: state.review.pageSize,
    pageNum: state.review.pageNum,
    searchParams: state.review.searchParams,
    isFilterHidden: state.insights.isFilterHidden,
    isSourceReviewVarianceData: state.variance.isSourceReviewVarianceData,
  }
}

LeftWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  storeFilters: PropTypes.func.isRequired,
  setFilterSelection: PropTypes.func.isRequired,
  isFilterHidden: PropTypes.bool.isRequired,
  ratingRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  filters: PropTypes.shape({
    department: PropTypes.array,
    category: PropTypes.array,
    subcatgory: PropTypes.object,
    attribute_value: PropTypes.object,
    brand: PropTypes.array,
    source: PropTypes.array,
    theme: PropTypes.array,
    aspect: PropTypes.array,
    theme_sentiment: PropTypes.array,
    user_attribute: PropTypes.array,
    review_sentiment: PropTypes.array,
    price: PropTypes.array,
    region: PropTypes.array,
    product_year: PropTypes.array,
    review_year: PropTypes.array,
    custom_attribute: PropTypes.array,
    rating: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  filterSelection: PropTypes.shape({}).isRequired,
  themeAspectValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  userAttributeValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  brandSubbrandValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  reviewTimeValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  ctgySubctgyValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  sourceValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  attribute_value_values: PropTypes.arrayOf(PropTypes.string).isRequired,
  theme_sentiment_values: PropTypes.arrayOf(PropTypes.string).isRequired,
  review_sentiment_values: PropTypes.arrayOf(PropTypes.string).isRequired,
  price_values: PropTypes.arrayOf(PropTypes.string).isRequired,
  regionValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  departmentValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  custom_attribute_values: PropTypes.arrayOf(PropTypes.string).isRequired,
  ratingValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentViewName: PropTypes.string.isRequired,
  filterSetType: PropTypes.string.isRequired,
  isSourceReviewVarianceData: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, {
  getFilters,
  storeFilters,
})(LeftWrapper)
