const personaInfoWho = [
  {
    segment: 'Free Spirit',
    segment_id: 1,
    Reviews: 23788,
    Top3Categories: [
      {
        category: 'Pants',
        client_sentiment: 0.35,
        competitor_sentiment: 0.24,
      },
      {
        category: 'Shirts',
        client_sentiment: 0.36,
        competitor_sentiment: 0.42,
      },
      {
        category: 'Underwear',
        client_sentiment: 0.63,
        competitor_sentiment: 0.26,
      },
    ],
    Top3Classes: [
      {
        class: 'Pockets',
        client_sentiment: 0.37,
        competitor_sentiment: 0.47,
      },
      {
        class: 'Spandex',
        client_sentiment: 0.37,
        competitor_sentiment: 0.5,
      },
      {
        class: 'Wicking',
        client_sentiment: 0.87,
        competitor_sentiment: 0.32,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.24,
        competitor_sentiment: 0.24,
      },
      {
        theme: 'Value',
        client_sentiment: 0.24,
        competitor_sentiment: 0.18,
      },
      {
        theme: 'Style',
        client_sentiment: 0.43,
        competitor_sentiment: 0.38,
      },
    ],
    Top3Products: ['Wunder Under Pant', 'High Times Pant', 'Wunder Under Pant Iii'],
    ClientSentiment: 0.38,
    CompetitorSentiment: 0.23,
    SegmentGender: 'female',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
  },
  {
    segment: 'Fashionable Athlete',
    segment_id: 2,
    Reviews: 39529,
    Top3Categories: [
      {
        category: 'Outerwear',
        client_sentiment: 0.51,
        competitor_sentiment: 0.38,
      },
      {
        category: 'Pants',
        client_sentiment: 0.35,
        competitor_sentiment: 0.24,
      },
      {
        category: 'Shirts',
        client_sentiment: 0.36,
        competitor_sentiment: 0.42,
      },
    ],
    Top3Classes: [
      {
        class: 'Fitted',
        client_sentiment: 0.41,
        competitor_sentiment: 0.33,
      },
      {
        class: 'Pockets',
        client_sentiment: 0.37,
        competitor_sentiment: 0.47,
      },
      {
        class: 'Spandex',
        client_sentiment: 0.37,
        competitor_sentiment: 0.5,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.24,
        competitor_sentiment: 0.24,
      },
      {
        theme: 'Value',
        client_sentiment: 0.24,
        competitor_sentiment: 0.18,
      },
      {
        theme: 'Style',
        client_sentiment: 0.43,
        competitor_sentiment: 0.38,
      },
    ],
    Top3Products: ['High Times Pant', 'Align Pant', 'Wunder Under Pant'],
    ClientSentiment: 0.39,
    CompetitorSentiment: 0.28,
    SegmentGender: 'female',
    SegmentAge: '25-34',
    SegmentStyle: '',
    SegmentBodyType: '',
  },
  {
    segment: 'Urban Fashionista',
    segment_id: 3,
    Reviews: 34073,
    Top3Categories: [
      {
        category: 'Outerwear',
        client_sentiment: 0.51,
        competitor_sentiment: 0.38,
      },
      {
        category: 'Pants',
        client_sentiment: 0.35,
        competitor_sentiment: 0.24,
      },
      {
        category: 'Shirts',
        client_sentiment: 0.36,
        competitor_sentiment: 0.42,
      },
    ],
    Top3Classes: [
      {
        class: 'Pockets',
        client_sentiment: 0.37,
        competitor_sentiment: 0.47,
      },
      {
        class: 'Cotton',
        client_sentiment: 0.42,
        competitor_sentiment: 0.37,
      },
      {
        class: 'Spandex',
        client_sentiment: 0.37,
        competitor_sentiment: 0.5,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.24,
        competitor_sentiment: 0.24,
      },
      {
        theme: 'Value',
        client_sentiment: 0.24,
        competitor_sentiment: 0.18,
      },
      {
        theme: 'Style',
        client_sentiment: 0.43,
        competitor_sentiment: 0.38,
      },
    ],
    Top3Products: ['Metro Slouch', 'Stripes Chaturanga™ Capri', 'La Viva Pant'],
    ClientSentiment: 0.37,
    CompetitorSentiment: 0.35,
    SegmentGender: 'female',
    SegmentAge: '35-44',
    SegmentStyle: '',
    SegmentBodyType: '',
  },
  {
    segment: 'Suburban Chic',
    segment_id: 4,
    Reviews: 21576,
    Top3Categories: [
      {
        category: 'Accessories',
        client_sentiment: 0.36,
        competitor_sentiment: 0.48,
      },
      {
        category: 'Pants',
        client_sentiment: 0.35,
        competitor_sentiment: 0.24,
      },
      {
        category: 'Shirts',
        client_sentiment: 0.36,
        competitor_sentiment: 0.42,
      },
    ],
    Top3Classes: [
      {
        class: 'Pockets',
        client_sentiment: 0.37,
        competitor_sentiment: 0.47,
      },
      {
        class: 'Cotton',
        client_sentiment: 0.42,
        competitor_sentiment: 0.37,
      },
      {
        class: 'Spandex',
        client_sentiment: 0.37,
        competitor_sentiment: 0.5,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.24,
        competitor_sentiment: 0.24,
      },
      {
        theme: 'Value',
        client_sentiment: 0.24,
        competitor_sentiment: 0.18,
      },
      {
        theme: 'Style',
        client_sentiment: 0.43,
        competitor_sentiment: 0.38,
      },
    ],
    Top3Products: ['Metro Slouch', 'Bi-Stretch Skinny Ankle Pants', 'Stripes Chaturanga™ Capri'],
    ClientSentiment: 0.38,
    CompetitorSentiment: 0.38,
    SegmentGender: 'female',
    SegmentAge: '45-54',
    SegmentStyle: '',
    SegmentBodyType: '',
  },
  {
    segment: 'Classic Elegance',
    segment_id: 5,
    Reviews: 34751,
    Top3Categories: [
      {
        category: 'Accessories',
        client_sentiment: 0.36,
        competitor_sentiment: 0.48,
      },
      {
        category: 'Pants',
        client_sentiment: 0.35,
        competitor_sentiment: 0.24,
      },
      {
        category: 'Shirts',
        client_sentiment: 0.36,
        competitor_sentiment: 0.42,
      },
    ],
    Top3Classes: [
      {
        class: 'Pockets',
        client_sentiment: 0.37,
        competitor_sentiment: 0.47,
      },
      {
        class: 'Cotton',
        client_sentiment: 0.42,
        competitor_sentiment: 0.37,
      },
      {
        class: 'Spandex',
        client_sentiment: 0.37,
        competitor_sentiment: 0.5,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.24,
        competitor_sentiment: 0.24,
      },
      {
        theme: 'Value',
        client_sentiment: 0.24,
        competitor_sentiment: 0.18,
      },
      {
        theme: 'Style',
        client_sentiment: 0.43,
        competitor_sentiment: 0.38,
      },
    ],
    Top3Products: ['Metro Slouch', 'Bi-Stretch Skinny Ankle Pants', 'Stripes Chaturanga™ Capri'],
    ClientSentiment: 0.39,
    CompetitorSentiment: 0.43,
    SegmentGender: 'female',
    SegmentAge: '55+',
    SegmentStyle: '',
    SegmentBodyType: '',
  },
  {
    segment: 'Youthful Vogue',
    segment_id: 6,
    Reviews: 9509,
    Top3Categories: [
      {
        category: 'Accessories',
        client_sentiment: 0.36,
        competitor_sentiment: 0.48,
      },
      {
        category: 'Pants',
        client_sentiment: 0.35,
        competitor_sentiment: 0.24,
      },
      {
        category: 'Shirts',
        client_sentiment: 0.36,
        competitor_sentiment: 0.42,
      },
    ],
    Top3Classes: [
      {
        class: 'Fitted',
        client_sentiment: 0.41,
        competitor_sentiment: 0.33,
      },
      {
        class: 'Pockets',
        client_sentiment: 0.37,
        competitor_sentiment: 0.47,
      },
      {
        class: 'Spandex',
        client_sentiment: 0.37,
        competitor_sentiment: 0.5,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.24,
        competitor_sentiment: 0.24,
      },
      {
        theme: 'Value',
        client_sentiment: 0.24,
        competitor_sentiment: 0.18,
      },
      {
        theme: 'Style',
        client_sentiment: 0.43,
        competitor_sentiment: 0.38,
      },
    ],
    Top3Products: ['Wunder Under Pant', 'Wunder Under Pant Iii', 'Speed Short'],
    ClientSentiment: 0.42,
    CompetitorSentiment: 0.36,
    SegmentGender: 'female',
    SegmentAge: 'under 18',
    SegmentStyle: '',
    SegmentBodyType: '',
  },
]

export default personaInfoWho
