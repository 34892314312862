import axios from 'axios'

const Api = {
  getPeiTrendBrand: queryObject => axios.put('/pei/trend/competitor', queryObject),
  getPeiTrendCompetitor: queryObject => axios.put('/pei/trend/brand', queryObject),
  getThemeTrendBrand: queryObject => axios.put('/theme/trend/competitor', queryObject),
  getThemeTrendCompetitor: queryObject => axios.put('/theme/trend/brand', queryObject),
  getRisksProduct: queryObject => axios.put('/views/topproductdrivers?limit=6', queryObject),
  getThemeFrequency: queryObject => axios.put('/theme/frequency', queryObject),
  getTopicFrequency: queryObject => axios.put('reviews/frequency/source/brand', queryObject)
}

export default Api
