const personaInfo = [
  {
    segment: 'J.C. Penney',
    segment_id: 1,
    Reviews: 10669,
    Top3Classes: [
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Pants',
        client_sentiment: 0.29,
        competitor_sentiment: 0.11,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Cotton',
        client_sentiment: 0.29,
        competitor_sentiment: 0.23,
      },
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
    ],
    Top3Products: ['Lee® Regular-Fit Straight-Leg Jeans', 'Lee® Relaxed Fit Jeans', 'Levi’S 505 Straight Leg Jeans'],
    ClientSentiment: 0.57,
    CompetitorSentiment: 0.45,
    SegmentGender: 'penney',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
  {
    segment: 'Kohls',
    segment_id: 2,
    Reviews: 17337,
    Top3Classes: [
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Pants',
        client_sentiment: 0.29,
        competitor_sentiment: 0.11,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Cotton',
        client_sentiment: 0.29,
        competitor_sentiment: 0.23,
      },
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
    ],
    Top3Products: ["Men'S Levi'S® 501™ Original Fit Jeans", "Women'S Lee Relaxed Fit Straight Leg Jeans", "Men'S Levi'S® 505™ Regular Jeans"],
    ClientSentiment: 0.25,
    CompetitorSentiment: 0.1,
    SegmentGender: 'kohls',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
  {
    segment: 'Macys',
    segment_id: 3,
    Reviews: 20825,
    Top3Classes: [
      {
        class: 'Coat',
        client_sentiment: 0.48,
        competitor_sentiment: 0.23,
      },
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Cotton',
        client_sentiment: 0.29,
        competitor_sentiment: 0.23,
      },
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
    ],
    Top3Products: ["Levi'S Men'S 501 Original Fit Jeans", "Levi'S® 505™ Regular Fit Jeans", "Levi'S® 511™ Slim Fit Jeans"],
    ClientSentiment: 0.37,
    CompetitorSentiment: 0.33,
    SegmentGender: 'macys',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
  {
    segment: 'Walmart',
    segment_id: 4,
    Reviews: 14385,
    Top3Classes: [
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Pants',
        client_sentiment: 0.29,
        competitor_sentiment: 0.11,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Work',
        client_sentiment: 0.39,
        competitor_sentiment: 0.33,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
    ],
    Top3Products: ["Wrangler Men'S Regular Fit Jean", "Rustler Men'S Regular Fit Jean", "Wrangler Men'S Relaxed Fit Jean"],
    ClientSentiment: 0.36,
    CompetitorSentiment: 0.17,
    SegmentGender: 'walmart',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
  {
    segment: 'Amazon',
    segment_id: 5,
    Reviews: 258026,
    Top3Classes: [
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Pants',
        client_sentiment: 0.29,
        competitor_sentiment: 0.11,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Cotton',
        client_sentiment: 0.29,
        competitor_sentiment: 0.23,
      },
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
    ],
    Top3Products: ["Levi'S Men'S 505 Regular Fit Jean", "Levi'S Men'S 511 Slim Fit Jean", "Levi'S Men'S 550 Relaxed-Fit Jean"],
    ClientSentiment: 0.27,
    CompetitorSentiment: 0.2,
    SegmentGender: 'amazon',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
  {
    segment: 'Wrangler',
    segment_id: 6,
    Reviews: 6517,
    Top3Classes: [
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Pants',
        client_sentiment: 0.29,
        competitor_sentiment: 0.11,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Buttons',
        client_sentiment: 0.27,
        competitor_sentiment: 0.23,
      },
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
    ],
    Top3Products: ['Wrangler® Five Star Premium Denim Regular Fit Jean', 'Wrangler® Comfort Solutions Series Comfort Fit Jean', 'Wrangler® Cowboy Cut® Original Fit Jean'],
    ClientSentiment: 0.29,
    CompetitorSentiment: 0.33,
    SegmentGender: 'wrangler',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
  {
    segment: 'Target',
    segment_id: 7,
    Reviews: 3547,
    Top3Classes: [
      {
        class: 'Jeans',
        client_sentiment: 0.28,
        competitor_sentiment: 0.22,
      },
      {
        class: 'Pants',
        client_sentiment: 0.29,
        competitor_sentiment: 0.11,
      },
      {
        class: 'Shorts',
        client_sentiment: 0.29,
        competitor_sentiment: 0.35,
      },
    ],
    Top3Attributes: [
      {
        attribute: 'Cotton',
        client_sentiment: 0.29,
        competitor_sentiment: 0.23,
      },
      {
        attribute: 'Denim',
        client_sentiment: 0.3,
        competitor_sentiment: 0.22,
      },
      {
        attribute: 'Pockets',
        client_sentiment: 0.29,
        competitor_sentiment: 0.22,
      },
    ],
    Top3Themes: [
      {
        theme: 'Fit',
        client_sentiment: 0.72,
        competitor_sentiment: 0.72,
      },
      {
        theme: 'Style',
        client_sentiment: 0.67,
        competitor_sentiment: 0.69,
      },
      {
        theme: 'Quality',
        client_sentiment: 0.63,
        competitor_sentiment: 0.54,
      },
    ],
    Top3Products: ["Wrangler Men'S 5-Star Regular Fit Jeans", "Wrangler Men'S Loose Fit Twill Cargo Pants", "Denizen From Levi'S Women'S Modern Skinny Jeans Bombshell"],
    ClientSentiment: 0.45,
    CompetitorSentiment: 0.31,
    SegmentGender: 'target',
    SegmentAge: '18-24',
    SegmentStyle: '',
    SegmentBodyType: '',
    visibility: false,
  },
]

export default personaInfo
