import _ from 'lodash'
import excellent from './excellent.svg'
import nice from './nice.svg'
import warning from './warning.svg'
import danger from './danger.svg'
import risk from './risk.svg'
import safety from './safety.svg'
import tie from './tie.svg'

export const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.union(objValue, srcValue)
  }
}

// export const getAllCheckedChildrenNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => _.isEmpty(checkedNode.children));
//
// export const getAllCheckedParentNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => !_.isEmpty(checkedNode.children));

// The following approach (using parentValue) is buggy when user click "delete" icon on the
// selected child option, it will return the checkedNode which only has "value", "key" in its props.
// no parentValue

// 05/17/2018 Enable search in filterSelection, still waiting for antd team to fix the issue
// fix temporarily by deal with two different data format
export const getAllCheckedGrandChildrenNodes = allCheckedNodes =>
  _.filter(allCheckedNodes, checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return !_.isUndefined(checkedNode.node.props.parentValue) && _.isEmpty(checkedNode.children)
    }
    return !_.isUndefined(checkedNode.props.parentValue) && _.isEmpty(checkedNode.props.children)
  })

export const getAllCheckedChildrenNodes = allCheckedNodes =>
  _.filter(allCheckedNodes, checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return !_.isUndefined(checkedNode.node.props.parentValue) && !_.isEmpty(checkedNode.children)
    }
    return !_.isUndefined(checkedNode.props.parentValue) && !_.isEmpty(checkedNode.props.children)
  })

export const getAllCheckedParentNodes = allCheckedNodes =>
  _.filter(allCheckedNodes, checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return _.isUndefined(checkedNode.node.props.parentValue)
    }
    return _.isUndefined(checkedNode.props.parentValue)
  })

// export const getAllCheckedChildrenNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => !_.isUndefined(checkedNode.node.props.parentValue));
//
// export const getAllCheckedParentNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => _.isUndefined(checkedNode.node.props.parentValue));

export const getParentChildValues = allCheckedNodes => {
  const allCheckedParentNodes = getAllCheckedParentNodes(allCheckedNodes)
  const allCheckedChildrenNodes = getAllCheckedChildrenNodes(allCheckedNodes)
  const allCheckedGrandChildrenNodes = getAllCheckedGrandChildrenNodes(allCheckedNodes)

  // parent values whose children are fully selected
  const parentValues = allCheckedParentNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return {
        value: checkedNode.node.props.value,
        label: checkedNode.node.props.title,
      }
    }
    return {
      value: checkedNode.props.value,
      label: checkedNode.props.title,
    }
  })

  // children values
  const childrenValues = allCheckedChildrenNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return {
        value: checkedNode.node.props.value,
        label: checkedNode.node.props.title,
      }
    }
    return {
      value: checkedNode.props.value,
      label: checkedNode.props.title,
    }
  })

  const grandChildrenValues = allCheckedGrandChildrenNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return {
        value: checkedNode.node.props.value,
        label: checkedNode.node.props.title,
      }
    }
    return {
      value: checkedNode.props.value,
      label: checkedNode.props.title,
    }
  })

  return [parentValues, childrenValues, grandChildrenValues]
}

// TODO cannot get parentValue when user clicking X to remove item
export const getPartiallySelectedParentValues = allCheckedChildrenNodes => {
  const parentValuesPartial = allCheckedChildrenNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return checkedNode.node.props.parentValue
    }
    return checkedNode.props.parentValue
  })
  // de-duplicate
  return _.uniq(parentValuesPartial)
}

export const getAttrAttrFilter = (destArrOfObj, schemaObj) => {
  const { attributeValue, attribute } = destArrOfObj
  const newArrOfObj = []
  const keys = Object.keys(schemaObj)
  for (let i = 0; i < keys.length; i++) {
    const attr = attribute[keys[i]]
    newArrOfObj[i] = { ...attr, children: [] }
    const childKeys = schemaObj[keys[i]]
    for (let j = 0; j < childKeys.length; j += 1) {
      newArrOfObj[i].children[j] = { ...attributeValue[childKeys[j]] }
    }
  }
  return newArrOfObj
}

export const getProcessedAttrVal = (selectedAttrVal, newMergedObj) => {
  let processedAttrVal = []
  if (!_.isEmpty(selectedAttrVal)) {
    const newAttrValArr = _.concat(...Object.values(newMergedObj))
    // (the data type in the cat_att_attval should be string but it is integer)
    processedAttrVal = selectedAttrVal.value.filter(each => _.includes(newAttrValArr, each))
  }
  return processedAttrVal
}

/**
 * inject UI params recursively
 * @param  {array} destArrOfObj the destination array of object
 * @param  {array} srcArrOfObj the source array of object
 * @param  {object} keyValueObj object that k:v pair to be injected into each object
 * @return {object} new object injected with keyValueObj
 */
// const injectUIParams = (srcArrOfObj, keyValueObj, leafOnlyKeyValueObj = {}) => {
//   if (!_.isArray(srcArrOfObj) || _.isEmpty(srcArrOfObj)) {
//     return [];
//   }
//   const newArrOfObj = [];
//   srcArrOfObj.forEach((eachObj, idx) => {
//     if (_.isEmpty(eachObj.children)) {
//       newArrOfObj[idx] = { ...eachObj, ...keyValueObj, ...leafOnlyKeyValueObj };
//     } else {
//       newArrOfObj[idx] = { ...eachObj, ...keyValueObj };
//       newArrOfObj[idx].children = injectUIParams(eachObj.children, keyValueObj, leafOnlyKeyValueObj);
//     }
//   });
//   return newArrOfObj;
// };
export const injectUIParams = (srcArrOfObj, keyValueObj) => {
  if (!_.isArray(srcArrOfObj) || _.isEmpty(srcArrOfObj)) {
    return []
  }
  const newArrOfObj = []
  srcArrOfObj.forEach((eachObj, idx) => {
    newArrOfObj[idx] = { ...eachObj, ...keyValueObj, children: [] }
    newArrOfObj[idx].children = injectUIParams(eachObj.children, keyValueObj)
    if (_.isEmpty(newArrOfObj[idx].children)) {
      delete newArrOfObj[idx].children
    }
  })
  return newArrOfObj
}

export const icons = {
  excellent,
  nice,
  'needs improvement': warning,
  risk: danger,
  underperforming: risk,
  outperforming: safety,
  neutral: tie,
}

export const convertFromCompetitorSentiment = cs => {
  switch (cs) {
    case 1:
      return 'underperforming'
    case 2:
      return 'neutral'
    case 3:
      return 'outperforming'
    default:
      return ''
  }
}

// convert average sentiment to context name
// face
export const convertFromAvgSentiment = s => {
  switch (s) {
    case 1:
      return 'risk'
    case 2:
      return 'needs improvement'
    case 3:
      return 'nice'
    case 4:
      return 'excellent'
    default:
      return ''
  }
}

export const parseRating = ratingArr => {
  if (_.isEmpty(ratingArr) || !_.isArray(ratingArr.value) || ratingArr.value.length !== 2) {
    return []
  }
  const rating = []
  for (let i = ratingArr.value[0]; i <= ratingArr.value[1]; i++) {
    rating.push(i)
  }
  return rating
}

/**
 * parse data of review count
 * @param  {string} range
 * @return {array}
 */
export const parseRange = (total, responseURL) => {
  const countMin = parseInt(responseURL.split('offset=')[1])
  const limit = parseInt(responseURL.split('limit=')[1].split('&')[0])
  const countMax = countMin + limit
  let countArr = [countMin, countMax, parseInt(total)]
  if (countArr.length !== 3) {
    countArr = [0, 0, 0]
  } else {
    countArr[0] += 1
    countArr[1] += 1
  }
  return countArr
}

export const buildFilterSelectionObject = filterSelection => {
  if (_.isEmpty(filterSelection)) {
    // Object.keys cannot accept undefined or null as input
    return {
      filter_object: {},
    }
  }
  const queryObject = {}
  Object.keys(filterSelection).forEach(key => {
    if (!_.isEmpty(filterSelection[key])) {
      const valueArr = _.isUndefined(filterSelection[key].value) ? filterSelection[key] : filterSelection[key].value
      if (_.isEmpty(valueArr)) {
        return
      }
      if (_.isNaN(_.toNumber(valueArr[0]))) {
        // format like this: ('neg','pos')
        queryObject[key] = `('${valueArr.join("','")}')`
      } else {
        queryObject[key] = `(${valueArr.join(',')})`
      }
      // queryObject[key] = `(${filterSelection[key].join(',')})`;
    }
  })

  return {
    filter_object: queryObject,
  }
}

export const buildAdditionalFilterObject = filters => {
  const queryObject = {}

  if (_.isEmpty(filters)) {
    return { additional_filter: queryObject }
  }
  Object.keys(filters).forEach(key => {
    if (!_.isUndefined(filters[key])) {
      const value = filters[key]
      if (key === 'time') {
        return
      }
      if ((key === 'review_year' || key === 'review_quarter' || key === 'review_month') && value <= 0) {
        return
      }
      if (key === 'sentimentType') {
        queryObject.faceted_client_type = value
        return
      }
      if (key === 'theme') {
        const themeArr = _.concat([], value)
        if (themeArr.length === 2) {
          ;[queryObject.insight_theme, queryObject.pro_theme] = themeArr
          return
        }
        queryObject.insight_theme = value
        return
      }
      if (key === 'subcategory') {
        queryObject.risk_subcategory = value
        return
      }
      if (key === 'product') {
        if (_.isArray(value)) {
          queryObject.pro_product_id = value
        } else {
          queryObject.pro_product_id = [value]
        }
        return
      }
      queryObject[key] = value
    }
  })
  return { additional_filter: queryObject }
}

export const buildSearchObject = searchParams => {
  const { review, product, tag } = searchParams
  const searchObject = {}
  if (!_.isEmpty(review) && !_.isEmpty(review.keyWords)) {
    searchObject.search_review = review.keyWords.join(review.logicParam.character)
  }
  if (!_.isEmpty(product) && !_.isEmpty(product.keyWords)) {
    searchObject.search_product = product.keyWords.join(product.logicParam.character)
    searchObject.search_product = searchObject.search_product.replace("'", "''")
  }
  if (!_.isEmpty(tag) && !_.isEmpty(tag.keyWords)) {
    searchObject.search_tag = tag.keyWords.join(tag.logicParam.character)
  }

  return { search_object: searchObject }
}

export const buildQueryObject = (filterSelection, additionalFilter, searchParams) => {
  const filterSelectionObject = buildFilterSelectionObject(filterSelection)
  const additionalFilterObject = buildAdditionalFilterObject(additionalFilter)
  const searchObject = buildSearchObject(searchParams)
  return {
    ...filterSelectionObject,
    ...additionalFilterObject,
    ...searchObject,
  }
}
