import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const SelectionInfo = ({
  product,
  risk_category,
  risk_subcategory,
  risk_attribute,
  add_comp_attribute_val,
  theme,
  time,
  sentimentType,
  style,
  clientBrand = window.localStorage.getItem('clientName'),
}) =>
  _.isEmpty(risk_category) &&
  _.isEmpty(risk_subcategory) &&
  _.isEmpty(risk_attribute) &&
  _.isEmpty(add_comp_attribute_val) &&
  _.isEmpty(product) &&
  _.isEmpty(theme) &&
  _.isEmpty(time) &&
  _.isEmpty(sentimentType) ? null : (
    <div
      style={
        _.isEmpty(style)
          ? {
              background: '#fbfbfb',
              padding: '15px 24px 0 24px',
              borderRadius: '4px',
            }
          : style
      }
    >
      {_.isEmpty(risk_category) ? null : (
        <div>
          <strong>Category</strong>:{risk_category.name}
        </div>
      )}
      {_.isEmpty(risk_subcategory) ? null : (
        <div>
          <strong>Subcategory</strong>:{risk_subcategory.name}
        </div>
      )}
      {_.isEmpty(risk_attribute) ? null : (
        <div>
          <strong>Attribute</strong>:{risk_attribute.name}
        </div>
      )}
      {_.isEmpty(add_comp_attribute_val) ? null : (
        <div>
          <strong>Attribute Value</strong>:{add_comp_attribute_val.name}
        </div>
      )}
      {_.isEmpty(product) ? null : (
        <div>
          <strong>Product</strong>:{product.name}
        </div>
      )}
      {_.isEmpty(theme) ? null : (
        <div>
          <strong>Theme</strong>:{theme.name}
        </div>
      )}
      {_.isEmpty(time) ? null : (
        <div>
          <strong>Time</strong>:{time.name}
        </div>
      )}
      {_.isEmpty(sentimentType) ? null : (
        <div>
          <strong>Client / Competitor</strong>:{sentimentType.name === 'client' ? clientBrand : 'Competitor(s)'}
        </div>
      )}
    </div>
  )

SelectionInfo.defaultProps = {
  sentimentType: {},
  product: {},
  risk_category: {},
  risk_subcategory: {},
  risk_attribute: {},
  add_comp_attribute_val: {},
  theme: {},
  time: {},
  style: {},
}

SelectionInfo.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  risk_category: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  risk_subcategory: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  risk_attribute: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  add_comp_attribute_val: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  theme: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  time: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  sentimentType: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  style: PropTypes.shape({}),
}

export default SelectionInfo
