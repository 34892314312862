import axios from 'axios'
import * as AWSCognito from 'amazon-cognito-identity-js'
import { USER_POOL_ID, CLIENT_ID } from '../common'

const Api = {
  homepage: queryObject => axios.put('/views/homepage', queryObject),
  switchLogin: login =>
    new Promise((resolve, reject) => {
      const poolData = {
        UserPoolId: USER_POOL_ID,
        ClientId: CLIENT_ID,
      }

      const userPool = new AWSCognito.CognitoUserPool(poolData)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser !== null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            alert(err)
            return
          }
          const refreshToken = session.getRefreshToken()

          const attributes = [
            {
              Name: 'custom:customer',
              Value: login.name,
            },
            {
              Name: 'custom:schema',
              Value: login.schema,
            },
          ]

          cognitoUser.updateAttributes(attributes, (err, result) => {
            if (err) {
              alert(err)
              return
            }
            cognitoUser.refreshSession(refreshToken, (err, result) => {
              if (err) {
                alert(err)
                return
              }

              resolve({
                data: {
                  user_id: result.idToken.payload['cognito:username'],
                  token: result.idToken.jwtToken,
                  customer: result.idToken.payload['custom:customer'],
                  schema: result.idToken.payload['custom:schema'],
                  logins: result.idToken.payload['custom:logins'],
                },
              })
            })
          })
        })
      }
    }),
}

export default Api
