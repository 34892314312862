import _ from 'lodash'

export const isTimeSuccessive = (reviewYear = [], reviewQuarter = [], reviewMonth = []) => {
  const arr = []
  reviewYear.forEach(e => {
    for (let i = 1; i <= 12; i++) {
      arr.push((parseInt(e, 10) % 100) * 12 + i)
    }
  })
  reviewQuarter.forEach(e => {
    const year = (parseInt(e.substring(0, 4), 10) % 100) * 12
    const q = parseInt(e.substring(5), 10)
    for (let i = (q - 1) * 3 + 1; i <= q * 3; i++) {
      arr.push(year + i)
    }
  })
  reviewMonth.forEach(e => {
    arr.push((parseInt(e.substring(0, 4), 10) % 100) * 12 + parseInt(e.substring(4), 10))
  })
  arr.sort((a, b) => a - b)
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i] + 1 !== arr[i + 1]) return false
  }
  return arr
}

export const getLastYearTime = e => parseInt(e.substring(0, 4) - 1) + e.substring(4)

export const getLastYearFilterSelection = obj => {
  let {
    review_year: { value: yearValue },
    review_quarter: { value: quarterValue },
    review_month: { value: monthValue },
  } = obj
  yearValue = yearValue.map(e => getLastYearTime(e))
  quarterValue = quarterValue.map(e => getLastYearTime(e))
  monthValue = monthValue.map(e => getLastYearTime(e))
  return {
    ...obj,
    review_year: { value: yearValue },
    review_quarter: { value: quarterValue },
    review_month: { value: monthValue },
  }
}

export const isBrandValid = (brand, subBrand) => {
  const brandId = _.isEmpty(brand) ? [] : brand.value
  const subBrandId = _.isEmpty(subBrand) ? [] : subBrand.value
  if ((_.isEmpty(brandId) && _.isEmpty(subBrandId)) || (!_.isEmpty(subBrandId) && brandId.length > 4) || brandId.length > 5) {
    return false
  }
  return true
}
