import { SET_COMPETITIVE_ADVANTAGE_CHART_TYPE, RECEIVE_COMPETITIVE_ADVANTAGE, SET_COMPETITIVE_ADVANTAGE_LOADING_STATE, SET_COMPETITIVE_ADVANTAGE_BRANDS } from './constants/ActionTypes'

const INITIAL_STATE = {
  chartType: 'heatmap',
  competitiveAdvantage: {},
  isLoading: false,
  brandLabel: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_COMPETITIVE_ADVANTAGE:
      return { ...state, competitiveAdvantage: action.payload }
    case SET_COMPETITIVE_ADVANTAGE_CHART_TYPE:
      return { ...state, chartType: action.payload }
    case SET_COMPETITIVE_ADVANTAGE_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_COMPETITIVE_ADVANTAGE_BRANDS:
      return { ...state, brandLabel: action.payload }
    default:
      return state
  }
}
