import { schema } from 'normalizr'

const attributeValueSchema = new schema.Entity('attributeValue', {}, { idAttribute: 'value' })
const attributeSchema = new schema.Entity(
  'attribute',
  {
    children: [attributeValueSchema],
  },
  { idAttribute: 'value' },
)

export const attributeListSchema = [attributeSchema]
