import {
  REQUEST_FORUMS,
  RECEIVE_FORUMS,
  REQUEST_SET_FLAG,
  REQUEST_ADD_TAG,
  REQUEST_DELETE_TAG,
  REQUEST_ADD_ALL_TAGS,
  REQUEST_DELETE_ALL_TAGS,
  REQUEST_DELETE_FLAG,
  REQUEST_WORDS,
  RECEIVE_SET_FLAG,
  RECEIVE_ADD_TAG,
  RECEIVE_DELETE_TAG,
  RECEIVE_WORDS,
  SET_RANGE,
  SET_SEARCH_PARAMS,
  SET_EXPANDED_POSTS_KEYS,
  SET_EXPANDED_COMMENTS_KEYS,
  SET_EDITABLE_TAG_KEYS,
  SET_FLAG_KEYS,
  SET_PAGINATION,
  SET_FORUM_LOADING_STATE,
  SET_FILTER_OBJECT,
  SET_FORUM_TAGS,
  SET_DELETE_TAGS_ID,
  SET_SELECTED_PAGINATION,
  SET_CHART_LOADING_STATE,
} from './constants/ActionTypes'

/**
 * Action creator to fetch forums
 * @param  {object} params {
 *    forumFilterSelection,
 *    pageNum,
 *    pageSize,
 *    searchParams
 * }
 * @return {object} plain object
 */
export const getForums = (params, additionalParams) => ({
  type: REQUEST_FORUMS,
  payload: {
    params,
    additionalParams,
  },
})

// notes: reset expandedRowKeys, tagEditableKeys
export const storeForums = forums => ({
  type: RECEIVE_FORUMS,
  payload: forums,
})

export const getWords = (params, additionalParams) => ({
  type: REQUEST_WORDS,
  payload: {
    params,
    additionalParams,
  },
})

export const storeWords = words => ({
  type: RECEIVE_WORDS,
  payload: words,
})

export const storeForumCount = countArr => ({
  type: SET_RANGE,
  payload: countArr,
})

export const setSearchParams = searchParams => ({
  type: SET_SEARCH_PARAMS,
  payload: searchParams,
})

export const setExpandedPostsKeys = expandedPostsKeys => ({
  type: SET_EXPANDED_POSTS_KEYS,
  payload: expandedPostsKeys,
})

export const setExpandedCommentsKeys = expandedCommentsKeys => ({
  type: SET_EXPANDED_COMMENTS_KEYS,
  payload: expandedCommentsKeys,
})


export const setEditableTagKeys = editableTagKeys => ({
  type: SET_EDITABLE_TAG_KEYS,
  payload: editableTagKeys,
})

export const setFlagKeys = flagKeys => ({
  type: SET_FLAG_KEYS,
  payload: flagKeys,
})

export const updateFlag = (index, forumId, forumExternalId, comment) => ({
  type: REQUEST_SET_FLAG,
  payload: {
    index,
    forumId,
    forumExternalId,
    comment,
  },
})

export const setFlag = (index, flag, forum_flag_id) => ({
  type: RECEIVE_SET_FLAG,
  payload: {
    index,
    flag,
    forum_flag_id,
  },
})

export const deleteFlag = (index, forumFlagId) => ({
  type: REQUEST_DELETE_FLAG,
  payload: {
    index,
    forumFlagId,
  },
})

export const addTag = (index, forumId, forumExternalId, tag) => ({
  type: REQUEST_ADD_TAG,
  payload: {
    index,
    forumId,
    forumExternalId,
    tag,
  },
})

export const addAllTags = (indexArr, forumIdAndExternalIdArr, tag) => ({
  type: REQUEST_ADD_ALL_TAGS,
  payload: {
    indexArr,
    forumIdAndExternalIdArr,
    tag,
  },
})

export const storeTag = (index, tag) => ({
  type: RECEIVE_ADD_TAG,
  payload: {
    index,
    tag,
  },
})

export const deleteTag = (index, tagId) => ({
  type: REQUEST_DELETE_TAG,
  payload: {
    index,
    tagId,
  },
})

export const deleteAllTags = (indexArr, tagIdArr) => ({
  type: REQUEST_DELETE_ALL_TAGS,
  payload: {
    indexArr,
    tagIdArr,
  },
})

export const storeDeleteTag = (index, tagId) => ({
  type: RECEIVE_DELETE_TAG,
  payload: {
    index,
    tagId,
  },
})

export const setPagination = (pageNum, pageSize) => ({
  type: SET_PAGINATION,
  payload: {
    pageNum,
    pageSize,
  },
})

export const setForumLoadingState = isLoading => ({
  type: SET_FORUM_LOADING_STATE,
  payload: isLoading,
})

export const setFilterObject = filterObject => ({
  type: SET_FILTER_OBJECT,
  payload: filterObject,
})

export const setForumTags = forumTagsObj => ({
  type: SET_FORUM_TAGS,
  payload: forumTagsObj,
})

export const setDeleteTagsId = deleteTagsIdArr => ({
  type: SET_DELETE_TAGS_ID,
  payload: deleteTagsIdArr,
})

export const setSelectedPagination = selectedObj => ({
  type: SET_SELECTED_PAGINATION,
  payload: selectedObj,
})

export const setChartLoadingState = isLoading => ({
  type: SET_CHART_LOADING_STATE,
  payload: isLoading,
})
