import {
  REQUEST_FILTERS,
  RECEIVE_FILTERS,
  REQUEST_FORUM_FILTERS,
  RECEIVE_FORUM_FILTERS,
  REQUEST_CUSTOM_ATTRIBUTES,
  SET_FILTER_SELECTION,
  SET_FORUM_FILTER_SELECTION,
  SET_FILTER_ISHIDDEN,
  SET_ADDITIONAL_PARAMS,
} from './constants/ActionTypes'

export const getFilters = () => ({
  type: REQUEST_FILTERS,
})

export const storeFilters = filters => ({
  type: RECEIVE_FILTERS,
  payload: filters,
})

export const getForumFilters = () => ({
  type: REQUEST_FORUM_FILTERS,
})

export const storeForumFilters = filters => ({
  type: RECEIVE_FORUM_FILTERS,
  payload: filters,
})

export const getCustomAttributes = () => ({
  type: REQUEST_CUSTOM_ATTRIBUTES,
})

export const setFilterSelection = filterSelectionObj => ({
  type: SET_FILTER_SELECTION,
  payload: filterSelectionObj,
})

export const setForumFilterSelection = filterSelectionObj => {
  return {
    type: SET_FORUM_FILTER_SELECTION,
    payload: filterSelectionObj,
  }
}

export const setIsFilterHidden = isHidden => ({
  type: SET_FILTER_ISHIDDEN,
  payload: isHidden,
})

export const setAdditionalParams = params => ({
  type: SET_ADDITIONAL_PARAMS,
  payload: params,
})
