import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ title, href }) => (
  <p style={{textAlign: 'center'}}>
    <a href={href} target='_blank'>{title}</a>
  </p>
)

ExternalLink.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

export default ExternalLink
