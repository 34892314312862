import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Button, Select } from 'antd'
import _ from 'lodash'
import { addCustomAttr } from '../actions'

class AddCustomAttributeForm extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.handelAddCustomAttr = this.handelAddCustomAttr.bind(this)
  }

  handelAddCustomAttr() {
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        const { custom_attribute } = this.props.filters
        const result = _.reduce(
          custom_attribute,
          (obj, c) => {
            obj[c.label] = c.children
            return obj
          },
          {},
        )

          const customAttrObj = _.find(result.private, ['label', values.customAttr])
          if (_.isUndefined(customAttrObj)) {
            this.props.addCustomAttr(values.customAttr)
          }

        this.props.form.resetFields()
      }
    })
  }

  render() {
    const FormItem = Form.Item
    const { getFieldDecorator } = this.props.form

    return (
      <Form layout="inline" style={{ paddingBottom: 10 }}>
        <FormItem>
          {getFieldDecorator('customAttr', {
            rules: [
              {
                required: true,
                message: 'Please input your custom attribute!',
              },
            ],
          })(<Input placeholder="Enter custom attribute" />)}
        </FormItem>
        <FormItem>
          <FormItem>
            <Button type="primary" onClick={this.handelAddCustomAttr}>
              Add
            </Button>
          </FormItem>
        </FormItem>
      </Form>
    )
  }
}

AddCustomAttributeForm.propTypes = {
  filters: PropTypes.shape({
    custom_attribute: PropTypes.object,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  addCustomAttr: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    filters: state.insights.filters,
  }
}

AddCustomAttributeForm = Form.create()(AddCustomAttributeForm)
export { AddCustomAttributeForm }
export default connect(mapStateToProps, { addCustomAttr })(AddCustomAttributeForm)
