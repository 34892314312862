import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Button, Tooltip, Select, Modal } from 'antd'

class CustomAttributeForm extends Component {
  constructor(props) {
    super(props)

    this.state = { addDisplay: 'none', deleteDisplay: 'none' }

    this.handleAddCustomAttr = this.handleAddCustomAttr.bind(this)
    this.handleDeleteCustomAttr = this.handleDeleteCustomAttr.bind(this)
    this.handleOnClickAdd = this.handleOnClickAdd.bind(this)
    this.handleOnClickDelete = this.handleOnClickDelete.bind(this)
    this.handleAddReset = this.handleAddReset.bind(this)
    this.handleDeleteReset = this.handleDeleteReset.bind(this)
    this.handleReloadProduct = this.handleReloadProduct.bind(this)
  }

  handleAddCustomAttr() {
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        const [attributeText, attributeType, attributeId] = values.customAttr.split(', ')
        this.props.addCustomAttr(attributeText, attributeType, parseInt(attributeId, 10))
        this.props.form.resetFields()
        this.setState({ addDisplay: 'none' })
      }
    })
  }

  handleDeleteCustomAttr() {
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        this.props.deleteCustomAttr(parseInt(values.customAttr.split(', ')[2], 10))
        this.props.form.resetFields()
        this.setState({ deleteDisplay: 'none' })
      }
    })
  }

  handleAddReset = () => {
    this.props.form.resetFields()
    this.setState({ addDisplay: 'none' })
  }

  handleDeleteReset = () => {
    this.props.form.resetFields()
    this.setState({ deleteDisplay: 'none' })
  }

  handleOnClickAdd = () => {
    const {
      selectedProducts,
      filters: { custom_attribute },
    } = this.props
    if (_.isEmpty(selectedProducts) || _.isEmpty(custom_attribute)) {
      this.showModal()
    } else {
      const { addDisplay } = this.state
      if (addDisplay === 'inline') {
        this.handleAddReset()
      } else {
        this.setState({ addDisplay: 'inline' })
        this.setState({ deleteDisplay: 'none' })
      }
    }
  }

  handleOnClickDelete = () => {
    const {
      selectedProducts,
      filters: { custom_attribute },
    } = this.props
    if (_.isEmpty(selectedProducts) || _.isEmpty(custom_attribute)) {
      this.showModal()
    } else {
      const { deleteDisplay } = this.state
      if (deleteDisplay === 'inline') {
        this.handleDeleteReset()
      } else {
        this.setState({ deleteDisplay: 'inline' })
        this.setState({ addDisplay: 'none' })
      }
    }
  }

  handleReloadProduct = () => {
    this.props.form.resetFields()
    this.setState({ addDisplay: 'none' })
    this.setState({ deleteDisplay: 'none' })
    this.props.reloadProduct()
  }

  showModal = () => {
    Modal.info({
      title: 'Instruction',
      content: (
        <div style={{ marginBottom: -20 }}>
          <p>You should select a product(s) before you add/delete a custom attribute.</p>
          <p>You must also add a custom attribute to the custom attribute table.</p>
        </div>
      ),
      onOk() {},
    })
  }

  render() {
    const FormItem = Form.Item
    const { getFieldDecorator } = this.props.form
    const { addDisplay, deleteDisplay } = this.state
    const { selectedProducts } = this.props
    const { custom_attribute = [] } = this.props.filters
    return (
      <div style={{ marginBottom: 10, borderBottom: '1px solid #eee' }}>
        <Form layout="inline" style={{ paddingBottom: 10 }}>
          <FormItem>
            <Button onClick={this.handleOnClickAdd}>Add Custom Attributes</Button>
          </FormItem>
          <div style={{ display: addDisplay }}>
            <FormItem>
              {getFieldDecorator('customAttr', {
                rules: [
                  {
                    required: true,
                    message: 'Please Select your custom attribute!',
                  },
                ],
              })(
                <Select style={{ width: 200 }} placeholder="Select custom attribute" showSearch>
                  {custom_attribute.map(e =>
                    _.map(e.children, o => (
                      <Select.Option value={`${o.label}, ${e.label}, ${o.key}`} key={o.key}>
                        {`${o.label}, ${e.label}`}
                      </Select.Option>
                    )),
                  )}
                </Select>,
              )}
            </FormItem>
            <FormItem>
              <FormItem>
                <Button type="primary" onClick={this.handleAddCustomAttr}>
                  Add
                </Button>
              </FormItem>
            </FormItem>
          </div>
          <FormItem>
            <Button onClick={this.handleOnClickDelete}>Remove Custom Attributes</Button>
          </FormItem>
          <div style={{ display: deleteDisplay }}>
            <FormItem>
              {getFieldDecorator('customAttr', {
                rules: [
                  {
                    required: true,
                    message: 'Please select your custom attribute!',
                  },
                ],
              })(
                <Select style={{ width: 200 }} placeholder="Select custom attribute" showSearch>
                  {custom_attribute.map(e =>
                    _.map(e.children, o => (
                      <Select.Option value={`${o.label}, ${e.label}, ${o.key}`} key={o.key}>
                        {`${o.label}, ${e.label}`}
                      </Select.Option>
                    )),
                  )}
                </Select>,
              )}
            </FormItem>
            <FormItem>
              <FormItem>
                <Button type="primary" onClick={this.handleDeleteCustomAttr}>
                  Delete
                </Button>
              </FormItem>
            </FormItem>
          </div>
          <FormItem>
            <Tooltip title="Unselect Products">
              <Button shape="circle" icon="reload" onClick={this.handleReloadProduct} />
            </Tooltip>
          </FormItem>
          <FormItem>
            <span>{!_.isEmpty(selectedProducts) ? `Selected ${selectedProducts.length} products` : ''}</span>
          </FormItem>
        </Form>
      </div>
    )
  }
}

CustomAttributeForm.propTypes = {
  filters: PropTypes.shape({
    custom_attribute: PropTypes.object,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  addCustomAttr: PropTypes.func.isRequired,
  deleteCustomAttr: PropTypes.func.isRequired,
  reloadProduct: PropTypes.func.isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

function mapStateToProps(state) {
  return {
    filters: state.insights.filters,
  }
}

CustomAttributeForm = Form.create()(CustomAttributeForm)
export { CustomAttributeForm }
export default connect(mapStateToProps, {})(CustomAttributeForm)
