import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Result } from 'antd'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('error: ', error)
    console.log('errorInfo: ', errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <Result status="warning" subTitle="An error occured during the operation." />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.shape({}).isRequired,
}

export default ErrorBoundary
