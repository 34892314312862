const config = {
  title: {
    show: false,
  },
  tooltip: {
    show: true,
    trigger: 'axis',
    position: point => [point[0] + 35, point[1] - 40],
    textStyle: {
      fontSize: 12,
    },
  },
  grid: {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
  },
  toolbox: {
    show: false,
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    axisLabel: {
      show: false,
    },
  },
  yAxis: [
    {
      type: 'value',
      // name: 'Reviews',
      max: 'dataMax',
      min: 'dataMin',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    {
      type: 'value',
      // name: 'Sentiment',
      max: 'dataMax',
      min: 'dataMin',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
  ],
  color: ['#069', '#993300'],
  animationEasing: 'elasticOut',
}

export default config
