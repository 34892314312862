import { takeLatest, call, put, all } from 'redux-saga/effects'
import _ from 'lodash'
import { monthLabel } from '../common'
import { parseRating, buildQueryObject } from '../insights'
import { setPeiScorecardLoadingState, setOriginalFilterSelection, setTimePeriod, setPEIByRetailerLoadingState } from './actions'
import { REQUEST_PEI_SCORECARD, REQUEST_PEI_BY_RETAILER } from './constants/ActionTypes'
import { REQUEST_PEI_SCORECARD_QUARTER } from '../epic/constants/ActionTypes'
import { isTimeSuccessive, getLastYearFilterSelection, isBrandValid } from './constants/helper'
import Api from './api'

export function* getPeiScorecard(action) {
  yield put(setPeiScorecardLoadingState(true))

  const { filterSelection, storePeiScorecard } = action.payload

  // Determin whether brand filerSelection is valid or not
  const { brand, sub_brand } = filterSelection
  if (!isBrandValid(brand, sub_brand)) {
    yield put(storePeiScorecard(null))
    yield put(setPeiScorecardLoadingState(false))
    return
  }
  // FilterSelection is valid
  // Step1: men, women, kids department ID mapping
  const schema = window.localStorage.getItem('schema')
  const department = JSON.parse(window.localStorage.getItem('department'))
  const departmentIdObj = {}
  if (schema.startsWith('fashion')) {
    const kidsMap = ['girls', 'boys', 'kids', 'toddler girls', 'toddler boys', 'toddlers', 'baby girls', 'baby boys', 'babies', 'junior girls', 'junior boys']
    const kidsArr = []
    _.keys(department).forEach(e => {
      if (e === 'men') {
        departmentIdObj.a = [department[e]]
      } else if (e === 'women') {
        departmentIdObj.b = [department[e]]
      } else if (kidsMap.indexOf(e) !== -1) {
        kidsArr.push(department[e])
      }
    })
    departmentIdObj.c = kidsArr
  } else if (schema.startsWith('lubricant')) {
    _.keys(department).forEach(e => {
      if (e === 'industrial') {
        departmentIdObj.a = [department[e]]
      } else if (e === 'automotive') {
        departmentIdObj.b = [department[e]]
      } else if (e === 'multi department') {
        departmentIdObj.c = [department[e]]
      } else if (e === 'scientific') {
        departmentIdObj.d = [department[e]]
      } else if (e === 'home') {
        departmentIdObj.e = [department[e]]
      } else if (e === 'janitorial') {
        departmentIdObj.f = [department[e]]
      }
    })
  } else if (schema.startsWith('beauty')) {
    _.keys(department).forEach(e => {
      if (e === 'skin care') {
        departmentIdObj.a = [department[e]]
      } else if (e === 'cosmetics') {
        departmentIdObj.b = [department[e]]
      } else if (e === 'hair care') {
        departmentIdObj.c = [department[e]]
      }
    })
  } else if (schema.startsWith('health')) {
    _.keys(department).forEach(e => {
      if (e === 'adult') {
        departmentIdObj.a = [department[e]]
      } else if (e === 'kids') {
        departmentIdObj.b = [department[e]]
      } else if (e === 'baby') {
        departmentIdObj.c = [department[e]]
      }
    })
  }

  // Step2
  yield put(setOriginalFilterSelection(filterSelection))
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObject = buildQueryObject({ ...filterSelection, department: null, review_rating: ratingArr }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObject.additional_filter,
      ...formattedQueryObject.filter_object,
      ...formattedQueryObject.search_object,
    },
  }

  // Check whether time filter is successive or not
  let isSuccessive = false
  if (!_.isEmpty(filterSelection.review_year)) {
    const isSuccessiveArr = isTimeSuccessive(filterSelection.review_year.value, filterSelection.review_quarter.value, filterSelection.review_month.value)
    const startTime = isSuccessiveArr[0] - 1
    const endTime = isSuccessiveArr[isSuccessiveArr.length - 1] - 1
    const monthArr = monthLabel
    const timePeriod = `20${Math.floor(startTime / 12)} ${monthArr[startTime % 12]} - 20${Math.floor(endTime / 12)} ${monthArr[endTime % 12]}`
    yield put(setTimePeriod(timePeriod.includes('undefined') ? '' : timePeriod))
    isSuccessive = isSuccessiveArr.length > 0
  }

  // Department keys must be alphabetized in order. 10 departments should be sufficient.
  let dept_arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']

  // Generate required queries for each department
  const dept_query_arr = []
  for (let i = 0; i < _.keys(departmentIdObj).length; i++) {
    let keyObj = null
    if (!_.isEmpty(departmentIdObj[dept_arr[i]])) {
      keyObj = JSON.parse(JSON.stringify(queryObject))
      keyObj.query_object.department = `(${departmentIdObj[dept_arr[i]].join(',')})`
      keyObj = { ...keyObj }
    }
    dept_query_arr.push(keyObj)
  }

  try {
    const data = []
    if (!isSuccessive) {
      const arr = [queryObject, ...dept_query_arr]
      for (const x of arr) {
        let res = null
        if (x !== null) {
          res = yield call(Api.getPeiScorecard, { ...x })
          const resData = [ ...res.data ]
          if (resData.length) {
            resData[0].department_id = (x.query_object.department ? x.query_object.department : 0)
          } else {
            resData.push({department_id: x.query_object.department})
          }
          data.push(resData)
        } else {
          data.push(null)
        }
        data.push(null)
      }
    } else {
      // Time Filter is successive then calculate the corresponding last year
      const obj = JSON.parse(JSON.stringify(filterSelection))
      const lastYearFilterSelection = getLastYearFilterSelection(obj)
      const formattedIsSuccessionQueryObject = buildQueryObject({ ...lastYearFilterSelection, department: null, review_rating: ratingArr }, {}, {})
      const isSuccessionQueryObject = {
        query_object: {
          ...formattedIsSuccessionQueryObject.additional_filter,
          ...formattedIsSuccessionQueryObject.filter_object,
          ...formattedIsSuccessionQueryObject.search_object,
        },
      }

      // Generate required successive queries for each department
      const dept_successive_query_arr = []
      for (let i = 0; i < _.keys(departmentIdObj).length; i++) {
        let keyObjSuccession = null
        if (!_.isEmpty(departmentIdObj[dept_arr[i]])) {
          keyObjSuccession = JSON.parse(JSON.stringify(isSuccessionQueryObject))
          keyObjSuccession.query_object.department = `(${departmentIdObj[dept_arr[i]].join(',')})`
          keyObjSuccession = { ...keyObjSuccession }
        }
        dept_successive_query_arr.push(dept_query_arr[i]) // Append dept query
        dept_successive_query_arr.push(keyObjSuccession) // Append successive query
      }

      const arr = [queryObject, isSuccessionQueryObject, ...dept_successive_query_arr]
      for (const x of arr) {
        let res = null
        if (x !== null) {
          res = yield call(Api.getPeiScorecard, { ...x })
          const resData = [ ...res.data ]
          if (resData.length) {
            resData[0].department_id = (x.query_object.department ? x.query_object.department : 0)
          } else {
            resData.push({department_id: x.query_object.department})
          }
          data.push(resData)
        } else {
          data.push(null)
        }
      }
    }
    yield put(storePeiScorecard(data))
    yield put(setPeiScorecardLoadingState(false))
  } catch (err) {
    yield put(setPeiScorecardLoadingState(false))
  }
}

export function* getPEIByRetailer(action) {
  const { type, department, storePEIByRetailer, filterSelection } = action.payload
  yield put(setPEIByRetailerLoadingState(true))
  const clientName = window.localStorage.getItem('clientName')
  const formattedQueryObject = buildQueryObject({ ...filterSelection }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObject.additional_filter,
      ...formattedQueryObject.filter_object,
      ...formattedQueryObject.search_object,
    },
  }
  if (department) queryObject.query_object.department = department

  try {
    let response = {}
    switch (type) {
      case 'source':
        response = yield call(Api.getPEIBySource, { ...queryObject })
        break;
      case 'category':
        response = yield call(Api.getPEIByCategory, { ...queryObject })
        break;
      default:
        response = yield call(Api.getPEIByCategory, { ...queryObject })
        break;
    }
    const data = response.data[0].data
    let returnedData = [['source', clientName, 'Competitors']]
    if (data) {
      const allPeiResponse = yield call(Api.getAllPei, { ...queryObject })
      returnedData.push([
        'All',
        _.round(allPeiResponse.data[0].client.pei * 100),
        _.round(allPeiResponse.data[0].competitor.pei * 100)
      ])
      data.forEach(pei => {
        returnedData.push([
          pei.source_name || _.startCase(pei.category_name),
          _.round(pei.client_pei * 100),
          _.round(pei.competitor_pei * 100)
        ])
      })
    } else {
      returnedData = []
    }

    yield put(storePEIByRetailer(returnedData))
    yield put(setPEIByRetailerLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setPEIByRetailerLoadingState(false))
  }
}

export default function* watchPeiScorecard() {
  yield all([
    takeLatest(REQUEST_PEI_SCORECARD, getPeiScorecard),
    takeLatest(REQUEST_PEI_SCORECARD_QUARTER, getPeiScorecard),
    takeLatest(REQUEST_PEI_BY_RETAILER, getPEIByRetailer)
  ])
}
