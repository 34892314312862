import {
  REQUEST_REVIEWS,
  REQUEST_ALL_REVIEWS,
  REQUEST_SET_FLAG,
  REQUEST_ADD_TAG,
  REQUEST_DELETE_TAG,
  REQUEST_ADD_ALL_TAGS,
  REQUEST_DELETE_ALL_TAGS,
  REQUEST_DELETE_FLAG,
  REQUEST_WORDS,
  REQUEST_PEI,
  RECEIVE_REVIEWS,
  RECEIVE_ALL_REVIEWS,
  RECEIVE_SET_FLAG,
  RECEIVE_ADD_TAG,
  RECEIVE_DELETE_TAG,
  RECEIVE_WORDS,
  RECEIVE_PEI,
  SET_DOWNLOAD_PERCENTAGE,
  SET_RANGE,
  SET_SEARCH_PARAMS,
  SET_EXPANDED_ROW_KEYS,
  SET_EDITABLE_TAG_KEYS,
  SET_FLAG_KEYS,
  SET_PAGINATION,
  SET_REVIEW_LOADING_STATE,
  SET_PEI_LOADING_STATE,
  SET_FILTER_OBJECT,
  SET_REVIEW_TAGS,
  SET_DELETE_TAGS_ID,
  SET_SELECTED_PAGINATION,
  SET_CHART_LOADING_STATE,
} from './constants/ActionTypes'

/**
 * Action creator to fetch reviews
 * @param  {object} params {
 *    filterSelection,
 *    pageNum,
 *    pageSize,
 *    searchParams
 * }
 * @return {object} plain object
 */
export const getReviews = (params, additionalParams) => ({
  type: REQUEST_REVIEWS,
  payload: {
    params,
    additionalParams,
  },
})

// notes: reset expandedRowKeys, tagEditableKeys
export const storeReviews = reviews => ({
  type: RECEIVE_REVIEWS,
  payload: reviews,
})

export const getAllReviews = (params, additionalParams, resolve, reject) => ({
  type: REQUEST_ALL_REVIEWS,
  payload: {
    params,
    additionalParams,
    resolve,
    reject
  },
})

export const storeAllReviews = reviews => ({
  type: RECEIVE_ALL_REVIEWS,
  payload: reviews,
})

export const storeDownloadPercentage = percentage => ({
  type: SET_DOWNLOAD_PERCENTAGE,
  payload: percentage,
})

export const getWords = (params, additionalParams) => ({
  type: REQUEST_WORDS,
  payload: {
    params,
    additionalParams,
  },
})

export const storeWords = words => ({
  type: RECEIVE_WORDS,
  payload: words,
})

export const getPEI = (params, additionalParams) => ({
  type: REQUEST_PEI,
  payload: {
    params,
    additionalParams,
  },
})

export const storePEI = pei => ({
  type: RECEIVE_PEI,
  payload: pei,
})

export const storeReviewCount = countArr => ({
  type: SET_RANGE,
  payload: countArr,
})

export const setSearchParams = searchParams => ({
  type: SET_SEARCH_PARAMS,
  payload: searchParams,
})

export const setExpandedRowKeys = expandedRowKeys => ({
  type: SET_EXPANDED_ROW_KEYS,
  payload: expandedRowKeys,
})

export const setEditableTagKeys = editableTagKeys => ({
  type: SET_EDITABLE_TAG_KEYS,
  payload: editableTagKeys,
})

export const setFlagKeys = flagKeys => ({
  type: SET_FLAG_KEYS,
  payload: flagKeys,
})

export const updateFlag = (index, reviewId, reviewExternalId, comment) => ({
  type: REQUEST_SET_FLAG,
  payload: {
    index,
    reviewId,
    reviewExternalId,
    comment,
  },
})

export const setFlag = (index, flag, review_flag_id) => ({
  type: RECEIVE_SET_FLAG,
  payload: {
    index,
    flag,
    review_flag_id,
  },
})

export const deleteFlag = (index, reviewFlagId) => ({
  type: REQUEST_DELETE_FLAG,
  payload: {
    index,
    reviewFlagId,
  },
})

export const addTag = (index, reviewId, reviewExternalId, tag) => ({
  type: REQUEST_ADD_TAG,
  payload: {
    index,
    reviewId,
    reviewExternalId,
    tag,
  },
})

export const addAllTags = (indexArr, reviewIdAndExternalIdArr, tag) => ({
  type: REQUEST_ADD_ALL_TAGS,
  payload: {
    indexArr,
    reviewIdAndExternalIdArr,
    tag,
  },
})

export const storeTag = (index, tag) => ({
  type: RECEIVE_ADD_TAG,
  payload: {
    index,
    tag,
  },
})

export const deleteTag = (index, tagId) => ({
  type: REQUEST_DELETE_TAG,
  payload: {
    index,
    tagId,
  },
})

export const deleteAllTags = (indexArr, tagIdArr) => ({
  type: REQUEST_DELETE_ALL_TAGS,
  payload: {
    indexArr,
    tagIdArr,
  },
})

export const storeDeleteTag = (index, tagId) => ({
  type: RECEIVE_DELETE_TAG,
  payload: {
    index,
    tagId,
  },
})

export const setPagination = (pageNum, pageSize) => ({
  type: SET_PAGINATION,
  payload: {
    pageNum,
    pageSize,
  },
})

export const setReviewLoadingState = isLoading => ({
  type: SET_REVIEW_LOADING_STATE,
  payload: isLoading,
})

export const setPEILoadingState = isLoading => ({
  type: SET_PEI_LOADING_STATE,
  payload: isLoading,
})

export const setFilterObject = filterObject => ({
  type: SET_FILTER_OBJECT,
  payload: filterObject,
})

export const setReviewTags = reviewTagsObj => ({
  type: SET_REVIEW_TAGS,
  payload: reviewTagsObj,
})

export const setDeleteTagsId = deleteTagsIdArr => ({
  type: SET_DELETE_TAGS_ID,
  payload: deleteTagsIdArr,
})

export const setSelectedPagination = selectedObj => ({
  type: SET_SELECTED_PAGINATION,
  payload: selectedObj,
})

export const setChartLoadingState = isLoading => ({
  type: SET_CHART_LOADING_STATE,
  payload: isLoading,
})
