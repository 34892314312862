import { REQUEST_PEI_SCORE_PRODUCT, RECEIVE_PEI_SCORE_PRODUCT, SET_MARKETPLACE_TREND_THRESHOLD, SET_MARKETPLACE_TREND_LOADING_STATE } from './constants/ActionTypes'

export const getPeiScoreProduct = obj => ({
  type: REQUEST_PEI_SCORE_PRODUCT,
  payload: obj,
})

export const storePeiScoreProduct = obj => ({
  type: RECEIVE_PEI_SCORE_PRODUCT,
  payload: obj,
})

export const setMarketplaceTrendThreshold = threshold => ({
  type: SET_MARKETPLACE_TREND_THRESHOLD,
  payload: threshold,
})

export const setMarketplaceTrendLoadingState = isLoading => ({
  type: SET_MARKETPLACE_TREND_LOADING_STATE,
  payload: isLoading,
})
