import {
  REQUEST_COMPETITIVE_ADVANTAGE,
  RECEIVE_COMPETITIVE_ADVANTAGE,
  SET_COMPETITIVE_ADVANTAGE_CHART_TYPE,
  SET_COMPETITIVE_ADVANTAGE_LOADING_STATE,
  SET_COMPETITIVE_ADVANTAGE_BRANDS,
} from './constants/ActionTypes'

export const getCompetitiveAdvantage = params => ({
  type: REQUEST_COMPETITIVE_ADVANTAGE,
  payload: params,
})

export const storeCompetitiveAdvantage = competitiveAdvantage => ({
  type: RECEIVE_COMPETITIVE_ADVANTAGE,
  payload: competitiveAdvantage,
})

export const setCompetitiveAdvantageChartType = chartType => ({
  type: SET_COMPETITIVE_ADVANTAGE_CHART_TYPE,
  payload: chartType,
})

export const setCompetitiveAdvantageLoadingState = isLoading => ({
  type: SET_COMPETITIVE_ADVANTAGE_LOADING_STATE,
  payload: isLoading,
})

export const setCompetitiveAdvantageBrands = obj => ({
  type: SET_COMPETITIVE_ADVANTAGE_BRANDS,
  payload: obj,
})
