import {
  REQUEST_SENTIMENT_TREND,
  REQUEST_PEI_TREND_BRAND,
  REQUEST_THEME_TREND_COMPETITOR,
  REQUEST_RISKS_PRODUCT,
  REQUEST_TOPIC_FREQUENCY,
  RECEIVE_PEI_TREND_BRAND,
  RECEIVE_PEI_TREND_COMPETITOR,
  RECEIVE_THEME_TREND_BRAND,
  RECEIVE_THEME_TREND_COMPETITOR,
  RECEIVE_RISKS_PRODUCT,
  RECEIVE_THEME_FREQUENCY,
  RECEIVE_TOPIC_FREQUENCY,
  SET_SENTIMENT_TREND_LOADING_STATE,
  SET_TOPIC_FREQUENCY_LOADING_STATE,
} from './constants/ActionTypes'

export const getSentimentTrend = obj => ({
  type: REQUEST_SENTIMENT_TREND,
  payload: obj,
})

export const getPeiTrendBrand = obj => ({
  type: REQUEST_PEI_TREND_BRAND,
  payload: obj,
})

export const getThemeTrendCompetitor = obj => ({
  type: REQUEST_THEME_TREND_COMPETITOR,
  payload: obj,
})

export const getRisksProduct = obj => ({
  type: REQUEST_RISKS_PRODUCT,
  payload: obj,
})

export const getTopicFrequency = obj => ({
  type: REQUEST_TOPIC_FREQUENCY,
  payload: obj,
})

export const storePeiTrendBrand = obj => ({
  type: RECEIVE_PEI_TREND_BRAND,
  payload: obj,
})

export const storePeiTrendCompetitor = obj => ({
  type: RECEIVE_PEI_TREND_COMPETITOR,
  payload: obj,
})

export const storeThemeTrendBrand = obj => ({
  type: RECEIVE_THEME_TREND_BRAND,
  payload: obj,
})

export const storeRisksProduct = obj => ({
  type: RECEIVE_RISKS_PRODUCT,
  payload: obj,
})

export const storeThemeTrendCompetitor = obj => ({
  type: RECEIVE_THEME_TREND_COMPETITOR,
  payload: obj,
})

export const storeThemeFrequency = obj => ({
  type: RECEIVE_THEME_FREQUENCY,
  payload: obj,
})

export const storeTopicFrequency = obj => ({
  type: RECEIVE_TOPIC_FREQUENCY,
  payload: obj,
})

export const setSentimentTrendLoadingState = isLoading => ({
  type: SET_SENTIMENT_TREND_LOADING_STATE,
  payload: isLoading,
})

export const setTopicFrequencyLoadingState = isLoading => ({
  type: SET_TOPIC_FREQUENCY_LOADING_STATE,
  payload: isLoading,
})