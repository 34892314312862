export const searchTypes = [
  {
    id: 1,
    name: 'Product Search',
    type: 'Product',
    fieldOfSearch: 'product',
  },
]

export const logicParams = [
  {
    name: 'And',
    character: '&',
    id: 0,
    encoding: '%26',
  },
  {
    name: 'Or',
    character: '|',
    id: 1,
    encoding: '%7C',
  },
]
