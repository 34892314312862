import { REQUEST_PEI_SCORECARD_QUARTER, RECEIVE_PEI_SCORECARD_QUARTER } from './constants/ActionTypes'

export const getPeiScorecardQuarter = (obj, storePeiScorecard) => ({
  type: REQUEST_PEI_SCORECARD_QUARTER,
  payload: {
    filterSelection: obj,
    storePeiScorecard,
  },
})

export const storePeiScorecardQuarter = obj => ({
  type: RECEIVE_PEI_SCORECARD_QUARTER,
  payload: obj,
})
