import { RECEIVE_HOMEPAGE_VAR, SET_HOMEPAGE_LOADING_STATE, SET_HOMEPAGE_SELECTION, SET_FILTER_SELECTION } from './constants/ActionTypes'

const INITIAL_STATE = {
  homepageVar: {},
  isHomepageLoading: false,
  selection: '',
  filterSelection: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_HOMEPAGE_VAR:
      return { ...state, homepageVar: action.payload }
    case SET_HOMEPAGE_LOADING_STATE:
      return { ...state, isHomepageLoading: action.payload }
    case SET_HOMEPAGE_SELECTION:
      return { ...state, selection: action.payload }
    case SET_FILTER_SELECTION:
      return { ...state, filterSelection: { ...state.filterSelection, ...action.payload } }
    default:
      return state
  }
}
