import React from 'react'
import PropTypes from 'prop-types'
import { Result } from 'antd'

const EmptyStateInstruction = ({ instruction }) => <Result subTitle={instruction} />

EmptyStateInstruction.defaultProps = {
  instruction: '',
}

EmptyStateInstruction.propTypes = {
  instruction: PropTypes.string,
}
export default EmptyStateInstruction
