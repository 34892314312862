import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects'
import { storeFacets, setSaved, storeSavedFacets, storeDeleteFacets, setFacetsLoadingState, setExpandedRowKeys, setSavedExpandedRowKeys } from './actions'
import { REQUEST_FACETS, SAVE_FACETS, REQUEST_SAVED_FACETS, DELETE_FACETS } from './constants/ActionTypes'
import { parseRating, buildQueryObject, buildAdditionalFilterObject } from '../insights'
import Api from './api'

export function selectFacets(state) {
  return state.facetedExplorer.facets
}

export function selectExpandedRowKeys(state) {
  return state.facetedExplorer.expandedRowKeys
}
/**
 * Fetch facet explorer report from the server
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* getFacets(action) {
  yield put(setFacetsLoadingState(true))

  const filterSelection = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    {},
    {},
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
    },
  }
  try {
    const response = yield call(Api.getFacets, { ...queryObject })
    const { data } = response
    // insert the search filterSelection into response data
    data.filterSelection = filterSelection
    const facets = yield select(selectFacets)
    const newFacets = [...facets, data]
    // set expandedRowKeys to make all rows expanded default
    const expandedRowKeys = yield select(selectExpandedRowKeys)
    yield put(setExpandedRowKeys([...expandedRowKeys, `${newFacets.length - 1}`]))
    yield put(storeFacets(newFacets))
    yield put(setFacetsLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setFacetsLoadingState(false))
  }
}

export function* saveFacets(action) {
  const { index, filterSelection } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    {},
    {},
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.search_object,
      filter_selection: formattedQueryObj.filter_object,
      filter_selection_text: filterSelection,
    },
  }
  try {
    yield call(Api.saveFacets, { ...queryObject })
    yield put(setSaved(index, true))
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * Fetch all saved facet reports from the server
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* getSavedFacets() {
  yield put(setFacetsLoadingState(true))

  try {
    const response = yield call(Api.getSavedFacets)
    const saveFacetsData = response.data
    const expandedRowKeys = []
    saveFacetsData.forEach((value, index) => expandedRowKeys.push(`${index}`))
    yield put(setSavedExpandedRowKeys([...expandedRowKeys]))
    const responseArr = yield all(
      saveFacetsData.map(e => {
        const queryObject = {
          query_object: {
            ...e.filter_selection,
          },
        }
        const response = call(Api.getFacets, { ...queryObject })
        return response
      }),
    )
    const savedFacetsArr = responseArr.map((res, i) => ({
      ...res.data,
      filter_selection_text: saveFacetsData[i].filter_selection_text,
      saved_report_id: saveFacetsData[i].saved_report_id,
    }))
    yield put(storeSavedFacets(savedFacetsArr))
    yield put(setFacetsLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setFacetsLoadingState(false))
  }
}

export function* deleteFacets(action) {
  const { facetedId, index } = action.payload

  const queryObject = {
    query_object: {
      saved_report_id: facetedId,
    },
  }
  try {
    yield call(Api.deleteFacets, { ...queryObject })
    yield put(storeDeleteFacets(index))
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * Watcher Saga for actions of product explorer
 * @return {Generator}
 */
export default function* watchFacets() {
  yield all([takeLatest(REQUEST_FACETS, getFacets)])
  yield all([takeLatest(REQUEST_SAVED_FACETS, getSavedFacets)])
  yield all([takeEvery(SAVE_FACETS, saveFacets)])
  yield all([takeEvery(DELETE_FACETS, deleteFacets)])
}
