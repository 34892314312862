import React from 'react'
import { Spin } from 'antd'

const NoFilter = () => (
  <Spin tip="Loading...">
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginBottom: '40px' }}>FILTER SET</h3>
    </div>
  </Spin>
)

export default NoFilter
