import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import _ from 'lodash'
import { Button, Tooltip, Input, Select, Tag, Popover, Icon } from 'antd'
import { setSearchParams, getForums, getWords } from '../actions'
import { searchTypes, logicParams } from '../constants/textSearch'
import FrequencyHistogram from './frequency-histogram'
// import './forum.css'

export class ForumControls extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
      loading: false,
    }

    this.handleTextSearchParamSelect = this.handleTextSearchParamSelect.bind(this)
    this.handleTextSearch = this.handleTextSearch.bind(this)
    this.handleDeleteKeyWords = this.handleDeleteKeyWords.bind(this)
    this.handleClearTextSearch = this.handleClearTextSearch.bind(this)
    this.handleGetWords = this.handleGetWords.bind(this)
  }

  handleTextSearchParamSelect(newSearchParams) {
    this.props.setSearchParams(newSearchParams)
  }

  handleClearTextSearch(fieldOfSearch) {
    // clear keyWords
    const { searchParams, forumFilterSelection, pageSize } = this.props
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: { ...searchParams[fieldOfSearch], keyWords: [] },
    }
    this.props.setSearchParams(newSearchParams)
    // fetch reviews based on filter selection (pageNum = 1)
    this.props.getForums({
      forumFilterSelection,
      pageNum: 1,
      pageSize,
      searchParams: newSearchParams,
    })
  }

  handleTextSearch(text) {
    const { forumFilterSelection, pageSize, searchParams } = this.props
    // clean text in input box
    this.setState({ text: '' })
    // if the user typed "tight pants" in the search text
    // should split them into 2 separate words
    const rule = /\"(.*?)\"/g
    // trim text, abort searching if trimed text is empty or already exists
    const trimedText = text.trim().toLowerCase()
    if (_.isEmpty(trimedText)) {
      return
    }
    let curKeyWords = trimedText.match(rule)
    if (!curKeyWords) curKeyWords = [trimedText]
    curKeyWords.forEach((kw, i) => {
      curKeyWords[i] = _.replace(curKeyWords[i], new RegExp('"', 'g'), '')
    })
    // get current search type
    const { fieldOfSearch } = searchParams.searchType
    // union the new added key words (de-duplicate)
    const newKeyWords = _.union(searchParams[fieldOfSearch].keyWords, curKeyWords)
    if (_.isEqual(searchParams[fieldOfSearch].keyWords, newKeyWords)) {
      // terminate if the new added key words are exactly the same as the former key words list
      return
    }
    // insert keyWords
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: {
        ...searchParams[fieldOfSearch],
        keyWords: newKeyWords,
      },
    }
    this.props.setSearchParams(newSearchParams)
    // search reviews
    this.props.getForums({
      forumFilterSelection,
      pageNum: 1,
      pageSize,
      searchParams: newSearchParams,
    })
  }

  handleDeleteKeyWords(keyWord, fieldOfSearch) {
    const { searchParams, forumFilterSelection, pageSize } = this.props
    // remove this keyword from searchParams.keyWords array
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: {
        ...searchParams[fieldOfSearch],
        keyWords: searchParams[fieldOfSearch].keyWords.filter(word => word !== keyWord),
      },
    }
    this.props.setSearchParams(newSearchParams)
    // re-search reviews
    this.props.getForums({
      forumFilterSelection,
      pageNum: 1, // reset to first page in case of "out-of-range"
      pageSize,
      searchParams: newSearchParams,
    })
  }

  handleGetWords() {
    const { forumFilterSelection, searchParams } = this.props
    this.props.getWords({
      forumFilterSelection,
      searchParams,
    })
  }

  generateKeyWords(keyWords, logicParam, fieldOfSearch) {
    if (_.isEmpty(keyWords)) {
      return null
    }
    const keyWordsList = []
    keyWords.forEach((word, idx) => {
      if (idx !== keyWords.length - 1) {
        keyWordsList.push(
          <Tag className="keyword" key={word} closable onClose={() => this.handleDeleteKeyWords(word, fieldOfSearch)}>
            {word}
          </Tag>,
        )
        keyWordsList.push(
          <span key={`${logicParam.name}-${word}`} className="logic-param">
            {logicParam.name}
          </span>,
        )
      } else {
        keyWordsList.push(
          <Tag className="keyword" key={word} closable onClose={() => this.handleDeleteKeyWords(word, fieldOfSearch)}>
            {word}
          </Tag>,
        )
      }
    })
    return keyWordsList
  }

  render() {
    const {
      searchParams,
      searchParams: { searchType, logicParam, forum },
      arePostsMetadataShown,
      areCommentsMetadataShown,
      areTagsEditable,
      togglePostsMetadata,
      toggleCommentsMetadata,
      toggleTagEditable,
    } = this.props
    const { keyWords: forumKeyWords } = forum
    const { text } = this.state

    return (
      <div className="forum-controls">
        <Input.Group compact className="mb-10 mr-10 text-search">
          <Tooltip title={searchType ? `Search In ${searchType.type}` : ''}>
            <Select
              onChange={value => {
                const curSearchType = searchTypes.find(eachSearchType => eachSearchType.type === value)
                this.handleTextSearchParamSelect({
                  ...searchParams,
                  searchType: curSearchType,
                  [curSearchType.fieldOfSearch]: {
                    ...searchParams[curSearchType.fieldOfSearch],
                    logicParam: searchParams.logicParam,
                  },
                })
              }}
              value={searchType.type}
              placeholder="Type"
            >
              {searchTypes.map(each => (
                <Select.Option key={each.id} value={each.type}>
                  {each.type}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
          <Tooltip title="Logical Operator Between Keywords">
            <Select
              onChange={value => {
                const {
                  searchType: { fieldOfSearch },
                } = searchParams
                const curLogicParam = logicParams.find(eachLogicParam => eachLogicParam.name === value)
                this.handleTextSearchParamSelect({
                  ...searchParams,
                  logicParam: curLogicParam,
                  [fieldOfSearch]: {
                    ...searchParams[fieldOfSearch],
                    logicParam: curLogicParam,
                  },
                })
              }}
              value={logicParam.name}
              placeholder="logic Parameter"
            >
              {logicParams.map(each => (
                <Select.Option key={each.id} value={each.name}>
                  {each.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
          <Input.Search
            value={text}
            placeholder={searchType ? `Search in ${searchType.type} ...` : 'Search ...'}
            style={{ width: 200 }}
            onChange={e => this.setState({ text: e.target.value })}
            onSearch={this.handleTextSearch}
          />
          <Tooltip
            placement="bottom"
            title={
              <span>
                Keywords or phrases can be searched.
                <br />
                <br />
                Separate multiple keywords or phrases with quotation marks, ex: "comfortable fit" "soft".
                <br />
                <br />
                Common words may be excluded from search.
              </span>
            }
          >
            <Icon className="help-icon" type="question-circle" theme="filled" />
          </Tooltip>
        </Input.Group>
        <Button.Group className="mb-10 mr-10">
          <Button onClick={togglePostsMetadata} icon={arePostsMetadataShown ? 'shrink' : 'arrows-alt'}>
            {arePostsMetadataShown ? 'Hide All Posts' : 'Expand All Posts'}
          </Button>
          <Button onClick={toggleCommentsMetadata} icon={areCommentsMetadataShown ? 'shrink' : 'arrows-alt'}>
            {areCommentsMetadataShown ? 'Hide All Comments' : 'Expand All Comments'}
          </Button>
          <Tooltip title={areTagsEditable ? 'Preview All Tags' : 'Edit All Tags'}>
            <Button onClick={toggleTagEditable} icon={areTagsEditable ? 'eye-o' : 'edit'} />
          </Tooltip>
        </Button.Group>
        <Popover content={<FrequencyHistogram />} placement="bottom" title="Text Histogram" trigger="click">
          <Button onClick={this.handleGetWords}>Text Histogram</Button>
        </Popover>
        {_.isEmpty(forumKeyWords) ? null : (
          <div className="keywords-list">
            <span className="title">Key Words:&nbsp;</span>
            {this.generateKeyWords(forumKeyWords, forum.logicParam, 'forum')}
            <span className="search-type-desc">by Forum Search</span>
            <span className="span-link clear-search" onClick={() => this.handleClearTextSearch('forum')}>
              Clear Search
            </span>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { currentViewName } = ownProps
  return {
    forumFilterSelection: state[currentViewName].forumFilterSelection,
    searchParams: state.forum.searchParams,
    pageSize: state.forum.pageSize,
  }
}

ForumControls.propTypes = {
  forumFilterSelection: PropTypes.shape({}).isRequired,
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    forum: PropTypes.shape({}).isRequired,
  }),
  pageSize: PropTypes.number.isRequired,
  arePostsMetadataShown: PropTypes.bool.isRequired,
  areCommentsMetadataShown: PropTypes.bool.isRequired,
  areTagsEditable: PropTypes.bool.isRequired,
  setSearchParams: PropTypes.func.isRequired,
  getForums: PropTypes.func.isRequired,
  getWords: PropTypes.func.isRequired,
  togglePostsMetadata: PropTypes.func.isRequired,
  toggleCommentsMetadata: PropTypes.func.isRequired,
  toggleTagEditable: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  setSearchParams,
  getForums,
  getWords,
})(ForumControls)
