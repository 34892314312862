import React from 'react'
import splashImg from '../img/splash.png'

const SplashImg = () => (
  <div style={{ display: 'flex', height: 'calc(100vh - 64px - 48px)' }}>
    <img src={splashImg} style={{ margin: 'auto', width: 671, height: 386 }} alt="splash" />
  </div>
)

SplashImg.defaultProps = {}

SplashImg.propTypes = {}

export default SplashImg
