import _ from 'lodash'
import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects'
import { Input } from 'antd'
import { parseRating, parseRange, buildQueryObject, getCustomAttributes } from '../insights'
import {
  storeProducts,
  storeSortFilters,
  storeAllProducts,
  setProductLoadingState,
  setPagination,
  storeProductCount,
  storeProductAttr,
  storeProductAttrs,
  setProductAttrs,
  setDeleteProductAttrsId,
  storeDeleteProductAttr,
  storeDeleteProductAttrs,
} from './actions'
import { REQUEST_PRODUCTS, REQUEST_ALL_PRODUCTS, REQUEST_ADD_CUSTOM_ATTRIBUTE, REQUEST_DELETE_CUSTOM_ATTRIBUTE, REQUEST_ADD_PRODUCTS_ATTR, REQUEST_DELETE_PRODUCTS_ATTR } from './constants/ActionTypes'
import Api from './api'

export function selectProductAttrs(state) {
  return state.product.productAttrs
}

export function selectProducts(state) {
  return state.product.products
}

export function selectdeleteProductAttrIdArr(state) {
  return state.product.deleteProductAttrIdArr
}

export function* getAllProducts(action) {
  const { filterSelection, searchParams, sortAttribute, sortOrder } = action.payload.params
  const { additionalParams, resolve, reject } = action.payload
  let offset = 0
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: sortAttribute || 'review_volume',
      sort_order: sortOrder || 'desc',
    },
  }
  try {
    const totalProducts = []
    let retrievingProducts = true
    while (retrievingProducts) {
      const pagination = `?limit=1000&offset=${offset}`
      const response = yield call(Api.getProducts, pagination, { ...queryObject })
      if (response.data.length) {
        totalProducts.push(...response.data)
        offset += 1000
        if (totalProducts.length >= response.data[0].total_record_count) retrievingProducts = false
      } else {
        retrievingProducts = false
      }
    }
    yield put(storeAllProducts(totalProducts))
    resolve()
  } catch (err) {
    reject()
    console.log('err', err)
  }
}

/**
 * Fetch products from the server
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */

export function* getProducts(action) {
  yield put(setProductLoadingState(true))
  yield put(storeAllProducts([]))
  const { filterSelection, pageNum, pageSize, searchParams, sortAttribute, sortOrder } = action.payload.params
  yield put(storeSortFilters(sortAttribute, sortOrder))
  const offset = (pageNum - 1) * pageSize
  const pagination = `?limit=${pageSize}&offset=${offset}`
  const { additionalParams } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: sortAttribute || 'review_volume',
      sort_order: sortOrder || 'desc',
    },
  }

  try {
    const response = yield call(Api.getProducts, pagination, { ...queryObject })
    let productCount = 0
    if (response.data[0]) productCount = response.data[0].total_record_count
    yield put(storeProductCount(parseRange(productCount, response.request.responseURL)))
    const formattedResponse = [...response.data]
    formattedResponse.forEach(product => {
      const newThemes = []
      if (product.themes) {
        Object.keys(product.themes).forEach(theme => {
          const newTheme = { ...product.themes[theme] }
          newTheme.theme_name = theme
          newThemes.push(newTheme)
        })
      }
      product.themes = newThemes
    })
    yield put(storeProducts(formattedResponse))
    yield put(setPagination(pageNum, pageSize))
    yield put(setProductLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setProductLoadingState(false))
  }
}

export function* addCustomAttr(action) {
  const { customAttr, attributeType } = action.payload

  try {
    yield call(Api.addCustomAttr, {
      query_object: {
        attribute_text: customAttr,
        attribute_type: attributeType,
      },
    })
    yield put.resolve(getCustomAttributes())
  } catch (err) {
    console.log('err', err)
  }
}

export function* deleteCustomAttr(action) {
  const { customAttrId, resolve, reject } = action.payload

  try {
    yield call(Api.deleteCustomAttr, {
      query_object: {
        attribute_id: customAttrId,
      },
    })
    yield put.resolve(getCustomAttributes())
    resolve()
  } catch (err) {
    reject()
    console.log('err', err)
  }
}

export function* addProductsAttr(action) {
  const { indexArr, productIdAndExternalId, customAttr, attributeId } = action.payload
  const largeAttributeArr = indexArr.length >= 10
  if (largeAttributeArr) yield put(setProductLoadingState(true))
  try {
    const response = yield call(Api.addProductAttr, {
      query_object: {
        agg_product_id: _.map(productIdAndExternalId, 'id'),
        agg_product_external_id: _.map(productIdAndExternalId, 'externalId'),
        attribute_id: attributeId,
      },
    })
    const responseArr = _.reverse(response.data)

    // productAttrs store the new attributes you just added, you need to check this
    // before you add new attributes
    // cause if you haven't refresh the page since the last add,
    // you don't have the latest data from the backend
    // this may cause duplicate value or add failed
    const productAttrs = yield select(selectProductAttrs)
    responseArr.forEach(e => {
      const { product_custom_attribute_id, product_id: productId } = e
      if (_.has(productAttrs, productId)) {
        productAttrs[`${productId}`] = [...productAttrs[`${productId}`], { product_custom_attribute_id, attribute_text: customAttr, attribute_id: attributeId }]
      } else {
        productAttrs[`${productId}`] = [{ product_custom_attribute_id, attribute_text: customAttr, attribute_id: attributeId }]
      }
    })
    yield put(setProductAttrs(productAttrs))
    const productsArr = yield select(selectProducts)

    if (largeAttributeArr) {
      indexArr.forEach((i, index) => {
        if (!productsArr[i.index].custom_attributes) {
          productsArr[i.index].custom_attributes = []
        }
        productsArr[i.index].custom_attributes.push({
          product_custom_attribute_id: responseArr[index].product_custom_attribute_id,
          attribute_text: customAttr,
          attribute_id: attributeId,
        })
      })
      yield put(storeProductAttrs(productAttrs))
      yield put(storeProducts(productsArr))
      yield put(setProductLoadingState(false))
    } else {
      yield all(
        responseArr.map(e => {
          const { product_custom_attribute_id, product_id: productId } = e
          const indexObject = _.find(indexArr, ['id', productId])
          if (!_.isEmpty(indexObject)) {
            const response = put(
              storeProductAttr(indexObject.index, {
                product_custom_attribute_id,
                attribute_text: customAttr,
                attribute_id: attributeId,
              }),
            )
            return response
          }
          return ''
        }),
      )
    }
  } catch (err) {
    console.log('err', err)
    yield put(setProductLoadingState(false))
  }
}

export function* deleteProductsAttr(action) {
  const { indexArr, productAttrIdArr, productIdsArr, attributeId } = action.payload
  const largeAttributeArr = indexArr.length >= 10
  if (largeAttributeArr) yield put(setProductLoadingState(true))

  try {
    // yield all(productIdsArr.map((x) => {
    //   const response = call(Api.deleteProductAttr, {
    //     query_object: {
    //       agg_product_id: x,
    //       attribute_id: attributeId,
    //     }
    //   });
    //   return response;
    // }));

    yield call(Api.deleteProductAttr, {
      query_object: {
        agg_product_id: productIdsArr,
        attribute_id: attributeId,
      },
    })
    const productAttrs = yield select(selectProductAttrs)
    productAttrIdArr.forEach(productAttrId => {
      _.forIn(productAttrs, (value, key) => {
        if (_.includes(_.map(value, 'product_custom_attribute_id'), productAttrId)) {
          productAttrs[key] = _.reject(value, e => e.product_custom_attribute_id === productAttrId)
        }
      })
    })
    const deleteProductAttrIdArr = yield select(selectdeleteProductAttrIdArr)
    yield put(setDeleteProductAttrsId([...deleteProductAttrIdArr, ...productAttrIdArr]))

    yield put(setProductAttrs(productAttrs))

    if (largeAttributeArr) {
      const productsArr = yield select(selectProducts)
      indexArr.forEach(i => {
        productsArr[i.index].custom_attributes = productsArr[i.index].custom_attributes.filter(attr => attr.product_custom_attribute_id !== i.id)
      })
      indexArr.forEach(e => {
        deleteProductAttrIdArr.push(e.id)
      })
      yield put(storeDeleteProductAttrs(deleteProductAttrIdArr))
      yield put(storeProducts(productsArr))
      yield put(setProductLoadingState(false))
    } else {
      yield all(
        indexArr.map(e => {
          const { id, index } = e
          const response = put(storeDeleteProductAttr(index, id))
          return response
        }),
      )
    }
  } catch (err) {
    console.log('err', err)
    yield put(setProductLoadingState(false))
  }
}

/**
 * Watcher Saga for actions of product explorer
 * @return {Generator}
 */
export default function* watchProduct() {
  yield all([
    takeLatest(REQUEST_PRODUCTS, getProducts),
    takeLatest(REQUEST_ALL_PRODUCTS, getAllProducts),
    takeEvery(REQUEST_ADD_CUSTOM_ATTRIBUTE, addCustomAttr),
    takeEvery(REQUEST_DELETE_CUSTOM_ATTRIBUTE, deleteCustomAttr),
    takeEvery(REQUEST_ADD_PRODUCTS_ATTR, addProductsAttr),
    takeEvery(REQUEST_DELETE_PRODUCTS_ATTR, deleteProductsAttr),
  ])
}
