import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactEcharts from 'echarts-for-react'
import { Table } from 'antd'
import { SparklineConfig } from '../../common'
import { constructThemeColumns } from '../constants/helper'
import ProductDetailsPopover from './product-details-popover'
import './product-details.css'

class ProductDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.getConfig = this.getConfig.bind(this)
    this.constructColumns = this.constructColumns.bind(this)
  }

  getConfig(record) {
    const peiMultiply = obj => {
      return Math.round(obj.pei_score * 100)
    }

    const { currentViewName } = this.props
    const series =
      currentViewName === 'insights'
        ? [
            {
              name: 'Reviews',
              data: _.map(record, 'review_volume'),
              type: 'line',
            },
            {
              name: 'PEI',
              data: _.map(record, peiMultiply),
              type: 'line',
              yAxisIndex: 1,
            },
          ]
        : [
            {
              name: 'Reviews',
              data: _.map(record, 'quality_total'),
              type: 'line',
            },
            {
              name: 'Sentiment',
              data: _.map(record, 'quality_sentiment_score'),
              type: 'line',
              yAxisIndex: 1,
            },
          ]
    const legend = {
      data: ['Reviews', 'PEI'],
    }
    const xAxis = {
      ...SparklineConfig.xAxis,
      data: _.map(record, o => `${_.toInteger(o.review_quarter / 100)}-Q${o.review_quarter % 10}`),
    }
    const grid = {
      top: 60,
      bottom: 20,
      left: 40,
      right: 40,
    }
    const tooltip = {
      show: true,
      trigger: 'axis',
      position: point => [point[0] - 40, point[1] - 40],
      textStyle: {
        fontSize: 12,
      },
    }
    const yAxis = [
      {
        type: 'value',
        // name: 'Reviews',
        max: 'dataMax',
        min: 'dataMin',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#069',
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
      {
        type: 'value',
        // name: 'Sentiment',
        max: 'dataMax',
        min: 'dataMin',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#993300',
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ]
    return {
      ...SparklineConfig,
      series,
      xAxis,
      yAxis,
      legend,
      grid,
      tooltip,
    }
  }

  constructColumns() {
    const { theme } = this.props
    const columns = constructThemeColumns(theme)
    return columns
  }

  render() {
    const { record, currentViewName } = this.props
    const echartsData = currentViewName === 'insights' ? record.productTrendData : record.qualityTrendData
    return _.isEmpty(record) ? null : (
      <div className="product-details mb-14">
        <div className="product-details-left">
          <ProductDetailsPopover record={record} />
        </div>
        <div style={{ display: 'block' }} className="product-details-right">
          <div style={{ padding: '0 0 14px 24px' }}>
            <Table className="product-table" columns={this.constructColumns()} dataSource={[record]} pagination={false} />
          </div>
          <div style={{ padding: '10px 0 0 24px' }}>
            <ReactEcharts option={this.getConfig(echartsData)} notMerge lazyUpdate={false} style={{ height: 330 }} />
          </div>
        </div>
      </div>
    )
  }
}

ProductDetails.defaultProps = {
  record: {},
}

ProductDetails.propTypes = {
  record: PropTypes.shape({}),
  theme: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentViewName: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    theme: state.insights.filters.theme,
  }
}

export default connect(mapStateToProps, {})(ProductDetails)
