import _ from 'lodash'
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga'
import { parseRating, parseRange, buildQueryObject } from '../insights'
import { storeProducts, storeAllProducts, storeSortFilters, storeAvgSentScore, setProductLoadingState, setPagination, storeProductCount, setQualityThreshold } from './actions'
import { REQUEST_PRODUCTS, REQUEST_ALL_PRODUCTS } from './constants/ActionTypes'
import Api from './api'
// import { Api as QualityControlApi } from './api';

export function* getProducts(action) {
  yield put(setProductLoadingState(true))
  const { filterSelection, pageNum, pageSize, qualityThreshold, sortAttribute, sortOrder } = action.payload.params
  // const offset = (pageNum - 1) * pageSize;
  // const pagination = `?limit=${pageSize}&offset=${offset}`;
  const { additionalParams } = action.payload
  yield put(storeSortFilters(sortAttribute, sortOrder))
  const searchParams = {}
  const ratingArr = parseRating(filterSelection.review_rating)
  const offset = (pageNum - 1) * pageSize
  const pagination = `?limit=${pageSize}&offset=${offset}`
  // theme should always be set to quality for quality control view via additionalFilter
  const themeId = parseInt(filterSelection.theme.value[0], 10)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  formattedQueryObj.filter_object.theme_sentiment = null
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: sortAttribute || 'review_volume',
      sort_order: sortOrder || 'desc',
      theme: `(${themeId})`,
      theme_threshold: qualityThreshold
    },
  }

  try {
    let [productResponse, avgSentResponse, qualityIndex] = [{}, {}, 0]
    if (qualityThreshold !== '') {
      [productResponse, avgSentResponse] = yield all([call(Api.getProducts, pagination, { ...queryObject }), call(Api.getAvgSentScore, { ...queryObject })])
      qualityIndex = avgSentResponse.data.map(function(e) { return e.theme_name; }).indexOf('quality')
    } else {
      avgSentResponse = yield call(Api.getAvgSentScore, { ...queryObject })
      qualityIndex = avgSentResponse.data.map(function(e) { return e.theme_name; }).indexOf('quality')
      const { client_theme_avg: qualityClientAvg } = avgSentResponse.data[qualityIndex]
      yield put(setQualityThreshold(qualityClientAvg.toString()))
      queryObject.query_object.theme_threshold = qualityClientAvg
      productResponse = yield call(Api.getProducts, pagination, { ...queryObject })
    }
    let productCount = 0
    if (productResponse.data[0]) productCount = productResponse.data[0].total_record_count
    yield put(storeProductCount(parseRange(productCount, productResponse.request.responseURL)))
    // const products = _.filter(productResponse.data, o => !_.isEmpty(o.themes));
    const products = productResponse.data
    _.orderBy(products, ['neg_quality_review_volume', 'desc'])
    yield put(storeProducts(products))
    yield put(storeAvgSentScore(avgSentResponse.data[qualityIndex]))
    yield put(setPagination(pageNum, pageSize))
    yield put(setProductLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setProductLoadingState(false))
  }
}

export function* getAllProducts(action) {
  const { filterSelection, searchParams, qualityThreshold, sortAttribute, sortOrder } = action.payload.params
  const { additionalParams, resolve, reject } = action.payload
  let offset = 0
  const ratingArr = parseRating(filterSelection.review_rating)
  const themeId = parseInt(filterSelection.theme.value[0], 10)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  formattedQueryObj.filter_object.theme_sentiment = null
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: sortAttribute || 'review_volume',
      sort_order: sortOrder || 'desc',
      theme: `(${themeId})`,
    },
  }
  try {
    let avgSentResponse = {}
    if (qualityThreshold !== '') {
      queryObject.query_object.theme_threshold = qualityThreshold
    } else {
      avgSentResponse = yield call(Api.getAvgSentScore, { ...queryObject })
      const { client_theme_avg: qualityClientAvg } = avgSentResponse.data[1]
      queryObject.query_object.theme_threshold = qualityClientAvg
    }
    const totalProducts = []
    let retrievingProducts = true
    while (retrievingProducts) {
      const pagination = `?limit=1000&offset=${offset}`
      const response = yield call(Api.getProducts, pagination, { ...queryObject })
      if (response.data.length) {
        totalProducts.push(...response.data)
        offset += 1000
        if (totalProducts.length >= response.data[0].total_record_count) retrievingProducts = false
      } else {
        retrievingProducts = false
      }
    }
    yield put(storeAllProducts(totalProducts))
    resolve()
  } catch (err) {
    reject()
    console.log('err', err)
  }
}

export default function* watchQualityControl() {
  yield all([takeLatest(REQUEST_PRODUCTS, getProducts), takeLatest(REQUEST_ALL_PRODUCTS, getAllProducts)])
}
