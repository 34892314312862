import _ from 'lodash'
import { monthLabel } from '../../common'

export const monthComparator = (a, b) => {
  const [yearA, monthA] = a.split('-')
  const [yearB, monthB] = b.split('-')
  if (yearA === yearB) {
    return monthLabel.indexOf(monthB) - monthLabel.indexOf(monthA)
  }
  return yearB - yearA
}

export const quarterComparator = (a, b) => {
  const [yearA, quarterA] = a.split('-Q')
  const [yearB, quarterB] = b.split('-Q')
  if (_.isEqual(yearA, yearB)) {
    return _.toInteger(quarterB) - _.toInteger(quarterA)
  }
  return yearB - yearA
}

export const yearComparator = (a, b) => b - a

export const buildRiskOpportunityData = (data, timeMode) => {
  switch (timeMode) {
    case 'all': {
      const timeArray = { 'All Periods': data }
      const subcategoryTimeTrend = _.groupBy(data, 'hierarchy_label')
      const times = ['All Periods']
      return { times, timeArray, subcategoryTimeTrend }
    }
    case 'month': {
      const timeArray = _.groupBy(data, o => `${_.toInteger(o.time_mode / 100)}-${monthLabel[(o.time_mode % 100) - 1]}`)
      const subcategoryTimeTrend = _.groupBy(data, 'hierarchy_label')
      _.forEach(subcategoryTimeTrend, value => {
        _.sortBy(value, [o => o.time_mode])
        _.forEach(value, o =>
          _.assign(o, {
            timeLabel: `${_.toInteger(o.time_mode / 100)}-${monthLabel[(o.time_mode % 100) - 1]}`,
          }),
        )
      })
      const times = _.keys(timeArray).sort(monthComparator)
      return { times, timeArray, subcategoryTimeTrend }
    }
    case 'quarter': {
      const timeArray = _.groupBy(data, o => `${_.toInteger(o.time_mode / 100)}-Q${o.time_mode % 10}`)
      const subcategoryTimeTrend = _.groupBy(data, 'hierarchy_label')
      _.forEach(subcategoryTimeTrend, value => {
        _.sortBy(value, [o => o.time_mode])
        _.forEach(value, o =>
          _.assign(o, {
            timeLabel: `${_.toInteger(o.time_mode / 100)}-Q${o.time_mode % 10}`,
          }),
        )
      })
      const times = _.keys(timeArray).sort(quarterComparator)
      return { times, timeArray, subcategoryTimeTrend }
    }
    case 'year': {
      const timeArray = _.groupBy(data, 'time_mode')
      const subcategoryTimeTrend = _.groupBy(data, 'hierarchy_label')
      _.forEach(subcategoryTimeTrend, value => {
        _.sortBy(value, [o => o.time_mode])
        _.forEach(value, o => _.assign(o, { timeLabel: o.time_mode }))
      })
      const times = _.keys(timeArray).sort(yearComparator)
      return { times, timeArray, subcategoryTimeTrend }
    }
    default:
      return {}
  }
}

export const buildAdditionalParamsQueryObject = (timeMode, threshold, levelFilter) => {
  const additional_filter = { risk_time_mode: timeMode, risk_hier_threshold: threshold }

  const levelAttrName = ['risk_category', 'risk_subcategory', 'risk_attribute']
  levelFilter.forEach((e, index) => {
    additional_filter[levelAttrName[index]] = e.id
  })
  return {
    additional_filter,
  }
}

export const scaleOpacitySize = volume => {
  const opacity = [1, 0.85, 0.7, 0.55]
  const size = [15, 30, 45, 60]
  let tempOpacity = opacity[3]
  let tempSize = size[3]
  if (volume < 100) {
    ;[tempOpacity, tempSize] = [opacity[0], size[0]]
  } else if (volume < 1000) {
    ;[tempOpacity, tempSize] = [opacity[1], size[1]]
  } else if (volume < 10000) {
    ;[tempOpacity, tempSize] = [opacity[2], size[2]]
  }
  return [tempOpacity, tempSize]
}
