import React from 'react'

const PEI = props => {
  const { pei } = props

  return (
    <h1 >PEI: <span>{pei}</span></h1>
  )
}

export default PEI