import { takeLatest, call, put, all } from 'redux-saga/effects'
import { parseRating, buildQueryObject } from '../insights'
import { storePeiTrendBrand,
  storePeiTrendCompetitor,
  storeThemeTrendBrand,
  storeThemeTrendCompetitor,
  storeRisksProduct,
  storeThemeFrequency,
  setSentimentTrendLoadingState,
  setTopicFrequencyLoadingState,
  storeTopicFrequency
} from './actions'
import { REQUEST_SENTIMENT_TREND, REQUEST_PEI_TREND_BRAND, REQUEST_THEME_TREND_COMPETITOR, REQUEST_RISKS_PRODUCT, REQUEST_TOPIC_FREQUENCY } from './constants/ActionTypes'
import Api from './api'

export function* getSentimentTrend(action) {
  yield put(setSentimentTrendLoadingState(true))
  const filterSelection = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject({ ...filterSelection, review_rating: ratingArr }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
    },
  }
  const negativeQueryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: 'contribution_score',
      sort_order: 'asc',
      brand: '(2)',
    },
  }
  const positiveQueryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: 'contribution_score',
      sort_order: 'desc',
      brand: '(2)',
    },
  }

  try {
    const [r1, r2, r3, r4, r5, r6, r7] = yield all([
      call(Api.getPeiTrendBrand, { ...queryObject }),
      call(Api.getPeiTrendCompetitor, { ...queryObject }),
      call(Api.getThemeTrendBrand, { ...queryObject }),
      call(Api.getThemeTrendCompetitor, { ...queryObject }),
      call(Api.getRisksProduct, { ...positiveQueryObject }),
      call(Api.getRisksProduct, { ...negativeQueryObject }),
      call(Api.getThemeFrequency, { ...queryObject }),
    ])
    const risksProduct = [r5.data, r6.data]

    // Construct brand pei data
    const { client: clientData, competitor: competitorData } = r1.data[0]
    const peiTrendBrandData = []
    clientData.trend_data.map((quarter, index) => {
      peiTrendBrandData.push({
        review_quarter: quarter.review_quarter,
        client_pei: quarter.pei_score,
        competitor_pei: competitorData.trend_data[index].pei_score
      })
    })

    yield all([
      put(storeTopicFrequency({})),
      put(storePeiTrendBrand(peiTrendBrandData)),
      put(storePeiTrendCompetitor(r2.data)),
      put(storeThemeTrendBrand(r3.data)),
      put(storeThemeTrendCompetitor(r4.data)),
      put(storeRisksProduct(risksProduct)),
      put(storeThemeFrequency(r7.data)),
    ])
    yield put(setSentimentTrendLoadingState(false))
  } catch (err) {
    yield put(setSentimentTrendLoadingState(false))
  }
}

export function* getPeiTrendBrand(action) {
  const filterSelection = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObject = buildQueryObject({ ...filterSelection, review_rating: ratingArr }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObject.additional_filter,
      ...formattedQueryObject.filter_object,
      ...formattedQueryObject.search_object,
    },
  }
  try {
    const response = yield call(Api.getPeiTrendBrand, {
      ...queryObject,
    })

    // Construct brand pei data
    const { client: clientData, competitor: competitorData } = response.data[0]
    const peiTrendBrandData = []
    clientData.trend_data.map((quarter, index) => {
      peiTrendBrandData.push({
        review_quarter: quarter.review_quarter,
        client_pei: quarter.pei_score,
        competitor_pei: competitorData.trend_data[index].pei_score
      })
    })

    yield put(storePeiTrendBrand(peiTrendBrandData))
  } catch (err) {
    console.log('err', err)
  }
}

export function* getThemeTrendCompetitor(action) {
  const filterSelection = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObject = buildQueryObject({ ...filterSelection, review_rating: ratingArr }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObject.additional_filter,
      ...formattedQueryObject.filter_object,
      ...formattedQueryObject.search_object,
    },
  }
  try {
    const response = yield call(Api.getThemeTrendCompetitor, {
      ...queryObject,
    })
    yield put(storeThemeTrendCompetitor(response.data))
  } catch (err) {
    console.log('err', err)
  }
}

export function* getRisksProduct(action) {
  const filterSelection = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject({ ...filterSelection, review_rating: ratingArr }, {}, {})
  const negativeQueryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
      sort_attribute: 'contribution_score',
      sort_order: 'asc',
      brand: '(2)',
    },
  }
  const positiveQueryObject = {
    query_object: { ...negativeQueryObject.query_object },
  }
  positiveQueryObject.query_object.sort_order = 'desc'
  try {
    const [r1, r2] = yield all([
      call(Api.getRisksProduct, {
        ...positiveQueryObject,
      }),
      call(Api.getRisksProduct, {
        ...negativeQueryObject,
      }),
    ])
    yield put(storeRisksProduct([r1.data, r2.data]))
  } catch (err) {
    console.log('err', err)
  }
}

export function* getTopicFrequency(action) {
  yield put(setTopicFrequencyLoadingState(true))
  const filterSelection = action.payload
  const formattedQueryObject = buildQueryObject({ ...filterSelection }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObject.additional_filter,
      ...formattedQueryObject.filter_object,
      ...formattedQueryObject.search_object,
    },
  }
  try {
    const response = yield call(Api.getTopicFrequency, {
      ...queryObject,
    })
    yield put(storeTopicFrequency(response.data[0]))
    yield put(setTopicFrequencyLoadingState(false))
  } catch (err) {
    yield put(setTopicFrequencyLoadingState(false))
    console.log('err', err)
  }
}

export default function* watchSentimentTrend() {
  yield all([
    takeLatest(REQUEST_SENTIMENT_TREND, getSentimentTrend),
    takeLatest(REQUEST_PEI_TREND_BRAND, getPeiTrendBrand),
    takeLatest(REQUEST_THEME_TREND_COMPETITOR, getThemeTrendCompetitor),
    takeLatest(REQUEST_RISKS_PRODUCT, getRisksProduct),
    takeLatest(REQUEST_TOPIC_FREQUENCY, getTopicFrequency),
  ])
}
