const prefix = 'sentimenttrend'

export const REQUEST_SENTIMENT_TREND = `${prefix}/request_sentiment_trend`

export const REQUEST_PEI_TREND_BRAND = `${prefix}/request_pei_trend_brand`

export const REQUEST_THEME_TREND_COMPETITOR = `${prefix}/request_theme_trend_competitor`

export const REQUEST_RISKS_PRODUCT = `${prefix}/request_risks_product`

export const REQUEST_TOPIC_FREQUENCY = `${prefix}/request_topic_frequency`

export const RECEIVE_PEI_TREND_BRAND = `${prefix}/receive_pei_trend_brand`

export const RECEIVE_PEI_TREND_COMPETITOR = `${prefix}/receive_pei_trend_competitor`

export const RECEIVE_THEME_TREND_BRAND = `${prefix}/receive_theme_trend_brand`

export const RECEIVE_THEME_TREND_COMPETITOR = `${prefix}/receive_theme_trend_competitor`

export const RECEIVE_RISKS_PRODUCT = `${prefix}/receive_risks_product`

export const RECEIVE_THEME_FREQUENCY = `${prefix}/receive_theme_frequency`

export const RECEIVE_TOPIC_FREQUENCY = `${prefix}/receive_topic_frequency`

export const SET_SENTIMENT_TREND_LOADING_STATE = `${prefix}/set_sentiment_trend_loading_state`

export const SET_TOPIC_FREQUENCY_LOADING_STATE = `${prefix}/set_topic_frequency_loading_state`
