import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, Menu, Dropdown, Icon, Rate, Tag, Button, Input, Pagination, BackTop } from 'antd'
import { insertCommas, TagForm } from '../../common'
import { getAdditionalFilters, getAllSelectedThemes } from '../../insights'
import {
  getReviews,
  getPEI,
  setExpandedRowKeys,
  setEditableTagKeys,
  setFlagKeys,
  updateFlag,
  deleteFlag,
  addTag,
  deleteTag,
  addAllTags,
  deleteAllTags,
  setReviewTags,
  setDeleteTagsId,
  setSelectedPagination,
} from '../actions'
import { blueColor } from '../constants/color'
import PopOver from './pop-over'
import './review.css'

class ReviewSnippets extends Component {
  constructor(props) {
    super(props)

    const len = this.props.reviews.length
    this.state = {
      inputTagValue: '',
      curTagInputIdx: -1,
      visible: _.fill(Array(len), false),
      selectedReviews: [], // all selectedReviews from each pages
      currentPageSelectedReviews: [], // selectedReviews on current page
      selectedRowKeys: [],
    }

    this.generateReviewSnippet = this.generateReviewSnippet.bind(this)
    this.expandedRowRender = this.expandedRowRender.bind(this)
    this.handleOnReviewExpand = this.handleOnReviewExpand.bind(this)
    this.constructDataSource = this.constructDataSource.bind(this)
    this.constructColumns = this.constructColumns.bind(this)
    this.handleTagInputChange = this.handleTagInputChange.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onSelectAllChange = this.onSelectAllChange.bind(this)
    this.addAllTag = this.addAllTag.bind(this)
    this.deleteAllTag = this.deleteAllTag.bind(this)
    this.reloadReview = this.reloadReview.bind(this)
  }

  componentDidMount() {
    // reviewTags and deleteTagsId are used for adding and deleting multiply tags
    this.props.setReviewTags({})
    this.props.setDeleteTagsId([])
  }

  generateReviewSnippet(index) {
    const reactStringReplace = require('react-string-replace')
    const { reviews, expandedRowKeys, filterSelection, pageNum, pageSize, searchParams } = this.props
    const { review_text: reviewText, review_title, theme_text_representation: themeTextRepresentation = {} } = reviews[index]
    const reviewTextAbbr = _.truncate(reviewText, {
      length: 135,
      separator: ' ',
    })

    let revText
    let revTitle
    let revTextAbbr
    const searchArray = searchParams.review.keyWords
    searchArray.forEach((keyword, i) => {
      revText = reactStringReplace(i === 0 ? reviewText : revText, keyword, (match, i) => (
        <span key={match + i} style={{ backgroundColor: 'yellow' }}>
          {match}
        </span>
      ))
      revTitle = reactStringReplace(i === 0 ? review_title : revTitle, keyword, (match, i) => (
        <span key={match + i} style={{ backgroundColor: 'yellow' }}>
          {match}
        </span>
      ))
      revTextAbbr = reactStringReplace(i === 0 ? reviewTextAbbr : revTextAbbr, keyword, (match, i) => (
        <span key={match + i} style={{ backgroundColor: 'yellow' }}>
          {match}
        </span>
      ))
    })

    if (_.isEmpty(revText)) revText = reviewText
    if (_.isEmpty(revTitle)) revTitle = review_title
    if (_.isEmpty(revTextAbbr)) revTextAbbr = reviewTextAbbr

    const isReviewExpanded = _.includes(expandedRowKeys, (pageNum - 1) * pageSize + index)
    const reviewTextExpanded = (
      <div>
        <p
          style={{
            fontStyle: 'italic',
            wordBreak: 'keep-all',
          }}
          className="mb-10 bolder"
        >
          {revTitle}
        </p>
        <p className="mb-10">{revText}</p>
      </div>
    )
    if (isReviewExpanded) {
      return reviewTextExpanded
    }
    const selectedThemes = _.map(getAllSelectedThemes(filterSelection), _.parseInt)
    if (selectedThemes.length === 1 && !_.isEmpty(themeTextRepresentation)) {
      // use theme related snippet
      // in case it is undeifned, return general snippet instead
      const themeTextRepresentationAbbr = _.isUndefined(themeTextRepresentation[selectedThemes[0]])
        ? revTextAbbr
        : _.truncate(themeTextRepresentation[selectedThemes[0]], {
            length: 135,
            separator: ' ',
          })
      return themeTextRepresentationAbbr || revTextAbbr
    }
    return revTextAbbr
  }

  generateReviewLabel(index) {
    const { filterObject, reviews } = this.props
    const keys = _.keys(filterObject)
    const labels = []
    const colorArray = ['red', 'blue', 'green', 'purple', 'volcano', 'orange', 'lime', 'geekblue', 'magenta']
    keys.forEach((key, keyIndex) => {
      const curFilterObj = filterObject[key]
      const rawMetaData = reviews[index][`${key}_meta`]
      if (_.isEmpty(curFilterObj) || _.isEmpty(rawMetaData)) return
      const metaData = _.isObject(rawMetaData) ? _.keys(rawMetaData) : [rawMetaData]

      // metadata contains all data for the key property,
      // but we may only select part of them in our filterSelection,
      // we only need to show the ones appear in the filterSelection

      // case 0: the keys we don't need to show
      if (key === 'theme_sentiment' || key === 'review_rating' || key === 'partiallySelectedThemeValues') {
        return
      }
      // case 1: review/product year quarter month
      if ((_.includes(key, 'month') || _.includes(key, 'quarter') || _.includes(key, 'year')) && !_.isEmpty(curFilterObj.label)) {
        // year
        if (_.includes(key, 'year')) {
          metaData.forEach((val, valIndex) => {
            if (!_.includes(curFilterObj.label, `${val}`)) return
            const color = `${colorArray[keyIndex % 9]}`
            labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${val}`}</Tag>)
          })
          return
        }
        // quarter/month
        const year = reviews[index][`${_.split(key, '_')[0]}_year_meta`]
        metaData.forEach((val, valIndex) => {
          if (!_.includes(curFilterObj.label, `${year}-${val}`)) return
          const color = `${colorArray[keyIndex % 9]}`
          labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${year}-${val}`}</Tag>)
        })
        return
      }
      // case 2: theme and aspect with/without theme sentiment theme:fit / fit:pos
      if ((key === 'theme' || key === 'aspect') && !_.isEmpty(curFilterObj.label)) {
        const themeAspectMeta = reviews[index][`${key}_meta`]
        const pairs = _.toPairs(themeAspectMeta)
        if (_.includes(keys, 'theme_sentiment') && !_.isEmpty(filterObject.theme_sentiment) && !_.isEmpty(filterObject.theme_sentiment.value)) {
          pairs.forEach((val, valIndex) => {
            if (!_.includes(_.toLower(curFilterObj.label), `${val[0]}`)) return
            const color = `${colorArray[keyIndex % 9]}`
            labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${val[0]} : ${val[1]}`}</Tag>)
          })
          return
        }
        pairs.forEach((val, valIndex) => {
          if (!_.includes(_.toLower(curFilterObj.label), `${val[0]}`)) return
          const color = `${colorArray[keyIndex % 9]}`
          labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${val[0]}`}</Tag>)
        })
        return
      }
      // case 3: all other filter selections
      const originalVal = _.toLower(curFilterObj.label)
      metaData.forEach((val, valIndex) => {
        if (!_.includes(originalVal, _.toLower(val))) return
        const color = `${colorArray[keyIndex % 9]}`
        labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${val}`}</Tag>)
      })
    })
    return labels
  }

  expandedRowRender(record) {
    const { reviews, pageNum, pageSize } = this.props
    // record: { key: , id: , review: { ... } }
    const { key } = record // key is the index of the current review
    const columns = [
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        width: '10%',
      },
      {
        title: 'Product',
        dataIndex: 'product_name',
        key: 'product_name',
        width: '35%',
      },
      {
        title: 'Review Stars',
        dataIndex: 'review_rating',
        key: 'review_rating',
        width: '15%',
        render: text => <Rate disabled allowHalf defaultValue={text} />,
      },
      {
        title: 'Review Submission Date',
        dataIndex: 'review_submission_date',
        key: 'review_submission_date',
        width: '20%',
      },
      {
        title: 'External ID',
        dataIndex: 'external_id',
        key: 'external_id',
        width: '20%',
      },
    ]
    // use data of sub_brand as brand
    // update: use brand when sub_brand is null
    const { sub_brand_meta: subBrandMeta, brand_meta: brandMeta, product_name, review_rating, review_id, review_submission_date, external_id } = reviews[key - (pageNum - 1) * pageSize]
    const brandName = _.isEqual(subBrandMeta, 'NULL') ? brandMeta : subBrandMeta
    const data = [
      {
        key: review_id,
        brand: brandName,
        product_name,
        review_rating,
        review_submission_date,
        external_id,
      },
    ]
    return <Table size="small" columns={columns} dataSource={data} pagination={false} />
  }

  /**
   * callback when clicking on the menu items of each review (flag, unflag, tag, email)
   * @param  {string}  key           key of menu item being clicked
   * @param  {number}  index         index of the review of the current Tags
   * @param  {number}  reviewId      reviewId of the current review
   * @param  {Boolean} isTagEditable is the tag editable before clicking
   * @param  {Boolean} isFlagVisible has the review already been flagged
   */
  handleMenuClick(key, index, reviewId, isTagEditable, isFlagVisible, reviewFlagId) {
    const { pageNum, pageSize, editableTagKeys, flagKeys } = this.props
    const offsite = (pageNum - 1) * pageSize + index
    switch (key) {
      case 'tag':
        if (isTagEditable) {
          this.props.setEditableTagKeys(_.without(editableTagKeys, offsite))
        } else {
          this.props.setEditableTagKeys([...editableTagKeys, offsite])
        }
        break
      case 'unflag':
        this.props.deleteFlag(index, reviewFlagId)
        break
      case 'flag':
        if (!isFlagVisible) {
          this.props.setFlagKeys([...flagKeys, offsite])
        }
        break
      default:
        break
    }
  }

  /**
   * construct the dropdown menu for each review, flag, unflag, tag, email
   * @param  {number}  index         index of the current review
   */
  constructMenu(index) {
    const { flagKeys, editableTagKeys, reviews, pageNum, pageSize } = this.props
    const isTagEditable = _.includes(editableTagKeys, (pageNum - 1) * pageSize + index)
    const isFlagVisible = _.includes(flagKeys, (pageNum - 1) * pageSize + index)
    const reviewFlagId = reviews[index].review_flag_id
    const { review_text: reviewText } = reviews[index]
    const isFlaged = reviews && reviews[index] && reviews[index].flag
    const reviewId = reviews && reviews[index] && reviews[index].review_id
    const reviewExternalId = reviews && reviews[index] && reviews[index].external_id

    return (
      <Menu onClick={({ key }) => this.handleMenuClick(key, index, reviewId, isTagEditable, isFlagVisible, reviewFlagId)}>
        <Menu.Item key="flag" disabled={isFlaged}>
          <PopOver index={index} reviewId={reviewId} reviewExternalId={reviewExternalId} onSubmit={this.onSubmit} onCancel={this.onCancel} visible={isFlagVisible} />
        </Menu.Item>
        <Menu.Item key="unflag" disabled={!isFlaged}>
          <Icon type="flag" />
          &nbsp;&nbsp;Unflag Review
        </Menu.Item>
        <Menu.Item key="tag">
          <Icon type={isTagEditable ? 'eye-o' : 'edit'} />
          &nbsp;&nbsp;
          {isTagEditable ? 'Preview Tags' : 'Edit Tags'}
        </Menu.Item>
        <Menu.Item key="email">
          <a href={`mailto:insert_recipient_address@address.com?subject=Review Explorer From HearFul 2.0&body=Reviews Reference:   ${reviewText}`}>
            <Icon type="mail" />
            &nbsp;&nbsp;Send Email
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  /**
   * construct columns for the whole review table
   */
  constructColumns() {
    const { reviews } = this.props
    const columns = [
      { title: 'Reviews', dataIndex: 'review', key: 'review' },
      {
        title: 'Actions',
        key: 'action',
        width: '80px',
        render: (text, record, index) => (
          <Dropdown overlay={this.constructMenu(index)} onVisibleChange={flag => this.handleVisibleChange(flag, index)} visible={this.state.visible[index]}>
            <Button>
              {reviews && reviews[index] && reviews[index].flag && <Icon type="flag" />}
              &nbsp;&nbsp;More
              <Icon type="down" />
            </Button>
          </Dropdown>
        ),
      },
    ]
    return columns
  }

  /*
    record: {
      key: review index in the list
      id: review id
      review: { ... }
    }
  */
  handleOnReviewExpand(expanded, record) {
    const { expandedRowKeys } = this.props
    if (expanded) {
      this.props.setExpandedRowKeys([...expandedRowKeys, record.key])
    } else {
      this.props.setExpandedRowKeys(_.without(expandedRowKeys, record.key))
    }
  }

  handleTagInputChange(e) {
    this.setState({ inputTagValue: e.target.value })
  }

  handleTagInputConfirm(index, reviewId, reviewExternalId) {
    // insert tag into DB, if succeeds, then redux store
    const { tags } = this.props.reviews[index]
    const inputTagValue = _.trim(this.state.inputTagValue)
    if (_.isEmpty(inputTagValue) || (tags && tags.find(tag => tag.text === inputTagValue))) {
      // if it is empty tag or there is a duplicate
      // don't store in DB, clear input, and hide tag input
      this.setState({ inputTagValue: '', curTagInputIdx: -1 })
      return
    }
    // Send request, if succeeds, insert the tag at right place
    this.props.addTag(index, reviewId, reviewExternalId, inputTagValue)
    this.setState({ inputTagValue: '', curTagInputIdx: -1 })
  }

  handleTagDelete(index, tagId) {
    this.props.deleteTag(index, tagId)
  }

  handleVisibleChange = (flag, index) => {
    if (!flag) {
      this.onCancel(index)
    }
    this.setState({
      visible: [...this.state.visible.slice(0, index), flag, ...this.state.visible.slice(index + 1)],
    })
  }

  onSelectChange = (record, selected, selectedRows) => {
    if (selected) {
      this.setState({
        selectedReviews: [...this.state.selectedReviews, record],
        currentPageSelectedReviews: [...selectedRows],
      })
    } else {
      this.setState({
        selectedReviews: _.reject(this.state.selectedReviews, o => o.id === record.id),
        currentPageSelectedReviews: [...selectedRows],
      })
    }
  }

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setState({
        selectedReviews: [...this.state.selectedReviews, ...changeRows],
        currentPageSelectedReviews: [...selectedRows],
      })
    } else {
      this.setState({
        selectedReviews: _.reject(this.state.selectedReviews, o => _.includes(_.map(changeRows, 'id'), o.id)),
        currentPageSelectedReviews: [],
      })
    }
  }

  onChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }

  addAllTag(value) {
    const { selectedReviews, currentPageSelectedReviews } = this.state
    const { pageNum, pageSize, reviewTags, deleteTagsIdArr } = this.props

    const selectedReviewIdAndExternalIdArr = _.reject(
      selectedReviews,
      o =>
        (o.tags && o.tags.find(tag => tag.text === value && !_.includes(deleteTagsIdArr, tag.review_tag_id))) ||
        (reviewTags[`${o.id}`] && reviewTags[`${o.id}`].find(tag => tag.tag === value && !_.includes(deleteTagsIdArr, tag.tagId))),
    ).map(x => ({
      id: x.id,
      externalId: x.external_id,
    }))
    const currentPageSelectedReviewsIndex = _.map(
      _.reject(
        currentPageSelectedReviews,
        o =>
          (o.tags && o.tags.find(tag => tag.text === value && !_.includes(deleteTagsIdArr, tag.review_tag_id))) ||
          (reviewTags[`${o.id}`] && reviewTags[`${o.id}`].find(tag => tag.tag === value && !_.includes(deleteTagsIdArr, tag.tagId))),
      ),
      o => ({ index: o.key - (pageNum - 1) * pageSize, id: o.id }),
    )

    this.props.addAllTags(currentPageSelectedReviewsIndex, selectedReviewIdAndExternalIdArr, value)
  }

  deleteAllTag(value) {
    const { selectedReviews, currentPageSelectedReviews } = this.state
    const { pageNum, pageSize, reviewTags } = this.props
    const selectedTagIds = []
    selectedReviews.forEach(o => {
      if (!_.isEmpty(reviewTags[o.id])) {
        const tagObject = reviewTags[o.id].find(tag => tag.tag === value)
        if (!_.isEmpty(tagObject)) {
          selectedTagIds.push(tagObject.tagId)
          return
        }
      }
      if (!_.isEmpty(o.tags)) {
        const tagObject = o.tags.find(tag => tag.text === value)
        if (!_.isEmpty(tagObject)) {
          selectedTagIds.push(tagObject.review_tag_id)
        }
      }
    })
    const currentPageSelectedReviewsIndex = []
    currentPageSelectedReviews.forEach(o => {
      if (!_.isEmpty(reviewTags[o.id])) {
        const tagObject = reviewTags[o.id].find(tag => tag.tag === value)
        if (!_.isEmpty(tagObject)) {
          currentPageSelectedReviewsIndex.push({
            index: o.key - (pageNum - 1) * pageSize,
            id: tagObject.tagId,
          })
          return
        }
      }
      if (!_.isEmpty(o.tags)) {
        const tagObject = o.tags.find(tag => tag.text === value)
        if (!_.isEmpty(tagObject)) {
          currentPageSelectedReviewsIndex.push({
            index: o.key - (pageNum - 1) * pageSize,
            id: tagObject.review_tag_id,
          })
        }
      }
    })
    this.props.deleteAllTags(currentPageSelectedReviewsIndex, selectedTagIds)
  }

  reloadReview() {
    this.setState({
      selectedRowKeys: [],
      selectedReviews: [],
      currentPageSelectedReviews: [],
    })
    this.props.setSelectedPagination({})
  }

  constructDataSource() {
    const { reviews, editableTagKeys, pageNum, pageSize } = this.props
    const { inputTagValue, curTagInputIdx } = this.state
    const data = []
    for (let i = 0; i < reviews.length; i++) {
      const { review_id, tags, review_text, external_id } = reviews[i]
      const isTagEditable = _.includes(editableTagKeys, (pageNum - 1) * pageSize + i)
      const isTagInputVisible = curTagInputIdx === i
      const labels = this.generateReviewLabel(i)
      data.push({
        key: (pageNum - 1) * pageSize + i, // use index as the row keys
        id: review_id, // pass additional "id" property to store review id
        external_id,
        tags,
        reviewText: review_text,
        review: (
          <div>
            <div className="review-text">{this.generateReviewSnippet(i)}</div>
            <div className="mb-5">{!_.isEmpty(labels) && labels}</div>
            {!_.isEmpty(tags) &&
              tags.map(tag => (
                <Tag key={tag.review_tag_id} closable={isTagEditable} onClose={() => this.handleTagDelete(i, tag.review_tag_id)}>
                  {tag.text}
                </Tag>
              ))}
            {isTagEditable && isTagInputVisible && (
              <Input
                ref={e => (this.tagInput = e)}
                type="text"
                size="small"
                style={{ width: 78 }}
                value={inputTagValue}
                onChange={this.handleTagInputChange}
                onBlur={() => this.handleTagInputConfirm(i, review_id, external_id)}
                onPressEnter={() => this.handleTagInputConfirm(i, review_id, external_id)}
              />
            )}
            {isTagEditable && !isTagInputVisible && (
              <Button size="small" type="dashed" style={{ color: blueColor }} onClick={() => this.setState({ curTagInputIdx: i }, () => this.tagInput.focus())}>
                <Icon type="plus" />
                New Tag
              </Button>
            )}
          </div>
        ),
      })
    }
    return data
  }

  handlePaginationChange(pageNum, pageSize) {
    const { selectedObj, filterSelection, searchParams, additionalParams } = this.props
    selectedObj[`${this.props.pageNum}`] = _.map(this.state.currentPageSelectedReviews, o => ({
      key: o.key,
      id: o.id,
      tags: o.tags,
    }))
    this.props.setSelectedPagination(selectedObj)
    if (this.props.areMetadataShown) {
      const expandedRowKeys = []
      for (let i = 0; i < pageSize; i++) {
        expandedRowKeys.push((pageNum - 1) * pageSize + i)
      }
      this.props.setExpandedRowKeys(expandedRowKeys)
    }

    if (this.props.areTagsEditable) {
      const editableTagKeys = []
      for (let i = 0; i < pageSize; i++) {
        editableTagKeys.push((pageNum - 1) * pageSize + i)
      }
      this.props.setEditableTagKeys(editableTagKeys)
    }

    this.props.getReviews(
      {
        filterSelection,
        pageNum,
        pageSize,
        searchParams,
      },
      additionalParams,
    )

    if (_.has(selectedObj, pageNum)) {
      this.setState({ currentPageSelectedReviews: selectedObj[`${pageNum}`] })
    }
  }

  onSubmit(index, reviewId, reviewExternalId, comment) {
    const { pageNum, pageSize } = this.props
    this.props.setFlagKeys(_.without(this.props.flagKeys, (pageNum - 1) * pageSize + index))
    this.props.updateFlag(index, reviewId, reviewExternalId, comment)
  }

  onCancel(index) {
    const { pageNum, pageSize } = this.props
    this.props.setFlagKeys(_.without(this.props.flagKeys, (pageNum - 1) * pageSize + index))
  }

  render() {
    const { range, pageSize, pageNum, expandedRowKeys } = this.props
    const { selectedRowKeys } = this.state
    const rowSelection = {
      onSelect: this.onSelectChange,
      onSelectAll: this.onSelectAllChange,
      onChange: this.onChange,
      selectedRowKeys,
    }

    return (
      <div className="review-snippets">
        <BackTop />
        <TagForm addAllTag={this.addAllTag} deleteAllTag={this.deleteAllTag} reloadReview={this.reloadReview} selectedReviews={this.state.selectedReviews} path="review" />
        <Pagination
          total={range[2]} // total review number
          pageSize={pageSize}
          size="small"
          current={pageNum}
          showQuickJumper
          showSizeChanger
          showTotal={(total, reviewRange) => (
            <span className="review-range">
              {insertCommas(reviewRange[0])}-{insertCommas(reviewRange[1])} of {insertCommas(total)} reviews
            </span>
          )}
          onShowSizeChange={this.handlePaginationChange}
          onChange={this.handlePaginationChange}
        />
        <Table
          size="middle"
          columns={this.constructColumns()}
          expandedRowRender={this.expandedRowRender}
          dataSource={this.constructDataSource()}
          expandedRowKeys={expandedRowKeys}
          onExpand={this.handleOnReviewExpand}
          pagination={false}
          rowSelection={rowSelection}
        />
        <Pagination
          className="mt-10"
          total={range[2]} // total review number
          pageSize={pageSize}
          size="small"
          current={pageNum}
          showQuickJumper
          showSizeChanger
          showTotal={(total, reviewRange) => (
            <span className="review-range">
              {insertCommas(reviewRange[0])}-{insertCommas(reviewRange[1])} of {insertCommas(total)} reviews
            </span>
          )}
          onShowSizeChange={this.handlePaginationChange}
          onChange={this.handlePaginationChange}
        />
      </div>
    )
  }
}

ReviewSnippets.propTypes = {
  filterSelection: PropTypes.shape({}).isRequired,
  filterObject: PropTypes.shape({}).isRequired,
  expandedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  editableTagKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  flagKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    review: PropTypes.shape({}).isRequired,
    product: PropTypes.shape({}).isRequired,
  }).isRequired,
  additionalParams: PropTypes.shape({}).isRequired,
  areMetadataShown: PropTypes.bool.isRequired,
  areTagsEditable: PropTypes.bool.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteTagsIdArr: PropTypes.arrayOf(PropTypes.number).isRequired,
  reviewTags: PropTypes.shape({}).isRequired,
  selectedObj: PropTypes.shape({}).isRequired,
  setExpandedRowKeys: PropTypes.func.isRequired,
  setEditableTagKeys: PropTypes.func.isRequired,
  setFlagKeys: PropTypes.func.isRequired,
  getReviews: PropTypes.func.isRequired,
  getPEI: PropTypes.func.isRequired,
  updateFlag: PropTypes.func.isRequired,
  deleteFlag: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  addAllTags: PropTypes.func.isRequired,
  deleteAllTags: PropTypes.func.isRequired,
  setReviewTags: PropTypes.func.isRequired,
  setDeleteTagsId: PropTypes.func.isRequired,
  setSelectedPagination: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    filterSelection: state.insights.filterSelection,
    reviews: state.review.reviews,
    deleteTagsIdArr: state.review.deleteTagsIdArr,
    reviewTags: state.review.reviewTags,
    selectedObj: state.review.selectedObj,
    filterObject: state.review.filterObject,
    expandedRowKeys: state.review.expandedRowKeys,
    editableTagKeys: state.review.editableTagKeys,
    flagKeys: state.review.flagKeys,
    range: state.review.range,
    pageSize: state.review.pageSize,
    pageNum: state.review.pageNum,
    searchParams: state.review.searchParams,
    additionalParams: getAdditionalFilters(state.insights),
  }
}

export default connect(mapStateToProps, {
  getReviews,
  getPEI,
  setExpandedRowKeys,
  setEditableTagKeys,
  setFlagKeys,
  updateFlag,
  deleteFlag,
  addTag,
  deleteTag,
  addAllTags,
  deleteAllTags,
  setReviewTags,
  setDeleteTagsId,
  setSelectedPagination,
})(ReviewSnippets)
