import axios from 'axios'

const Api = {
  getReviews: (pagination, queryObject) => axios.put(`/reviews${pagination}`, queryObject),
  getWords: queryObject => axios.put('/reviews/histogram?limit=15&offset=', queryObject),
  updateFlag: queryObject => axios.post('/reviews/comments', queryObject),
  deleteFlag: queryObject =>
    axios.delete('/reviews/comments', {
      data: queryObject,
    }),
  addTag: queryObject => axios.post('/reviews/tags', queryObject),
  deleteTag: queryObject =>
    axios.delete('/reviews/tags', {
      data: queryObject,
    }),
}

export default Api
