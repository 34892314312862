export const filters = {
  id: 1,
  department: [
    {
      label: 'Baby Boys',
      key: '1',
      value: '1',
      children: [
        {
          label: 'Dress',
          key: '1-1',
          value: '1-1',
          children: [
            {
              label: 'Kimono',
              key: '1-1-5000',
              parentValue: '1-1',
              value: '1-1-5000',
              children: [
                {
                  label: 'Fabrication',
                  key: '1-1-5000-4',
                  value: '1-1-5000-4',
                  parentValue: '1-1-5000',
                  children: [
                    {
                      label: 'Denim',
                      key: '1-1-5000-4-8027',
                      parentValue: '1-1-5000-4',
                      value: '1-1-5000-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '1-1-5000-4-8028',
                      parentValue: '1-1-5000-4',
                      value: '1-1-5000-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '1-1-5000-5',
                  value: '1-1-5000-5',
                  parentValue: '1-1-5000',
                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '1-1-5000-5-8040',
                      parentValue: '1-1-5000-5',
                      value: '1-1-5000-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '1-1-5000-9',
                  value: '1-1-5000-9',
                  parentValue: '1-1-5000',

                  children: [
                    {
                      label: 'Polyester',
                      key: '1-1-5000-9-8079',
                      parentValue: '1-1-5000-9',
                      value: '1-1-5000-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '1-1-5000-9-8080',
                      parentValue: '1-1-5000-9',
                      value: '1-1-5000-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '1-1-5000-11',
                  value: '1-1-5000-11',
                  parentValue: '1-1-5000',

                  children: [
                    {
                      label: 'Casual',
                      key: '1-1-5000-11-8093',
                      parentValue: '1-1-5000-11',
                      value: '1-1-5000-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Shirtdress',
              key: '1-1-5001',
              parentValue: '1-1',
              value: '1-1-5001',
              children: [
                {
                  label: 'Fabrication',
                  key: '1-1-5001-4',
                  value: '1-1-5001-4',
                  parentValue: '1-1-5001',

                  children: [
                    {
                      label: 'Denim',
                      key: '1-1-5001-4-8027',
                      parentValue: '1-1-5001-4',
                      value: '1-1-5001-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '1-1-5001-4-8028',
                      parentValue: '1-1-5001-4',
                      value: '1-1-5001-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '1-1-5001-5',
                  value: '1-1-5001-5',
                  parentValue: '1-1-5001',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '1-1-5001-5-8040',
                      parentValue: '1-1-5001-5',
                      value: '1-1-5001-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '1-1-5001-9',
                  value: '1-1-5001-9',
                  parentValue: '1-1-5001',

                  children: [
                    {
                      label: 'Polyester',
                      key: '1-1-5001-9-8079',
                      parentValue: '1-1-5001-9',
                      value: '1-1-5001-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '1-1-5001-9-8080',
                      parentValue: '1-1-5001-9',
                      value: '1-1-5001-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '1-1-5001-11',
                  value: '1-1-5001-11',
                  parentValue: '1-1-5001',

                  children: [
                    {
                      label: 'Casual',
                      key: '1-1-5001-11-8093',
                      parentValue: '1-1-5001-11',
                      value: '1-1-5001-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Jumpsuit',
          key: '1-2',
          value: '1-2',
          children: [
            {
              label: 'Overalls',
              key: '1-2-5003',
              parentValue: '1-2',
              value: '1-2-5003',
              children: [
                {
                  label: 'Fabrication',
                  key: '1-2-5003-4',
                  value: '1-2-5003-4',
                  parentValue: '1-2-5003',

                  children: [
                    {
                      label: 'Denim',
                      key: '1-2-5003-4-8027',
                      parentValue: '1-2-5003-4',
                      value: '1-2-5003-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '1-2-5003-4-8028',
                      parentValue: '1-2-5003-4',
                      value: '1-2-5003-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '1-2-5003-5',
                  value: '1-2-5003-5',
                  parentValue: '1-2-5003',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '1-2-5003-5-8040',
                      parentValue: '1-2-5003-5',
                      value: '1-2-5003-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '1-2-5003-9',
                  value: '1-2-5003-9',
                  parentValue: '1-2-5003',

                  children: [
                    {
                      label: 'Polyester',
                      key: '1-2-5003-9-8079',
                      parentValue: '1-2-5003-9',
                      value: '1-2-5003-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '1-2-5003-9-8080',
                      parentValue: '1-2-5003-9',
                      value: '1-2-5003-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '1-2-5003-11',
                  value: '1-2-5003-11',
                  parentValue: '1-2-5003',

                  children: [
                    {
                      label: 'Casual',
                      key: '1-2-5003-11-8093',
                      parentValue: '1-2-5003-11',
                      value: '1-2-5003-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Pants',
          key: '1-4',
          value: '1-4',
          children: [
            {
              label: 'Jeans',
              key: '1-4-5018',
              parentValue: '1-4',
              value: '1-4-5018',
              children: [
                {
                  label: 'Fabrication',
                  key: '1-4-5018-4',
                  value: '1-4-5018-4',
                  parentValue: '1-4-5018',

                  children: [
                    {
                      label: 'Denim',
                      key: '1-4-5018-4-8027',
                      parentValue: '1-4-5018-4',
                      value: '1-4-5018-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '1-4-5018-9',
                  value: '1-4-5018-9',
                  parentValue: '1-4-5018',

                  children: [
                    {
                      label: 'Polyester',
                      key: '1-4-5018-9-8079',
                      parentValue: '1-4-5018-9',
                      value: '1-4-5018-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '1-4-5018-9-8080',
                      parentValue: '1-4-5018-9',
                      value: '1-4-5018-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '1-4-5018-11',
                  value: '1-4-5018-11',
                  parentValue: '1-4-5018',

                  children: [
                    {
                      label: 'Casual',
                      key: '1-4-5018-11-8093',
                      parentValue: '1-4-5018-11',
                      value: '1-4-5018-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Leggings',
              key: '1-4-5020',
              parentValue: '1-4',
              value: '1-4-5020',
              children: [
                {
                  label: 'Fabrication',
                  key: '1-4-5020-4',
                  value: '1-4-5020-4',
                  parentValue: '1-4-5020',

                  children: [
                    {
                      label: 'Denim',
                      key: '1-4-5020-4-8027',
                      parentValue: '1-4-5020-4',
                      value: '1-4-5020-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '1-4-5020-9',
                  value: '1-4-5020-9',
                  parentValue: '1-4-5020',

                  children: [
                    {
                      label: 'Polyester',
                      key: '1-4-5020-9-8079',
                      parentValue: '1-4-5020-9',
                      value: '1-4-5020-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '1-4-5020-9-8080',
                      parentValue: '1-4-5020-9',
                      value: '1-4-5020-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '1-4-5020-11',
                  value: '1-4-5020-11',
                  parentValue: '1-4-5020',

                  children: [
                    {
                      label: 'Casual',
                      key: '1-4-5020-11-8093',
                      parentValue: '1-4-5020-11',
                      value: '1-4-5020-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Baby Girls',
      key: '2',
      value: '2',
      children: [
        {
          label: 'Dress',
          key: '2-1',
          value: '2-1',
          children: [
            {
              label: 'Kimono',
              key: '2-1-5000',
              parentValue: '2-1',
              value: '2-1-5000',
              children: [
                {
                  label: 'Fabrication',
                  key: '2-1-5000-4',
                  value: '2-1-5000-4',
                  parentValue: '2-1-5000',

                  children: [
                    {
                      label: 'Denim',
                      key: '2-1-5000-4-8027',
                      parentValue: '2-1-5000-4',
                      value: '2-1-5000-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '2-1-5000-4-8028',
                      parentValue: '2-1-5000-4',
                      value: '2-1-5000-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '2-1-5000-5',
                  value: '2-1-5000-5',
                  parentValue: '2-1-5000',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '2-1-5000-5-8040',
                      parentValue: '2-1-5000-5',
                      value: '2-1-5000-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '2-1-5000-9',
                  value: '2-1-5000-9',
                  parentValue: '2-1-5000',

                  children: [
                    {
                      label: 'Polyester',
                      key: '2-1-5000-9-8079',
                      parentValue: '2-1-5000-9',
                      value: '2-1-5000-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '2-1-5000-9-8080',
                      parentValue: '2-1-5000-9',
                      value: '2-1-5000-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '2-1-5000-11',
                  value: '2-1-5000-11',
                  parentValue: '2-1-5000',

                  children: [
                    {
                      label: 'Casual',
                      key: '2-1-5000-11-8093',
                      parentValue: '2-1-5000-11',
                      value: '2-1-5000-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Shirtdress',
              key: '2-1-5001',
              parentValue: '2-1',
              value: '2-1-5001',
              children: [
                {
                  label: 'Fabrication',
                  key: '2-1-5001-4',
                  value: '2-1-5001-4',
                  parentValue: '2-1-5001',

                  children: [
                    {
                      label: 'Denim',
                      key: '2-1-5001-4-8027',
                      parentValue: '2-1-5001-4',
                      value: '2-1-5001-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '2-1-5001-4-8028',
                      parentValue: '2-1-5001-4',
                      value: '2-1-5001-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '2-1-5001-5',
                  value: '2-1-5001-5',
                  parentValue: '2-1-5001',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '2-1-5001-5-8040',
                      parentValue: '2-1-5001-5',
                      value: '2-1-5001-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '2-1-5001-9',
                  value: '2-1-5001-9',
                  parentValue: '2-1-5001',

                  children: [
                    {
                      label: 'Polyester',
                      key: '2-1-5001-9-8079',
                      parentValue: '2-1-5001-9',
                      value: '2-1-5001-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '2-1-5001-9-8080',
                      parentValue: '2-1-5001-9',
                      value: '2-1-5001-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '2-1-5001-11',
                  value: '2-1-5001-11',
                  parentValue: '2-1-5001',

                  children: [
                    {
                      label: 'Casual',
                      key: '2-1-5001-11-8093',
                      parentValue: '2-1-5001-11',
                      value: '2-1-5001-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Jumpsuit',
          key: '2-2',
          value: '2-2',
          children: [
            {
              label: 'Overalls',
              key: '2-2-5003',
              parentValue: '2-2',
              value: '2-2-5003',
              children: [
                {
                  label: 'Fabrication',
                  key: '2-2-5003-4',
                  value: '2-2-5003-4',
                  parentValue: '2-2-5003',

                  children: [
                    {
                      label: 'Denim',
                      key: '2-2-5003-4-8027',
                      parentValue: '2-2-5003-4',
                      value: '2-2-5003-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '2-2-5003-4-8028',
                      parentValue: '2-2-5003-4',
                      value: '2-2-5003-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '2-2-5003-5',
                  value: '2-2-5003-5',
                  parentValue: '2-2-5003',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '2-2-5003-5-8040',
                      parentValue: '2-2-5003-5',
                      value: '2-2-5003-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '2-2-5003-9',
                  value: '2-2-5003-9',
                  parentValue: '2-2-5003',

                  children: [
                    {
                      label: 'Polyester',
                      key: '2-2-5003-9-8079',
                      parentValue: '2-2-5003-9',
                      value: '2-2-5003-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '2-2-5003-9-8080',
                      parentValue: '2-2-5003-9',
                      value: '2-2-5003-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '2-2-5003-11',
                  value: '2-2-5003-11',
                  parentValue: '2-2-5003',

                  children: [
                    {
                      label: 'Casual',
                      key: '2-2-5003-11-8093',
                      parentValue: '2-2-5003-11',
                      value: '2-2-5003-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Pants',
          key: '2-4',
          value: '2-4',
          children: [
            {
              label: 'Jeans',
              key: '2-4-5018',
              parentValue: '2-4',
              value: '2-4-5018',
              children: [
                {
                  label: 'Fabrication',
                  key: '2-4-5018-4',
                  value: '2-4-5018-4',
                  parentValue: '2-4-5018',

                  children: [
                    {
                      label: 'Denim',
                      key: '2-4-5018-4-8027',
                      parentValue: '2-4-5018-4',
                      value: '2-4-5018-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '2-4-5018-9',
                  value: '2-4-5018-9',
                  parentValue: '2-4-5018',

                  children: [
                    {
                      label: 'Polyester',
                      key: '2-4-5018-9-8079',
                      parentValue: '2-4-5018-9',
                      value: '2-4-5018-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '2-4-5018-9-8080',
                      parentValue: '2-4-5018-9',
                      value: '2-4-5018-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '2-4-5018-11',
                  value: '2-4-5018-11',
                  parentValue: '2-4-5018',

                  children: [
                    {
                      label: 'Casual',
                      key: '2-4-5018-11-8093',
                      parentValue: '2-4-5018-11',
                      value: '2-4-5018-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Leggings',
              key: '2-4-5020',
              parentValue: '2-4',
              value: '2-4-5020',
              children: [
                {
                  label: 'Fabrication',
                  key: '2-4-5020-4',
                  value: '2-4-5020-4',
                  parentValue: '2-4-5020',

                  children: [
                    {
                      label: 'Denim',
                      key: '2-4-5020-4-8027',
                      parentValue: '2-4-5020-4',
                      value: '2-4-5020-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '2-4-5020-9',
                  value: '2-4-5020-9',
                  parentValue: '2-4-5020',

                  children: [
                    {
                      label: 'Polyester',
                      key: '2-4-5020-9-8079',
                      parentValue: '2-4-5020-9',
                      value: '2-4-5020-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '2-4-5020-9-8080',
                      parentValue: '2-4-5020-9',
                      value: '2-4-5020-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '2-4-5020-11',
                  value: '2-4-5020-11',
                  parentValue: '2-4-5020',

                  children: [
                    {
                      label: 'Casual',
                      key: '2-4-5020-11-8093',
                      parentValue: '2-4-5020-11',
                      value: '2-4-5020-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Boys',
      key: '3',
      value: '3',
      children: [
        {
          label: 'Dress',
          key: '3-1',
          value: '3-1',
          children: [
            {
              label: 'Kimono',
              key: '3-1-5000',
              parentValue: '3-1',
              value: '3-1-5000',
              children: [
                {
                  label: 'Fabrication',
                  key: '3-1-5000-4',
                  value: '3-1-5000-4',
                  parentValue: '3-1-5000',

                  children: [
                    {
                      label: 'Denim',
                      key: '3-1-5000-4-8027',
                      parentValue: '3-1-5000-4',
                      value: '3-1-5000-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '3-1-5000-4-8028',
                      parentValue: '3-1-5000-4',
                      value: '3-1-5000-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '3-1-5000-5',
                  value: '3-1-5000-5',
                  parentValue: '3-1-5000',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '3-1-5000-5-8040',
                      parentValue: '3-1-5000-5',
                      value: '3-1-5000-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '3-1-5000-9',
                  value: '3-1-5000-9',
                  parentValue: '3-1-5000',

                  children: [
                    {
                      label: 'Polyester',
                      key: '3-1-5000-9-8079',
                      parentValue: '3-1-5000-9',
                      value: '3-1-5000-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '3-1-5000-9-8080',
                      parentValue: '3-1-5000-9',
                      value: '3-1-5000-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '3-1-5000-11',
                  value: '3-1-5000-11',
                  parentValue: '3-1-5000',

                  children: [
                    {
                      label: 'Casual',
                      key: '3-1-5000-11-8093',
                      parentValue: '3-1-5000-11',
                      value: '3-1-5000-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Shirtdress',
              key: '3-1-5001',
              parentValue: '3-1',
              value: '3-1-5001',
              children: [
                {
                  label: 'Fabrication',
                  key: '3-1-5001-4',
                  value: '3-1-5001-4',
                  parentValue: '3-1-5001',

                  children: [
                    {
                      label: 'Denim',
                      key: '3-1-5001-4-8027',
                      parentValue: '3-1-5001-4',
                      value: '3-1-5001-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '3-1-5001-4-8028',
                      parentValue: '3-1-5001-4',
                      value: '3-1-5001-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '3-1-5001-5',
                  value: '3-1-5001-5',
                  parentValue: '3-1-5001',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '3-1-5001-5-8040',
                      parentValue: '3-1-5001-5',
                      value: '3-1-5001-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '3-1-5001-9',
                  value: '3-1-5001-9',
                  parentValue: '3-1-5001',

                  children: [
                    {
                      label: 'Polyester',
                      key: '3-1-5001-9-8079',
                      parentValue: '3-1-5001-9',
                      value: '3-1-5001-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '3-1-5001-9-8080',
                      parentValue: '3-1-5001-9',
                      value: '3-1-5001-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '3-1-5001-11',
                  value: '3-1-5001-11',
                  parentValue: '3-1-5001',

                  children: [
                    {
                      label: 'Casual',
                      key: '3-1-5001-11-8093',
                      parentValue: '3-1-5001-11',
                      value: '3-1-5001-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Jumpsuit',
          key: '3-2',
          value: '3-2',
          children: [
            {
              label: 'Overalls',
              key: '3-2-5003',
              parentValue: '3-2',
              value: '3-2-5003',
              children: [
                {
                  label: 'Fabrication',
                  key: '3-2-5003-4',
                  value: '3-2-5003-4',
                  parentValue: '3-2-5003',

                  children: [
                    {
                      label: 'Denim',
                      key: '3-2-5003-4-8027',
                      parentValue: '3-2-5003-4',
                      value: '3-2-5003-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '3-2-5003-4-8028',
                      parentValue: '3-2-5003-4',
                      value: '3-2-5003-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '3-2-5003-5',
                  value: '3-2-5003-5',
                  parentValue: '3-2-5003',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '3-2-5003-5-8040',
                      parentValue: '3-2-5003-5',
                      value: '3-2-5003-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '3-2-5003-9',
                  value: '3-2-5003-9',
                  parentValue: '3-2-5003',

                  children: [
                    {
                      label: 'Polyester',
                      key: '3-2-5003-9-8079',
                      parentValue: '3-2-5003-9',
                      value: '3-2-5003-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '3-2-5003-9-8080',
                      parentValue: '3-2-5003-9',
                      value: '3-2-5003-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '3-2-5003-11',
                  value: '3-2-5003-11',
                  parentValue: '3-2-5003',

                  children: [
                    {
                      label: 'Casual',
                      key: '3-2-5003-11-8093',
                      parentValue: '3-2-5003-11',
                      value: '3-2-5003-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Pants',
          key: '3-4',
          value: '3-4',
          children: [
            {
              label: 'Jeans',
              key: '3-4-5018',
              parentValue: '3-4',
              value: '3-4-5018',
              children: [
                {
                  label: 'Fabrication',
                  key: '3-4-5018-4',
                  value: '3-4-5018-4',
                  parentValue: '3-4-5018',

                  children: [
                    {
                      label: 'Denim',
                      key: '3-4-5018-4-8027',
                      parentValue: '3-4-5018-4',
                      value: '3-4-5018-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '3-4-5018-9',
                  value: '3-4-5018-9',
                  parentValue: '3-4-5018',

                  children: [
                    {
                      label: 'Polyester',
                      key: '3-4-5018-9-8079',
                      parentValue: '3-4-5018-9',
                      value: '3-4-5018-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '3-4-5018-9-8080',
                      parentValue: '3-4-5018-9',
                      value: '3-4-5018-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '3-4-5018-11',
                  value: '3-4-5018-11',
                  parentValue: '3-4-5018',

                  children: [
                    {
                      label: 'Casual',
                      key: '3-4-5018-11-8093',
                      parentValue: '3-4-5018-11',
                      value: '3-4-5018-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Leggings',
              key: '3-4-5020',
              parentValue: '3-4',
              value: '3-4-5020',
              children: [
                {
                  label: 'Fabrication',
                  key: '3-4-5020-4',
                  value: '3-4-5020-4',
                  parentValue: '3-4-5020',

                  children: [
                    {
                      label: 'Denim',
                      key: '3-4-5020-4-8027',
                      parentValue: '3-4-5020-4',
                      value: '3-4-5020-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '3-4-5020-9',
                  value: '3-4-5020-9',
                  parentValue: '3-4-5020',

                  children: [
                    {
                      label: 'Polyester',
                      key: '3-4-5020-9-8079',
                      parentValue: '3-4-5020-9',
                      value: '3-4-5020-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '3-4-5020-9-8080',
                      parentValue: '3-4-5020-9',
                      value: '3-4-5020-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '3-4-5020-11',
                  value: '3-4-5020-11',
                  parentValue: '3-4-5020',

                  children: [
                    {
                      label: 'Casual',
                      key: '3-4-5020-11-8093',
                      parentValue: '3-4-5020-11',
                      value: '3-4-5020-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Girls',
      key: '4',
      value: '4',
      children: [
        {
          label: 'Dress',
          key: '4-1',
          value: '4-1',
          children: [
            {
              label: 'Kimono',
              key: '4-1-5000',
              parentValue: '4-1',
              value: '4-1-5000',
              children: [
                {
                  label: 'Fabrication',
                  key: '4-1-5000-4',
                  value: '4-1-5000-4',
                  parentValue: '4-1-5000',

                  children: [
                    {
                      label: 'Denim',
                      key: '4-1-5000-4-8027',
                      parentValue: '4-1-5000-4',
                      value: '4-1-5000-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '4-1-5000-4-8028',
                      parentValue: '4-1-5000-4',
                      value: '4-1-5000-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '4-1-5000-5',
                  value: '4-1-5000-5',
                  parentValue: '4-1-5000',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '4-1-5000-5-8040',
                      parentValue: '4-1-5000-5',
                      value: '4-1-5000-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '4-1-5000-9',
                  value: '4-1-5000-9',
                  parentValue: '4-1-5000',

                  children: [
                    {
                      label: 'Polyester',
                      key: '4-1-5000-9-8079',
                      parentValue: '4-1-5000-9',
                      value: '4-1-5000-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '4-1-5000-9-8080',
                      parentValue: '4-1-5000-9',
                      value: '4-1-5000-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '4-1-5000-11',
                  value: '4-1-5000-11',
                  parentValue: '4-1-5000',

                  children: [
                    {
                      label: 'Casual',
                      key: '4-1-5000-11-8093',
                      parentValue: '4-1-5000-11',
                      value: '4-1-5000-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Shirtdress',
              key: '4-1-5001',
              parentValue: '4-1',
              value: '4-1-5001',
              children: [
                {
                  label: 'Fabrication',
                  key: '4-1-5001-4',
                  value: '4-1-5001-4',
                  parentValue: '4-1-5001',

                  children: [
                    {
                      label: 'Denim',
                      key: '4-1-5001-4-8027',
                      parentValue: '4-1-5001-4',
                      value: '4-1-5001-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '4-1-5001-4-8028',
                      parentValue: '4-1-5001-4',
                      value: '4-1-5001-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '4-1-5001-5',
                  value: '4-1-5001-5',
                  parentValue: '4-1-5001',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '4-1-5001-5-8040',
                      parentValue: '4-1-5001-5',
                      value: '4-1-5001-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '4-1-5001-9',
                  value: '4-1-5001-9',
                  parentValue: '4-1-5001',

                  children: [
                    {
                      label: 'Polyester',
                      key: '4-1-5001-9-8079',
                      parentValue: '4-1-5001-9',
                      value: '4-1-5001-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '4-1-5001-9-8080',
                      parentValue: '4-1-5001-9',
                      value: '4-1-5001-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '4-1-5001-11',
                  value: '4-1-5001-11',
                  parentValue: '4-1-5001',

                  children: [
                    {
                      label: 'Casual',
                      key: '4-1-5001-11-8093',
                      parentValue: '4-1-5001-11',
                      value: '4-1-5001-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Jumpsuit',
          key: '4-2',
          value: '4-2',
          children: [
            {
              label: 'Overalls',
              key: '4-2-5003',
              parentValue: '4-2',
              value: '4-2-5003',
              children: [
                {
                  label: 'Fabrication',
                  key: '4-2-5003-4',
                  value: '4-2-5003-4',
                  parentValue: '4-2-5003',

                  children: [
                    {
                      label: 'Denim',
                      key: '4-2-5003-4-8027',
                      parentValue: '4-2-5003-4',
                      value: '4-2-5003-4-8027',
                    },
                    {
                      label: 'Not Denim',
                      key: '4-2-5003-4-8028',
                      parentValue: '4-2-5003-4',
                      value: '4-2-5003-4-8028',
                    },
                  ],
                },
                {
                  label: 'Feature',
                  key: '4-2-5003-5',
                  value: '4-2-5003-5',
                  parentValue: '4-2-5003',

                  children: [
                    {
                      label: 'Short-Sleeves',
                      key: '4-2-5003-5-8040',
                      parentValue: '4-2-5003-5',
                      value: '4-2-5003-5-8040',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '4-2-5003-9',
                  value: '4-2-5003-9',
                  parentValue: '4-2-5003',

                  children: [
                    {
                      label: 'Polyester',
                      key: '4-2-5003-9-8079',
                      parentValue: '4-2-5003-9',
                      value: '4-2-5003-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '4-2-5003-9-8080',
                      parentValue: '4-2-5003-9',
                      value: '4-2-5003-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '4-2-5003-11',
                  value: '4-2-5003-11',
                  parentValue: '4-2-5003',

                  children: [
                    {
                      label: 'Casual',
                      key: '4-2-5003-11-8093',
                      parentValue: '4-2-5003-11',
                      value: '4-2-5003-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Pants',
          key: '4-4',
          value: '4-4',
          children: [
            {
              label: 'Jeans',
              key: '4-4-5018',
              parentValue: '4-4',
              value: '4-4-5018',
              children: [
                {
                  label: 'Fabrication',
                  key: '4-4-5018-4',
                  value: '4-4-5018-4',
                  parentValue: '4-4-5018',

                  children: [
                    {
                      label: 'Denim',
                      key: '4-4-5018-4-8027',
                      parentValue: '4-4-5018-4',
                      value: '4-4-5018-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '4-4-5018-9',
                  value: '4-4-5018-9',
                  parentValue: '4-4-5018',

                  children: [
                    {
                      label: 'Polyester',
                      key: '4-4-5018-9-8079',
                      parentValue: '4-4-5018-9',
                      value: '4-4-5018-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '4-4-5018-9-8080',
                      parentValue: '4-4-5018-9',
                      value: '4-4-5018-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '4-4-5018-11',
                  value: '4-4-5018-11',
                  parentValue: '4-4-5018',

                  children: [
                    {
                      label: 'Casual',
                      key: '4-4-5018-11-8093',
                      parentValue: '4-4-5018-11',
                      value: '4-4-5018-11-8093',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Leggings',
              key: '4-4-5020',
              parentValue: '4-4',
              value: '4-4-5020',
              children: [
                {
                  label: 'Fabrication',
                  key: '4-4-5020-4',
                  value: '4-4-5020-4',
                  parentValue: '4-4-5020',

                  children: [
                    {
                      label: 'Denim',
                      key: '4-4-5020-4-8027',
                      parentValue: '4-4-5020-4',
                      value: '4-4-5020-4-8027',
                    },
                  ],
                },
                {
                  label: 'Material',
                  key: '4-4-5020-9',
                  value: '4-4-5020-9',
                  parentValue: '4-4-5020',

                  children: [
                    {
                      label: 'Polyester',
                      key: '4-4-5020-9-8079',
                      parentValue: '4-4-5020-9',
                      value: '4-4-5020-9-8079',
                    },
                    {
                      label: 'Rayon',
                      key: '4-4-5020-9-8080',
                      parentValue: '4-4-5020-9',
                      value: '4-4-5020-9-8080',
                    },
                  ],
                },
                {
                  label: 'Occasion',
                  key: '4-4-5020-11',
                  value: '4-4-5020-11',
                  parentValue: '4-4-5020',

                  children: [
                    {
                      label: 'Casual',
                      key: '4-4-5020-11-8093',
                      parentValue: '4-4-5020-11',
                      value: '4-4-5020-11-8093',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

export default filters
