import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { LocaleProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import { Router, Route } from 'react-router'
import { ScrollContext } from 'react-router-scroll-4'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './root-saga'
import rootReducer from './root-reducer'
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker'
import history from './history'
import { Loadable } from './common'

import withTracker from './withTracker'

import './common/styles/global.css'

const LoadableApp = Loadable({
  loader: () => import(/* webpackChunkName: "App" */ './layout/components/app'),
})

const sagaMiddleware = createSagaMiddleware()
// const createStoreWithMiddleware = applyMiddleware(sagaMiddleware)(createStore);
// const store = createStoreWithMiddleware(
//   rootReducer,
//    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   // window.devToolsExtension && window.devToolsExtension(),
// );

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <LocaleProvider locale={enUS}>
      <Router history={history}>
        <ScrollContext>
          <div>
            <Route path="/" component={withTracker(LoadableApp)} />
          </div>
        </ScrollContext>
      </Router>
    </LocaleProvider>
  </Provider>,
  document.querySelector('#root'),
)
// registerServiceWorker();
unregister()
