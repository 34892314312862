import _ from 'lodash'
import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects'
import { parseRating, parseRange, buildQueryObject } from '../insights'
import {
  storeReviews,
  storeAllReviews,
  storeDownloadPercentage,
  storeWords,
  storePEI,
  storeReviewCount,
  setFlag,
  storeTag,
  storeDeleteTag,
  setPagination,
  setReviewLoadingState,
  setPEILoadingState,
  setChartLoadingState,
  setFilterObject,
  setReviewTags,
  setDeleteTagsId,
} from './actions'
import {
  REQUEST_REVIEWS,
  REQUEST_ALL_REVIEWS,
  REQUEST_WORDS,
  REQUEST_PEI,
  REQUEST_SET_FLAG,
  REQUEST_ADD_TAG,
  REQUEST_DELETE_TAG,
  REQUEST_ADD_ALL_TAGS,
  REQUEST_DELETE_ALL_TAGS,
  REQUEST_DELETE_FLAG,
} from './constants/ActionTypes'
import Api from './api'

const delay = ms => new Promise(res => setTimeout(res, ms))

export function selectReviewTags(state) {
  return state.review.reviewTags
}

export function selectDeleteTagsIdArr(state) {
  return state.review.deleteTagsIdArr
}

export function* getAllReviews(action) {
  const { filterSelection, searchParams } = action.payload.params
  const { additionalParams, resolve, reject } = action.payload
  let offset = 0
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
    },
  }

  try {
    const totalReviews = []
    let retrievingReviews = true
    while (retrievingReviews) {
      const pagination = `?limit=1000&offset=${offset}`
      const response = yield call(Api.getReviews, pagination, { ...queryObject })
      if (response.data.length) {
        totalReviews.push(...response.data)
        offset += 1000
        const downloadPercentage = Math.round((totalReviews.length / response.data[0].total_record_count) * 100)
        yield put(storeDownloadPercentage(downloadPercentage))
        if (totalReviews.length >= response.data[0].total_record_count) retrievingReviews = false
      } else {
        retrievingReviews = false
      }
    }
    yield put(storeAllReviews(totalReviews))
    resolve()
  } catch (err) {
    reject()
    console.log('err', err)
  }
}

/**
 * Fetch reviews from the server and execute other actions
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* getReviews(action) {
  yield put(setReviewLoadingState(true))
  const { filterSelection, pageNum, pageSize, searchParams } = action.payload.params
  const offset = (pageNum - 1) * pageSize
  const pagination = `?limit=${pageSize}&offset=${offset}`
  const { additionalParams } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
    },
  }
  if (queryObject.query_object.search_review) {
    queryObject.query_object.search_review = _.replace(queryObject.query_object.search_review, new RegExp(' ', 'g'), ' <-> ')
  }

  try {
    const response = yield call(Api.getReviews, pagination, { ...queryObject })
    yield put(setFilterObject({ ...filterSelection }))

    let reviewCount = 0
    if (response.data[0]) reviewCount = response.data[0].total_record_count
    yield put(storeReviewCount(parseRange(reviewCount, response.request.responseURL)))
    yield put(storeReviews(response.data))
    yield put(setPagination(pageNum, pageSize))
    yield put(setReviewLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setReviewLoadingState(false))
  }
}

/**
 * Fetch reviews from the server and calculate PEI based off of total count of reviews for pos and neg
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* getWords(action) {
  yield put(setChartLoadingState(true))
  const { filterSelection, searchParams } = action.payload.params
  const { additionalParams } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )
  const queryObject = {
    query_object: {
      ...formattedQueryObj.additional_filter,
      ...formattedQueryObj.filter_object,
      ...formattedQueryObj.search_object,
    },
  }
  try {
    const response = yield call(Api.getWords, { ...queryObject })
    yield put(storeWords(response.data[0]))
    yield put(setChartLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setChartLoadingState(false))
  }
}

export function* getPEI(action) {
  yield put(setReviewLoadingState(true))
  yield put(setPEILoadingState(true))
  const { filterSelection, pageNum, pageSize, searchParams } = action.payload.params
  const offset = (pageNum - 1) * pageSize
  const pagination = `?limit=${pageSize}&offset=${offset}`
  const { additionalParams } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObj = buildQueryObject(
    {
      ...filterSelection,
      review_rating: ratingArr,
    },
    { ...additionalParams },
    { ...searchParams },
  )

  if (formattedQueryObj.filter_object.review_rating === '(1,2,3,4,5)' || formattedQueryObj.filter_object.review_rating === undefined) {
    const queryObjectTotal = {
      query_object: {
        ...formattedQueryObj.additional_filter,
        ...formattedQueryObj.filter_object,
        ...formattedQueryObj.search_object,
      },
    }
    const queryObjectRatingPos = {
      query_object: {
        ...formattedQueryObj.additional_filter,
        ...formattedQueryObj.filter_object,
        ...formattedQueryObj.search_object,
        review_rating: '(5)',
      },
    }
    const queryObjectRatingNeg = {
      query_object: {
        ...formattedQueryObj.additional_filter,
        ...formattedQueryObj.filter_object,
        ...formattedQueryObj.search_object,
        review_rating: '(1,2,3)',
      },
    }

    if (queryObjectTotal.query_object.search_review) {
      queryObjectTotal.query_object.search_review = _.replace(queryObjectTotal.query_object.search_review, new RegExp(' ', 'g'), ' <-> ')
      queryObjectRatingPos.query_object.search_review = _.replace(queryObjectRatingPos.query_object.search_review, new RegExp(' ', 'g'), ' <-> ')
      queryObjectRatingNeg.query_object.search_review = _.replace(queryObjectRatingNeg.query_object.search_review, new RegExp(' ', 'g'), ' <-> ')
    }
  
    try {
      const responseTotal = yield call(Api.getReviews, pagination, { ...queryObjectTotal })
      const responsePos = yield call(Api.getReviews, pagination, { ...queryObjectRatingPos })
      const responseNeg = yield call(Api.getReviews, pagination, { ...queryObjectRatingNeg })
      yield put(setFilterObject({ ...filterSelection }))

      let totalPosReviewCount = 0
      let totalNegReviewCount = 0
      let totalReviewCount = 0
      if (responsePos.data[0]) totalPosReviewCount = responsePos.data[0].total_record_count
      if (responseNeg.data[0]) totalNegReviewCount = responseNeg.data[0].total_record_count
      if (responseTotal.data[0]) totalReviewCount = responseTotal.data[0].total_record_count
      const PEI = Math.round(((totalPosReviewCount - totalNegReviewCount) / totalReviewCount) * 100)

      yield put(storePEI(PEI))
      yield put(setReviewLoadingState(false))
      yield put(setPEILoadingState(false))
    } catch (err) {
      console.log('err', err)
      yield put(setReviewLoadingState(false))
      yield put(setPEILoadingState(false))
    }
  } else {
    // if user filters by star rating, PEI is 'N/A'
    yield put(storePEI('N/A'))
    yield put(setPEILoadingState(false))
  }
}

/**
 * Unflag/Flag review
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* updateFlag(action) {
  const { index, reviewId: review_id, reviewExternalId: review_external_id, comment } = action.payload

  const queryObject = {
    query_object: {
      review_id,
      review_external_id,
      comment,
    },
  }
  try {
    const response = yield call(Api.updateFlag, { ...queryObject })
    const reviewFlagId = response.data[0].review_flag_id
    yield put(setFlag(index, true, reviewFlagId))
  } catch (err) {
    console.log('err', err)
  }
}

export function* deleteFlag(action) {
  const { index, reviewFlagId: review_flag_id } = action.payload

  const queryObject = {
    query_object: { review_flag_id },
  }
  try {
    yield call(Api.deleteFlag, { ...queryObject })
    yield put(setFlag(index, false))
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * Add tag for certain review
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* addTag(action) {
  const { index, reviewId, reviewExternalId, tag } = action.payload

  const queryObject = {
    query_object: {
      agg_review_id: [reviewId],
      agg_review_external_id: [reviewExternalId],
      tag,
    },
  }
  try {
    const response = yield call(Api.addTag, { ...queryObject })
    const { review_tag_id: tagId } = response.data[0]
    yield put(storeTag(index, { review_tag_id: tagId, text: tag }))
    const reviewTags = yield select(selectReviewTags)
    if (_.has(reviewTags, reviewId)) {
      reviewTags[`${reviewId}`] = [...reviewTags[`${reviewId}`], { tagId, tag }]
    } else {
      reviewTags[`${reviewId}`] = [{ tagId, tag }]
    }
    yield put(setReviewTags(reviewTags))
  } catch (err) {
    console.log('err', err)
  }
}

export function* addAllTags(action) {
  const { indexArr, reviewIdAndExternalIdArr, tag } = action.payload

  const queryObject = {
    query_object: {
      agg_review_id: _.map(reviewIdAndExternalIdArr, 'id'),
      agg_review_external_id: _.map(reviewIdAndExternalIdArr, 'externalId'),
      tag,
    },
  }

  try {
    const response = yield call(Api.addTag, { ...queryObject })
    const responseArr = response.data
    const reviewTags = yield select(selectReviewTags)
    responseArr.forEach(e => {
      const { review_tag_id: tagId, review_id: reviewId } = e
      if (_.has(reviewTags, reviewId)) {
        reviewTags[`${reviewId}`] = [...reviewTags[`${reviewId}`], { tagId, tag }]
      } else {
        reviewTags[`${reviewId}`] = [{ tagId, tag }]
      }
    })
    yield put(setReviewTags(reviewTags))
    yield all(
      responseArr.map(e => {
        const { review_tag_id: tagId, review_id: reviewId } = e
        const indexObject = _.find(indexArr, ['id', reviewId])
        if (!_.isEmpty(indexObject)) {
          const response = put(storeTag(indexObject.index, { id: tagId, text: tag }))
          return response
        }
        return ''
      }),
    )
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * Delete tag for certain review
 * @param  {object}    action plain object returned from action creator
 * @return {Generator}
 */
export function* deleteTag(action) {
  const { index, tagId } = action.payload

  const queryObject = {
    query_object: { agg_review_tag_id: [tagId] },
  }
  try {
    yield call(Api.deleteTag, { ...queryObject })
    const reviewTags = yield select(selectReviewTags)
    _.forIn(reviewTags, (value, key) => {
      if (_.includes(_.map(value, 'tagId'), tagId)) {
        reviewTags[key] = _.reject(value, e => e.tagId === tagId)
      }
    })
    yield put(setReviewTags(reviewTags))
    const deleteTagsIdArr = yield select(selectDeleteTagsIdArr)
    yield put(setDeleteTagsId([...deleteTagsIdArr, tagId]))
    yield put(storeDeleteTag(index, tagId))
  } catch (err) {
    console.log('err', err)
  }
}

export function* deleteAllTags(action) {
  const { indexArr, tagIdArr } = action.payload

  const queryObject = {
    query_object: { agg_review_tag_id: tagIdArr },
  }
  try {
    yield call(Api.deleteTag, { ...queryObject })
    const reviewTags = yield select(selectReviewTags)
    tagIdArr.forEach(tagId => {
      _.forIn(reviewTags, (value, key) => {
        if (_.includes(_.map(value, 'tagId'), tagId)) {
          reviewTags[key] = _.reject(value, e => e.tagId === tagId)
        }
      })
    })
    const deleteTagsIdArr = yield select(selectDeleteTagsIdArr)
    yield put(setDeleteTagsId([...deleteTagsIdArr, ...tagIdArr]))
    yield put(setReviewTags(reviewTags))
    yield all(
      indexArr.map(e => {
        const { id: tagId, index } = e
        const response = put(storeDeleteTag(index, tagId))
        return response
      }),
    )
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * Watcher Saga for actions of review explorer
 * @return {Generator}
 */
export default function* watchReview() {
  yield all([
    takeLatest(REQUEST_REVIEWS, getReviews),
    takeLatest(REQUEST_ALL_REVIEWS, getAllReviews),
    takeLatest(REQUEST_WORDS, getWords),
    takeLatest(REQUEST_PEI, getPEI),
    takeLatest(REQUEST_SET_FLAG, updateFlag),
    takeEvery(REQUEST_ADD_TAG, addTag),
    takeEvery(REQUEST_DELETE_TAG, deleteTag),
    takeEvery(REQUEST_ADD_ALL_TAGS, addAllTags),
    takeEvery(REQUEST_DELETE_ALL_TAGS, deleteAllTags),
    takeEvery(REQUEST_DELETE_FLAG, deleteFlag),
  ])
}
