import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { imageDomainName, DescriptionItem } from '../../common'

const ProductDetailsPopover = ({ record }) => (
  <div>
    <div
      style={{
        height: 200,
      }}
      className="center"
    >
      <img
        src={!_.isEmpty(record.imagePath) ? `${imageDomainName}${record.imagePath.large}` : `/assets/img/brand/${record.brand.replace("'", '')}.png`}
        style={{
          maxWidth: 200,
          maxHeight: 200,
        }}
        alt={`${record.brand.replace("'", '')}`}
      />
    </div>
    <DescriptionItem title="Product Name" content={record.productName} />
    <DescriptionItem title="Brand" content={record.brand} inline={true} />
    {record.subBrand === 'NULL' ? null : <DescriptionItem title="Subbrand" content={record.subBrand} inline={true} />}
    <DescriptionItem title="Source" content={record.source.join(', ')} inline={true} />
    {_.isEmpty(record.price) ? null : <DescriptionItem title="Price" content={record.price} inline={true} />}
    <DescriptionItem title="External ID" content={record.external_id} inline={true} />
  </div>
)

ProductDetailsPopover.defaultProps = {
  record: {},
}

ProductDetailsPopover.propTypes = {
  record: PropTypes.shape({}),
}
export default ProductDetailsPopover
