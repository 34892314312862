import _ from 'lodash'
import { defaultPageSize } from '../common'
import { searchTypes, logicParams } from './constants/textSearch'
import {
  RECEIVE_PRODUCTS,
  RECEIVE_ALL_PRODUCTS,
  RECEIVE_SORT_FILTERS,
  RECEIVE_ADD_PRODUCT_ATTR,
  RECEIVE_ADD_PRODUCT_ATTRS,
  RECEIVE_DELETE_PRODUCT_ATTR,
  RECEIVE_DELETE_PRODUCT_ATTRS,
  SET_PRODUCT_LOADING_STATE,
  SET_RANGE,
  SET_PAGINATION,
  SET_SEARCH_PARAMS,
  SET_PRODUCT_ATTRS,
  SET_DELETED_PRODUCT_ATTR_ID_ARR,
  SET_SELECTED_PAGINATION,
  SET_IS_PRODUCT_DETAILS_HIDDEN,
} from './constants/ActionTypes'

const INITIAL_STATE = {
  products: [],
  allProducts: [],
  deleteProductAttrIdArr: [],
  productAttrs: {},
  selectedObj: {},
  isLoading: false,
  range: [],
  pageNum: 1,
  pageSize: defaultPageSize,
  searchParams: {
    searchType: searchTypes && searchTypes[0], // General search as default
    logicParam: logicParams && logicParams[0], // And as default
    product: {
      keyWords: [],
      logicParam: logicParams && logicParams[0],
    },
  },
  isProductDetailsHidden: false,
  sortAttribute: 'review_volume',
  sortOrder: 'desc',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.payload }
    case RECEIVE_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload }
    case RECEIVE_SORT_FILTERS:
      return {
        ...state,
        sortAttribute: action.payload.sortAttribute,
        sortOrder: action.payload.sortOrder,
      }
    case RECEIVE_ADD_PRODUCT_ATTRS:
      return { ...state, productAttrs: action.payload }
    case RECEIVE_ADD_PRODUCT_ATTR:
      const { index: idx, customAttr } = action.payload
      return {
        ...state,
        products: [
          ...state.products.slice(0, idx),
          {
            ...state.products[idx],
            custom_attributes: _.isEmpty(state.products[idx].custom_attributes) ? [customAttr] : [...state.products[idx].custom_attributes, customAttr],
          },
          ...state.products.slice(idx + 1),
        ],
      }
    case RECEIVE_DELETE_PRODUCT_ATTRS:
      return { ...state, deleteProductAttrIdArr: action.payload }
    case RECEIVE_DELETE_PRODUCT_ATTR:
      return {
        ...state,
        products: [
          ...state.products.slice(0, action.payload.index),
          {
            ...state.products[action.payload.index],
            custom_attributes: state.products[action.payload.index].custom_attributes.filter(eachCustomAttr => eachCustomAttr.product_custom_attribute_id !== action.payload.productAttrId),
          },
          ...state.products.slice(action.payload.index + 1),
        ],
      }
    case SET_PRODUCT_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_RANGE:
      return { ...state, range: action.payload }
    case SET_PAGINATION:
      return { ...state, pageNum: action.payload.pageNum, pageSize: action.payload.pageSize }
    case SET_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    case SET_PRODUCT_ATTRS:
      return { ...state, productAttrs: action.payload }
    case SET_DELETED_PRODUCT_ATTR_ID_ARR:
      return { ...state, deleteProductAttrIdArr: action.payload }
    case SET_SELECTED_PAGINATION:
      return { ...state, selectedObj: action.payload }
    case SET_IS_PRODUCT_DETAILS_HIDDEN:
      return { ...state, isProductDetailsHidden: action.payload }
    default:
      return state
  }
}
