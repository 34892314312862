import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import { Input, Select, Tag, Tooltip, Button, Popover } from 'antd'
import { setSearchParams, getProducts, getAllProducts } from '../actions'
import { getAdditionalFilters } from '../../insights'
import { searchTypes, logicParams } from '../constants/textSearch'
import CustomAttributeTable from './custom-attribute-table'
import './product.css'

export class SearchBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
      visible: false,
      loading: false,
    }

    this.handleTextSearchParamSelect = this.handleTextSearchParamSelect.bind(this)
    this.handleTextSearch = this.handleTextSearch.bind(this)
    this.handleDeleteKeyWords = this.handleDeleteKeyWords.bind(this)
    this.handleClearTextSearch = this.handleClearTextSearch.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.buildCSVData = this.buildCSVData.bind(this)
  }

  hide = () => {
    this.setState({
      visible: false,
    })
  }

  handleVisibleChange = visible => {
    this.setState({ visible })
  }

  handleTextSearchParamSelect(newSearchParams) {
    this.props.setSearchParams(newSearchParams)
  }

  handleClearTextSearch(fieldOfSearch) {
    // clear keyWords
    const { searchParams, filterSelection, pageSize, additionalParams } = this.props
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: { ...searchParams[fieldOfSearch], keyWords: [] },
    }
    this.props.setSearchParams(newSearchParams)
    // fetch reviews based on filter selection (pageNum = 1)
    this.props.getProducts(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
  }

  handleTextSearch(text) {
    const { filterSelection, pageSize, searchParams, additionalParams } = this.props
    // clean text in input box
    this.setState({ text: '' })
    // if the user typed "tight pants" in the search text
    // should split them into 2 separate words
    const rule = /\s{1,}/g
    // trim text, abort searching if trimed text is empty or already exists
    const trimedText = text.trim().toLowerCase()
    if (_.isEmpty(trimedText)) {
      return
    }
    const curKeyWords = trimedText.split(rule)
    // get current search type
    const { fieldOfSearch } = searchParams.searchType
    // union the new added key words (de-duplicate)
    const newKeyWords = _.union(searchParams[fieldOfSearch].keyWords, curKeyWords)
    if (_.isEqual(searchParams[fieldOfSearch].keyWords, newKeyWords)) {
      // terminate if the new added key words are exactly the same as the former key words list
      return
    }
    // insert keyWords
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: {
        ...searchParams[fieldOfSearch],
        keyWords: newKeyWords,
      },
    }
    this.props.setSearchParams(newSearchParams)
    // search reviews
    this.props.getProducts(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
  }

  handleDeleteKeyWords(keyWord, fieldOfSearch) {
    const { searchParams, filterSelection, pageSize, additionalParams } = this.props
    // remove this keyword from searchParams.keyWords array
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: {
        ...searchParams[fieldOfSearch],
        keyWords: searchParams[fieldOfSearch].keyWords.filter(word => word !== keyWord),
      },
    }
    this.props.setSearchParams(newSearchParams)
    // re-search reviews
    this.props.getProducts(
      {
        filterSelection,
        pageNum: 1, // reset to first page in case of "out-of-range"
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
  }

  generateKeyWords(keyWords, logicParam, fieldOfSearch) {
    if (_.isEmpty(keyWords)) {
      return null
    }
    const keyWordsList = []
    keyWords.forEach((word, idx) => {
      if (idx !== keyWords.length - 1) {
        keyWordsList.push(
          <Tag className="keyword" key={word} closable onClose={() => this.handleDeleteKeyWords(word, fieldOfSearch)}>
            {word}
          </Tag>,
        )
        keyWordsList.push(
          <span key={`${logicParam.name}-${word}`} className="logic-param">
            {logicParam.name}
          </span>,
        )
      } else {
        keyWordsList.push(
          <Tag className="keyword" key={word} closable onClose={() => this.handleDeleteKeyWords(word, fieldOfSearch)}>
            {word}
          </Tag>,
        )
      }
    })
    return keyWordsList
  }

  buildCSVData() {
    const { themeFilters, allProducts } = this.props
    const themeLabels = _.map(themeFilters, themeOption => themeOption.label)
    const competitorThemeLabels = _.map(themeFilters, themeOption => `Competitor_${themeOption.label}`)

    const data = []
    data.push(['Rank', 'External ID', 'Product_Name', 'Brand', 'PEI', 'Reviews', ...themeLabels, ...competitorThemeLabels])
    for (let i = 0; i < allProducts.length; i++) {
      const clientSentimentColumes = []
      const competitorSentimentColumes = []
      themeFilters.forEach(themeOption => {
        const curTheme = _.find(allProducts[i].themes, ['theme_id', _.toInteger(themeOption.value)])
        if (!_.isEmpty(curTheme)) {
          const { theme_sentiment_score, theme_diff_score } = curTheme
          if (!_.isUndefined(theme_sentiment_score)) {
            clientSentimentColumes.push(theme_sentiment_score)
          } else {
            clientSentimentColumes.push('')
          }
          if (!_.isUndefined(theme_diff_score)) {
            competitorSentimentColumes.push(theme_diff_score)
          } else {
            competitorSentimentColumes.push('')
          }
        } else {
          clientSentimentColumes.push('')
          competitorSentimentColumes.push('')
        }
      })
      data.push([
        i + 1,
        allProducts[i].external_id,
        allProducts[i].name,
        allProducts[i].brand,
        Math.round(allProducts[i].product_pei * 100),
        allProducts[i].review_volume,
        ...clientSentimentColumes,
        ...competitorSentimentColumes,
      ])
    }
    return data
  }

  csvLink = React.createRef()

  handleOnClick() {
    this.setState({ loading: true })
    const { searchParams, filterSelection, additionalParams, sortAttribute, sortOrder } = this.props
    const promise = new Promise((resolve, reject) => {
      this.props.getAllProducts(
        {
          filterSelection,
          pageNum: 1,
          searchParams,
          sortAttribute,
          sortOrder,
        },
        additionalParams,
        resolve,
        reject,
      )
    })
    promise.then(() => {
      this.csvLink.current.link.click()
      this.setState({ loading: false })
    })
  }

  render() {
    const {
      searchParams,
      searchParams: { searchType, logicParam, product },
      allProducts
    } = this.props
    const { keyWords: productKeyWords } = product
    const { text } = this.state
    const customer = window.localStorage.getItem('customer')
    const username = window.localStorage.getItem('username').split('@')[0]

    return (
      <div className="product-controls">
        <div style={{ display: 'flex' }}>
          <Input.Group compact className="mb-10 mr-10 text-search">
            <Tooltip title={searchType ? `Search In ${searchType.type}` : ''}>
              <Select
                onChange={value => {
                  const curSearchType = searchTypes.find(eachSearchType => eachSearchType.type === value)
                  this.handleTextSearchParamSelect({
                    ...searchParams,
                    searchType: curSearchType,
                    [curSearchType.fieldOfSearch]: {
                      ...searchParams[curSearchType.fieldOfSearch],
                      logicParam: searchParams.logicParam,
                    },
                  })
                }}
                value={searchType.type}
                placeholder="Type"
              >
                {searchTypes.map(each => (
                  <Select.Option key={each.id} value={each.type}>
                    {each.type}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
            <Tooltip title="Logical Operator Between Keywords">
              <Select
                onChange={value => {
                  const {
                    searchType: { fieldOfSearch },
                  } = searchParams
                  const curLogicParam = logicParams.find(eachLogicParam => eachLogicParam.name === value)
                  this.handleTextSearchParamSelect({
                    ...searchParams,
                    logicParam: curLogicParam,
                    [fieldOfSearch]: {
                      ...searchParams[fieldOfSearch],
                      logicParam: curLogicParam,
                    },
                  })
                }}
                value={logicParam.name}
                placeholder="logic Parameter"
              >
                {logicParams.map(each => (
                  <Select.Option key={each.id} value={each.name}>
                    {each.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
            <Input.Search
              value={text}
              placeholder={searchType ? `Search in ${searchType.type} ...` : 'Search ...'}
              style={{ width: 200 }}
              onChange={e => this.setState({ text: e.target.value })}
              onSearch={this.handleTextSearch}
            />
          </Input.Group>
          <Popover
            content={
              <div>
                <CustomAttributeTable />
                <Button onClick={this.hide} className="mt-15">
                  Close
                </Button>
              </div>
            }
            placement="bottom"
            title="Manage Custom Attributes"
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button type="primary">Custom Attribute Table</Button>
          </Popover>
          <Button
            type="primary"
            style={{ marginLeft: 'auto' }}
            onClick={this.handleOnClick}
            loading={this.state.loading}
          >
            Download CSV
          </Button>
          {allProducts.length ? <CSVLink filename="product-explorer.csv" data={this.buildCSVData()} className="hidden" ref={this.csvLink} target="_blank" /> : null}
        </div>
        {_.isEmpty(productKeyWords) ? null : (
          <div className="keywords-list">
            <span className="title">Key Words:&nbsp;</span>
            {this.generateKeyWords(productKeyWords, product.logicParam, 'product')}
            <span className="search-type-desc">by Product Search</span>
            <span className="span-link clear-search" onClick={() => this.handleClearTextSearch('product')}>
              Clear Search
            </span>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    filterSelection: state.insights.filterSelection,
    themeFilters: state.insights.filters.theme,
    allProducts: state.product.allProducts,
    searchParams: state.product.searchParams,
    pageSize: state.product.pageSize,
    pageNum: state.product.pageNum,
    additionalParams: getAdditionalFilters(state.insights),
    sortAttribute: state.product.sortAttribute,
    sortOrder: state.product.sortOrder,
  }
}

SearchBar.defaultProps = {
  themeFilters: [],
}

SearchBar.propTypes = {
  setSearchParams: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  allProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterSelection: PropTypes.shape({}).isRequired,
  themeFilters: PropTypes.arrayOf(PropTypes.object),
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    product: PropTypes.shape({}).isRequired,
  }).isRequired,
  additionalParams: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, {
  setSearchParams,
  getProducts,
  getAllProducts,
})(SearchBar)
