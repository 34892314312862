import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { Table, Spin, Pagination, BackTop, Popover, Tag } from 'antd'
import { NoData, insertCommas, SparklineConfig, imageDomainName } from '../../common'
import { getAdditionalFilters } from '../../insights'
import { getProducts, addProductsAttr, deleteProductsAttr, setProductAttrs, setDeleteProductAttrsId, setSelectedPagination } from '../actions'
import { constructThemeColumns } from '../constants/helper'
import SearchBar from './search-bar'
import CustomAttributeForm from './custom-attribute-form'
import ProductDetailsPopover from './product-details-popover'
import './product.css'

export class Product extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedRowKeys: [],
      selectedProducts: [], // all selectedReviews from each pages
      currentPageSelectedProducts: [], // selectedReviews on current page
      sortAttribute: 'review_volume',
      order: 'descend',
      sortOrder: { product_name: '', product_pei: '', review_volume: 'descend' },
    }

    this.constructDataSource = this.constructDataSource.bind(this)
    this.constructColumns = this.constructColumns.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.getConfig = this.getConfig.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onSelectAllChange = this.onSelectAllChange.bind(this)
    this.addCustomAttr = this.addCustomAttr.bind(this)
    this.deleteCustomAttr = this.deleteCustomAttr.bind(this)
    this.reloadProduct = this.reloadProduct.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
  }

  componentDidMount() {
    this.props.setProductAttrs({})
    this.props.setDeleteProductAttrsId([])
  }

  getConfig(record, type) {
    const peiMultiply = obj => {
      return Math.round(obj.pei_score * 100)
    }

    const series = [
      {
        name: 'Reviews',
        data: _.map(record, 'review_volume'),
        type: 'line',
      },
      {
        name: 'PEI ',
        data: _.map(record, peiMultiply),
        type: 'line',
        yAxisIndex: 1,
      },
    ]
    const xAxis = {
      ...SparklineConfig.xAxis,
      data: _.map(record, o => `${_.toInteger(o.review_quarter / 100)}-Q${o.review_quarter % 10}`),
    }
    return { ...SparklineConfig, series, xAxis }
  }

  handleClickProductTheme = (text, themeOption, record) =>
    this.props.onClickProductTheme &&
    this.props.onClickProductTheme({
      theme: {
        name: themeOption.label,
        id: text.theme_id,
      },
      product: {
        name: record.productName,
        id: record.aggProductId,
      },
      record,
    })

  handleTableChange = (pagination, filters, sorter) => {
    let { sortOrder } = this.state
    let { columnKey } = sorter
    let newSortOrder = sorter.order || 'ascend'

    let currentSortAttribute
    if (sortOrder.product_name) currentSortAttribute = 'productName'
    else if (sortOrder.product_pei) currentSortAttribute = 'pei'
    else currentSortAttribute = 'volume'

    if (!columnKey) {
      if (sortOrder.product_name) columnKey = 'productName'
      else if (sortOrder.product_pei) columnKey = 'pei'
      else columnKey = 'volume'
    }

    if (currentSortAttribute !== columnKey) {
      newSortOrder = sortOrder.product_name + sortOrder.product_pei + sortOrder.review_volume
    }

    const order = newSortOrder === 'descend' ? 'desc' : 'asc'

    let sortAttribute
    switch (columnKey) {
      case 'productName':
        sortAttribute = 'product_name'
        sortOrder = { product_name: newSortOrder, product_pei: '', review_volume: '' }
        break
      case 'pei':
        sortAttribute = 'product_pei'
        sortOrder = { product_name: '', product_pei: newSortOrder, review_volume: '' }
        break
      default:
        sortAttribute = 'review_volume'
        sortOrder = { product_name: '', product_pei: '', review_volume: newSortOrder }
        break
    }

    const { filterSelection, pageSize, searchParams, additionalFilterSelection } = this.props

    this.props.getProducts(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        sortOrder: order,
        sortAttribute,
        searchParams,
      },
      additionalFilterSelection,
    )

    this.setState({ sortOrder, sortAttribute })
  }

  constructColumns() {
    const { theme: themeFilters } = this.props
    const { sortOrder } = this.state
    let columns = [
      {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank',
      },
      {
        title: 'Product Name',
        dataIndex: 'productName',
        sortOrder: sortOrder.product_name,
        sorter: (a, b) => a.placeholder_name - b.placeholder_name,
        key: 'productName',
        width: '35%',
        render: (text, record) => (
          <Popover
            content={
              <div style={{ width: 240 }}>
                <ProductDetailsPopover record={record} />
              </div>
            }
            placement="left"
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: 50,
                  height: 50,
                  minWidth: 50,
                  marginRight: 14,
                  backgroundColor: '#fff',
                }}
                className="center"
              >
                <img
                  src={!_.isEmpty(record.imagePath) ? `${imageDomainName}${record.imagePath.small}` : `/assets/img/brand/${record.brand.replace("'", '')}.png`}
                  style={{
                    maxWidth: 50,
                    maxHeight: 50,
                  }}
                  alt={`${record.brand.replace("'", '')}`}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ width: '100%' }}>
                  <span
                    className="span-link pull-left"
                    onClick={() => this.props.onClickProduct && this.props.onClickProduct(record)}
                    style={{
                      textAlign: 'left',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {text}
                  </span>
                </div>
                {!_.isEmpty(record.productAttributes) &&
                  record.productAttributes.map(e => (
                    <Tag key={e.product_custom_attribute_id} closable={false}>
                      {e.attribute_text}
                    </Tag>
                  ))}
              </div>
            </div>
          </Popover>
        ),
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        width: 90,
      },
      {
        title: 'PEI',
        dataIndex: 'pei',
        key: 'pei',
        width: 60,
        sortOrder: sortOrder.product_pei,
        sorter: (a, b) => a.placeholder_pei - b.placeholder_pei,
      },
      {
        title: 'Reviews',
        dataIndex: 'volume',
        key: 'volume',
        width: 90,
        sortOrder: sortOrder.review_volume,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.placeholder_volume - b.placeholder_volume,
      },
      {
        title: 'Trend',
        key: 'trend',
        width: 90,
        render: (text, record) => (
          <div style={{ padding: '0 10px 0 10px' }}>
            <ReactEcharts option={this.getConfig(record.productTrendData)} notMerge lazyUpdate={false} style={{ height: 40, width: 78 }} />
          </div>
        ),
      },
    ]
    columns = [...columns, ...constructThemeColumns(themeFilters, this.handleClickProductTheme)]
    return columns
  }

  constructDataSource() {
    const { pageNum, pageSize } = this.props
    const { products } = this.props
    const data = []
    for (let i = 0; i < products.length; i++) {
      const productData = {}
      const product = products[i]
      if (!_.isEmpty(product.themes)) {
        product.themes.forEach(theme => {
          productData[theme.theme_id] = theme
        })
      }

      data.push({
        rank: (pageNum - 1) * pageSize + i + 1,
        key: (pageNum - 1) * pageSize + i,
        id: product.product_id,
        aggProductId: product.agg_product_id,
        productName: product.name,
        volume: product.review_volume,
        productTrendData: _.sortBy(product.product_trend_data, [o => o.review_quarter]),
        imagePath: product.image_thumb_paths,
        brand: product.brand,
        subBrand: product.sub_brand,
        source: product.source,
        price: product.price,
        productAttributes: product.custom_attributes,
        external_id: product.external_id,
        pei: product.product_pei ? Math.round(product.product_pei * 100) : '0',
        ...productData,
      })
    }
    return data
  }

  handlePaginationChange(pageNum, pageSize) {
    const { selectedObj, filterSelection, additionalFilterSelection, searchParams } = this.props
    selectedObj[`${this.props.pageNum}`] = _.map(this.state.currentPageSelectedProducts, o => ({
      key: o.key,
      id: o.aggProductId,
      productAttributes: o.productAttributes,
    }))
    this.props.setSelectedPagination(selectedObj)
    const { sortAttribute, sortOrder } = this.state
    const order = sortOrder[sortAttribute] === 'descend' ? 'desc' : 'asc'
    this.props.getProducts(
      {
        filterSelection,
        pageNum,
        pageSize,
        searchParams,
        sortAttribute,
        sortOrder: order,
      },
      additionalFilterSelection,
    )

    if (_.has(selectedObj, pageNum)) {
      this.setState({ currentPageSelectedProducts: selectedObj[`${pageNum}`] })
    }
  }

  onSelectChange = (record, selected, selectedRows) => {
    if (selected) {
      this.setState({
        selectedProducts: [...this.state.selectedProducts, record],
        currentPageSelectedProducts: [...selectedRows],
      })
    } else {
      this.setState({
        selectedProducts: _.reject(this.state.selectedProducts, o => o.aggProductId === record.aggProductId),
        currentPageSelectedProducts: [...selectedRows],
      })
    }
  }

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setState({
        selectedProducts: [...this.state.selectedProducts, ...changeRows],
        currentPageSelectedProducts: [...selectedRows],
      })
    } else {
      this.setState({
        selectedProducts: _.reject(this.state.selectedProducts, o => _.includes(_.map(changeRows, 'id'), o.id)),
        currentPageSelectedProducts: [],
      })
    }
  }

  onChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }

  addCustomAttr(value, attributeType, attributeId) {
    const { selectedProducts, currentPageSelectedProducts } = this.state
    const { pageNum, pageSize, productAttrs, deleteProductAttrIdArr } = this.props
    const selectedProductIdAndExternalIds = _.reject(
      selectedProducts,
      o =>
        (o.productAttributes && o.productAttributes.find(e => e.attribute_id === attributeId && !_.includes(deleteProductAttrIdArr, e.product_custom_attribute_id))) ||
        (productAttrs[`${o.aggProductId}`] && productAttrs[`${o.aggProductId}`].find(e => e.attribute_id === attributeId && !_.includes(deleteProductAttrIdArr, e.product_custom_attribute_id))),
    ).map(x => ({
      id: x.aggProductId,
      externalId: x.external_id
    }))

    console.log(selectedProductIdAndExternalIds)

    const currentPageSelectedProductsIndex = _.map(
      _.reject(
        currentPageSelectedProducts,
        o =>
          (o.productAttributes && o.productAttributes.find(e => e.attribute_id === attributeId && !_.includes(deleteProductAttrIdArr, e.product_custom_attribute_id))) ||
          (productAttrs[`${o.aggProductId}`] && productAttrs[`${o.aggProductId}`].find(e => e.attribute_id === attributeId && !_.includes(deleteProductAttrIdArr, e.product_custom_attribute_id))),
      ),
      o => ({ index: o.key - (pageNum - 1) * pageSize, id: o.aggProductId }),
    )
    // test
    this.props.addProductsAttr(currentPageSelectedProductsIndex, selectedProductIdAndExternalIds, value, attributeId)
  }

  deleteCustomAttr(value) {
    const { selectedProducts, currentPageSelectedProducts } = this.state
    const { pageNum, pageSize, productAttrs } = this.props
    const selectedProductAttrIds = []
    const selectedProductIds = []
    selectedProducts.forEach(o => {
      selectedProductIds.push(o.aggProductId)
      if (!_.isEmpty(productAttrs[o.aggProductId])) {
        const customAttrObject = productAttrs[o.aggProductId].find(e => e.attribute_id === value)
        if (!_.isEmpty(customAttrObject)) {
          selectedProductAttrIds.push(customAttrObject.product_custom_attribute_id)
          return
        }
      }
      if (!_.isEmpty(o.productAttributes)) {
        const customAttrObject = o.productAttributes.find(e => e.attribute_id === value)
        if (!_.isEmpty(customAttrObject)) {
          selectedProductAttrIds.push(customAttrObject.product_custom_attribute_id)
        }
      }
    })
    const currentPageSelectedProductsIndex = []
    currentPageSelectedProducts.forEach(o => {
      if (!_.isEmpty(productAttrs[o.aggProductId])) {
        const customAttrObject = productAttrs[o.aggProductId].find(e => e.attribute_id === value)
        if (!_.isEmpty(customAttrObject)) {
          currentPageSelectedProductsIndex.push({
            index: o.key - (pageNum - 1) * pageSize,
            id: customAttrObject.product_custom_attribute_id,
          })
          return
        }
      }
      if (!_.isEmpty(o.productAttributes)) {
        const customAttrObject = o.productAttributes.find(e => e.attribute_id === value)
        if (!_.isEmpty(customAttrObject)) {
          currentPageSelectedProductsIndex.push({
            index: o.key - (pageNum - 1) * pageSize,
            id: customAttrObject.product_custom_attribute_id,
          })
        }
      }
    })
    this.props.deleteProductsAttr(currentPageSelectedProductsIndex, selectedProductAttrIds, selectedProductIds, value)
  }

  reloadProduct() {
    this.setState({
      selectedRowKeys: [],
      selectedProducts: [],
      currentPageSelectedProducts: [],
    })
    this.props.setSelectedPagination({})
  }

  render() {
    const { theme, products, isLoading, range, pageSize, pageNum } = this.props
    const { selectedRowKeys } = this.state
    const rowSelection = {
      onSelect: this.onSelectChange,
      onSelectAll: this.onSelectAllChange,
      onChange: this.onChange,
      selectedRowKeys,
    }
    if (_.isEmpty(products) && !isLoading) {
      return (
        <div className="product">
          <SearchBar />
          <CustomAttributeForm addCustomAttr={this.addCustomAttr} deleteCustomAttr={this.deleteCustomAttr} reloadProduct={this.reloadProduct} selectedProducts={this.state.selectedProducts} />
          <NoData />
        </div>
      )
    }
    // if the theme filter is empty or it is loading products
    if (_.isEmpty(theme) || (_.isEmpty(products) && isLoading)) {
      return (
        <Spin tip="Loading..." spinning>
          <div className="product">
            <SearchBar />
            <CustomAttributeForm addCustomAttr={this.addCustomAttr} deleteCustomAttr={this.deleteCustomAttr} reloadProduct={this.reloadProduct} selectedProducts={this.state.selectedProducts} />
          </div>
        </Spin>
      )
    }

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <div className="product">
          <BackTop />
          <SearchBar />
          <CustomAttributeForm addCustomAttr={this.addCustomAttr} deleteCustomAttr={this.deleteCustomAttr} reloadProduct={this.reloadProduct} selectedProducts={this.state.selectedProducts} />
          <Pagination
            style={{
              textAlign: 'left',
            }}
            total={range[2]} // total review number
            pageSize={pageSize}
            size="small"
            current={pageNum}
            showQuickJumper
            showSizeChanger
            showTotal={(total, reviewRange) => (
              <span className="product-range">
                {insertCommas(reviewRange[0])}-{insertCommas(reviewRange[1])} of {insertCommas(total)} products
              </span>
            )}
            onShowSizeChange={this.handlePaginationChange}
            onChange={this.handlePaginationChange}
          />
          <Table
            className="product-table"
            columns={this.constructColumns()}
            dataSource={this.constructDataSource()}
            pagination={false}
            sortDirections={['ascend', 'descend']}
            rowSelection={rowSelection}
            onChange={this.handleTableChange}
          />
          <Pagination
            style={{
              textAlign: 'left',
              marginTop: 10,
            }}
            total={range[2]} // total review number
            pageSize={pageSize}
            size="small"
            current={pageNum}
            showQuickJumper
            showSizeChanger
            showTotal={(total, reviewRange) => (
              <span className="product-range">
                {insertCommas(reviewRange[0])}-{insertCommas(reviewRange[1])} of {insertCommas(total)} products
              </span>
            )}
            onShowSizeChange={this.handlePaginationChange}
            onChange={this.handlePaginationChange}
          />
        </div>
      </Spin>
    )
  }
}

Product.defaultProps = {
  isLoading: false,
  theme: [],
  onClickProduct: undefined,
  onClickProductTheme: undefined,
}

Product.propTypes = {
  filterSelection: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  theme: PropTypes.arrayOf(PropTypes.object),
  additionalFilterSelection: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteProductAttrIdArr: PropTypes.arrayOf(PropTypes.number).isRequired,
  productAttrs: PropTypes.shape({}).isRequired,
  selectedObj: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    product: PropTypes.shape({}).isRequired,
  }).isRequired,
  onClickProduct: PropTypes.func,
  onClickProductTheme: PropTypes.func,
  getProducts: PropTypes.func.isRequired,
  addProductsAttr: PropTypes.func.isRequired,
  deleteProductsAttr: PropTypes.func.isRequired,
  setProductAttrs: PropTypes.func.isRequired,
  setDeleteProductAttrsId: PropTypes.func.isRequired,
  setSelectedPagination: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    filters: state.insights.filters,
    filterSelection: state.insights.filterSelection,
    theme: state.insights.filters.theme,
    additionalFilterSelection: getAdditionalFilters(state.insights),
    products: state.product.products,
    deleteProductAttrIdArr: state.product.deleteProductAttrIdArr,
    productAttrs: state.product.productAttrs,
    selectedObj: state.product.selectedObj,
    isLoading: state.product.isLoading,
    range: state.product.range,
    pageSize: state.product.pageSize,
    pageNum: state.product.pageNum,
    searchParams: state.product.searchParams,
  }
}

export default connect(mapStateToProps, {
  getProducts,
  addProductsAttr,
  deleteProductsAttr,
  setProductAttrs,
  setDeleteProductAttrsId,
  setSelectedPagination,
})(Product)
