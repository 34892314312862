import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'

const ProductDetailsNav = ({ isProductDetailsHidden, toggleProductDetails }) => (
  <div>
    <Tooltip placement="top" title={`${isProductDetailsHidden ? 'Show Product Details' : 'Hide Product Details'}`}>
      <Button icon={`${isProductDetailsHidden ? 'down' : 'up'}`} onClick={toggleProductDetails} className="ml-5">
        {isProductDetailsHidden ? 'Show' : 'Hide'}
      </Button>
    </Tooltip>
  </div>
)

ProductDetailsNav.propTypes = {
  toggleProductDetails: PropTypes.func.isRequired,
  isProductDetailsHidden: PropTypes.bool.isRequired,
}

export default ProductDetailsNav
