import React from 'react'
import PropTypes from 'prop-types'

const DescriptionItem = ({ content, inline, title }) => (
  <p>
    <span className="bold">{title}:</span>
    {inline ? ' ' : <br />}
    {content}
  </p>
)

DescriptionItem.defaultProps = {
  content: '',
  inline: false,
  title: '',
}

DescriptionItem.propTypes = {
  content: PropTypes.string,
  inline: PropTypes.bool,
  title: PropTypes.string,
}

export default DescriptionItem
