import { SET_AUTH, SET_SIGNIN_LOADING_STATE, SET_SIGNIN_USER_ATTRIBUTES, SET_COGNITO_ERROR } from './constants/ActionTypes'

const INITIAL_STATE = {
  isAuthed: false, // discarded
  isLoading: false,
  cognitoError: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH:
      return { ...state, isAuthed: action.payload }
    case SET_SIGNIN_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_SIGNIN_USER_ATTRIBUTES:
      return { ...state, userAttributes: action.payload }
    case SET_COGNITO_ERROR:
      return { ...state, cognitoError: action.payload }
    default:
      return state
  }
}
