export const searchTypes = [
  {
    id: 0,
    name: 'Review Search',
    type: 'Review',
    fieldOfSearch: 'review',
  },
  {
    id: 1,
    name: 'Product Search',
    type: 'Product',
    fieldOfSearch: 'product',
  },
]

export const logicParams = [
  {
    name: 'And',
    character: '&',
    id: 0,
    encoding: '%26',
  },
  {
    name: 'Or',
    character: '|',
    id: 1,
    encoding: '%7C',
  },
]

export const sortOrders = [
  {
    name: 'Ascend',
    field: 'ASC',
    id: 0,
  },
  {
    name: 'Descend',
    field: 'DESC',
    id: 1,
  },
]

export const sortFields = [
  {
    name: 'Review Date',
    field: 'review_submission_date',
    id: 0,
  },
  {
    name: 'Review Rating',
    field: 'review_rating',
    id: 1,
  },
  {
    name: 'Tagged Date',
    field: 'review_max_tag_date',
    id: 2,
  },
]
