import { combineReducers } from 'redux'
import { reducer as competitiveAdvantage } from './competitive-advantage'
import { reducer as epic } from './epic'
import { reducer as facetedExplorer } from './faceted-explorer'
import { reducer as forum } from './forum'
import { reducer as homepage } from './homepage'
import { reducer as insights } from './insights'
import { reducer as marketplaceTrend } from './marketplace-trend'
import { reducer as peiScorecard } from './pei-scorecard'
import { reducer as product } from './product'
import { reducer as qualityControl } from './quality-control'
import { reducer as review } from './review'
import { reducer as riskOpportunity } from './risk-opportunity'
import { reducer as sentimentTrend } from './sentiment-trend'
import { reducer as user } from './user'
import { reducer as variance } from './variance'
import { reducer as who } from './who'
import { REQUEST_SWITCH_LOGIN, REQUEST_LOGOUT } from './homepage/constants/ActionTypes'

const appReducer = combineReducers({
  /* app’s top-level reducers */
  competitiveAdvantage,
  epic,
  facetedExplorer,
  forum,
  homepage,
  insights,
  marketplaceTrend,
  peiScorecard,
  product,
  qualityControl,
  review,
  riskOpportunity,
  sentimentTrend,
  user,
  variance,
  who,
})

// reset the state of the Redux store when logout or swich client
const rootReducer = (state, action) => {
  if (action.type === REQUEST_SWITCH_LOGIN || action.type === REQUEST_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
