import React, { Component } from 'react'
import GoogleAnalytics from 'react-ga'

GoogleAnalytics.initialize('UA-151334676-1')

const withTracker = WrappedComponent => {
  const trackPage = page => {
    if (process.env.REACT_APP_TRACKING_STAGE === 'prod') {
      GoogleAnalytics.set({
        page,
        dimension1: window.localStorage.getItem('customer') || '',
        dimension2: window.localStorage.getItem('schema') || '',
        userId: window.localStorage.getItem('user_id') || '',
      })
      GoogleAnalytics.pageview(page)
    }
  }

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page)
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search
      const nextPage = this.props.location.pathname + this.props.location.search

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default withTracker
