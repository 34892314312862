import { createSelector } from 'reselect'
import _ from 'lodash'
// create select functions to pick off the pieces of state we care about for the calculation

export const concatTimeArraySelectorFactory = (property1, property2, property3) =>
  createSelector(
    state => state.filterSelection[property1],
    state => state.filterSelection[property2],
    state => state.filterSelection[property3],
    (selectedValues1, selectedValues2, selectedValues3) => {
      let returnValue = []
      if (!_.isEmpty(selectedValues1)) {
        returnValue = [...returnValue, ...selectedValues1.value]
      }
      if (!_.isEmpty(selectedValues2)) {
        returnValue = [...returnValue, ...selectedValues2.value]
      }
      if (!_.isEmpty(selectedValues3)) {
        returnValue = [...returnValue, ...selectedValues3.value]
      }
      return returnValue
    },
  )

export const concatArraySelectorFactory = (property1, property2) =>
  createSelector(
    state => state.filterSelection[property1],
    state => state.filterSelection[property2],
    (selectedValues1, selectedValues2) => {
      if (!_.isEmpty(selectedValues1) && !_.isEmpty(selectedValues2)) {
        return [...selectedValues1.value, ...selectedValues2.value]
      }
      if (!_.isEmpty(selectedValues1)) {
        return selectedValues1.value
      }
      if (!_.isEmpty(selectedValues2)) {
        return selectedValues2.value
      }
      return []
    },
  )

export const valueArraySelectorFactory = property =>
  createSelector(
    state => state.filterSelection[property],
    selectedValues => {
      if (!_.isEmpty(selectedValues)) {
        return [...selectedValues.value]
      }
      return []
    },
  )

/**
 * ['1', '2', '3', '4', '5'] => [1, 5]
 * @param  {array of string} strArrs
 * @return {array of number}
 */
const getRange = strArr => {
  const numberArr = strArr.map(each => Number(each))
  return [_.min(numberArr), _.max(numberArr)]
}

const ratingSelector = state => state.filters.review_rating
export const getRatingRange = createSelector(ratingSelector, rating => {
  if (_.isEmpty(rating)) {
    return []
  }
  return getRange(rating.map(each => each.value))
})

const additionalParamsSelector = state => {
  if (_.isEmpty(state.additionalParams)) {
    return {}
  }
  return state.additionalParams
}

export const getAdditionalFilters = createSelector(additionalParamsSelector, additionalParams => {
  if (_.isEmpty(additionalParams)) {
    return {}
  }
  const additionalFilterSelection = {}
  Object.keys(additionalParams).forEach(key => {
    if (!_.isEmpty(additionalParams[key]) && key !== 'filterSelection') {
      additionalFilterSelection[key] = additionalParams[key].id
    }
  })
  return additionalFilterSelection
})

export const getAllSelectedThemes = createSelector(
  [state => (_.isEmpty(state.theme) ? [] : state.theme.value), state => (_.isEmpty(state.partiallySelectedThemeValues) ? [] : state.partiallySelectedThemeValues)],
  (selectedThemes, partiallySelectedThemes) => [...selectedThemes, ...partiallySelectedThemes],
)
