import { REQUEST_HOMEPAGE_VAR, RECEIVE_HOMEPAGE_VAR, SET_HOMEPAGE_LOADING_STATE, SET_HOMEPAGE_SELECTION, SET_FILTER_SELECTION, REQUEST_SWITCH_LOGIN, REQUEST_LOGOUT } from './constants/ActionTypes'

export const getHomepageVar = filterSelection => ({
  type: REQUEST_HOMEPAGE_VAR,
  payload: filterSelection,
})

export const storeHomepageVar = homepageVar => ({
  type: RECEIVE_HOMEPAGE_VAR,
  payload: homepageVar,
})

export const switchLogin = login => ({
  type: REQUEST_SWITCH_LOGIN,
  payload: login,
})

export const logout = () => ({
  type: REQUEST_LOGOUT,
})

export const setHomepageLoadingState = isLoading => ({
  type: SET_HOMEPAGE_LOADING_STATE,
  payload: isLoading,
})

export const setHomepageSelection = selection => ({
  type: SET_HOMEPAGE_SELECTION,
  payload: selection,
})

export const setFilterSelection = filterSelectionObj => ({
  type: SET_FILTER_SELECTION,
  payload: filterSelectionObj,
})
