import {
  REQUEST_FACETS,
  RECEIVE_FACETS,
  SAVE_FACETS,
  SET_SAVED,
  REQUEST_SAVED_FACETS,
  RECEIVE_SAVED_FACETS,
  DELETE_FACETS,
  RECEIVE_DELETE_FACETS,
  SET_FACETS_LOADING_STATE,
  SET_EXPANDED_ROW_KEYS,
  SET_SAVED_EXPANDED_ROW_KEYS,
} from './constants/ActionTypes'

export const getFacets = params => ({
  type: REQUEST_FACETS,
  payload: params,
})

export const storeFacets = facets => ({
  type: RECEIVE_FACETS,
  payload: facets,
})

export const saveFacets = (index, filterSelection) => ({
  type: SAVE_FACETS,
  payload: {
    index,
    filterSelection,
  },
})

export const setSaved = (index, saved) => ({
  type: SET_SAVED,
  payload: {
    index,
    saved,
  },
})

export const getSavedFacets = params => ({
  type: REQUEST_SAVED_FACETS,
  payload: params,
})

export const storeSavedFacets = savedFacets => ({
  type: RECEIVE_SAVED_FACETS,
  payload: savedFacets,
})

export const deleteFacets = (facetedId, index) => ({
  type: DELETE_FACETS,
  payload: {
    facetedId,
    index,
  },
})

export const storeDeleteFacets = index => ({
  type: RECEIVE_DELETE_FACETS,
  payload: index,
})

export const setFacetsLoadingState = isLoading => ({
  type: SET_FACETS_LOADING_STATE,
  payload: isLoading,
})

export const setExpandedRowKeys = expandedRowKeys => ({
  type: SET_EXPANDED_ROW_KEYS,
  payload: expandedRowKeys,
})

export const setSavedExpandedRowKeys = expandedRowKeys => ({
  type: SET_SAVED_EXPANDED_ROW_KEYS,
  payload: expandedRowKeys,
})
