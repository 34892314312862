import _ from 'lodash'
import { PERSONAINFO, CHANNELINFO, FLIPVISIBILITY, FLIPVISIBILITYCHANNEL, SETHIGHLIGHT } from './constants/ActionTypes'

const INITIAL_STATE = {
  personaInfo: [],
  channelInfo: [],
  curHighlightedSegmentId: -1,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERSONAINFO:
      return { ...state, personaInfo: action.payload }
    case CHANNELINFO:
      return { ...state, channelInfo: action.payload }
    case FLIPVISIBILITY:
      const newPersonaInfo = _.clone(state.personaInfo)
      const idx = _.findIndex(newPersonaInfo, { segment_id: action.payload })
      newPersonaInfo[idx].visibility = !newPersonaInfo[idx].visibility
      return { ...state, personaInfo: newPersonaInfo }
    case FLIPVISIBILITYCHANNEL:
      const newPersonaInfoChannel = _.clone(state.channelInfo)
      const idxChannel = _.findIndex(newPersonaInfoChannel, { segment_id: action.payload })
      newPersonaInfoChannel[idxChannel].visibility = !newPersonaInfoChannel[idxChannel].visibility
      return { ...state, channelInfo: newPersonaInfoChannel }
    case SETHIGHLIGHT:
      return {
        ...state,
        curHighlightedSegmentId: action.payload.highlight ? action.payload.segmentId : -1,
      }
    default:
      return state
  }
}
