import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, Button, Popconfirm } from 'antd'
import { deleteCustomAttr, getProducts } from '../actions'
import AddCustomAttributeForm from './add-custom-attribute-form'
import './product.css'

export class CustomAttributeTable extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.update = this.update.bind(this)
  }

  update() {
    const { filterSelection, pageNum, pageSize, searchParams } = this.props
    this.props.getProducts({
      filterSelection,
      pageNum,
      pageSize,
      searchParams,
    })
  }

  confirm(record) {
    const promise = new Promise((resolve, reject) => {
      this.props.deleteCustomAttr(record.key, resolve, reject)
    })
    promise.then(() => this.update())
  }

  cancel(e) {
    console.log('canceled')
  }

  constructColumns() {
    const columns = [
      {
        title: 'Custom Attribute',
        dataIndex: 'customAttribute',
      },
      {
        title: 'Attribute Type',
        dataIndex: 'attributeType',
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (text, record) => (
          <Popconfirm title="Are you sure to delete this report ?" onConfirm={() => this.confirm(record)} onCancel={this.cancel} okText="Yes" cancelText="No">
            <Button icon="delete" disabled={record.attributeType === 'public'} />
          </Popconfirm>
        ),
      },
    ]
    return columns
  }

  constructDataSource() {
    const { custom_attribute } = this.props.filters

    const data = []
    custom_attribute.forEach(e => {
      for (let i = 0; i < e.children.length; i++) {
        data.push({
          key: e.children[i].key,
          customAttribute: e.children[i].label,
          attributeType: e.label,
        })
      }
    })
    return data
  }

  render() {
    return (
      <div>
        <AddCustomAttributeForm />
        <Table className="product-table" bordered columns={this.constructColumns()} dataSource={this.constructDataSource()} pagination={false} />
      </div>
    )
  }
}

CustomAttributeTable.propTypes = {
  filters: PropTypes.shape({
    custom_attribute: PropTypes.object,
  }).isRequired,
  filterSelection: PropTypes.shape({}).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    product: PropTypes.shape({}).isRequired,
  }).isRequired,
  getProducts: PropTypes.func.isRequired,
  deleteCustomAttr: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    filters: state.insights.filters,
    filterSelection: state.insights.filterSelection,
    pageSize: state.product.pageSize,
    pageNum: state.product.pageNum,
    searchParams: state.product.searchParams,
  }
}

export default connect(mapStateToProps, { getProducts, deleteCustomAttr })(CustomAttributeTable)
