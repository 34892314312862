const prefix = 'faceted-explorer'

// faceted explorer
export const REQUEST_FACETS = `${prefix}/request_facets`
export const RECEIVE_FACETS = `${prefix}/receive_facets`
export const SAVE_FACETS = `${prefix}/save_facets`
export const SET_SAVED = `${prefix}/set_saved`
// faceted dashboard
export const REQUEST_SAVED_FACETS = `${prefix}/request_saved_facets`
export const RECEIVE_SAVED_FACETS = `${prefix}/receive_saved_facets`
export const RECEIVE_DELETE_FACETS = `${prefix}/receive_delete_facets`
export const DELETE_FACETS = `${prefix}/delete_facets`

export const SET_FACETS_LOADING_STATE = `${prefix}/set_facets_loading_state`
export const SET_EXPANDED_ROW_KEYS = `${prefix}/set_expanded_row_keys`
export const SET_SAVED_EXPANDED_ROW_KEYS = `${prefix}/set_saved_expanded_row_keys`
