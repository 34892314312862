import { defaultPageSize } from '../common'
import {
  RECEIVE_PRODUCTS,
  RECEIVE_ALL_PRODUCTS,
  RECEIVE_SORT_FILTERS,
  RECEIVE_AVG_SENT_SCORE,
  SET_PRODUCT_LOADING_STATE,
  SET_RANGE,
  SET_PAGINATION,
  SET_QUALITY_THRESHOLD,
  SET_FILTER_SELECTION,
} from './constants/ActionTypes'

const INITIAL_STATE = {
  products: [],
  allProducts: [],
  avgSentScore: {},
  qualityThreshold: '',
  isLoading: false,
  range: [],
  pageNum: 1,
  pageSize: defaultPageSize,
  filterSelection: {},
  sortAttribute: 'review_volume',
  sortOrder: 'desc',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.payload }
    case RECEIVE_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload }
    case RECEIVE_SORT_FILTERS:
      return {
        ...state,
        sortAttribute: action.payload.sortAttribute,
        sortOrder: action.payload.sortOrder,
      }
    case RECEIVE_AVG_SENT_SCORE:
      return { ...state, avgSentScore: action.payload }
    case SET_PRODUCT_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_RANGE:
      return { ...state, range: action.payload }
    case SET_PAGINATION:
      return { ...state, pageNum: action.payload.pageNum, pageSize: action.payload.pageSize }
    case SET_QUALITY_THRESHOLD:
      return { ...state, qualityThreshold: action.payload }
    case SET_FILTER_SELECTION:
      return { ...state, filterSelection: { ...state.filterSelection, ...action.payload } }
    default:
      return state
  }
}
