import { RECEIVE_FACETS, RECEIVE_SAVED_FACETS, RECEIVE_DELETE_FACETS, SET_SAVED, SET_FACETS_LOADING_STATE, SET_EXPANDED_ROW_KEYS, SET_SAVED_EXPANDED_ROW_KEYS } from './constants/ActionTypes'

const INITIAL_STATE = {
  facets: [],
  savedFacets: [],
  isLoading: false,
  expandedRowKeys: [],
  savedExpandedRowKeys: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_FACETS:
      return { ...state, facets: action.payload }
    case RECEIVE_SAVED_FACETS:
      return { ...state, savedFacets: action.payload }
    case SET_SAVED:
      const { index, saved } = action.payload
      return {
        ...state,
        facets: [
          ...state.facets.slice(0, index),
          {
            ...state.facets[index],
            saved,
          },
          ...state.facets.slice(index + 1),
        ],
      }
    case RECEIVE_DELETE_FACETS:
      return {
        ...state,
        savedFacets: [...state.savedFacets.slice(0, action.payload), ...state.savedFacets.slice(action.payload + 1)],
      }
    case SET_FACETS_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_EXPANDED_ROW_KEYS:
      return { ...state, expandedRowKeys: action.payload }
    case SET_SAVED_EXPANDED_ROW_KEYS:
      return { ...state, savedExpandedRowKeys: action.payload }
    default:
      return state
  }
}
