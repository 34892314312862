const config = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: params => {
      const line = `<span style={{ fontSize: 16 }}>${params[0].axisValue}</span><br /><span>${params[0].seriesName} : ${params[0].data.value} <br /> ${params[1].seriesName} : ${-params[1].data
        .value}</span>`
      return line
    },
  },
  legend: {
    data: ['Negative', 'Positive'],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    top: '8%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'value',
      axisTick: { show: false },
      splitLine: {
        show: false,
      },
    },
  ],
  yAxis: [
    {
      type: 'category',
      axisTick: { show: false },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
      data: ['Rank: 15', 'Rank: 14', 'Rank: 13', 'Rank: 12', 'Rank: 11', 'Rank: 10', 'Rank: 9', 'Rank: 8', 'Rank: 7', 'Rank: 6', 'Rank: 5', 'Rank: 4', 'Rank: 3', 'Rank: 2', 'Rank: 1'],
    },
  ],
}

export default config
