import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import _ from 'lodash'
import { NoData } from '../../common'
import { setExpandedRowKeys, setEditableTagKeys } from '../actions'
import ReviewSnippets from './review-snippets'
import ReviewControls from './review-controls'
import './review.css'

export class Review extends Component {
  constructor(props) {
    super(props)

    this.state = {
      areMetadataShown: false, // state for "arrow" icon
      areTagsEditable: false, // state for "edit" icon
    }

    this.toggleMetadata = this.toggleMetadata.bind(this)
    this.toggleTagEditable = this.toggleTagEditable.bind(this)
  }

  toggleMetadata() {
    const { areMetadataShown } = this.state
    const { reviews, pageNum, pageSize } = this.props
    this.setState({ areMetadataShown: !areMetadataShown }, () => {
      if (areMetadataShown) {
        // hide all
        this.props.setExpandedRowKeys([])
      } else {
        // expand all
        const expandedRowKeys = []
        reviews.forEach((review, idx) => expandedRowKeys.push((pageNum - 1) * pageSize + idx))
        this.props.setExpandedRowKeys(expandedRowKeys)
      }
    })
  }

  toggleTagEditable() {
    const { areTagsEditable } = this.state
    const { pageNum, pageSize } = this.props
    this.setState({ areTagsEditable: !this.state.areTagsEditable }, () => {
      if (areTagsEditable) {
        // disable all
        this.props.setEditableTagKeys([])
      } else {
        // enable all
        const editableTagKeys = []
        for (let i = 0; i < pageSize; i++) {
          editableTagKeys.push((pageNum - 1) * pageSize + i)
        }
        this.props.setEditableTagKeys(editableTagKeys)
      }
    })
  }

  render() {
    const { areMetadataShown, areTagsEditable } = this.state
    const { reviews, isReviewLoading, currentViewName } = this.props
    
    // it is loading data, although current data is empty
    // use a Spinner instead
    if (_.isEmpty(reviews) && isReviewLoading) {
      return (
        <Spin tip="Loading..." spinning>
          <div className="review">
            <ReviewControls
              areMetadataShown={areMetadataShown}
              areTagsEditable={areTagsEditable}
              toggleMetadata={this.toggleMetadata}
              toggleTagEditable={this.toggleTagEditable}
              currentViewName={currentViewName}
            />
          </div>
        </Spin>
      )
    }
    // after loading the data, but get nothing
    if (_.isEmpty(reviews) && !isReviewLoading) {
      return (
        <div className="review">
          <ReviewControls
            areMetadataShown={areMetadataShown}
            areTagsEditable={areTagsEditable}
            toggleMetadata={this.toggleMetadata}
            toggleTagEditable={this.toggleTagEditable}
            currentViewName={currentViewName}
          />
          <NoData />
        </div>
      )
    }
    // it is loading data, although current data are not empty
    // use a spinner so that it can overlay on the review panel
    // to avoid unexpected user actions
    return (
      <Spin tip="Loading..." spinning={isReviewLoading}>
        <div className="review">
          <ReviewControls
            areMetadataShown={areMetadataShown}
            areTagsEditable={areTagsEditable}
            toggleMetadata={this.toggleMetadata}
            toggleTagEditable={this.toggleTagEditable}
            currentViewName={currentViewName}
          />
          <ReviewSnippets areMetadataShown={areMetadataShown} areTagsEditable={areTagsEditable} />
        </div>
      </Spin>
    )
  }
}

Review.defaultProps = {
  currentViewName: 'insights',
}

Review.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  isReviewLoading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  setExpandedRowKeys: PropTypes.func.isRequired,
  setEditableTagKeys: PropTypes.func.isRequired,
  currentViewName: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    reviews: state.review.reviews,
    isReviewLoading: state.review.isReviewLoading,
    pageSize: state.review.pageSize,
    pageNum: state.review.pageNum,
    searchParams: state.review.searchParams,
  }
}

export default connect(mapStateToProps, {
  setExpandedRowKeys,
  setEditableTagKeys,
})(Review)
