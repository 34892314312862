import { REQUEST_REVIEW_VARIANCE_COUNT, RECEIVE_REVIEW_VARIANCE_COUNT, SET_REVIEW_VARIANCE_LOADING_STATE, SET_SOURCE_REVIEW_VARIANCE_DATA_STATE } from './constants/ActionTypes'

export const getReviewVarianceCount = obj => ({
  type: REQUEST_REVIEW_VARIANCE_COUNT,
  payload: obj,
})

export const setReviewVarianceLoadingState = isLoading => ({
  type: SET_REVIEW_VARIANCE_LOADING_STATE,
  payload: isLoading,
})

export const storeReviewVarianceCount = obj => ({
  type: RECEIVE_REVIEW_VARIANCE_COUNT,
  payload: obj,
})

export const setIsSourceData = isSourceReviewVarianceData => ({
  type: SET_SOURCE_REVIEW_VARIANCE_DATA_STATE,
  payload: isSourceReviewVarianceData,
})
