import { normalize } from 'normalizr'
import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { storeFilters, storeForumFilters } from './actions'
import { REQUEST_FILTERS, REQUEST_FORUM_FILTERS, REQUEST_CUSTOM_ATTRIBUTES } from './constants/ActionTypes'
import { attributeListSchema } from './constants/schema'
import { injectUIParams, buildAdditionalFilterObject } from './constants/helper'
import Api from './api'

export function selectFilters(state) {
  return state.insights.filters
}

export function selectForumFilters(state) {
  return state.insights.forumFilters
}

/**
 * worker saga
 * @return {Generator}
 */

export function* getCustomAttributes() {
  try {
    const queryObjectInitial = buildAdditionalFilterObject({})
    const queryObject = { query_object: { ...queryObjectInitial.additional_filter } }
    const response = yield call(Api.getCustomAttributes, {
      ...queryObject,
    })
    const filters = yield select(selectFilters)
    const { custom_attribute } = response.data[0]
    yield put(storeFilters({ ...filters, custom_attribute }))
  } catch (err) {
    console.log('err', err)
  }
}

export function* getFilters() {
  try {
    const result = yield call(Api.getFilters)

    const data = result.data[0]

    data.brand.sort((a, b) => (a.label > b.label ? 1 : -1))

    const source = injectUIParams(data.source, { selectable: false })
    const category = injectUIParams(data.category, { selectable: false })
    const theme = injectUIParams(data.theme, { selectable: false })
    const theme_sentiment = injectUIParams(data.theme_sentiment, { selectable: false })
    const user_attribute = injectUIParams(data.user_attribute, { selectable: false })
    const review_sentiment = injectUIParams(data.review_sentiment, { selectable: false })
    const region = injectUIParams(data.region, { selectable: false })
    const department = injectUIParams(data.department, { selectable: false })
    const price = injectUIParams(data.price, { selectable: false })
    const product_year = injectUIParams(data.product_year, { selectable: false })
    const review_year = injectUIParams(data.review_year, { selectable: false })
    const normalizedAttribute = normalize(data.attribute, attributeListSchema)
    const filters = yield select(selectFilters)
    yield put(
      storeFilters({
        ...filters,
        ...data,
        source,
        category,
        theme,
        theme_sentiment,
        user_attribute,
        review_sentiment,
        region,
        department,
        price,
        product_year,
        review_year,
        filteredAttribute: data.attribute, // default using attributes
        normalizedAttribute,
      }),
    )
    yield call(getCustomAttributes)
  } catch (err) {
    console.log('err', err)
  }
}

export function* getForumFilters() {
  try {
    const result = yield call(Api.getForumFilters)

    const data = result.data.body[0]

    data.brand.sort((a, b) => (a.title > b.title ? 1 : -1))

    const forum_site = injectUIParams(data.forum_site, { selectable: false })
    const theme = injectUIParams(data.theme, { selectable: false })
    const theme_sentiment = injectUIParams(data.theme_sentiment, { selectable: false })
    const forumFilters = yield select(selectForumFilters)
    yield put(
      storeForumFilters({
        ...forumFilters,
        ...data,
        forum_site,
        theme,
        theme_sentiment,
      }),
    )
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * watcher saga: spawn a new task on each Action
 * @return {Generator}
 */
export default function* watchInsights() {
  yield all([takeLatest(REQUEST_FILTERS, getFilters), takeLatest(REQUEST_CUSTOM_ATTRIBUTES, getCustomAttributes), takeLatest(REQUEST_FORUM_FILTERS, getForumFilters)])
}
