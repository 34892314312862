import _ from 'lodash'
import { searchTypes, logicParams } from './constants/textSearch'
import { defaultPageSize } from '../common'

import {
  RECEIVE_FORUMS,
  RECEIVE_SET_FLAG,
  RECEIVE_ADD_TAG,
  RECEIVE_DELETE_TAG,
  RECEIVE_WORDS,
  SET_RANGE,
  SET_SEARCH_PARAMS,
  SET_EXPANDED_POSTS_KEYS,
  SET_EXPANDED_COMMENTS_KEYS,
  SET_EDITABLE_TAG_KEYS,
  SET_FLAG_KEYS,
  SET_PAGINATION,
  SET_FORUM_LOADING_STATE,
  SET_FILTER_OBJECT,
  SET_FORUM_TAGS,
  SET_DELETE_TAGS_ID,
  SET_SELECTED_PAGINATION,
  SET_CHART_LOADING_STATE,
} from './constants/ActionTypes'

const INITIAL_STATE = {
  forums: [],
  words: {},
  deleteTagsIdArr: [],
  forumTags: {},
  selectedObj: {},
  filterObject: {},
  editableTagKeys: [],
  flagKeys: [],
  expandedPostsKeys: [],
  expandedCommentsKeys: [],
  searchParams: {
    searchType: searchTypes && searchTypes[0], // general search as default
    logicParam: logicParams && logicParams[0], // And as default
    forum: {
      keyWords: [],
      logicParam: logicParams && logicParams[0],
    },
  },
  range: [],
  pageNum: 1,
  pageSize: defaultPageSize,
  isForumLoading: false,
  isChartLoading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_FORUMS:
      return { ...state, forums: action.payload }
    case RECEIVE_WORDS:
      return { ...state, words: action.payload }
    case RECEIVE_SET_FLAG:
      const { index, flag, forum_flag_id } = action.payload
      return {
        ...state,
        forums: [
          ...state.forums.slice(0, index),
          {
            ...state.forums[index],
            flag,
            forum_flag_id,
          },
          ...state.forums.slice(index + 1),
        ],
      }
    case RECEIVE_ADD_TAG:
      const { index: idx, tag } = action.payload
      return {
        ...state,
        forums: [
          ...state.forums.slice(0, idx),
          {
            ...state.forums[idx],
            tags: _.isEmpty(state.forums[idx].tags) ? [tag] : [...state.forums[idx].tags, tag],
          },
          ...state.forums.slice(idx + 1),
        ],
      }
    case RECEIVE_DELETE_TAG:
      return {
        ...state,
        forums: [
          ...state.forums.slice(0, action.payload.index),
          {
            ...state.forums[action.payload.index],
            tags: state.forums[action.payload.index].tags.filter(eachTag => eachTag.forum_tag_id !== action.payload.tagId),
          },
          ...state.forums.slice(action.payload.index + 1),
        ],
      }
    case SET_RANGE:
      return { ...state, range: action.payload }
    case SET_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    case SET_EDITABLE_TAG_KEYS:
      return { ...state, editableTagKeys: action.payload }
    case SET_EXPANDED_POSTS_KEYS:
      return { ...state, expandedPostsKeys: action.payload }
    case SET_EXPANDED_COMMENTS_KEYS:
      return { ...state, expandedCommentsKeys: action.payload }
    case SET_FLAG_KEYS:
      return { ...state, flagKeys: action.payload }
    case SET_PAGINATION:
      return { ...state, pageNum: action.payload.pageNum, pageSize: action.payload.pageSize }
    case SET_FORUM_LOADING_STATE:
      return { ...state, isForumLoading: action.payload }
    case SET_FILTER_OBJECT:
      return { ...state, filterObject: action.payload }
    case SET_FORUM_TAGS:
      return { ...state, forumTags: action.payload }
    case SET_DELETE_TAGS_ID:
      return { ...state, deleteTagsIdArr: action.payload }
    case SET_SELECTED_PAGINATION:
      return { ...state, selectedObj: action.payload }
    case SET_CHART_LOADING_STATE:
      return { ...state, isChartLoading: action.payload }
    default:
      return state
  }
}
