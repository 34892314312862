import { REQUEST_TOKEN, RECEIVE_TOKEN, RESUME_TOKEN, SET_AUTH, SET_SIGNIN_LOADING_STATE, SET_SIGNIN_USER_ATTRIBUTES, SET_COGNITO_ERROR } from './constants/ActionTypes'

export const getToken = (credentials, history) => ({
  type: REQUEST_TOKEN,
  payload: {
    credentials,
    history,
  },
})

export const storeToken = token => ({
  type: RECEIVE_TOKEN,
  payload: token,
})

export const resumeToken = credentials => ({
  type: RESUME_TOKEN,
  payload: {
    credentials,
  },
})

export const setAuth = isAuthed => ({
  type: SET_AUTH,
  payload: isAuthed,
})

export const setSigninLoadingState = isLoading => ({
  type: SET_SIGNIN_LOADING_STATE,
  payload: isLoading,
})

export const setSigninUserAttributes = userAttributes => ({
  type: SET_SIGNIN_USER_ATTRIBUTES,
  payload: userAttributes,
})

export const setCognitoError = err => ({
  type: SET_COGNITO_ERROR,
  payload: err,
})
