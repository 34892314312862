import { takeLatest, call, all, put } from 'redux-saga/effects'
import { buildQueryObject } from '../insights'
import { getFilters } from '../insights/saga'
import { setLocalStorage } from '../user/saga'
import { REQUEST_HOMEPAGE_VAR, REQUEST_SWITCH_LOGIN } from './constants/ActionTypes'
import { storeHomepageVar, setHomepageLoadingState } from './actions'
import Api from './api'

export function* getHomepageVar(action) {
  yield put(setHomepageLoadingState(true))
  try {
    const filterSelection = action.payload
    const formattedQueryObj = buildQueryObject(
      {
        ...filterSelection,
      },
      {},
      {},
    )
    const queryObject = {
      query_object: {
        ...formattedQueryObj.additional_filter,
        ...formattedQueryObj.filter_object,
        ...formattedQueryObj.search_object,
      },
    }
    const response = yield call(Api.homepage, { ...queryObject })
    yield put(storeHomepageVar(response.data[0]))
    yield put(setHomepageLoadingState(false))
  } catch (err) {
    yield put(setHomepageLoadingState(false))
    console.log('err', err)
  }
}

export function* switchLogin(action) {
  try {
    yield put(setHomepageLoadingState(true))
    yield put(storeHomepageVar({}))
    const response = yield call(Api.switchLogin, action.payload)

    if (response.data) {
      yield call(setLocalStorage, response)
      yield call(getHomepageVar, { payload: {} })
      yield call(getFilters)
      action.payload.history.push('/dashboard')
    } else if (response.newpassword) {
      yield put(setHomepageLoadingState(JSON.stringify(response.newpassword.userAttributes)))
    }
    yield put(setHomepageLoadingState(false))
  } catch (err) {
    console.log('err', err)
    yield put(setHomepageLoadingState(false))
  }
}

export default function* watchHomepage() {
  yield all([takeLatest(REQUEST_HOMEPAGE_VAR, getHomepageVar), takeLatest(REQUEST_SWITCH_LOGIN, switchLogin)])
}
