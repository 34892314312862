import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, Menu, Dropdown, Icon, Rate, Tag, Button, Input, Pagination, BackTop } from 'antd'
import { insertCommas } from '../../common'
import TagForm from './tag-form'
import { getAdditionalFilters, getAllSelectedThemes } from '../../insights'
import {
  getForums,
  setExpandedPostsKeys,
  setExpandedCommentsKeys,
  setEditableTagKeys,
  setFlagKeys,
  updateFlag,
  deleteFlag,
  addTag,
  deleteTag,
  addAllTags,
  deleteAllTags,
  setForumTags,
  setDeleteTagsId,
  setSelectedPagination,
} from '../actions'
import { blueColor } from '../constants/color'
import PopOver from './pop-over'
// import './forum.css'

class ForumSnippets extends Component {
  constructor(props) {
    super(props)

    const len = this.props.forums.length

    this.state = {
      inputTagValue: '',
      curTagInputIdx: -1,
      visible: _.fill(Array(len), false),
      selectedForums: [], // all selectedForums from each pages
      currentPageSelectedForums: [], // selectedForums on current page
      selectedRowKeys: [],
    }

    this.generateForumSnippet = this.generateForumSnippet.bind(this)
    this.expandThread = this.expandThread.bind(this)
    this.createCommentTables = this.createCommentTables.bind(this)
    this.createReplyRows = this.createReplyRows.bind(this)
    this.handleOnForumExpand = this.handleOnForumExpand.bind(this)
    this.constructDataSource = this.constructDataSource.bind(this)
    this.constructColumns = this.constructColumns.bind(this)
    this.handleTagInputChange = this.handleTagInputChange.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onSelectAllChange = this.onSelectAllChange.bind(this)
    this.addAllTag = this.addAllTag.bind(this)
    this.deleteAllTag = this.deleteAllTag.bind(this)
    this.reloadForum = this.reloadForum.bind(this)
  }

  componentDidMount() {
    // forumTags and deleteTagsId are used for adding and deleting multiply tags
    this.props.setForumTags({})
    this.props.setDeleteTagsId([])
  }

  generateForumSnippet(index) {
    const reactStringReplace = require('react-string-replace')
    const { forums, expandedPostsKeys, forumFilterSelection, pageNum, pageSize, searchParams } = this.props
    const { forum_text: forumText, forum_title, theme_text_representation: themeTextRepresentation = {} } = forums[index]
    const forumTextAbbr = _.truncate(forumText, {
      length: 135,
      separator: ' ',
    })

    let revText
    let revTitle
    let revTextAbbr
    const searchArray = searchParams.forum.keyWords
    searchArray.forEach((keyword, i) => {
      revText = reactStringReplace(i === 0 ? forumText : revText, keyword, (match, i) => (
        <span key={match + i} style={{ backgroundColor: 'yellow' }}>
          {match}
        </span>
      ))
      revTitle = reactStringReplace(i === 0 ? forum_title : revTitle, keyword, (match, i) => (
        <span key={match + i} style={{ backgroundColor: 'yellow' }}>
          {match}
        </span>
      ))
      revTextAbbr = reactStringReplace(i === 0 ? forumTextAbbr : revTextAbbr, keyword, (match, i) => (
        <span key={match + i} style={{ backgroundColor: 'yellow' }}>
          {match}
        </span>
      ))
    })

    if (_.isEmpty(revText)) revText = forumText
    if (_.isEmpty(revTitle)) revTitle = forum_title
    if (_.isEmpty(revTextAbbr)) revTextAbbr = forumTextAbbr

    const isForumExpanded = _.includes(expandedPostsKeys, (pageNum - 1) * pageSize + index)
    const forumTextExpanded = (
      <div>
        <p
          style={{
            fontStyle: 'italic',
            wordBreak: 'keep-all',
          }}
          className="mb-10 bolder"
        >
          {revTitle}
        </p>
        <p className="mb-10">{revText}</p>
      </div>
    )
    if (isForumExpanded) {
      return forumTextExpanded
    }
    const selectedThemes = _.map(getAllSelectedThemes(forumFilterSelection), _.parseInt)
    if (selectedThemes.length === 1 && !_.isEmpty(themeTextRepresentation)) {
      // use theme related snippet
      // in case it is undeifned, return general snippet instead
      const themeTextRepresentationAbbr = _.isUndefined(themeTextRepresentation[selectedThemes[0]])
        ? revTextAbbr
        : _.truncate(themeTextRepresentation[selectedThemes[0]], {
            length: 135,
            separator: ' ',
          })
      return themeTextRepresentationAbbr || revTextAbbr
    }
    return revTextAbbr
  }

  generateForumLabel(index) {
    const { filterObject, forums } = this.props
    const keys = _.keys(filterObject)
    const labels = []
    const colorArray = ['red', 'blue', 'green', 'purple', 'volcano', 'orange', 'lime', 'geekblue', 'magenta']
    keys.forEach((key, keyIndex) => {
      const curFilterObj = filterObject[key]
      const rawMetaData = forums[index][`${key}_meta`]
      if (_.isEmpty(curFilterObj) || _.isEmpty(rawMetaData)) return
      const metaData = _.isObject(rawMetaData) ? _.keys(rawMetaData) : [rawMetaData]

      // metadata contains all data for the key property,
      // but we may only select part of them in our forumFilterSelection,
      // we only need to show the ones appear in the forumFilterSelection

      // case 0: the keys we don't need to show
      if (key === 'theme_sentiment' || key === 'forum_rating' || key === 'partiallySelectedThemeValues') {
        return
      }
      // case 1: forum/product year quarter month
      if ((_.includes(key, 'month') || _.includes(key, 'quarter') || _.includes(key, 'year')) && !_.isEmpty(curFilterObj.label)) {
        // year
        if (_.includes(key, 'year')) {
          metaData.forEach((val, valIndex) => {
            if (!_.includes(curFilterObj.label, `${val}`)) return
            const color = `${colorArray[keyIndex % 9]}`
            labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${val}`}</Tag>)
          })
          return
        }
        // quarter/month
        const year = forums[index][`${_.split(key, '_')[0]}_year_meta`]
        metaData.forEach((val, valIndex) => {
          if (!_.includes(curFilterObj.label, `${year}-${val}`)) return
          const color = `${colorArray[keyIndex % 9]}`
          labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${year}-${val}`}</Tag>)
        })
        return
      }
      // case 2: theme and aspect with/without theme sentiment theme:fit / fit:pos
      if ((key === 'theme' || key === 'aspect') && !_.isEmpty(curFilterObj.label)) {
        const themeAspectMeta = forums[index][`${key}_meta`]
        const pairs = _.toPairs(themeAspectMeta)
        if (_.includes(keys, 'theme_sentiment') && !_.isEmpty(filterObject.theme_sentiment) && !_.isEmpty(filterObject.theme_sentiment.value)) {
          pairs.forEach((val, valIndex) => {
            if (!_.includes(_.toLower(curFilterObj.label), `${val[0]}`)) return
            const color = `${colorArray[keyIndex % 9]}`
            labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${val[0]} : ${val[1]}`}</Tag>)
          })
          return
        }
        pairs.forEach((val, valIndex) => {
          if (!_.includes(_.toLower(curFilterObj.label), `${val[0]}`)) return
          const color = `${colorArray[keyIndex % 9]}`
          labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${val[0]}`}</Tag>)
        })
        return
      }
      // case 3: all other filter selections
      const originalVal = _.toLower(curFilterObj.label)
      metaData.forEach((val, valIndex) => {
        if (!_.includes(originalVal, _.toLower(val))) return
        const color = `${colorArray[keyIndex % 9]}`
        labels.push(<Tag key={`${keyIndex}-${valIndex}`} color={color}>{`${key}: ${val}`}</Tag>)
      })
    })
    return labels
  }

  constructDataSource() {
    const { forums, editableTagKeys, pageNum, pageSize } = this.props
    const { inputTagValue, curTagInputIdx } = this.state
    const data = []
    for (let i = 0; i < forums.length; i++) {
      const { _id, url, title, tags } = forums[i]
      const isTagEditable = _.includes(editableTagKeys, (pageNum - 1) * pageSize + i)
      const isTagInputVisible = curTagInputIdx === i
      const labels = this.generateForumLabel(i)
      data.push({
        key: (pageNum - 1) * pageSize + i, // use index as the row keys
        id: _id, // pass additional "id" property to store forum id
        forum: (
          <div>
            <div className="forum-text">
              <a href={url} target="_blank">
                {title}
              </a>
            </div>
            <div className="mb-5">{!_.isEmpty(labels) && labels}</div>
            {!_.isEmpty(tags) &&
              tags.map(tag => (
                <Tag key={tag.forum_tag_id} closable={isTagEditable} onClose={() => this.handleTagDelete(i, tag.forum_tag_id)}>
                  {tag.text}
                </Tag>
              ))}
            {isTagEditable && isTagInputVisible && (
              <Input
                ref={e => (this.tagInput = e)}
                type="text"
                size="small"
                style={{ width: 78 }}
                value={inputTagValue}
                onChange={this.handleTagInputChange}
                onBlur={() => this.handleTagInputConfirm(i, _id)}
                onPressEnter={() => this.handleTagInputConfirm(i, _id)}
              />
            )}
            {isTagEditable && !isTagInputVisible && (
              <Button size="small" type="dashed" style={{ color: blueColor }} onClick={() => this.setState({ curTagInputIdx: i }, () => this.tagInput.focus())}>
                <Icon type="plus" />
                New Tag
              </Button>
            )}
          </div>
        ),
        ...forums[i],
        tags,
      })
    }
    return data
  }

  expandThread(record) {
    const { forums, pageNum, pageSize, expandedCommentsKeys } = this.props
    // record: { key: , id: , forum: { ... } }
    const { key } = record // key is the index of the current forum
    const columns = [
      {
        title: '',
        dataIndex: 'expand_button',
        key: 'expand_button',
        width: '5%',
      },
      {
        title: 'Level',
        dataIndex: 'level',
        key: 'level',
        width: '15%',
        render: (text, row, index) => this.spanColumns(text, row, index),
      },
      {
        title: 'Author',
        dataIndex: 'author',
        key: 'author',
        width: '15%',
      },
      {
        title: 'Forum',
        dataIndex: 'forum',
        key: 'forum',
        width: '10%',
      },
      {
        title: 'Brands',
        dataIndex: 'brands',
        key: 'brands',
        width: '10%',
      },
      {
        title: 'Date/Time',
        dataIndex: 'submission_time',
        key: 'submission_time',
        width: '20%',
      },
      {
        title: '# Comments',
        dataIndex: 'number_of_comments',
        key: 'number_of_comments',
        width: '10%',
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '15%',
      },
    ]
    const { _id, forum_site, author, body, submission_time, number_of_comments, comments, possible_brands } = forums[key - (pageNum - 1) * pageSize]
    let brands = possible_brands.join(', ')
    const data = [
      {
        key: _id,
        level: 'Original Post',
        author,
        forum: forum_site,
        brands,
        submission_time,
        number_of_comments,
        id: _id,
        children: [
          {
            key: `${_id}-1`,
            colSpan: 7,
            level: body,
          },
          {
            key: `${_id}-2`,
            colSpan: 7,
            level: this.createCommentTables(comments, author, forum_site),
          },
        ],
      },
    ]
    return (
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        onExpand={(expanded, record) => this.handleOnCommentsExpand(expanded, record, key)} // this key is the parent key
        expandedRowKeys={expandedCommentsKeys}
        pagination={false}
      />
    )
  }

  createCommentTables(comments, parentAuthor, forum_site) {
    const columns = [
      {
        title: '',
        dataIndex: 'expand_button',
        key: 'expand_button',
        width: '5%',
      },
      {
        title: 'Level',
        dataIndex: 'level',
        key: 'level',
        width: '15%',
        render: (text, row, index) => this.spanColumns(text, row, index),
      },
      {
        title: 'Author',
        dataIndex: 'author',
        key: 'author',
        width: '20%',
      },
      {
        title: 'Date/Time',
        dataIndex: 'submission_time',
        key: 'submission_time',
        width: '35%',
      },
      {
        title: '# Replies',
        dataIndex: 'number_of_replies',
        key: 'number_of_replies',
        width: '15%',
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
      },
    ]
    const data = []
    for (let i = 0; i < comments.length; i++) {
      const { id, body, replies, author, submission_time, number_of_replies } = comments[i]
      data.push(
        // first object is comment info
        {
          key: id,
          level: 2,
          author,
          submission_time,
          number_of_replies,
          id,
        },
        // second object is comment content
        {
          key: `${id}-${i + 1}`,
          colSpan: 6,
          level: forum_site === 'reddit' ? `@${parentAuthor}: ${body}` : body,
        },
      )
      let countLevels = 2 // level 1 is the original post
      if (replies) {
        if (replies.length > 0) {
          data.push(...this.createReplyRows(comments[i].replies, author, countLevels))
        }
      }
    }
    return <Table size="middle" columns={columns} dataSource={data} pagination={false} defaultExpandAllRows={true} />
  }

  createReplyRows(replies, parentAuthor, countLevels) {
    countLevels++
    const data = []
    for (let i = 0; i < replies.length; i++) {
      const { id, body, author, submission_time, number_of_replies } = replies[i]
      data.push(
        // first object is reply info
        {
          key: id,
          level: countLevels,
          author,
          submission_time,
          number_of_replies,
          id,
        },
        // second object is reply content
        {
          key: `${id}-${i + 1}`,
          colSpan: 6,
          level: `@${parentAuthor}: ${body}`,
        },
      )
      if (replies[i].replies.length > 0) {
        data.push(...this.createReplyRows(replies[i].replies, author, countLevels))
      }
    }
    return data
  }

  spanColumns(text, row, index) {
    const { colSpan } = row
    if (colSpan > 1) {
      return {
        children: text,
        props: {
          colSpan,
        },
      }
    }
    return text
  }

  /**
   * callback when clicking on the menu items of each forum (flag, unflag, tag, email)
   * @param  {string}  key           key of menu item being clicked
   * @param  {number}  index         index of the forum of the current Tags
   * @param  {number}  forumId      forumId of the current forum
   * @param  {Boolean} isTagEditable is the tag editable before clicking
   * @param  {Boolean} isFlagVisible has the forum already been flagged
   */
  handleMenuClick(key, index, forumId, isTagEditable, isFlagVisible, forumFlagId) {
    const { pageNum, pageSize, editableTagKeys, flagKeys } = this.props
    const offsite = (pageNum - 1) * pageSize + index
    switch (key) {
      case 'tag':
        if (isTagEditable) {
          this.props.setEditableTagKeys(_.without(editableTagKeys, offsite))
        } else {
          this.props.setEditableTagKeys([...editableTagKeys, offsite])
        }
        break
      case 'unflag':
        this.props.deleteFlag(index, forumFlagId)
        break
      case 'flag':
        if (!isFlagVisible) {
          this.props.setFlagKeys([...flagKeys, offsite])
        }
        break
      default:
        break
    }
  }

  /**
   * construct the dropdown menu for each forum, flag, unflag, tag, email
   * @param  {number}  index         index of the current forum
   */
  constructMenu(index) {
    const { flagKeys, editableTagKeys, forums, pageNum, pageSize } = this.props
    const isTagEditable = _.includes(editableTagKeys, (pageNum - 1) * pageSize + index)
    const isFlagVisible = _.includes(flagKeys, (pageNum - 1) * pageSize + index)
    const forumFlagId = forums[index].forum_flag_id
    const { forum_text: forumText } = forums[index]
    const isFlaged = forums && forums[index] && forums[index].flag
    const forumId = forums && forums[index] && forums[index].forum_id
    const forumExternalId = forums && forums[index] && forums[index].external_id

    return (
      <Menu onClick={({ key }) => this.handleMenuClick(key, index, forumId, isTagEditable, isFlagVisible, forumFlagId)}>
        <Menu.Item key="flag" disabled={isFlaged}>
          <PopOver index={index} forumId={forumId} forumExternalId={forumExternalId} onSubmit={this.onSubmit} onCancel={this.onCancel} visible={isFlagVisible} />
        </Menu.Item>
        <Menu.Item key="unflag" disabled={!isFlaged}>
          <Icon type="flag" />
          &nbsp;&nbsp;Unflag Forum
        </Menu.Item>
        <Menu.Item key="tag">
          <Icon type={isTagEditable ? 'eye-o' : 'edit'} />
          &nbsp;&nbsp;
          {isTagEditable ? 'Pforum Tags' : 'Edit Tags'}
        </Menu.Item>
        <Menu.Item key="email">
          <a href={`mailto:insert_recipient_address@address.com?subject=Forum Explorer From HearFul 2.0&body=Forums Reference:   ${forumText}`}>
            <Icon type="mail" />
            &nbsp;&nbsp;Send Email
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  /**
   * construct columns for the whole forum table
   */
  constructColumns() {
    const { forums } = this.props
    const columns = [
      { title: 'Threads', dataIndex: 'forum', key: 'forum' },
      {
        title: 'Actions',
        key: 'action',
        width: '80px',
        render: (text, record, index) => (
          <Dropdown overlay={this.constructMenu(index)} onVisibleChange={flag => this.handleVisibleChange(flag, index)} visible={this.state.visible[index]}>
            <Button>
              {forums && forums[index] && forums[index].flag && <Icon type="flag" />}
              &nbsp;&nbsp;More
              <Icon type="down" />
            </Button>
          </Dropdown>
        ),
      },
    ]
    return columns
  }

  handleOnForumExpand(expanded, record) {
    const { expandedPostsKeys } = this.props
    if (expanded) {
      this.props.setExpandedPostsKeys([...expandedPostsKeys, record.key])
    } else {
      this.props.setExpandedPostsKeys(_.without(expandedPostsKeys, record.key))
    }
  }

  handleOnCommentsExpand(expanded, record, parentKey) {
    const { expandedPostsKeys, expandedCommentsKeys } = this.props
    if (expanded) {
      this.props.setExpandedPostsKeys([...expandedPostsKeys, parentKey])
      this.props.setExpandedCommentsKeys([...expandedCommentsKeys, record.key])
    } else {
      this.props.setExpandedPostsKeys(_.without(expandedPostsKeys, parentKey))
      this.props.setExpandedCommentsKeys(_.without(expandedCommentsKeys, record.key))
    }
  }

  handleTagInputChange(e) {
    this.setState({ inputTagValue: e.target.value })
  }

  handleTagInputConfirm(index, forumId) {
    // insert tag into DB, if succeeds, then redux store
    const { tags } = this.props.forums[index]
    const inputTagValue = _.trim(this.state.inputTagValue)
    if (_.isEmpty(inputTagValue) || (tags && tags.find(tag => tag.text === inputTagValue))) {
      // if it is empty tag or there is a duplicate
      // don't store in DB, clear input, and hide tag input
      this.setState({ inputTagValue: '', curTagInputIdx: -1 })
      return
    }
    // Send request, if succeeds, insert the tag at right place
    this.props.addTag(index, forumId, inputTagValue)
    this.setState({ inputTagValue: '', curTagInputIdx: -1 })
  }

  handleTagDelete(index, tagId) {
    this.props.deleteTag(index, tagId)
  }

  handleVisibleChange = (flag, index) => {
    if (!flag) {
      this.onCancel(index)
    }
    this.setState({
      visible: [...this.state.visible.slice(0, index), flag, ...this.state.visible.slice(index + 1)],
    })
  }

  onSelectChange = (record, selected, selectedRows) => {
    if (selected) {
      this.setState({
        selectedForums: [...this.state.selectedForums, record],
        currentPageSelectedForums: [...selectedRows],
      })
    } else {
      this.setState({
        selectedForums: _.reject(this.state.selectedForums, o => o.id === record.id),
        currentPageSelectedForums: [...selectedRows],
      })
    }
  }

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setState({
        selectedForums: [...this.state.selectedForums, ...changeRows],
        currentPageSelectedForums: [...selectedRows],
      })
    } else {
      this.setState({
        selectedForums: _.reject(this.state.selectedForums, o => _.includes(_.map(changeRows, 'id'), o.id)),
        currentPageSelectedForums: [],
      })
    }
  }

  onChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }

  addAllTag(value) {
    const { selectedForums, currentPageSelectedForums } = this.state
    const { pageNum, pageSize, forumTags, deleteTagsIdArr } = this.props

    const selectedForumIdAndExternalIdArr = _.reject(
      selectedForums,
      o =>
        (o.tags && o.tags.find(tag => tag.text === value && !_.includes(deleteTagsIdArr, tag.forum_tag_id))) ||
        (forumTags[`${o.id}`] && forumTags[`${o.id}`].find(tag => tag.tag === value && !_.includes(deleteTagsIdArr, tag.tagId))),
    ).map(x => ({
      id: x.id,
      externalId: x.external_id,
    }))
    const currentPageSelectedForumsIndex = _.map(
      _.reject(
        currentPageSelectedForums,
        o =>
          (o.tags && o.tags.find(tag => tag.text === value && !_.includes(deleteTagsIdArr, tag.forum_tag_id))) ||
          (forumTags[`${o.id}`] && forumTags[`${o.id}`].find(tag => tag.tag === value && !_.includes(deleteTagsIdArr, tag.tagId))),
      ),
      o => ({ index: o.key - (pageNum - 1) * pageSize, id: o.id }),
    )

    this.props.addAllTags(currentPageSelectedForumsIndex, selectedForumIdAndExternalIdArr, value)
  }

  deleteAllTag(value) {
    const { selectedForums, currentPageSelectedForums } = this.state
    const { pageNum, pageSize, forumTags } = this.props
    const selectedTagIds = []
    selectedForums.forEach(o => {
      if (!_.isEmpty(forumTags[o.id])) {
        const tagObject = forumTags[o.id].find(tag => tag.tag === value)
        if (!_.isEmpty(tagObject)) {
          selectedTagIds.push(tagObject.tagId)
          return
        }
      }
      if (!_.isEmpty(o.tags)) {
        const tagObject = o.tags.find(tag => tag.text === value)
        if (!_.isEmpty(tagObject)) {
          selectedTagIds.push(tagObject.forum_tag_id)
        }
      }
    })
    const currentPageSelectedForumsIndex = []
    currentPageSelectedForums.forEach(o => {
      if (!_.isEmpty(forumTags[o.id])) {
        const tagObject = forumTags[o.id].find(tag => tag.tag === value)
        if (!_.isEmpty(tagObject)) {
          currentPageSelectedForumsIndex.push({
            index: o.key - (pageNum - 1) * pageSize,
            id: tagObject.tagId,
          })
          return
        }
      }
      if (!_.isEmpty(o.tags)) {
        const tagObject = o.tags.find(tag => tag.text === value)
        if (!_.isEmpty(tagObject)) {
          currentPageSelectedForumsIndex.push({
            index: o.key - (pageNum - 1) * pageSize,
            id: tagObject.forum_tag_id,
          })
        }
      }
    })
    this.props.deleteAllTags(currentPageSelectedForumsIndex, selectedTagIds)
  }

  reloadForum() {
    this.setState({
      selectedRowKeys: [],
      selectedForums: [],
      currentPageSelectedForums: [],
    })
    this.props.setSelectedPagination({})
  }

  handlePaginationChange(pageNum, pageSize) {
    const { selectedObj, forumFilterSelection, searchParams, additionalParams } = this.props
    selectedObj[`${this.props.pageNum}`] = _.map(this.state.currentPageSelectedForums, o => ({
      key: o.key,
      id: o.id,
      tags: o.tags,
    }))
    this.props.setSelectedPagination(selectedObj)
    if (this.props.arePostsMetadataShown) {
      const expandedPostsKeys = []
      for (let i = 0; i < pageSize; i++) {
        expandedPostsKeys.push((pageNum - 1) * pageSize + i)
      }
      this.props.setExpandedPostsKeys(expandedPostsKeys)
    }

    if (this.props.areTagsEditable) {
      const editableTagKeys = []
      for (let i = 0; i < pageSize; i++) {
        editableTagKeys.push((pageNum - 1) * pageSize + i)
      }
      this.props.setEditableTagKeys(editableTagKeys)
    }

    this.props.getForums(
      {
        forumFilterSelection,
        pageNum,
        pageSize,
        searchParams,
      },
      additionalParams,
    )

    if (_.has(selectedObj, pageNum)) {
      this.setState({ currentPageSelectedForums: selectedObj[`${pageNum}`] })
    }
  }

  onSubmit(index, forumId, forumExternalId, comment) {
    const { pageNum, pageSize } = this.props
    this.props.setFlagKeys(_.without(this.props.flagKeys, (pageNum - 1) * pageSize + index))
    this.props.updateFlag(index, forumId, forumExternalId, comment)
  }

  onCancel(index) {
    const { pageNum, pageSize } = this.props
    this.props.setFlagKeys(_.without(this.props.flagKeys, (pageNum - 1) * pageSize + index))
  }

  render() {
    const { range, pageSize, pageNum, expandedPostsKeys } = this.props
    const { selectedRowKeys } = this.state
    const rowSelection = {
      onSelect: this.onSelectChange,
      onSelectAll: this.onSelectAllChange,
      onChange: this.onChange,
      selectedRowKeys,
    }

    return (
      <div className="forum-snippets">
        <BackTop />
        <TagForm addAllTag={this.addAllTag} deleteAllTag={this.deleteAllTag} reloadForum={this.reloadForum} selectedForums={this.state.selectedForums} path="forum" />
        <Pagination
          total={range[2]} // total forum number
          pageSize={pageSize}
          size="small"
          current={pageNum}
          showQuickJumper
          showSizeChanger
          showTotal={(total, forumRange) => (
            <span className="forum-range">
              {insertCommas(forumRange[0])}-{insertCommas(forumRange[1])} of {insertCommas(total)} forums
            </span>
          )}
          onShowSizeChange={this.handlePaginationChange}
          onChange={this.handlePaginationChange}
        />
        <Table
          size="middle"
          columns={this.constructColumns()}
          expandedRowRender={this.expandThread}
          dataSource={this.constructDataSource()}
          expandedRowKeys={expandedPostsKeys}
          onExpand={this.handleOnForumExpand}
          pagination={false}
          rowSelection={rowSelection}
        />
        <Pagination
          className="mt-10"
          total={range[2]} // total forum number
          pageSize={pageSize}
          size="small"
          current={pageNum}
          showQuickJumper
          showSizeChanger
          showTotal={(total, forumRange) => (
            <span className="forum-range">
              {insertCommas(forumRange[0])}-{insertCommas(forumRange[1])} of {insertCommas(total)} forums
            </span>
          )}
          onShowSizeChange={this.handlePaginationChange}
          onChange={this.handlePaginationChange}
        />
      </div>
    )
  }
}

ForumSnippets.propTypes = {
  forumFilterSelection: PropTypes.shape({}).isRequired,
  filterObject: PropTypes.shape({}).isRequired,
  expandedPostsKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  expandedCommentsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  editableTagKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  flagKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    forum: PropTypes.shape({}).isRequired,
  }).isRequired,
  arePostsMetadataShown: PropTypes.bool.isRequired,
  areCommentsMetadataShown: PropTypes.bool.isRequired,
  areTagsEditable: PropTypes.bool.isRequired,
  forums: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteTagsIdArr: PropTypes.arrayOf(PropTypes.number).isRequired,
  forumTags: PropTypes.shape({}).isRequired,
  selectedObj: PropTypes.shape({}).isRequired,
  setExpandedPostsKeys: PropTypes.func.isRequired,
  setExpandedCommentsKeys: PropTypes.func.isRequired,
  setEditableTagKeys: PropTypes.func.isRequired,
  setFlagKeys: PropTypes.func.isRequired,
  getForums: PropTypes.func.isRequired,
  updateFlag: PropTypes.func.isRequired,
  deleteFlag: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  addAllTags: PropTypes.func.isRequired,
  deleteAllTags: PropTypes.func.isRequired,
  setForumTags: PropTypes.func.isRequired,
  setDeleteTagsId: PropTypes.func.isRequired,
  setSelectedPagination: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    forumFilterSelection: state.insights.forumFilterSelection,
    forums: state.forum.forums,
    deleteTagsIdArr: state.forum.deleteTagsIdArr,
    forumTags: state.forum.forumTags,
    selectedObj: state.forum.selectedObj,
    filterObject: state.forum.filterObject,
    expandedPostsKeys: state.forum.expandedPostsKeys,
    expandedCommentsKeys: state.forum.expandedCommentsKeys,
    editableTagKeys: state.forum.editableTagKeys,
    flagKeys: state.forum.flagKeys,
    range: state.forum.range,
    pageSize: state.forum.pageSize,
    pageNum: state.forum.pageNum,
    searchParams: state.forum.searchParams,
  }
}

export default connect(mapStateToProps, {
  getForums,
  setExpandedPostsKeys,
  setExpandedCommentsKeys,
  setEditableTagKeys,
  setFlagKeys,
  updateFlag,
  deleteFlag,
  addTag,
  deleteTag,
  addAllTags,
  deleteAllTags,
  setForumTags,
  setDeleteTagsId,
  setSelectedPagination,
})(ForumSnippets)
