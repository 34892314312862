import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { parseRating, parseRange, setFilterSelection } from '../insights'
import { buildQueryObject } from '../insights/constants/forum-helper'
import { storeForums, storeForumCount, setPagination, setForumLoadingState } from './actions'
import { REQUEST_FORUMS } from './constants/ActionTypes'
import Api from './api'

export function selectReviewTags(state) {
  return state.forum.forumTags
}

export function selectDeleteTagsIdArr(state) {
  return state.forum.deleteTagsIdArr
}

export function* getForums(action) {
  yield put(setForumLoadingState(true))

  const { forumFilterSelection, pageNum, pageSize, searchParams } = action.payload.params
  const offset = (pageNum - 1) * pageSize
  const pagination = `?limit=${pageSize}&offset=${offset}`
  const formattedQueryObj = buildQueryObject({ ...forumFilterSelection }, { ...searchParams })
  const queryObject = {
    ...formattedQueryObj.filter_object,
    ...formattedQueryObj.search_object,
  }

  try {
    const response = yield call(Api.getForums, pagination, { ...queryObject })
    const { result, totalDocuments } = response.data.body

    let forumCount = totalDocuments
    yield put(storeForumCount(parseRange(forumCount, response.request.responseURL)))

    // querying on theme
    if ('theme' in queryObject) {
      const { theme } = queryObject

      function filter(array, fn) {
        return array.reduce((r, o) => {
          var replies = filter(o.replies || [], fn)
          if (fn(o) || replies.length) {
            r.push(Object.assign({}, o, replies.length && { replies }))
          }
          return r
        }, [])
      }

      const filtered = []
      for (let i = 0; i < result.length; i++) {
        const { comments } = result[i]
        var res = filter(comments, ({ themes }) => theme.every(key => Object.keys(themes).includes(key.toLowerCase())))

        if (res.length > 0) {
          filtered.push({
            ...result[i],
            comments: res,
          })
        }
      }

      yield put(storeForums(filtered))
    } else {
      yield put(storeForums(result))
    }
    yield put(setPagination(pageNum, pageSize))
    yield put(setForumLoadingState(false))
  } catch (err) {
    console.error('err', err)
    yield put(setForumLoadingState(false))
  }
}

/**
 * Watcher Saga for actions of forum explorer
 * @return {Generator}
 */
export default function* watchForum() {
  yield all([takeLatest(REQUEST_FORUMS, getForums)])
}
