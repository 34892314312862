import { REQUEST_PEI_SCORECARD,
  REQUEST_PEI_BY_RETAILER,
  RECEIVE_PEI_SCORECARD,
  RECEIVE_PEI_BY_RETAILER,
  SET_PEI_SCORECARD_LOADING_STATE,
  SET_PEI_BY_RETAILER_LOADING_STATE,
  SET_FILTER_SELECTION,
  SET_ORIGINAL_FILTER_SELECTION,
  SET_TIME_PERIOD } from './constants/ActionTypes'

export const getPeiScorecard = (obj, storePeiScorecard) => ({
  type: REQUEST_PEI_SCORECARD,
  payload: {
    filterSelection: obj,
    storePeiScorecard,
  },
})

export const storePeiScorecard = obj => ({
  type: RECEIVE_PEI_SCORECARD,
  payload: obj,
})

export const setPeiScorecardLoadingState = isLoading => ({
  type: SET_PEI_SCORECARD_LOADING_STATE,
  payload: isLoading,
})

export const setPEIByRetailerLoadingState = isChartLoading => ({
  type: SET_PEI_BY_RETAILER_LOADING_STATE,
  payload: isChartLoading,
})

export const setFilterSelection = filterSelectionObj => ({
  type: SET_FILTER_SELECTION,
  payload: filterSelectionObj,
})

export const setOriginalFilterSelection = filterSelectionObj => ({
  type: SET_ORIGINAL_FILTER_SELECTION,
  payload: filterSelectionObj,
})

export const setTimePeriod = timePeriod => ({
  type: SET_TIME_PERIOD,
  payload: timePeriod,
})

export const getPEIByRetailer = (type, department, storePEIByRetailer, filterSelection) => ({
  type: REQUEST_PEI_BY_RETAILER,
  payload: {
    type,
    department,
    storePEIByRetailer,
    filterSelection
  }
})

export const storePEIByRetailer = obj => ({
  type: RECEIVE_PEI_BY_RETAILER,
  payload: obj
})