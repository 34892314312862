import _ from 'lodash'
import { searchTypes, logicParams } from './constants/textSearch'
import { defaultPageSize } from '../common'

import {
  RECEIVE_REVIEWS,
  RECEIVE_ALL_REVIEWS,
  RECEIVE_SET_FLAG,
  RECEIVE_ADD_TAG,
  RECEIVE_DELETE_TAG,
  RECEIVE_WORDS,
  RECEIVE_PEI,
  SET_DOWNLOAD_PERCENTAGE,
  SET_RANGE,
  SET_SEARCH_PARAMS,
  SET_EXPANDED_ROW_KEYS,
  SET_EDITABLE_TAG_KEYS,
  SET_FLAG_KEYS,
  SET_PAGINATION,
  SET_REVIEW_LOADING_STATE,
  SET_PEI_LOADING_STATE,
  SET_FILTER_OBJECT,
  SET_REVIEW_TAGS,
  SET_DELETE_TAGS_ID,
  SET_SELECTED_PAGINATION,
  SET_CHART_LOADING_STATE,
} from './constants/ActionTypes'

const INITIAL_STATE = {
  reviews: [],
  allReviews: [],
  downloadPercentage: 0,
  words: {},
  deleteTagsIdArr: [],
  reviewTags: {},
  selectedObj: {},
  filterObject: {},
  editableTagKeys: [],
  flagKeys: [],
  expandedRowKeys: [],
  searchParams: {
    searchType: searchTypes && searchTypes[0], // General search as default
    logicParam: logicParams && logicParams[0], // And as default
    review: {
      keyWords: [],
      logicParam: logicParams && logicParams[0],
    },
    product: {
      keyWords: [],
      logicParam: logicParams && logicParams[0],
    },
  },
  pei: 'N/A',
  range: [],
  pageNum: 1,
  pageSize: defaultPageSize,
  isReviewLoading: false,
  isPEILoading: false,
  isChartLoading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_REVIEWS:
      return { ...state, reviews: action.payload }
    case RECEIVE_ALL_REVIEWS:
      return { ...state, allReviews: action.payload }
    case RECEIVE_WORDS:
      return { ...state, words: action.payload }
    case RECEIVE_PEI:
      return { ...state, pei: action.payload }
    case RECEIVE_SET_FLAG:
      const { index, flag, review_flag_id } = action.payload
      return {
        ...state,
        reviews: [
          ...state.reviews.slice(0, index),
          {
            ...state.reviews[index],
            flag,
            review_flag_id,
          },
          ...state.reviews.slice(index + 1),
        ],
      }
    case RECEIVE_ADD_TAG:
      const { index: idx, tag } = action.payload
      return {
        ...state,
        reviews: [
          ...state.reviews.slice(0, idx),
          {
            ...state.reviews[idx],
            tags: _.isEmpty(state.reviews[idx].tags) ? [tag] : [...state.reviews[idx].tags, tag],
          },
          ...state.reviews.slice(idx + 1),
        ],
      }
    case RECEIVE_DELETE_TAG:
      return {
        ...state,
        reviews: [
          ...state.reviews.slice(0, action.payload.index),
          {
            ...state.reviews[action.payload.index],
            tags: state.reviews[action.payload.index].tags.filter(eachTag => eachTag.review_tag_id !== action.payload.tagId),
          },
          ...state.reviews.slice(action.payload.index + 1),
        ],
      }
    case SET_DOWNLOAD_PERCENTAGE:
      return { ...state, downloadPercentage: action.payload }
    case SET_RANGE:
      return { ...state, range: action.payload }
    case SET_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    case SET_EDITABLE_TAG_KEYS:
      return { ...state, editableTagKeys: action.payload }
    case SET_EXPANDED_ROW_KEYS:
      return { ...state, expandedRowKeys: action.payload }
    case SET_FLAG_KEYS:
      return { ...state, flagKeys: action.payload }
    case SET_PAGINATION:
      return { ...state, pageNum: action.payload.pageNum, pageSize: action.payload.pageSize }
    case SET_REVIEW_LOADING_STATE:
      return { ...state, isReviewLoading: action.payload }
    case SET_PEI_LOADING_STATE:
      return { ...state, isPEILoading: action.payload }
    case SET_FILTER_OBJECT:
      return { ...state, filterObject: action.payload }
    case SET_REVIEW_TAGS:
      return { ...state, reviewTags: action.payload }
    case SET_DELETE_TAGS_ID:
      return { ...state, deleteTagsIdArr: action.payload }
    case SET_SELECTED_PAGINATION:
      return { ...state, selectedObj: action.payload }
    case SET_CHART_LOADING_STATE:
      return { ...state, isChartLoading: action.payload }
    default:
      return state
  }
}
