import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  REQUEST_ALL_PRODUCTS,
  RECEIVE_ALL_PRODUCTS,
  RECEIVE_SORT_FILTERS,
  RECEIVE_AVG_SENT_SCORE,
  SET_PRODUCT_LOADING_STATE,
  SET_RANGE,
  SET_PAGINATION,
  SET_QUALITY_THRESHOLD,
  SET_FILTER_SELECTION,
} from './constants/ActionTypes'

export const getProducts = (params, additionalParams) => ({
  type: REQUEST_PRODUCTS,
  payload: {
    params,
    additionalParams,
  },
})

export const getAllProducts = (params, additionalParams, resolve, reject) => ({
  type: REQUEST_ALL_PRODUCTS,
  payload: {
    params,
    additionalParams,
    resolve,
    reject,
  },
})

export const storeProducts = products => ({
  type: RECEIVE_PRODUCTS,
  payload: products,
})

export const storeAllProducts = products => ({
  type: RECEIVE_ALL_PRODUCTS,
  payload: products,
})

export const storeSortFilters = (sortAttribute, sortOrder) => ({
  type: RECEIVE_SORT_FILTERS,
  payload: {
    sortAttribute,
    sortOrder,
  },
})

export const storeAvgSentScore = avgSentScore => ({
  type: RECEIVE_AVG_SENT_SCORE,
  payload: avgSentScore,
})

export const setProductLoadingState = isLoading => ({
  type: SET_PRODUCT_LOADING_STATE,
  payload: isLoading,
})

export const storeProductCount = countArr => ({
  type: SET_RANGE,
  payload: countArr,
})

export const setPagination = (pageNum, pageSize) => ({
  type: SET_PAGINATION,
  payload: {
    pageNum,
    pageSize,
  },
})

export const setQualityThreshold = qualityThreshold => ({
  type: SET_QUALITY_THRESHOLD,
  payload: qualityThreshold,
})

export const setFilterSelection = filterSelectionObj => ({
  type: SET_FILTER_SELECTION,
  payload: filterSelectionObj,
})

export const formatVarianceData = obj => {
  const finalArr = []
  for (const x in obj) {
    const src = obj[x]
    const srcObj = {
      source: x,
      key: x,
    }
    src.map((data, i) => {
      const count = [data[0], data[1]]
      let variance = []
      let moOverMo = ''
      if (i === 0) {
        moOverMo = Math.round((data[1] / src[src.length - 1][0]) * 100) - 100
      } else if (i === src.length - 1) {
        moOverMo = Math.round((data[0] / src[src.length - 2][0]) * 100) - 100
      } else {
        moOverMo = Math.round((data[1] / src[i - 1][1]) * 100) - 100
      }
      const yrOverYr = Math.round((data[1] / data[0]) * 100) - 100
      variance = [yrOverYr, moOverMo]
      switch (i) {
        case 0:
          srcObj['jan'] = { count, variance }
          break
        case 1:
          srcObj['feb'] = { count, variance }
          break
        case 2:
          srcObj['mar'] = { count, variance }
          break
        case 3:
          srcObj['apr'] = { count, variance }
          break
        case 4:
          srcObj['may'] = { count, variance }
          break
        case 5:
          srcObj['jun'] = { count, variance }
          break
        case 6:
          srcObj['jul'] = { count, variance }
          break
        case 7:
          srcObj['aug'] = { count, variance }
          break
        case 8:
          srcObj['sep'] = { count, variance }
          break
        case 9:
          srcObj['oct'] = { count, variance }
          break
        case 10:
          srcObj['nov'] = { count, variance }
          break
        case 11:
          srcObj['dec'] = { count, variance }
          break
      }
      srcObj.comparison = { count: ['2021', '2022'], variance: ['Yr/Yr', 'Mo/Mo'] }
    })
    finalArr.push(srcObj)
  }
  return finalArr
}
