import { takeLatest, call, put, all } from 'redux-saga/effects'
import { setReviewVarianceLoadingState, storeReviewVarianceCount } from './actions'
import { buildQueryObjectReviewVariance } from './constants/helpers'
import { REQUEST_REVIEW_VARIANCE_COUNT } from './constants/ActionTypes'
import Api from './api'
import _ from 'lodash'

export function* getReviewVarianceCount(action) {
  yield put(setReviewVarianceLoadingState(true))

  const { filterSelection } = action.payload
  const formattedQueryObject = buildQueryObjectReviewVariance({ ...filterSelection }, {}, {})
  const queryObject = {
    query_object: {
      ...formattedQueryObject.filter_object,
    },
  }

  try {
    const res = yield call(Api.getReviewVariance, { ...queryObject })
    if (!_.isEmpty(res.data)) {
      yield put(storeReviewVarianceCount(res.data))
      yield put(setReviewVarianceLoadingState(false))
    } else yield put(setReviewVarianceLoadingState(false))
  } catch (error) {
    yield put(setReviewVarianceLoadingState(false))
  }
}

export default function* watchReviewVarianceCount() {
  yield all([takeLatest(REQUEST_REVIEW_VARIANCE_COUNT, getReviewVarianceCount)])
}
