import axios from 'axios'

const Api = {
  getProducts: (pagination, queryObject) => axios.put(`/products${pagination}`, queryObject),
  getAllProducts: (pagination, queryObject) => axios.put(`/products${pagination}`, queryObject),
  addCustomAttr: queryObject => axios.post('/attributes', queryObject),
  deleteCustomAttr: queryObject =>
    axios.delete('/attributes', {
      data: queryObject,
    }),
  addProductAttr: queryObject => axios.post('/products/attributes', queryObject),
  deleteProductAttr: queryObject =>
    axios.delete('/products/attributes', {
      data: queryObject,
    }),
}

export default Api
