import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'antd'
import _ from 'lodash'

import { TreeSelect, GroupSelect } from '../../filter'
import { getForumFilters, storeForumFilters } from '../actions'
import { concatArraySelectorFactory, concatTimeArraySelectorFactory, getRatingRange, valueArraySelectorFactory } from '../forum-selectors'
import { customizer, getAttrAttrFilter, getProcessedAttrVal, getParentChildValues, getAllCheckedGrandChildrenNodes, getPartiallySelectedParentValues } from '../constants/forum-helper'
import { NoFilter } from '../../common'
import './insights.css'

const separator = '>'

export class ForumLeftWrapper extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleResetFilter = this.handleResetFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.getForumFilters()
  }

  handleSubmit() {
    // fetch reviews if it is on review explorer page
    this.props.onSubmit()
  }

  /**
   * Update filter selection, also need to update attr list if ctgy changed
   * @param  {[type]} value [description]
   * @param  {[type]} title [description]
   * @param  {[type]} extra [description]
   * @param  {[type]} name  [description]
   * @return {[type]}       [description]
   */
  handleChange(value, title, extra, name) {
    if (name.indexOf(separator) === -1) {
      // 1. flat filters
      this.props.setForumFilterSelection({ [name]: { value, title } })
    } else {
      // 2. nested filters，currently, limited to 2 levels, it has separator in the name
      // get filter names
      const [parent, children, grandChildren] = name.trim().split(separator)
      // flatten nested filters' value
      const [parentValues, childrenValues, grandChildrenValues] = getParentChildValues(extra.allCheckedNodes)
      const parentValueArray = _.map(parentValues, 'value')
      const parentTitleArray = _.map(parentValues, 'title')
      const childrenValueArray = _.map(childrenValues, 'value')
      const childrenTitleArray = _.map(childrenValues, 'title')
      const grandChildrenValueArray = _.map(grandChildrenValues, 'value')
      const grandChildrenTitleArray = _.map(grandChildrenValues, 'title')
      // if it is category subcategory, also need to update attr (list and selection)
      if (name === `forum_year${separator}forum_quarter${separator}forum_month`) {
        this.props.setForumFilterSelection({
          [parent]: { title: parentTitleArray, value: parentValueArray },
          [children]: {
            title: childrenTitleArray,
            value: childrenValueArray,
          },
          [grandChildren]: {
            title: grandChildrenTitleArray,
            value: grandChildrenValueArray,
          },
        })
      } else if (name === `theme${separator}aspect`) {
        const partiallySelectedThemeValues = getPartiallySelectedParentValues(getAllCheckedGrandChildrenNodes(extra.allCheckedNodes))
        this.props.setForumFilterSelection({
          [parent]: { title: parentTitleArray, value: parentValueArray },
          [children]: {
            title: grandChildrenTitleArray,
            value: grandChildrenValueArray,
          },
          partiallySelectedThemeValues,
        })
      } else {
        // update filters selection
        this.props.setForumFilterSelection({
          [parent]: { title: parentTitleArray, value: parentValueArray },
          [children]: {
            title: grandChildrenTitleArray,
            value: grandChildrenValueArray,
          },
        })
      }
    }
  }

  handleSelectAll(event, name, filterName) {
    const filter = this.props.forumFilters[filterName]
    const allValuesArray = _.map(filter, 'value')
    const allLabelsArray = _.map(filter, 'title')
    const { textContent } = event.target
    if (textContent === 'Select all') {
      if (name.indexOf(separator) === -1) {
        // 1. flat filters
        this.props.setForumFilterSelection({ [name]: { value: allValuesArray, title: allLabelsArray } })
      } else if (name === `forum_year${separator}forum_quarter${separator}forum_month`) {
        const [parent, children, grandChildren] = name.trim().split(separator)
        this.props.setForumFilterSelection({
          [parent]: { title: allLabelsArray, value: allValuesArray },
          [children]: {
            title: [],
            value: [],
          },
          [grandChildren]: {
            title: [],
            value: [],
          },
        })
      } else {
        const [parent, children] = name.trim().split(separator)
        this.props.setForumFilterSelection({
          [parent]: { title: allLabelsArray, value: allValuesArray },
          [children]: {
            title: [],
            value: [],
          },
        })
      }
    } else {
      if (name.indexOf(separator) === -1) {
        // 1. flat filters
        this.props.setForumFilterSelection({ [name]: { value: [], title: [] } })
      } else {
        const [parent, children] = name.trim().split(separator)
        this.props.setForumFilterSelection({
          [parent]: { title: [], value: [] },
          [children]: {
            title: [],
            value: [],
          },
        })
      }
    }
  }

  handleResetFilter() {
    const { forumFilterSelection } = this.props
    this.props.setForumFilterSelection(
      _.mapValues(forumFilterSelection, (value, key) => {
        return []
      }),
    )
  }

  render() {
    const {
      forumFilters,
      isFilterHidden,
      brandSubbrandValues,
      forumSiteValues,
      themeAspectValues,
      themeSentimentValues,
      // forumTimeValues
    } = this.props

    if (_.isEmpty(forumFilters)) {
      return (
        <div className="left-wrapper">
          <NoFilter />
        </div>
      )
    }
    const { brand, forum_site, theme, theme_sentiment } = forumFilters

    return (
      <div className={`${isFilterHidden ? 'ml-neg-sidebar' : 'ml-0'} left-wrapper`}>
        <Button onClick={this.handleSubmit} className="filter-set-submit">
          Submit
        </Button>
        <div className="filter-set-title">Filter Set</div>
        <div className="span-link reset-filters" onClick={this.handleResetFilter}>
          Clear Filters
        </div>

        {/* Brand -> Sub_brand */}
        <TreeSelect
          config={{
            id: 'brand',
            data: [
              {
                title: (
                  <span onClick={event => this.handleSelectAll(event, `brand${separator}sub_brand`, 'brand')} className="select-all">
                    {brandSubbrandValues.length > 0 ? 'Unselect all' : 'Select all'}
                  </span>
                ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...brand,
            ],
            onChange: (value, title, extra) => this.handleChange(value, title, extra, `brand${separator}sub_brand`),
            value: brandSubbrandValues,
            title: 'brand > subbrand',
          }}
        />

        {/* Forum Site*/}
        <TreeSelect
          config={{
            id: 'forum_site',
            title: 'forum site',
            data: [
              {
                title: (
                  <span onClick={event => this.handleSelectAll(event, 'forum_site', 'forum_site')} className="select-all">
                    {forumSiteValues.length > 0 ? 'Unselect all' : 'Select all'}
                  </span>
                ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...forum_site,
            ],
            value: forumSiteValues,
            onChange: (value, title, extra) => this.handleChange(value, title, extra, 'forum_site'),
          }}
        />

        {/* Themes -> Aspects */}
        <TreeSelect
          config={{
            id: 'theme',
            title: 'theme > aspect',
            data: [
              {
                title: (
                  <span onClick={event => this.handleSelectAll(event, `theme${separator}aspect`, 'theme')} className="select-all">
                    {themeAspectValues.length > 0 ? 'Unselect all' : 'Select all'}
                  </span>
                ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...theme,
            ],
            value: themeAspectValues,
            onChange: (value, title, extra) => this.handleChange(value, title, extra, `theme${separator}aspect`),
          }}
        />

        {/* Theme Sentiment */}
        <TreeSelect
          config={{
            id: 'theme_sentiment',
            title: 'theme sentiment',
            data: [
              {
                title: (
                  <span onClick={event => this.handleSelectAll(event, 'theme_sentiment', 'theme_sentiment')} className="select-all">
                    {themeSentimentValues.length > 0 ? 'Unselect all' : 'Select all'}
                  </span>
                ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...theme_sentiment,
            ],
            value: themeSentimentValues,
            onChange: (value, title, extra) => this.handleChange(value, title, extra, 'theme_sentiment'),
          }}
        />

        {/* Forum Year -> Quarter -> Month */}
        {/* <TreeSelect
          config={{
            id: 'forumTime',
            title: 'Forum Year > Quarter > Month',
            data: [
              {
                title: (
                  <span onClick={event => this.handleSelectAll(event, `forum_year${separator}forum_quarter${separator}forum_month`, 'forum_year')} className="select-all">
                    {forumTimeValues.length > 0 ? 'Unselect all' : 'Select all'}
                  </span>
                ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...forum_year,
            ],
            value: forumTimeValues,
            onChange: (value, title, extra) => this.handleChange(value, title, extra, `forum_year${separator}forum_quarter${separator}forum_month`),
          }}
        /> */}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { currentViewName } = ownProps
  return {
    forumFilters: state.insights.forumFilters,
    forumFilterSelection: state.insights.forumFilterSelection,
    // forumTimeValues: concatTimeArraySelectorFactory('forum_year', 'forum_quarter', 'forum_month')(state[currentViewName]),
    brandSubbrandValues: concatArraySelectorFactory('brand', 'sub_brand')(state[currentViewName]),
    themeAspectValues: concatArraySelectorFactory('theme', 'aspect')(state[currentViewName]),
    forumSiteValues: valueArraySelectorFactory('forum_site')(state[currentViewName]),
    themeSentimentValues: valueArraySelectorFactory('theme_sentiment')(state[currentViewName]),
    isFilterHidden: state.insights.isFilterHidden,
  }
}

ForumLeftWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  getForumFilters: PropTypes.func.isRequired,
  storeForumFilters: PropTypes.func.isRequired,
  setForumFilterSelection: PropTypes.func.isRequired,
  isFilterHidden: PropTypes.bool.isRequired,
  forumFilters: PropTypes.shape({
    brand: PropTypes.array,
    forum_site: PropTypes.array,
    theme: PropTypes.array,
    aspect: PropTypes.array,
    theme_sentiment: PropTypes.array,
  }).isRequired,
  forumFilterSelection: PropTypes.shape({}).isRequired,
  themeAspectValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  brandSubbrandValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  //   forumTimeValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  forumSiteValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  themeSentimentValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentViewName: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, {
  getForumFilters,
  storeForumFilters,
})(ForumLeftWrapper)
