import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Tag } from 'antd'

class SelectionInfoTag extends Component {
  constructor(props) {
    super(props)

    this.handleOnClose = this.handleOnClose.bind(this)
  }

  handleOnClose(e, val, filter) {
    this.props.onClose(e, val, filter)
  }

  render() {
    const clientBrand = window.localStorage.getItem('clientName')
    const { product, risk_category, risk_subcategory, risk_attribute, add_comp_attribute_val, theme, time, sentimentType } = this.props
    const themeArr = []
    if (!_.isEmpty(theme) && _.isArray(theme.name)) {
      const { id } = theme
      theme.name.forEach((each, index) =>
        themeArr.push(
          <Tag key={id[index]} closable onClose={e => this.handleOnClose(e, { name: each, id: id[index] }, 'theme')}>
            {each}
          </Tag>,
        ),
      )
    } else {
      themeArr.push(
        <Tag key={theme.id} closable onClose={e => this.handleOnClose(e, theme, 'theme')}>
          {theme.name}
        </Tag>,
      )
    }
    return _.isEmpty(risk_category) &&
      _.isEmpty(risk_subcategory) &&
      _.isEmpty(risk_attribute) &&
      _.isEmpty(add_comp_attribute_val) &&
      _.isEmpty(product) &&
      _.isEmpty(theme) &&
      _.isEmpty(time) &&
      _.isEmpty(sentimentType) ? null : (
      <div
        style={{
          background: '#fbfbfb',
          padding: '15px 24px 0 24px',
          borderRadius: '4px',
        }}
      >
        {_.isEmpty(risk_category) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Category</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, risk_category, 'risk_category')}>
              {risk_category.name}
            </Tag>
          </div>
        )}
        {_.isEmpty(risk_subcategory) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Subcategory</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, risk_subcategory, 'risk_subcategory')}>
              {risk_subcategory.name}
            </Tag>
          </div>
        )}
        {_.isEmpty(risk_attribute) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Attribute</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, risk_attribute, 'risk_attribute')}>
              {risk_attribute.name}
            </Tag>
          </div>
        )}
        {_.isEmpty(add_comp_attribute_val) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Attribute Value</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, add_comp_attribute_val, 'add_comp_attribute_val')}>
              {add_comp_attribute_val.name}
            </Tag>
          </div>
        )}
        {_.isEmpty(product) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Product</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, product, 'product')}>
              {product.name}
            </Tag>
          </div>
        )}
        {_.isEmpty(theme) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Theme</strong>:{themeArr}
          </div>
        )}
        {_.isEmpty(time) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Time</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, time, 'time')}>
              {time.name}
            </Tag>
          </div>
        )}
        {_.isEmpty(sentimentType) ? null : (
          <div style={{ marginTop: 5 }}>
            <strong>Client / Competitor</strong>:{' '}
            <Tag closable onClose={e => this.handleOnClose(e, sentimentType, 'sentimentType')}>
              {sentimentType.name === 'client' ? clientBrand : 'Competitor(s)'}
            </Tag>
          </div>
        )}
      </div>
    )
  }
}

SelectionInfoTag.defaultProps = {
  sentimentType: {},
  product: {},
  risk_category: {},
  risk_subcategory: {},
  risk_attribute: {},
  add_comp_attribute_val: {},
  theme: {},
  time: {},
}

SelectionInfoTag.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.arrayOf(PropTypes.number),
  }),
  risk_category: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  risk_subcategory: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  risk_attribute: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  add_comp_attribute_val: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  theme: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  }),
  time: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  sentimentType: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
}

export default SelectionInfoTag
