import {
  RECEIVE_RISK_OPPORTUNITY,
  REQUEST_RISK_OPPORTUNITY,
  SET_RISK_OPPORTUNITY_LOADING_STATE,
  SET_CURRENT_TIME,
  SET_TIME_MODE,
  SET_FILTER_SELECTION,
  SET_RISK_OPPORTUNITY_THRESHOLD,
  SET_LEVEL_FILTER,
} from './constants/ActionTypes'

export const getRiskOpportunity = params => ({
  type: REQUEST_RISK_OPPORTUNITY,
  payload: params,
})

export const storeRiskOpportunity = riskOpportunity => ({
  type: RECEIVE_RISK_OPPORTUNITY,
  payload: riskOpportunity,
})

export const setRiskOpportunityLoadingState = isLoading => ({
  type: SET_RISK_OPPORTUNITY_LOADING_STATE,
  payload: isLoading,
})

export const setCurrentTime = curTime => ({
  type: SET_CURRENT_TIME,
  payload: curTime,
})

export const setTimeMode = timeMode => ({
  type: SET_TIME_MODE,
  payload: timeMode,
})

export const setFilterSelection = filterSelectionObj => ({
  type: SET_FILTER_SELECTION,
  payload: filterSelectionObj,
})

export const setRiskOpportunityThreshold = threshold => ({
  type: SET_RISK_OPPORTUNITY_THRESHOLD,
  payload: threshold,
})

export const setLevelFilter = levelFilter => ({
  type: SET_LEVEL_FILTER,
  payload: levelFilter,
})
