import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select } from 'antd'
import './tree-select.css'

const { Option, OptGroup } = Select
const predefinedConfig = {
  allowClear: true,
  treeCheckable: true,
  mode: 'multiple',
  placeholder: 'No Filter',
  size: 'large',
  style: {
    width: '100%',
    fontSize: 14,
  },
  optionFilterProp: 'label',
  dropdownStyle: {},
}

const GroupSelect = ({ config: { title, data, value, onChange, ...restConfig } }) => {
  let combinedConfig = { ...predefinedConfig, ...restConfig }
  if (_.isArray(value)) {
    combinedConfig = { ...combinedConfig, value }
  }
  const optionGroups = data.map((group, i) => (
    <OptGroup label={group.label} key={group.key + i.toString()} value={group.value}>
      {group.children &&
        group.children.map(option => {
          if (_.isEmpty(option)) return null
          return (
            <Option value={option.value} key={option.key} label={option.label}>
              {option.label}
            </Option>
          )
        })}
    </OptGroup>
  ))
  return (
    <div className="filter-wrapper">
      <h6 className="filter-name">{title || ''}</h6>
      <Select {...combinedConfig} onChange={onChange}>
        {optionGroups}
      </Select>
    </div>
  )
}

GroupSelect.defaultProps = {}

GroupSelect.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
  }).isRequired,
}

export default GroupSelect
