import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Button } from 'antd'

class FlagForm extends Component {
  constructor(props) {
    super(props)

    this.handleClickSubmit = this.handleClickSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleClickSubmit(e) {
    e.preventDefault()
    const { reviewId, reviewExternalId, index } = this.props
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        this.props.onSubmit(index, reviewId, reviewExternalId, values.comment)
        this.props.form.resetFields()
      }
    })
  }

  handleReset = () => {
    const { index } = this.props
    this.props.form.resetFields()
    this.props.onCancel(index)
  }

  render() {
    const FormItem = Form.Item
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Form onSubmit={this.handleClickSubmit}>
          <FormItem>
            {getFieldDecorator('comment', {
              rules: [
                {
                  required: true,
                  message: 'Please input your comment!',
                },
              ],
            })(<Input.TextArea rows={4} placeholder="Enter comment" />)}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
              Cancel
            </Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

FlagForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  reviewId: PropTypes.number.isRequired,
  reviewExternalId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  return {}
}

FlagForm = Form.create()(FlagForm)
export { FlagForm }
export default connect(mapStateToProps, {})(FlagForm)
