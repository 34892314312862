const config = {
  title: {
    show: true,
    left: 'center',
    textStyle: {
      color: '#fbfbfb',
    },
  },
  legend: {
    show: true,
    left: 33,
    orient: 'vertical',
    textStyle: {
      color: 'white',
    },
  },
  tooltip: {
    show: true,
    trigger: 'axis',
    textStyle: {
      fontSize: 12,
    },
  },
  grid: {
    top: 30,
    bottom: 30,
    left: 33,
    right: 10,
  },
  toolbox: {
    show: false,
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    axisLabel: {
      show: true,
      color: '#fbfbfb',
    },
  },
  yAxis: {
    type: 'value',
    max: 'dataMax',
    min: 'dataMin',
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'hsl(208, 90%, 30%)',
      },
    },
    axisLabel: {
      show: true,
      color: '#fbfbfb',
    },
  },
  backgroundColor: 'hsl(208, 100%, 17%)',
  animationEasing: 'elasticOut',
}

export default config
