import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import config from './config'
import { scaleOpacitySize } from '../constants/helper'

export class ChartsWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.handleDbClickBubble = this.handleDbClickBubble.bind(this)
    this.handleClickBubble = this.handleClickBubble.bind(this)
    this.handleHoverBubble = this.handleHoverBubble.bind(this)
  }

  getConfig(option) {
    const { timeArray } = this.props.riskOpportunity
    const allHierarchy = _.concat(_.values(timeArray)).reduce((accumulator, currentValue) => _.unionBy(accumulator, currentValue, 'hierarchy_id'), [])
    const newOption = this.makeDataSeries(option)
    const fullSeries = this.makeDataSeries(allHierarchy).series
    const series = []
    fullSeries.forEach(each => {
      const hiera = _.find(newOption.series, curHiera => curHiera.name === each.name)
      if (_.isUndefined(hiera)) {
        series.push({ ...each, data: [['-', '-', '-']] })
      } else {
        series.push(_.cloneDeep(hiera))
      }
    })
    const { legendData } = newOption
    config.legend.data = legendData
    config.legend.show = this.props.showLegend
    return {
      ...config,
      series,
    }
  }

  handleClickBubble(params) {
    this.props.ClickBubble(params)
  }

  handleDbClickBubble(params) {
    const { levelFilter } = this.props
    if (levelFilter.length < 3) {
      this.props.DbClickBubble(params)
    }
  }

  handleHoverBubble(params) {
    this.props.HoverBubble(params)
  }

  makeDataSeries(option) {
    if (_.isArray(option)) {
      const series = []
      const legendData = []
      option.forEach(data => {
        const volume = data.client_total_review
        const [tempOpacity, tempSize] = scaleOpacitySize(volume)
        series.push({
          name: data.hierarchy_label,
          type: 'scatter',
          data: [[data.clientsentimentplot, data.competitorsentimentplot]],
          label: {
            normal: {
              show: this.props.showLabel,
              position: 'top',
              textStyle: {
                color: 'hsla(0, 0%, 0%, 0.63)',
                fontWeight: 'bold',
              },
              formatter: '{a}',
            },
            emphasis: {
              show: true,
            },
          },
          symbolSize: tempSize,
          itemStyle: {
            normal: {
              color: '#6eb2cd',
              opacity: tempOpacity,
              borderWidth: 1,
              borderColor: '#e8f4f3',
            },
            emphasis: {
              color: '#2481a1',
              opacity: 1,
              borderWidth: 1,
              borderColor: '#e8f4f3',
            },
          },
        })
        legendData.push(data.hierarchy_label)
      })
      return { series, legendData }
    }
    return ''
  }

  render() {
    const { riskOpportunity, curTime } = this.props
    return (
      <div>
        <ReactEcharts
          option={this.getConfig(riskOpportunity.timeArray[curTime])}
          notMerge={false}
          lazyUpdate={false}
          style={{ height: '500px' }}
          onEvents={{
            dblclick: this.handleDbClickBubble,
            click: this.handleClickBubble,
            mouseover: this.handleHoverBubble,
            mouseout: this.handleMouseOut,
          }}
        />
      </div>
    )
  }
}

ChartsWrapper.propTypes = {
  ClickBubble: PropTypes.func.isRequired,
  DbClickBubble: PropTypes.func.isRequired,
  HoverBubble: PropTypes.func.isRequired,
  showLabel: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  riskOpportunity: PropTypes.shape({
    times: PropTypes.array,
    timeArray: PropTypes.object,
    subcategoryTimeTrend: PropTypes.object,
  }).isRequired,
  curTime: PropTypes.string.isRequired,
  levelFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

function mapStateToProps(state) {
  return {
    riskOpportunity: state.riskOpportunity.riskOpportunity,
    curTime: state.riskOpportunity.curTime,
    levelFilter: state.riskOpportunity.levelFilter,
  }
}

export default connect(mapStateToProps, {})(ChartsWrapper)
