import { RECEIVE_PEI_SCORECARD, RECEIVE_PEI_BY_RETAILER, SET_PEI_SCORECARD_LOADING_STATE, SET_FILTER_SELECTION, SET_ORIGINAL_FILTER_SELECTION, SET_TIME_PERIOD, SET_PEI_BY_RETAILER_LOADING_STATE } from './constants/ActionTypes'

const INITIAL_STATE = {
  peiScorecard: null,
  peiByRetailer: null,
  isLoading: false,
  isChartLoading: false,
  filterSelection: {},
  originalFilterSelection: {},
  timePeriod: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PEI_SCORECARD:
      return { ...state, peiScorecard: action.payload }
    case RECEIVE_PEI_BY_RETAILER:
      return { ...state, peiByRetailer: action.payload }
    case SET_PEI_SCORECARD_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_PEI_BY_RETAILER_LOADING_STATE:
      return { ...state, isChartLoading: action.payload }
    case SET_FILTER_SELECTION:
      return { ...state, filterSelection: { ...state.filterSelection, ...action.payload } }
    case SET_ORIGINAL_FILTER_SELECTION:
      return {
        ...state,
        originalFilterSelection: { ...state.originalFilterSelection, ...action.payload },
      }
    case SET_TIME_PERIOD:
      return { ...state, timePeriod: action.payload }
    default:
      return state
  }
}
