import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Form, Button, Input } from 'antd'

class ThresholdForm extends Component {
  constructor(props) {
    super(props)

    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  componentDidMount() {
    const { threshold } = this.props
    this.props.form.setFields({
      threshold: { value: threshold },
    })
  }

  handleOnSubmit(e) {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (_.isEmpty(err)) {
        this.props.onSubmit(values.threshold)
      }
    })
  }

  render() {
    const FormItem = Form.Item
    const { label } = this.props
    const { getFieldDecorator } = this.props.form
    return (
      <Form layout="inline" onSubmit={this.handleOnSubmit}>
        <FormItem style={{ ...this.props.style }}>
          {getFieldDecorator('threshold', {
            getValueFromEvent: (e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget
              const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/
              if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
                return value
              }
              return ''
            },
            rules: [
              {
                required: true,
                message: 'Please input a number!',
              },
            ],
          })(<Input placeholder="Enter a number" addonBefore={label} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            Set
          </Button>
        </FormItem>
      </Form>
    )
  }
}

ThresholdForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  threshold: PropTypes.string.isRequired,
}

ThresholdForm = Form.create()(ThresholdForm)
export { ThresholdForm }
export default ThresholdForm
