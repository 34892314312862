import React from 'react'
import { Button, Icon } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'

const NavGroup = props => {
  const { isLeftDisabled, isRightDisabled, urlOfLeft, urlOfRight, leftTitle, rightTitle, leftIcon, rightIcon, history, className } = props
  return (
    <Button.Group className={className}>
      {!_.isEmpty(urlOfLeft) ? (
        <Button type="primary" disabled={isLeftDisabled} icon={leftIcon || 'left'} onClick={() => history.push(urlOfLeft)}>
          {leftTitle}
        </Button>
      ) : null}
      {!_.isEmpty(urlOfRight) ? (
        <Button type="primary" disabled={isRightDisabled} onClick={() => history.push(urlOfRight)}>
          {rightTitle}
          <Icon type={rightIcon || 'right'} />
        </Button>
      ) : null}
    </Button.Group>
  )
}

NavGroup.defaultProps = {
  urlOfLeft: '',
  urlOfRight: '',
  leftTitle: '',
  rightTitle: '',
  leftIcon: '',
  rightIcon: '',
  className: '',
  isLeftDisabled: false,
  isRightDisabled: false,
}

NavGroup.propTypes = {
  urlOfLeft: PropTypes.string,
  urlOfRight: PropTypes.string,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  className: PropTypes.string,
  history: PropTypes.shape({}).isRequired,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  isLeftDisabled: PropTypes.bool,
  isRightDisabled: PropTypes.bool,
}

export default NavGroup
