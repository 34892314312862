import React from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'

const NoData = ({ description }) => <Empty description={description} image={Empty.PRESENTED_IMAGE_SIMPLE} />

NoData.defaultProps = {
  description: 'No results to display.',
}

NoData.propTypes = {
  description: PropTypes.string,
}

export default NoData