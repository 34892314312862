const prefix = 'riskopportunity'

export const RECEIVE_RISK_OPPORTUNITY = `${prefix}/receive_risk_opportunity`
export const REQUEST_RISK_OPPORTUNITY = `${prefix}/request_risk_opportunity`
export const SET_RISK_OPPORTUNITY_LOADING_STATE = `${prefix}/set_risk_opportunity_loading_state`
export const SET_CURRENT_TIME = `${prefix}/set_current_time`
export const SET_TIME_MODE = `${prefix}/set_time_mode`
export const SET_FILTER_SELECTION = `${prefix}/set_filter_selection`
export const SET_RISK_OPPORTUNITY_THRESHOLD = `${prefix}/set_risk_opportunity_threshold`
export const SET_LEVEL_FILTER = `${prefix}/set_level_filter`
