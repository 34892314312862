import { PERSONAINFO, CHANNELINFO, FLIPVISIBILITY, FLIPVISIBILITYCHANNEL, SETHIGHLIGHT } from './constants/ActionTypes'
import personaInfo from './constants/personaInfo'
import personaInfoWho from './constants/personaInfoWho'

export const setHighlight = (segmentId, highlight) => ({
  type: SETHIGHLIGHT,
  payload: {
    segmentId,
    highlight,
  },
})

export const flipVisibility = segmentId => ({
  type: FLIPVISIBILITY,
  payload: segmentId,
})
export const flipVisibilityChannel = segmentId => ({
  type: FLIPVISIBILITYCHANNEL,
  payload: segmentId,
})

export const getChannelInfo = () => ({
  type: CHANNELINFO,
  payload: personaInfo,
})

export const getPersonaInfo = () => ({
  type: PERSONAINFO,
  payload: personaInfoWho,
})

export const setPersonaInfo = () => ({
  type: PERSONAINFO,
  payload: [],
})

export const setChannelInfo = () => ({
  type: CHANNELINFO,
  payload: [],
})
