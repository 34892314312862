import React from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { convertFromAvgSentiment, convertFromCompetitorSentiment, icons } from '../../insights'

/**
 * return the table columns for themes
 * @param  {[type]} themeFilters [description]
 * @return {[type]}              [description]
 */
export const constructThemeColumns = (themeFilters, handleClickProductTheme = undefined) =>
  themeFilters.map(themeOption => ({
    title: themeOption.label,
    dataIndex: themeOption.value,
    key: themeOption.label,
    render: (text, record) =>
      _.isEmpty(text) || _.isUndefined(text.theme_sentiment_color_code) ? (
        ''
      ) : (
        <div>
          <Tooltip
            title={() => (
              <div
                style={{
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  letterSpacing: '0.5px',
                }}
              >
                {convertFromAvgSentiment(text.theme_sentiment_color_code)}:{text.theme_sentiment_score}
              </div>
            )}
          >
            <div className="mb-10">
              <img
                src={icons[convertFromAvgSentiment(text.theme_sentiment_color_code)]}
                alt={text.theme_sentiment_color_code}
                className="product-value"
                onClick={() => handleClickProductTheme && handleClickProductTheme(text, themeOption, record)}
              />
            </div>
          </Tooltip>
          <Tooltip
            title={() => (
              <div
                style={{
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  letterSpacing: '0.5px',
                }}
              >
                {convertFromCompetitorSentiment(text.theme_diff_color_code)}:{text.theme_diff_score}
              </div>
            )}
          >
            <div>
              <img
                src={icons[convertFromCompetitorSentiment(text.theme_diff_color_code)]}
                alt={text.theme_diff_color_code}
                className="product-value"
                onClick={() => handleClickProductTheme && handleClickProductTheme(text, themeOption, record)}
              />
            </div>
          </Tooltip>
        </div>
      ),
  }))
