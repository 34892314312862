export { default as reducer } from './reducer'
/*
cannot export insights component via strict module boundaries
this will cause circular dependency after upgrade to react router v4
e.g insights import FacetedExplorer, FacetedExplorer import actions from '../../insights'
If module A depends on module B (that is, it imports something from B’s public interface),
then module B cannot depend on anything in module A.
* */
// export { default as Insights } from './components/insights';
export { default as LeftWrapper } from './components/left-wrapper'
export { default as ForumLeftWrapper } from './components/forum-left-wrapper'
export { default as LeftWrapperDemo } from './components/left-wrapper-demo'
export { default as saga } from './saga'
export * from './constants/helper'
export * from './actions'
export * from './selectors'