import {
  RECEIVE_RISK_OPPORTUNITY,
  SET_RISK_OPPORTUNITY_LOADING_STATE,
  SET_CURRENT_TIME,
  SET_TIME_MODE,
  SET_FILTER_SELECTION,
  SET_RISK_OPPORTUNITY_THRESHOLD,
  SET_LEVEL_FILTER,
} from './constants/ActionTypes'

const INITIAL_STATE = {
  riskOpportunity: {},
  filterSelection: {},
  threshold: '0',
  levelFilter: [],
  curTime: '',
  timeMode: '',
  isLoading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_RISK_OPPORTUNITY:
      return { ...state, riskOpportunity: action.payload }
    case SET_FILTER_SELECTION:
      return { ...state, filterSelection: action.payload }
    case SET_RISK_OPPORTUNITY_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_CURRENT_TIME:
      return { ...state, curTime: action.payload }
    case SET_TIME_MODE:
      return { ...state, timeMode: action.payload }
    case SET_RISK_OPPORTUNITY_THRESHOLD:
      return { ...state, threshold: action.payload }
    case SET_LEVEL_FILTER:
      return { ...state, levelFilter: action.payload }
    default:
      return state
  }
}
