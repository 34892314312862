import { RECEIVE_FILTERS, RECEIVE_FORUM_FILTERS, SET_FILTER_SELECTION, SET_FORUM_FILTER_SELECTION, SET_FILTER_ISHIDDEN, SET_ADDITIONAL_PARAMS } from './constants/ActionTypes'

const INITIAL_STATE = {
  filters: {},
  forumFilters: {},
  filterSelection: {},
  forumFilterSelection: {},
  isFilterHidden: false,
  additionalParams: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_FILTERS:
      return { ...state, filters: action.payload }
    case RECEIVE_FORUM_FILTERS:
      return { ...state, forumFilters: action.payload }
    case SET_FILTER_SELECTION:
      return { ...state, filterSelection: { ...state.filterSelection, ...action.payload } }
    case SET_FORUM_FILTER_SELECTION:
      return { ...state, forumFilterSelection: { ...state.forumFilterSelection, ...action.payload } }
    case SET_FILTER_ISHIDDEN:
      return { ...state, isFilterHidden: action.payload }
    case SET_ADDITIONAL_PARAMS:
      return { ...state, additionalParams: action.payload }
    default:
      return state
  }
}
