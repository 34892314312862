import { RECEIVE_PEI_SCORE_PRODUCT, SET_MARKETPLACE_TREND_THRESHOLD, SET_MARKETPLACE_TREND_LOADING_STATE } from './constants/ActionTypes'

const INITIAL_STATE = {
  peiScoreProduct: {},
  isLoading: false,
  threshold: '20',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PEI_SCORE_PRODUCT:
      return { ...state, peiScoreProduct: action.payload }
    case SET_MARKETPLACE_TREND_LOADING_STATE:
      return { ...state, isLoading: action.payload }
    case SET_MARKETPLACE_TREND_THRESHOLD:
      return { ...state, threshold: action.payload }
    default:
      return state
  }
}
