export const searchTypes = [
  {
    id: 0,
    name: ' Search',
    type: 'Forum',
    fieldOfSearch: 'forum',
  },
]

export const logicParams = [
  {
    name: 'And',
    character: '&',
    id: 0,
    encoding: '%26',
  },
  {
    name: 'Or',
    character: '|',
    id: 1,
    encoding: '%7C',
  },
]

export const sortOrders = [
  {
    name: 'Ascend',
    field: 'ASC',
    id: 0,
  },
  {
    name: 'Descend',
    field: 'DESC',
    id: 1,
  },
]

export const sortFields = [
  {
    name: 'Forum Date',
    field: 'forum_submission_date',
    id: 0,
  },
  {
    name: 'Forum Rating',
    field: 'forum_rating',
    id: 1,
  },
  {
    name: 'Tagged Date',
    field: 'forum_max_tag_date',
    id: 2,
  },
]
