import axios from 'axios'
import { takeLatest, call, all, put } from 'redux-saga/effects'
import { REQUEST_TOKEN, RESUME_TOKEN } from './constants/ActionTypes'
import { setAuth, setSigninLoadingState, setSigninUserAttributes, setCognitoError } from './actions'
import Api from './api'
/**
 * worker saga
 * @return {Generator}
 */

export function* setLocalStorage(response) {
  window.localStorage.setItem('token', `Bearer ${response.data.token}`)
  window.localStorage.setItem('user_id', response.data.user_id)
  window.localStorage.setItem('customer', response.data.customer)
  window.localStorage.setItem('schema', response.data.schema)
  window.localStorage.setItem('logins', response.data.logins)

  // Update the default API client with the authorization token
  axios.defaults.headers.common.Authorization = window.localStorage.getItem('token')

  const globalVar = yield call(Api.globalVar)

  window.localStorage.setItem('theme', JSON.stringify(globalVar.data[0].theme))
  window.localStorage.setItem('department', JSON.stringify(globalVar.data[0].department))
  window.localStorage.setItem('brand', JSON.stringify(globalVar.data[0].brand))
  window.localStorage.setItem('clientName', globalVar.data[0].client_name)
  window.localStorage.setItem('imageDomain', globalVar.data[0].image_domain)
  window.localStorage.setItem('reviewYear', JSON.stringify(globalVar.data[0].review_year.map(String)))
  window.localStorage.setItem('reviewQuarter', JSON.stringify(globalVar.data[0].review_quarter.map(String)))
  window.localStorage.setItem('reviewMonth', JSON.stringify(globalVar.data[0].review_month.map(String)))
}
export function* getToken(action) {
  try {
    yield put(setSigninLoadingState(true))
    const response = yield call(Api.signIn, action.payload.credentials)

    if (response.data) {
      yield call(setLocalStorage, response)
      action.payload.history.push('/dashboard')

    } else if (response.newpassword) {
      yield put(setSigninUserAttributes(JSON.stringify(response.newpassword.userAttributes)))
    }
    yield put(setSigninLoadingState(false))
  } catch (err) {
    console.error(err)
    yield put(setCognitoError(err))
    yield put(setSigninLoadingState(false))
  }
}

export function* resumeToken(action) {
  try {
    const response = yield call(Api.signIn, action.payload.credentials)

    window.localStorage.setItem('token', `Bearer ${response.data.token}`)

    // Update the default API client with the authorization token
    axios.defaults.headers.common.Authorization = window.localStorage.getItem('token')

    yield put(setAuth(true))
  } catch (err) {
    console.log('err', err)
  }
}

/**
 * watcher saga: spawn a new task on each Action
 * @return {Generator}
 */
export default function* watchUser() {
  yield all([takeLatest(REQUEST_TOKEN, getToken), takeLatest(RESUME_TOKEN, resumeToken)])
}
