import _ from 'lodash'
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { parseRating, buildQueryObject } from '../insights'
import { storePeiScoreProduct, setMarketplaceTrendLoadingState } from './actions'
import { REQUEST_PEI_SCORE_PRODUCT } from './constants/ActionTypes'
import Api from './api'

export function* getPeiScoreProduct(action) {
  yield put(setMarketplaceTrendLoadingState(true))

  const { filterSelection, threshold } = action.payload
  const ratingArr = parseRating(filterSelection.review_rating)
  const formattedQueryObject = buildQueryObject({ ...filterSelection, review_rating: ratingArr }, { review_threshold: parseInt(threshold) - 1 }, {})
  const queryObjectDesc = {
    query_object: {
      ...formattedQueryObject.additional_filter,
      ...formattedQueryObject.filter_object,
      sort_order: 'desc',
      sort_attribute: 'product_pei',
    },
  }

  const queryObjectAsc = _.cloneDeep(queryObjectDesc)
  queryObjectAsc.query_object.sort_order = 'asc'

  try {
    const pagination = `?limit=100&offset=0`

    const [respDesc, respAsc] = yield all([call(Api.getPeiScoreProduct, pagination, { ...queryObjectDesc }), call(Api.getPeiScoreProduct, pagination, { ...queryObjectAsc })])
    const productsDesc = respDesc.data.sort((a, b) => a.product_pei - b.product_pei)
    const productsAsc = respAsc.data.sort((a, b) => a.product_pei - b.product_pei)

    const marketplacetrendData = {}
    marketplacetrendData.positive = productsDesc.reverse()
    marketplacetrendData.negative = productsAsc
    yield put(storePeiScoreProduct(marketplacetrendData))
    yield put(setMarketplaceTrendLoadingState(false))
  } catch (err) {
    yield put(setMarketplaceTrendLoadingState(false))
  }
}

export default function* watchMarketplaceTrend() {
  yield all([takeLatest(REQUEST_PEI_SCORE_PRODUCT, getPeiScoreProduct)])
}
