const prefix = 'peiscorecard'

export const REQUEST_PEI_SCORECARD = `${prefix}/request_pei_scorecard`
export const RECEIVE_PEI_SCORECARD = `${prefix}/receive_pei_scorecard`
export const REQUEST_PEI_BY_RETAILER = `${prefix}/request_pei_by_retailer`
export const RECEIVE_PEI_BY_RETAILER = `${prefix}/receive_pei_by_retailer`

export const SET_PEI_SCORECARD_LOADING_STATE = `${prefix}/set_pei_scorecard_loading_state`
export const SET_PEI_BY_RETAILER_LOADING_STATE = `${prefix}/set_pei_by_retailer_loading_state`
export const SET_FILTER_SELECTION = `${prefix}/set_filter_selection`
export const SET_ORIGINAL_FILTER_SELECTION = `${prefix}/set_original_filter_selection`
export const SET_TIME_PERIOD = `${prefix}/set_time_period`
