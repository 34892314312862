import React, { Component } from 'react'
import { Icon, Popover } from 'antd'
import PropTypes from 'prop-types'
import { FlagForm } from './flag-form'

class PopOver extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleOnCancel = this.handleOnCancel.bind(this)
  }

  handleOnSubmit(index, reviewId, reviewExternalId, comment) {
    // force re-render the popover, so the popover can close correctly after submit/cancel
    this.forceUpdate()
    this.props.onSubmit(index, reviewId, reviewExternalId, comment)
  }

  handleOnCancel(index) {
    this.forceUpdate()
    this.props.onCancel(index)
  }

  render() {
    const { index, reviewId, reviewExternalId, visible } = this.props
    return (
      <Popover
        content={<FlagForm index={index} reviewId={reviewId} reviewExternalId={reviewExternalId} onSubmit={this.handleOnSubmit} onCancel={this.handleOnCancel} />}
        title="Hearful"
        trigger="click"
        placement="left"
        visible={visible}
      >
        <Icon type="flag" />
        &nbsp;&nbsp;Flag Review
      </Popover>
    )
  }
}

PopOver.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  reviewId: PropTypes.number.isRequired,
  reviewExternalId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}

export default PopOver
