import _ from 'lodash'

//returns an array of 3 most current years and the current month
export const getYears = () => {
  const today = new Date()
  const thisYear = today.getFullYear()
  const thisMonth = today.getMonth()
  const thisDate = today.getDate()
  const years = [thisYear, thisYear - 1, thisYear - 2]

  return { years, thisMonth, thisDate }
}

export const formatVarianceData = resArr => {
  const getYears = () => {
    const today = new Date()
    const thisYear = today.getFullYear()
    const thisMonth = today.getMonth()
    const thisDate = today.getDate()
    const years = [thisYear, thisYear - 1, thisYear - 2]

    return { years, thisMonth, thisDate }
  }
  const { years, thisMonth, thisDate } = getYears()
  let uniqueSources = []
  let sourceObj = {}
  resArr.map(({ name }) => {
    if (!uniqueSources.includes(name)) {
      uniqueSources.push(name)
      sourceObj[name] = []
    }
  })

  //adds only relevant years to dataObj
  resArr.map(sObj => {
    if (years.includes(sObj.review_year)) {
      let keyName = sObj.name
      sourceObj[keyName].push({
        year: sObj.review_year,
        data: [sObj.mon_1, sObj.mon_2, sObj.mon_3, sObj.mon_4, sObj.mon_5, sObj.mon_6, sObj.mon_7, sObj.mon_8, sObj.mon_9, sObj.mon_10, sObj.mon_11, sObj.mon_12],
      })
    }
  })
  //checks that all sources have all applicable years of data
  let editedSrcObj = {}
  for (const src in sourceObj) {
    if (sourceObj[src].length === years.length) {
      editedSrcObj[src] = sourceObj[src]
    }
  }

  //sorts years so all sourceObj's are in the same order
  let sortedSourceObj = {}
  for (const src in editedSrcObj) {
    editedSrcObj[src].sort((a, b) => (a.year < b.year ? 1 : a.year > b.year ? -1 : 0))
    sortedSourceObj[src] = editedSrcObj[src]
  }

  let dataObj = {}
  let mon
  if (document.location.href === 'https://portal.hearfulhub.com/dashboard/insights/review_variance') {
    mon = thisDate <= 15 ? thisMonth - 1 : thisMonth
  } else {
    mon = thisMonth
  }

  for (const src in sortedSourceObj) {
    const srcDataArr = []
    if (!_.isEmpty(sortedSourceObj[src])) {
      for (let i = 0; i < mon; i++) {
        if (sortedSourceObj[src][0].year === years[0] && sortedSourceObj[src][1].year === years[1]) {
          srcDataArr.push([sortedSourceObj[src][1].data[i], sortedSourceObj[src][0].data[i]])
        }
      }
      for (let i = mon; i < 12; i++) {
        if (sortedSourceObj[src][1].year === years[1] && sortedSourceObj[src][2].year === years[2]) {
          srcDataArr.push([sortedSourceObj[src][2].data[i], sortedSourceObj[src][1].data[i]])
        }
      }
    }
    if (!_.isEmpty(srcDataArr) && !(src in dataObj)) {
      dataObj[src] = srcDataArr
    }
  }

  const finalArr = []
  for (const x in dataObj) {
    const src = dataObj[x]
    const srcObj = {
      source: x,
      key: x,
    }
    src.map((data, i) => {
      const count = [data[0], data[1]]
      let variance = []
      let moOverMo = ''
      if (mon === i) {
        let prevMo = i > 0 ? src[i - 1][0] : src[src.length - 1][0]
        moOverMo = Math.round((data[1] / prevMo) * 100) - 100
      } else if (i === 0) {
        moOverMo = Math.round((data[1] / src[src.length - 1][1]) * 100) - 100
      } else {
        moOverMo = Math.round((data[1] / src[i - 1][1]) * 100) - 100
      }
      const yrOverYr = Math.round((data[1] / data[0]) * 100) - 100
      variance = [yrOverYr, moOverMo]
      switch (i) {
        case 0:
          srcObj['jan'] = { count, variance }
          break
        case 1:
          srcObj['feb'] = { count, variance }
          break
        case 2:
          srcObj['mar'] = { count, variance }
          break
        case 3:
          srcObj['apr'] = { count, variance }
          break
        case 4:
          srcObj['may'] = { count, variance }
          break
        case 5:
          srcObj['jun'] = { count, variance }
          break
        case 6:
          srcObj['jul'] = { count, variance }
          break
        case 7:
          srcObj['aug'] = { count, variance }
          break
        case 8:
          srcObj['sep'] = { count, variance }
          break
        case 9:
          srcObj['oct'] = { count, variance }
          break
        case 10:
          srcObj['nov'] = { count, variance }
          break
        case 11:
          srcObj['dec'] = { count, variance }
          break
      }
      srcObj.comparison = { count: ['2021', '2022'], variance: ['Yr/Yr', 'Mo/Mo'] }
    })
    finalArr.push(srcObj)
  }
  return finalArr
}

export const buildFilterSelectionObjectReviewVariance = filterSelection => {
  if (_.isEmpty(filterSelection)) {
    return {
      filter_object: {},
    }
  }
  const queryObject = {}
  Object.keys(filterSelection).forEach(key => {
    if (
      !_.isEmpty(filterSelection[key]) &&
      (key === 'sub_brand' || key === 'brand' || key === 'subcategory' || key === 'category' || key === 'quality_category' || key === 'department' || key === 'source')
    ) {
      const valueArr = _.isUndefined(filterSelection[key].value) ? filterSelection[key] : filterSelection[key].value
      if (_.isEmpty(valueArr)) {
        return
      }
      if (_.isNaN(_.toNumber(valueArr[0]))) {
        queryObject[key] = `('${valueArr.join("','")}')`
      } else {
        queryObject[key] = `(${valueArr.join(',')})`
      }
    }
  })

  return {
    filter_object: queryObject,
  }
}

export const buildQueryObjectReviewVariance = filterSelection => {
  const filterSelectionObject = buildFilterSelectionObjectReviewVariance(filterSelection)

  return {
    ...filterSelectionObject,
  }
}
