import React from 'react'
import ReactEcharts from 'echarts-for-react'
import PropTypes from 'prop-types'
import { default as config } from '../constants/sparkline-config'

/**
 * [Linechart description]
 * @param {number}  height    echart canvas height
 * @param {array}   series    echart config series
 * @param {object}  legend    echart config legend (show, data)
 * @param {string}  text      echart config title
 * @param {array}   xAxisData echart config xAxis data
 * @param {array}   color     echart config line color
 * @param {[type]}  }         [description]
 */
const Linechart = ({ height, series, legend, text, xAxisData, color }) => {
  const getConfig = () => ({
    ...config,
    series,
    legend: {
      ...config.legend,
      ...legend,
    },
    title: {
      ...config.title,
      text,
    },
    xAxis: {
      ...config.xAxis,
      data: xAxisData,
    },
    color,
  })

  return (
    <ReactEcharts
      option={getConfig()}
      notMerge
      lazyUpdate={false}
      style={{
        width: '100%',
        height,
      }}
    />
  )
}

Linechart.defaultProps = {
  height: 300,
}

Linechart.propTypes = {
  height: PropTypes.number,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legend: PropTypes.shape({}).isRequired,
  text: PropTypes.string.isRequired,
  xAxisData: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Linechart
