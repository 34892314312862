import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TreeSelect } from 'antd'
import './tree-select.css'

const { SHOW_PARENT } = TreeSelect
const predefinedConfig = {
  allowClear: true,
  treeCheckable: true,
  size: 'large',
  showCheckedStrategy: SHOW_PARENT,
  placeholder: 'No Filter',
  style: {
    width: '100%',
  },
  treeNodeFilterProp: 'label',
  dropdownStyle: { maxHeight: 300, overflow: 'auto' },
  epic: false,
}

const MyTreeSelect = ({ config: { id, title, data, value, onChange, ...restConfig } }) => {
  let combinedConfig = { ...predefinedConfig, ...restConfig }
  if (_.isArray(value)) {
    combinedConfig = { ...combinedConfig, value }
  }
  return (
    <div className="filter-wrapper">
      <h6 className="filter-name" style={!combinedConfig.epic ? null : { color: '#fff' }}>
        {title || ''}
      </h6>
      <div className="filters">
        <div id={id} className="filter">
          <TreeSelect
            // Disable the search function in filter selection
            // ref={() =>
            //   document
            //     .getElementById(id)
            //     .getElementsByClassName('ant-select-search__field')[0]
            //     .setAttribute('readOnly', 'readOnly')
            // }
            {...combinedConfig}
            treeData={data}
            onChange={onChange}
            getPopupContainer={() => document.getElementById(id)}
          />
        </div>
      </div>
    </div>
  )
}

MyTreeSelect.defaultProps = {}

MyTreeSelect.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
  }).isRequired,
}

export default MyTreeSelect
