import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { Spin } from 'antd'
import { EmptyStateInstruction } from '../../common'
import config from './config'
import './review.css'

export class FrequencyHistogram extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  getConfig() {
    const { words } = this.props
    const { pos_words: posWords, neg_words: negWords } = words
    const series = [
      {
        name: 'Positive',
        type: 'bar',
        stack: 'frequency',
        label: {
          normal: {
            show: true,
            formatter: params => params.data.name,
            position: 'right',
          },
        },
        data: _.sortBy(posWords, 'value'),
        itemStyle: {
          normal: {
            color: '#2ecc71',
          },
        },
      },
      {
        name: 'Negative',
        type: 'bar',
        stack: 'frequency',
        label: {
          normal: {
            show: true,
            position: 'left',
            formatter: params => params.data.name,
          },
        },
        data: _.sortBy(negWords, o => -o.value),
        itemStyle: {
          normal: {
            color: '#e74c3c',
          },
        },
      },
    ]
    return {
      ...config,
      series,
    }
  }

  render() {
    const { words, isChartLoading } = this.props

    if (_.isEmpty(words) && !isChartLoading) {
      const instruction = 'No Data.'
      return (
        <div className="frequency-histogram">
          <EmptyStateInstruction instruction={instruction} />
        </div>
      )
    }

    if (_.isEmpty(words) && isChartLoading) {
      return (
        <Spin tip="Loading..." spinning>
          <div className="frequency-histogram" />
        </Spin>
      )
    }

    return (
      <Spin tip="Loading..." spinning={isChartLoading}>
        <ReactEcharts option={this.getConfig()} notMerge={false} lazyUpdate={false} className="frequency-histogram" style={{ height: 400 }} />
      </Spin>
    )
  }
}

FrequencyHistogram.defaultProps = {
  isChartLoading: false,
}

function mapStateToProps(state) {
  return {
    words: state.review.words,
    isChartLoading: state.review.isChartLoading,
  }
}

FrequencyHistogram.propTypes = {
  words: PropTypes.shape({}).isRequired,
  isChartLoading: PropTypes.bool,
}

export default connect(mapStateToProps, {})(FrequencyHistogram)
