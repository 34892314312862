import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Rate } from 'antd'

class Rating extends Component {
  constructor(props) {
    super(props)

    this.state = {
      rating: props.defaultValue, // default value
    }
    this.handleRatingChange = this.handleRatingChange.bind(this)
  }

  handleRatingChange(rating) {
    const { onChange } = this.props
    if (onChange) {
      onChange(rating)
    }
    this.setState({ rating })
  }

  render() {
    const { className, style, value, count, allowHalf, defaultValue, disabled, onChange, showText } = this.props
    const { rating } = this.state
    return (
      <span className={className}>
        <Rate allowHalf={allowHalf} defaultValue={defaultValue} value={value || rating} onChange={this.handleRatingChange} count={count} style={style} disabled={disabled} />
        {showText && <span className="ant-rate-text">{value || rating} stars</span>}
      </span>
    )
  }
}

Rating.defaultProps = {
  allowHalf: false,
  showText: false,
  defaultValue: 0,
  style: {},
  className: '',
  onChange: null,
  value: undefined,
  count: 5,
  disabled: false,
}

Rating.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  allowHalf: PropTypes.bool,
  showText: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  count: PropTypes.number,
}

export default Rating
