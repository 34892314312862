export const clientCompetitorLineChartColor = ['#ffffff', '#fbc531']
export const linechartColor = [
  '#1f77b4', // med blue
  '#aec7e8', // light blue
  '#ff7f0e', // orange
  '#ffbb78', // light orange
  '#2ca02c', // green
  '#98df8a', // light green
  '#d62728', // red
  '#ff9896', // salmon
  '#9467bd', // purple
  '#c5b0d5', // light purple
  '#8c564b', // brown
  '#c49c94', // light brown
  '#e377c2', // pink
  '#7f7f7f', // gray
  '#c7c7c7', // light gray
  '#bcbd22', // yellow-green
  '#ff00ea', // hot pink
  '#dbdb8d', // light yellow-green
  '#17becf', // carolina blue
  '#9edae5', // unc blue
  '#eaff00', // yellow
  '#95ff00', // yellow green
  '#9400ff', // bright purple
]

export const piechartColor = ['#81ecec', '#0984e3', '#ff7a45', '#8c8c8c', '#fbc531']

export const scatterChartColor = [
  '#1f77b4', // med blue
  '#ffbb78', // light orange
  '#98df8a', // light green
  '#ff9896', // salmon
  '#c5b0d5', // light purple
  '#8c564b', // brown
  '#c7c7c7', // light gray
  '#c49c94', // light brown
  '#e377c2', // pink
  '#aec7e8', // light blue
  '#bcbd22', // yellow-green
  '#7f7f7f', // gray
  '#9467bd', // purple
  '#2ca02c', // green'
  '#ff006b', // fuschia
  '#dbdb8d', // light yellow-green
  '#d62728', // red
  '#17becf', // carolina blue
  '#ff7f0e', // orange
  '#9edae5', // unc blue
  '#eaff00', // yellow
  '#95ff00', // yellow green
  '#9400ff', // bright purple
  '#ff00ea', // hot pink
  '#ff006b', // fuschia
]

export const heatmapColor = [
  ['#e6f7ff', '#40a9ff', '#002766'], // skyblue, unc blue, dark purple
  ['#22075e', '#13c2c2', '#ffec3d'], // med purple, teal blue, yellow
  ['#ff7875', '#ffe58f', '#73d13d'], // salmon, pale yellow, green
]
