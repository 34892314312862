import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import _ from 'lodash'

import { TreeSelect } from '../../filter'
import { customizer, getAllCheckedChildrenNodes, getPartiallySelectedParentValues } from '../constants/helper'
import filters from '../constants/filter_tree_dummy'
import { NoFilter } from '../../common'
import './insights.css'

export class LeftWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: [],
    }

    this.onChange = this.onChange.bind(this)
    this.handleResetFilter = this.handleResetFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  onChange = value => {
    this.setState({ value }, () => console.log('value', this.state.value))
  }

  handleSubmit() {
    // fetch reviews if it is on review explorer page
    // this.props.onSubmit();
    console.log('handleSubmit is called')
  }

  handleResetFilter() {
    this.setState({ value: [] })
  }

  render() {
    const { isFilterHidden } = this.props
    if (_.isEmpty(filters)) {
      return (
        <div className="left-wrapper">
          <NoFilter />
        </div>
      )
    }
    const { department } = filters
    const departmentTree = []
    department.forEach(d => {
      departmentTree.push(
        <TreeSelect
          config={{
            id: `${d.key}`,
            data: d.children,
            value: this.state.value,
            title: `${d.label}`,
            onChange: this.onChange,
          }}
        />,
      )
    })
    return (
      <div className={`${isFilterHidden ? 'ml-neg-sidebar' : 'ml-0'} left-wrapper`}>
        <Button onClick={this.handleSubmit} className="filter-set-submit">
          Submit
        </Button>
        <div className="filter-set-title">Filter Set</div>
        <div className="span-link reset-filters" onClick={this.handleResetFilter}>
          Clear Filters
        </div>

        {departmentTree}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { currentViewName } = ownProps
  return {
    filterSelection: state[currentViewName].filterSelection,
    //ex: state.insights.filterSelection or state.peiScorecard.filterSelection -- whatever is in the LeftWrapper props on insights
    pageSize: state.review.pageSize,
    pageNum: state.review.pageNum,
    searchParams: state.review.searchParams,
    isFilterHidden: state.insights.isFilterHidden,
  }
}

LeftWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFilterHidden: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, {})(LeftWrapper)
