import React from 'react'
import { Progress } from 'antd'

const DownloadProgressBar = props => {
  const { downloadPercentage } = props

  return (
    <div className="download-progress-bar">
      <Progress percent={downloadPercentage} size="small" strokeColor="#006699" />
    </div>
  )
}

export default DownloadProgressBar
