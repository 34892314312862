import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import { Button, Tooltip, Input, Select, Tag, Popover, Icon } from 'antd'
import { getAdditionalFilters } from '../../insights'
import { setSearchParams, getAllReviews, getReviews, getPEI, getWords } from '../actions'
import { searchTypes, logicParams } from '../constants/textSearch'
import FrequencyHistogram from './frequency-histogram'
import DownloadProgressBar from './download-progress-bar'
import PEI from './pei'
import './review.css'

export class ReviewControls extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
      loading: false,
    }

    this.handleTextSearchParamSelect = this.handleTextSearchParamSelect.bind(this)
    this.handleTextSearch = this.handleTextSearch.bind(this)
    this.handleDeleteKeyWords = this.handleDeleteKeyWords.bind(this)
    this.handleClearTextSearch = this.handleClearTextSearch.bind(this)
    this.handleGetWords = this.handleGetWords.bind(this)
    this.buildCSVData = this.buildCSVData.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleTextSearchParamSelect(newSearchParams) {
    this.props.setSearchParams(newSearchParams)
  }

  handleClearTextSearch(fieldOfSearch) {
    // clear keyWords
    const { searchParams, filterSelection, pageSize, additionalParams } = this.props
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: { ...searchParams[fieldOfSearch], keyWords: [] },
    }
    this.props.setSearchParams(newSearchParams)
    // fetch reviews based on filter selection (pageNum = 1)
    this.props.getReviews(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
    this.props.getPEI(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
  }

  handleTextSearch(text) {
    const { filterSelection, pageSize, searchParams, additionalParams } = this.props
    // clean text in input box
    this.setState({ text: '' })
    // if the user typed "tight pants" in the search text
    // should split them into 2 separate words
    const rule = /\"(.*?)\"/g
    // trim text, abort searching if trimed text is empty or already exists
    const trimedText = text.trim().toLowerCase()
    if (_.isEmpty(trimedText)) {
      return
    }
    let curKeyWords = trimedText.match(rule)
    if (!curKeyWords) curKeyWords = [trimedText]
    curKeyWords.forEach((kw, i) => {
      curKeyWords[i] = _.replace(curKeyWords[i], new RegExp('"', 'g'), '')
    })
    // get current search type
    const { fieldOfSearch } = searchParams.searchType
    // union the new added key words (de-duplicate)
    const newKeyWords = _.union(searchParams[fieldOfSearch].keyWords, curKeyWords)
    if (_.isEqual(searchParams[fieldOfSearch].keyWords, newKeyWords)) {
      // terminate if the new added key words are exactly the same as the former key words list
      return
    }
    // insert keyWords
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: {
        ...searchParams[fieldOfSearch],
        keyWords: newKeyWords,
      },
    }
    this.props.setSearchParams(newSearchParams)
    // search reviews
    this.props.getReviews(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
    this.props.getPEI(
      {
        filterSelection,
        pageNum: 1,
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
  }

  handleDeleteKeyWords(keyWord, fieldOfSearch) {
    const { searchParams, filterSelection, pageSize, additionalParams } = this.props
    // remove this keyword from searchParams.keyWords array
    const newSearchParams = {
      ...searchParams,
      [fieldOfSearch]: {
        ...searchParams[fieldOfSearch],
        keyWords: searchParams[fieldOfSearch].keyWords.filter(word => word !== keyWord),
      },
    }
    this.props.setSearchParams(newSearchParams)
    // re-search reviews
    this.props.getReviews(
      {
        filterSelection,
        pageNum: 1, // reset to first page in case of "out-of-range"
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
    this.props.getPEI(
      {
        filterSelection,
        pageNum: 1, // reset to first page in case of "out-of-range"
        pageSize,
        searchParams: newSearchParams,
      },
      additionalParams,
    )
  }

  handleGetWords() {
    const { filterSelection, searchParams, additionalParams } = this.props
    this.props.getWords(
      {
        filterSelection,
        searchParams,
      },
      additionalParams,
    )
  }

  generateKeyWords(keyWords, logicParam, fieldOfSearch) {
    if (_.isEmpty(keyWords)) {
      return null
    }
    const keyWordsList = []
    keyWords.forEach((word, idx) => {
      if (idx !== keyWords.length - 1) {
        keyWordsList.push(
          <Tag className="keyword" key={word} closable onClose={() => this.handleDeleteKeyWords(word, fieldOfSearch)}>
            {word}
          </Tag>,
        )
        keyWordsList.push(
          <span key={`${logicParam.name}-${word}`} className="logic-param">
            {logicParam.name}
          </span>,
        )
      } else {
        keyWordsList.push(
          <Tag className="keyword" key={word} closable onClose={() => this.handleDeleteKeyWords(word, fieldOfSearch)}>
            {word}
          </Tag>,
        )
      }
    })
    return keyWordsList
  }

  buildCSVData() {
    const { allReviews } = this.props
    const data = []
    data.push(['#', 'Brand', 'Product', 'Review Stars', 'Submission Time', 'External ID', 'Review Title', 'Review Text'])
    for (let i = 0; i < allReviews.length; i++) {
      const { brand_meta, product_name, review_rating, review_submission_date, external_id, review_title, review_text } = allReviews[i]
      data.push([i + 1, brand_meta, product_name, review_rating, review_submission_date, external_id, review_title, review_text])
    }
    return data
  }

  csvLink = React.createRef()

  async handleOnClick() {
    this.setState({ loading: true })
    const { searchParams, filterSelection, pageSize, additionalParams } = this.props
    const promise = new Promise((resolve, reject) => {
      this.props.getAllReviews(
        {
          filterSelection,
          pageNum: 1, // reset to first page in case of "out-of-range"
          pageSize,
          searchParams,
        },
        additionalParams,
        resolve,
        reject,
      )
    })
    promise.then(() => {
      this.csvLink.current.link.click()
      this.setState({ loading: false })
    })
  }

  render() {
    const {
      searchParams,
      searchParams: { searchType, logicParam, product, review },
      areMetadataShown,
      areTagsEditable,
      toggleMetadata,
      toggleTagEditable,
      allReviews,
      downloadPercentage,
      pei,
      isPEILoading,
    } = this.props
    const { keyWords: reviewKeyWords } = review
    const { keyWords: productKeyWords } = product
    const { text } = this.state

    return (
      <div className="review-controls">
        <Input.Group compact className="mb-10 mr-10 text-search">
          <Tooltip title={searchType ? `Search In ${searchType.type}` : ''}>
            <Select
              onChange={value => {
                const curSearchType = searchTypes.find(eachSearchType => eachSearchType.type === value)
                this.handleTextSearchParamSelect({
                  ...searchParams,
                  searchType: curSearchType,
                  [curSearchType.fieldOfSearch]: {
                    ...searchParams[curSearchType.fieldOfSearch],
                    logicParam: searchParams.logicParam,
                  },
                })
              }}
              value={searchType.type}
              placeholder="Type"
            >
              {searchTypes.map(each => (
                <Select.Option key={each.id} value={each.type}>
                  {each.type}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
          <Tooltip title="Logical Operator Between Keywords">
            <Select
              onChange={value => {
                const {
                  searchType: { fieldOfSearch },
                } = searchParams
                const curLogicParam = logicParams.find(eachLogicParam => eachLogicParam.name === value)
                this.handleTextSearchParamSelect({
                  ...searchParams,
                  logicParam: curLogicParam,
                  [fieldOfSearch]: {
                    ...searchParams[fieldOfSearch],
                    logicParam: curLogicParam,
                  },
                })
              }}
              value={logicParam.name}
              placeholder="logic Parameter"
            >
              {logicParams.map(each => (
                <Select.Option key={each.id} value={each.name}>
                  {each.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
          <Input.Search
            value={text}
            placeholder={searchType ? `Search in ${searchType.type} ...` : 'Search ...'}
            style={{ width: 200 }}
            onChange={e => this.setState({ text: e.target.value })}
            onSearch={this.handleTextSearch}
          />
          <Tooltip
            placement="bottom"
            title={
              <span>
                Keywords or phrases can be searched.
                <br />
                <br />
                Separate multiple keywords or phrases with quotation marks, ex: "comfortable fit" "soft".
                <br />
                <br />
                Common words may be excluded from search.
              </span>
            }
          >
            <Icon className="help-icon" type="question-circle" theme="filled" />
          </Tooltip>
        </Input.Group>
        <Button.Group className="mb-10 mr-10">
          <Button onClick={toggleMetadata} icon={areMetadataShown ? 'shrink' : 'arrows-alt'}>
            {areMetadataShown ? 'Hide All' : 'Expand All'}
          </Button>
          {/* <Tooltip title="Flag All Reviews">
            <Button size="large" icon="heart" />
          </Tooltip> */}
          <Tooltip title={areTagsEditable ? 'Preview All Tags' : 'Edit All Tags'}>
            <Button onClick={toggleTagEditable} icon={areTagsEditable ? 'eye-o' : 'edit'} />
          </Tooltip>
        </Button.Group>
        <Popover content={<FrequencyHistogram />} placement="bottom" title="Text Histogram" trigger="click">
          <Button onClick={this.handleGetWords}>Text Histogram</Button>
        </Popover>
        {_.isEmpty(reviewKeyWords) ? null : (
          <div className="keywords-list">
            <span className="title">Key Words:&nbsp;</span>
            {this.generateKeyWords(reviewKeyWords, review.logicParam, 'review')}
            <span className="search-type-desc">by Review Search</span>
            <span className="span-link clear-search" onClick={() => this.handleClearTextSearch('review')}>
              Clear Search
            </span>
          </div>
        )}
        {_.isEmpty(productKeyWords) ? null : (
          <div className="keywords-list">
            <span className="title">Key Words:&nbsp;</span>
            {this.generateKeyWords(productKeyWords, product.logicParam, 'product')}
            <span className="search-type-desc">by Product Search</span>
            <span className="span-link clear-search" onClick={() => this.handleClearTextSearch('product')}>
              Clear Search
            </span>
          </div>
        )}
        <Button type="primary" className="download-csv" onClick={this.handleOnClick} loading={this.state.loading}>
          Download CSV
        </Button>
        <span className="pei-score">{isPEILoading ? <Spin tip="PEI loading..." spinning={true} /> : <PEI pei={pei} />}</span>
        {allReviews.length ? <CSVLink filename="review-explorer.csv" data={this.buildCSVData()} className="hidden" ref={this.csvLink} target="_blank" /> : null}
        {downloadPercentage > 0 ? <DownloadProgressBar downloadPercentage={downloadPercentage} /> : ''}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { currentViewName } = ownProps
  return {
    filterSelection: state[currentViewName].filterSelection,
    additionalParams: getAdditionalFilters(state.insights),
    searchParams: state.review.searchParams,
    pageSize: state.review.pageSize,
    allReviews: state.review.allReviews,
    downloadPercentage: state.review.downloadPercentage,
    pei: state.review.pei,
    isPEILoading: state.review.isPEILoading,
  }
}

ReviewControls.propTypes = {
  filterSelection: PropTypes.shape({}).isRequired,
  additionalParams: PropTypes.shape({}).isRequired,
  searchParams: PropTypes.shape({
    searchType: PropTypes.shape({}).isRequired,
    logicParam: PropTypes.shape({}).isRequired,
    review: PropTypes.shape({}).isRequired,
    product: PropTypes.shape({}).isRequired,
  }).isRequired,
  downloadPercentage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  areMetadataShown: PropTypes.bool.isRequired,
  areTagsEditable: PropTypes.bool.isRequired,
  setSearchParams: PropTypes.func.isRequired,
  getAllReviews: PropTypes.func.isRequired,
  getReviews: PropTypes.func.isRequired,
  getPEI: PropTypes.func.isRequired,
  getWords: PropTypes.func.isRequired,
  toggleMetadata: PropTypes.func.isRequired,
  toggleTagEditable: PropTypes.func.isRequired,
  isPEILoading: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, {
  setSearchParams,
  getAllReviews,
  getReviews,
  getPEI,
  getWords,
})(ReviewControls)
