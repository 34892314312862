import _ from 'lodash'
import excellent from './excellent.svg'
import nice from './nice.svg'
import warning from './warning.svg'
import danger from './danger.svg'
import risk from './risk.svg'
import safety from './safety.svg'
import tie from './tie.svg'

export const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.union(objValue, srcValue)
  }
}

// export const getAllCheckedChildrenNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => _.isEmpty(checkedNode.children));
//
// export const getAllCheckedParentNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => !_.isEmpty(checkedNode.children));

// The following approach (using parentValue) is buggy when user click "delete" icon on the
// selected child option, it will return the checkedNode which only has "value", "key" in its props.
// no parentValue

// 05/17/2018 Enable search in filterSelection, still waiting for antd team to fix the issue
// fix temporarily by deal with two different data format
export const getAllCheckedGrandChildrenNodes = allCheckedNodes =>
  _.filter(allCheckedNodes, checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return !_.isUndefined(checkedNode.node.props.parentValue) && _.isEmpty(checkedNode.children)
    }
    return !_.isUndefined(checkedNode.props.parentValue) && _.isEmpty(checkedNode.props.children)
  })

export const getAllCheckedChildrenNodes = allCheckedNodes =>
  _.filter(allCheckedNodes, checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return !_.isUndefined(checkedNode.node.props.parentValue) && !_.isEmpty(checkedNode.children)
    }
    return !_.isUndefined(checkedNode.props.parentValue) && !_.isEmpty(checkedNode.props.children)
  })

export const getAllCheckedParentNodes = allCheckedNodes =>
  _.filter(allCheckedNodes, checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return _.isUndefined(checkedNode.node.props.parentValue)
    }
    return _.isUndefined(checkedNode.props.parentValue)
  })

// export const getAllCheckedChildrenNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => !_.isUndefined(checkedNode.node.props.parentValue));
//
// export const getAllCheckedParentNodes = allCheckedNodes =>
//   _.filter(allCheckedNodes, checkedNode => _.isUndefined(checkedNode.node.props.parentValue));

export const getParentChildValues = allCheckedNodes => {
  const allCheckedParentNodes = getAllCheckedParentNodes(allCheckedNodes)
  const allCheckedChildrenNodes = getAllCheckedChildrenNodes(allCheckedNodes)
  const allCheckedGrandChildrenNodes = getAllCheckedGrandChildrenNodes(allCheckedNodes)

  // parent values whose children are fully selected
  const parentValues = allCheckedParentNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return {
        value: checkedNode.node.props.value,
        title: checkedNode.node.props.title,
      }
    }
    return {
      value: checkedNode.props.value,
      title: checkedNode.props.title,
    }
  })

  // children values
  const childrenValues = allCheckedChildrenNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return {
        value: checkedNode.node.props.value,
        title: checkedNode.node.props.title,
      }
    }
    return {
      value: checkedNode.props.value,
      title: checkedNode.props.title,
    }
  })

  const grandChildrenValues = allCheckedGrandChildrenNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return {
        value: checkedNode.node.props.value,
        title: checkedNode.node.props.title,
      }
    }
    return {
      value: checkedNode.props.value,
      title: checkedNode.props.title,
    }
  })

  return [parentValues, childrenValues, grandChildrenValues]
}

// TODO cannot get parentValue when user clicking X to remove item
export const getPartiallySelectedParentValues = allCheckedChildrenNodes => {
  const parentValuesPartial = allCheckedChildrenNodes.map(checkedNode => {
    if (!_.isUndefined(checkedNode.node)) {
      return checkedNode.node.props.parentValue
    }
    return checkedNode.props.parentValue
  })
  // de-duplicate
  return _.uniq(parentValuesPartial)
}

export const getAttrAttrFilter = (destArrOfObj, schemaObj) => {
  const { attributeValue, attribute } = destArrOfObj
  const newArrOfObj = []
  const keys = Object.keys(schemaObj)
  for (let i = 0; i < keys.length; i++) {
    const attr = attribute[keys[i]]
    newArrOfObj[i] = { ...attr, children: [] }
    const childKeys = schemaObj[keys[i]]
    for (let j = 0; j < childKeys.length; j += 1) {
      newArrOfObj[i].children[j] = { ...attributeValue[childKeys[j]] }
    }
  }
  return newArrOfObj
}

export const getProcessedAttrVal = (selectedAttrVal, newMergedObj) => {
  let processedAttrVal = []
  if (!_.isEmpty(selectedAttrVal)) {
    const newAttrValArr = _.concat(...Object.values(newMergedObj))
    // (the data type in the cat_att_attval should be string but it is integer)
    processedAttrVal = selectedAttrVal.value.filter(each => _.includes(newAttrValArr, each))
  }
  return processedAttrVal
}

/**
 * inject UI params recursively
 * @param  {array} destArrOfObj the destination array of object
 * @param  {array} srcArrOfObj the source array of object
 * @param  {object} keyValueObj object that k:v pair to be injected into each object
 * @return {object} new object injected with keyValueObj
 */
// const injectUIParams = (srcArrOfObj, keyValueObj, leafOnlyKeyValueObj = {}) => {
//   if (!_.isArray(srcArrOfObj) || _.isEmpty(srcArrOfObj)) {
//     return [];
//   }
//   const newArrOfObj = [];
//   srcArrOfObj.forEach((eachObj, idx) => {
//     if (_.isEmpty(eachObj.children)) {
//       newArrOfObj[idx] = { ...eachObj, ...keyValueObj, ...leafOnlyKeyValueObj };
//     } else {
//       newArrOfObj[idx] = { ...eachObj, ...keyValueObj };
//       newArrOfObj[idx].children = injectUIParams(eachObj.children, keyValueObj, leafOnlyKeyValueObj);
//     }
//   });
//   return newArrOfObj;
// };
export const injectUIParams = (srcArrOfObj, keyValueObj) => {
  if (!_.isArray(srcArrOfObj) || _.isEmpty(srcArrOfObj)) {
    return []
  }
  const newArrOfObj = []
  srcArrOfObj.forEach((eachObj, idx) => {
    newArrOfObj[idx] = { ...eachObj, ...keyValueObj, children: [] }
    newArrOfObj[idx].children = injectUIParams(eachObj.children, keyValueObj)
    if (_.isEmpty(newArrOfObj[idx].children)) {
      delete newArrOfObj[idx].children
    }
  })
  return newArrOfObj
}

export const icons = {
  excellent,
  nice,
  'needs improvement': warning,
  risk: danger,
  underperforming: risk,
  outperforming: safety,
  neutral: tie,
}

export const convertFromCompetitorSentiment = cs => {
  switch (cs) {
    case 1:
      return 'underperforming'
    case 2:
      return 'neutral'
    case 3:
      return 'outperforming'
    default:
      return ''
  }
}

// convert average sentiment to context name
// face
export const convertFromAvgSentiment = s => {
  switch (s) {
    case 1:
      return 'risk'
    case 2:
      return 'needs improvement'
    case 3:
      return 'nice'
    case 4:
      return 'excellent'
    default:
      return ''
  }
}

/**
 * parse data of forum count
 * @param  {string} range
 * @return {array}
 */
export const parseRange = (total, responseURL) => {
  const countMin = parseInt(responseURL.split('offset=')[1])
  const limit = parseInt(responseURL.split('limit=')[1].split('&')[0])
  const countMax = countMin + limit
  let countArr = [countMin, countMax, parseInt(total)]
  if (countArr.length !== 3) {
    countArr = [0, 0, 0]
  } else {
    countArr[0] += 1
    countArr[1] += 1
  }

  return countArr
}

export const buildFilterSelectionObject = filterSelection => {
  if (_.isEmpty(filterSelection)) {
    // Object.keys cannot accept undefined or null as input
    return {
      filter_object: {},
    }
  }
  const queryObject = {}
  Object.keys(filterSelection).forEach(key => {
    if (!_.isEmpty(filterSelection[key])) {
      const titleArr = _.isUndefined(filterSelection[key].title) ? filterSelection[key] : filterSelection[key].title
      if (_.isEmpty(titleArr)) {
        return
      }
      if (key === 'brand') {
        queryObject['possible_brands'] = titleArr
      } else {
        queryObject[key] = titleArr
      }
    }
  })
  return {
    filter_object: queryObject,
  }
}

export const buildSearchObject = searchParams => {
  const { forum, tag } = searchParams
  const searchObject = {}
  if (!_.isEmpty(forum) && !_.isEmpty(forum.keyWords)) {
    searchObject.search_forum = forum.keyWords.join(forum.logicParam.character)
  }
  if (!_.isEmpty(tag) && !_.isEmpty(tag.keyWords)) {
    searchObject.search_tag = tag.keyWords.join(tag.logicParam.character)
  }

  return { search_object: searchObject }
}

export const buildQueryObject = (filterSelection, searchParams) => {
  const filterSelectionObject = buildFilterSelectionObject(filterSelection)
  const searchObject = buildSearchObject(searchParams)
  return {
    ...filterSelectionObject,
    ...searchObject,
  }
}
