import React from 'react'
import PropTypes from 'prop-types'
import { Slider } from 'antd'
import _ from 'lodash'

const predefinedConfig = {
  min: 0,
  max: 100,
  range: true,
  dots: true,
}

const MySlider = ({ config: { title, value, tipFormatter, onChange, ...restConfig } }) => {
  let combinedConfig = { ...predefinedConfig, ...restConfig }
  if (!_.isEmpty(value) && _.isArray(value)) {
    combinedConfig = { ...combinedConfig, value }
  }
  if (!_.isUndefined(tipFormatter)) {
    combinedConfig = { ...combinedConfig, tipFormatter }
  }
  return (
    <div className="filter-wrapper">
      <h6 className="filter-name">{title || ''}</h6>
      <Slider
        // need get the range of the review rating from filter
        {...combinedConfig}
        onChange={valArr => onChange(valArr)}
      />
    </div>
  )
}

MySlider.defaultProps = {}

MySlider.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

export default MySlider
