const prefix = 'insights'

export const REQUEST_FILTERS = `${prefix}/request_filters`
export const RECEIVE_FILTERS = `${prefix}/receive_filters`
export const REQUEST_FORUM_FILTERS = `${prefix}/request_forum_filters`
export const RECEIVE_FORUM_FILTERS = `${prefix}/receive_forum_filters`
export const REQUEST_CUSTOM_ATTRIBUTES = `${prefix}/request_custom_attributes`

export const SET_FILTER_SELECTION = `${prefix}/set_filter_selection`
export const SET_FORUM_FILTER_SELECTION = `${prefix}/set_forum_filter_selection`
export const SET_FILTER_ISHIDDEN = `${prefix}/set_filter_ishidden`
export const SET_ADDITIONAL_PARAMS = `${prefix}/set_additional_params`
